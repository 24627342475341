.d-index-utp
  position: relative
  & .swiper
    @include bp($tablet)
      padding: 0 24px
    @include bp($phone)
      padding: 0 12px
  &._loop
    padding: 0 52px
    @include bp($tablet)
      padding: 0
    & .swiper
      padding: 0 12px 20px
      @include bp($tablet)
        padding: 0 24px
      @include bp($phone)
          padding: 0 12px
  @include bp($tablet)
    padding: 0
  &__slider
    border-radius: 32px
    @include bp($tablet)
      border-radius: 0

  &__arrow
    position: absolute
    top: 0
    width: 40px
    height: calc(100% - 20px)
    padding: 0
    background: $white-color
    border: none
    border-radius: 12px
    color: $pen-color
    cursor: pointer
    @include transition(color)
    @include bp($tablet)
      display: none
    @include hover
      color: $blue-color
    & svg
      fill: currentColor
    &._next
      right: 0
    &._prev
      left: 0
      & svg
        transform: rotate(180deg)

.d-index-utp-slide
  display: flex
  align-items: center
  background: $white-color
  border-radius: 32px
  padding: 32px
  position: relative
  @include bp($tablet)
    border-radius: 24px
    padding: 24px 16px
  @include bp($phone)
    flex-direction: column
    align-items: flex-start
    border-radius: 24px
    padding: 16px
  &.swiper-slide
    width: 100%
    height: 176px
    @include bp($tablet)
      width: 464px
      height: 128px
    @include bp($phone)
      width: 252px
      height: auto
  &__img
    flex-shrink: 0
    width: 88px
    height: 88px
    margin-right: 24px
    @include bp($tablet)
      width: 80px
      height: 80px
      margin-right: 8px
    @include bp($phone)
      width: 64px
      height: 64px
      margin: 0 0 12px
    & img
      @include objectFit(100%, contain)
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    @include bp($desktopXS)
      padding: 0
      //@include font-size-lh(14,18)
    @include bp($phone)
      flex-grow: 1
  &__name
    font-weight: 600
    margin-right: 32px
    @include transition(color)
    @include bp($desktopXS)
    @include font-size-lh(24,28)
    color: $pen-color
    max-height: 56px
    overflow: hidden
    @include bp($tablet)
      @include font-size-lh(20,24)
      max-height: 48px
      margin: 0 0 12px
  &__text
    max-height: 44px
    margin-top: 12px
    overflow: hidden
    @include bp($tablet)
      max-height: 66px
      margin-top: 8px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    border-radius: 32px
    z-index: 3
    @include transition(box-shadow)
    @include bp($phone)
      border-radius: 24px
    @include hover
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
