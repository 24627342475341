.d-gallery-popup
  left: 0
  top: 0
  height: 100%
  @include transition(all)
  pointer-events: none !important
  & *
    pointer-events: auto

  &__bg
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgba($black, 0.6)
    backdrop-filter: blur(20px)
    z-index: 1

  &__slider
    max-width: 100vw
    width: 100vw
    height: 100%
    padding: 0
    margin: 0 auto
    z-index: 2 !important

  &__slide
    & img
      @include objectFit(100%, contain)

  &__close, &__pagination, &__arrows, &__note
    position: absolute !important
    z-index: 3

  &__close, &__arrows
    right: 40px
    @include bp($tablet)
      right: 32px
    @include bp($phone)
      right: 12px

  &__close
    top: 40px
    @include bp($tablet)
      top: 32px
    @include bp($phone)
      top: 12px

  &__pagination, &__arrows
    bottom: 40px !important
    @include bp($tablet)
      bottom: 32px !important
    @include bp($phone)
      bottom: 12px !important

  &__pagination
    left: 40px !important
    @include bp($tablet)
      left: 32px !important
      bottom: auto !important
      top: 32px !important
    @include bp($phone)
      left: 12px !important
      top: 12px !important

  &__note
    display: flex
    max-width: 630px
    left: 40px
    bottom: 40px
    @include bp($tablet)
      left: 32px
      bottom: 32px
      max-width: 422px
    @include bp($phone)
      left: 12px
      bottom: 12px
      max-width: calc(100% - 128px)
    &._margin
      left: 130px
      @include bp($tablet)
        left: 32px
      @include bp($phone)
        left: 12px
    & .d-swipe-info button
      @include bp($phone)
        margin-left: 8px



.d-gallery-popup__slide .zoomist-container,
.d-gallery-popup__slide .zoomist-wrapper,
.d-gallery-popup__slide .zoomist-image
  width: 100%
  height: 100%

.d-gallery-popup__slide .zoomist-wrapper
  background: none

.d-gallery-popup__slide .zoomist-image img
  width: 100%
  height: 100%
  object-fit: contain
  object-position: center
