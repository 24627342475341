.ba-popup-content
  & .mfp-content
    max-width: 800px
    @include bp($tablet)
      width: 100%
      height: 100%
      max-width: 100%
    @include bp($phone)
      height: 100%
      width: 100%
  &__inner
    background: $white-color
    border-radius: 32px
    padding: 48px 68px
    position: relative
    color: $pen-color
    @include font-size-lh(18,22)
    @include bp($tablet)
      border-radius: 0
      height: 100%

    @include bp($phone)
      height: 100%
      border-radius: 0
      @include font-size-lh(14,18)
      padding: 40px 16px


  & .mfp-close
    background-color: #35b8fc
    opacity: 1
    border-radius: 50%
    color: $white-color !important
    transform: translate(-24px, 24px)
    outline: none
    @include bp($tablet)
      transform: translate(-16px, 16px)
    @include bp($phone)
      width: 32px
      height: 32px
      line-height: 32px
      transform: translate(-8px, 8px)