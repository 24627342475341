.d-visual-breadcrumbs
  display: flex
  align-items: center
  &__item
    margin-left: 20px
    color: $pen-color
    font-weight: 600
    position: relative
    @include font-size-lh(20,24)
    text-decoration: none
    @include bp($phone)
      @include font-size-lh(16,22)
    &:before
      content: ''
      width: 4px
      height: 4px
      border-radius: 50%
      background: $pen-color
      display: block
      position: absolute
      left: -8px
      top: 50%
      transform: translate(-100%, -50%)
    &:first-child
      margin-left: 0
      &:before
        display: none