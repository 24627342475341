.d-footer
  max-width: 1920px
  width: 100%
  padding: 40px
  margin: 0 auto
  @include bp($desktopXS)
    padding: 32px 24px
  @include bp($tablet)
    padding: 24px
  @include bp($phone)
    padding: 10px 12px 16px
  &__inner
    background: $white-color
    border-radius: 40px
    font-weight: 500
    @include bp($phone)
      border-radius: 24px
  &__top
    display: flex
    justify-content: space-between
    padding: 40px 40px 30px
    border-bottom: 1px solid $grey20
    color: $grey50
    @include bp($tablet)
      align-items: center
      padding-bottom: 40px
    @include bp($phone)
      flex-direction: column
      align-items: center
      padding: 24px 0 76px
      text-align: center
      position: relative
    &:before
      content: ''
      position: absolute
      left: 0
      top: 132px
      width: 100%
      height: 1px
      background: $grey20
      display: none
      @include bp($phone)
        display: block
  &__topLeft
    position: relative
    @include bp($phone)
      margin-bottom: 48px
    &._ny
      &:before
        content: ''
        position: absolute
        left: -15px
        top: -0px
        width: 44px
        height: 44px
        background: url('../assets/img/d/NY2024/snowhat.png') no-repeat center / contain
        //z-index: 2
        @include bp($tablet)
          top: -2px
  &__logo
    display: block
    width: 229px
    height: 60px
    margin: 2px 0 10px
    @include bp($tablet)
      margin-top: 0
  &__copy
    @include font-size-lh(12,15)
  &__phone
    display: inline-block
    font-weight: 600
    @include font-size-lh(48,52)
    color: $blue-color
    text-decoration: none
    @include transition(color)
    @include hover()
      color: $pen-color
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($tablet)
      @include font-size-lh(32,36)
  &__time, &__address
    text-align: right
    @include bp($phone)
      text-align: center
  &__time
    @include font-size-lh(14,18)
    margin-top: 4px
  &__address
    @include font-size-lh(16,22)
    font-style: normal
    color: $pen-color
    margin-top: 8px
    @include bp($tablet)
      @include font-size-lh(14,18)
  &__menu, &__bottom
    position: relative
    &:before
      content: ''
      position: absolute
      left: 368px
      top: 0
      width: 1px
      height: 100%
      background: $grey20
      @include bp($desktopS)
        display: none
  &__menu
    padding: 40px 72px 32px 416px
    @include bp($desktopS)
      padding: 40px
      border-bottom: 1px solid $grey20
    @include bp($phone)
      display: none
  &__bottom
    @include font-size-lh(12,14)
    color: $dark-grey
    padding: 0 48px 40px 416px
    @include bp($desktopS)
      display: flex
      align-items: center
      justify-content: space-between
      padding: 24px 40px
    @include bp($tablet)
      display: block
      padding-right: 126px
      padding-bottom: 32px
    @include bp($phone)
      padding: 16px 16px 24px
  &__partner
    position: absolute
    left: 40px
    top: -92px
    @include bp($desktopS)
      position: relative
      left: 0
      top: 0
      margin-right: 106px
    @include bp($desktopXS)
      margin-right: 42px
    @include bp($tablet)
      margin: 0 0 24px
    @include bp($phone)
      margin-bottom: 16px
  &__social
    position: absolute
    left: 40px
    bottom: 40px
    @include bp($desktopS)
      position: relative
      left: unset
      bottom: unset
      margin-left: 132px
    @include bp($desktopXS)
      margin-left: 36px
    @include bp($tablet)
      position: absolute
      right: 126px
      top: 24px
      margin: 0
    @include bp($phone)
      right: 50%
      top: -52px
      transform: translateX(50%)
    & .d-share
      @include bp($desktopXS)
        left: auto
        right: -10px
        transform: none
  &__note
    max-width: 1280px
    margin-right: auto
    @include bp($phone)
      text-align: center
    & a, & span
      text-decoration: underline
      text-underline-offset: 3px
      cursor: pointer
      @include transition(color)
      @include hover()
        color: $blue-color
  &__maker
    position: absolute
    right: 48px
    top: -26px
    color: $pen-color
    @include transition(color)
    @include hover()
      color: $blue-color
    @include bp($desktopS)
      position: relative
      right: 0
      top: unset
      margin-left: 100px
    @include bp($tablet)
      position: absolute
      right: 40px
      top: 24px
      margin: 0
    @include bp($phone)
      right: 22px
      top: 16px
    & svg
      width: 30px
      height: 30px
      fill: currentColor

  &._national
    & .d-footer
      &__top
        align-items: flex-start
        position: relative
        @include bp($tabletS)
          padding-bottom: 32px
        @include bp($phone)
          flex-direction: column
          align-items: center
          padding: 24px 12px
      &__partner
        position: relative
        left: 0
        top: 0
        margin: 20px 0 0
        @include bp($tabletS)
          display: none
          margin: 0
        &._mobile
          display: none
          @include bp($tabletS)
            display: flex
            position: absolute
            left: 40px
            top: 50%
            transform: translateY(-50%)
          @include bp($phone)
            left: 15px
            top: 16px
            transform: none
      &__logo
        position: absolute
        left: 50%
        top: 42px
        transform: translateX(-50%)
        width: 232px
        margin: 0
        @include bp($tabletS)
          position: relative
          left: 0
          top: 0
          transform: none
          margin-top: -10px
        @include bp($phone)
          margin-top: 0
      &__info
        display: flex
        flex-direction: column
        align-items: flex-end
        @include bp($phone)
          align-items: center
          margin-top: 73px
      &__social
        position: relative
        left: 0
        right: 0
        top: 0
        bottom: 0
        transform: none
      &__time
        margin-top: 8px
        @include bp($phone)
          margin-top: 16px
      &__address
        @include bp($phone)
          width: 260px
      &__bottom
        display: flex
        align-items: center
        padding: 24px 40px
        @include bp($phone)
          padding: 62px 12px 12px
        &:before
          display: none
      &__copy
        flex-shrink: 0
        width: 428px
        @include bp($desktopS)
          width: 268px
        @include bp($tabletS)
          position: absolute
          left: 40px
          bottom: calc(100% + 37px)
          width: auto
        @include bp($phone)
          display: none
          left: 0
          bottom: auto
          top: 94px
          width: 100%
          text-align: center
        &._mobile
          display: none
          @include bp($phone)
            display: block
      &__note
        max-width: 905px
        width: 100%
        @include bp($desktopXS)
          max-width: 678px
          margin-right: 40px
        @include bp($tabletS)
          margin-left: 204px
        @include bp($phone)
          margin: 0
        & p
          margin: 8px 0 0
      &__maker
        flex-shrink: 0
        position: relative
        right: 0
        top: 0
        @include bp($phone)
          position: absolute
          right: 22px
          top: 16px