.d-popup-simple
  width: 100%
  height: 100%
  position: relative
  &__overlay
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 0
  &._progress
    & .d-popup-simple__content
      display: flex
      align-items: center
      justify-content: center
  &._center
    & .d-popup-simple__content
      display: flex
      align-items: center
      justify-content: center
  &._contacts
    & .d-popup-simple__content
      width: 100%
      max-width: 720px
      height: auto
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      @include bp($phone)
        top: 0
        left: 0
        width: 100%
        height: 100%
        transform: none
    & .d-popup-simple__close
      top: 32px
      right: 32px
  &._type-single
    & .d-popup-simple
      &__content
        pointer-events: auto
      &__close
        @include bps($tablet)
          top: 24px
          right: 24px
        @include bps($phone)
          width: 32px
          height: 32px
          top: 12px
          right: 12px
  &._utp
    & .d-popup-simple__content
      width: 100%
      max-width: 720px
      height: auto
      min-height: 112px
      position: fixed
      top: 50%
      left: 50%
      background: $white-color
      margin: 32px auto
      padding: 32px
      border-radius: 40px
      transform: translate(-50%, -50%)
      color: $pen-color
      @include bp($tablet)
        top: 0
        left: 0
        width: 100%
        max-width: 100%
        border-radius: 0
        height: 100%
        transform: none
        margin: 0
      @include bp($phone)
        padding: 12px
      & div>*:first-child
        margin-right: 60px
    & .d-popup-simple__close
      top: 32px
      right: 32px
      @include bp($phone)
        top: 12px
        right: 12px
  &__inner
    position: relative
    z-index: 2
  &__content
    height: 100%
    //width: 100%
    pointer-events: none
    overflow-y: auto
    & *
      pointer-events: auto
  &__close
    position: absolute
    right: 12px
    top: 14px
    z-index: 999
  &._filter
    & .d-popup-simple
      &__content
        pointer-events: auto
      &__close
        right: 24px
        @include bp($phone)
          right: 12px
          width: 32px
          height: 32px

  &._bottom
    top: unset
    & ._close
      width: 32px
      height: 32px
      border-radius: 8px
    @include bp($phone)
      & ._close
        width: 48px
        height: 48px
        border-radius: 12px
    & .d-popup-simple__content
      height: fit-content
      width: fit-content
      bottom: 0
      left: 50%
      position: absolute
      transform: translateX(-50%)
      @include bp($phone)
        height: calc(var(--vh, 1vh) * 100)
        width: 100vw
        position: unset
        bottom: unset
        left: unset
        transform: unset
        background: $white-color
        overflow-y: auto
