

.d-search-notify
  background-color: $white-color
  border-radius: 16px
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.2)
  position: relative
  padding: 16px 20px
  will-change: transform
  @include bp($phone)
    padding: 12px
  &:after
    display: block
    position: absolute
    cursor: pointer
    width: 100%
    height: 100%
    top: 0
    content: ''
    z-index: 1
  &__close
    position: absolute
    top: 20px
    right: 20px
    width: 20px
    height: 20px
    border: none
    outline: none
    display: flex
    place-items: center
    background: none
    color: $pen-color
    cursor: pointer
    z-index: 2
    padding: 0
    & svg
      fill: currentColor
    &:hover, &:active
      color: $blue-color
  &__message
    font-weight: 400
    margin-bottom: 12px
    @include font-size-lh(14,18)
    color: $grey50
    user-select: none
    &._warn
      color: $red
      font-weight: 600
  &__title
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    user-select: none
  &__price
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
    user-select: none
  &._active
    transition: all 200ms linear


.vue-notification-group
  top: 120px!important
  width: calc(100% - 40px * 2)!important
  max-width: calc(1920px - 40px * 2)!important
  transition: top 0.5s ease
  left: 50%!important
  transform: translateX(-50%)
  pointer-events: none
  @include bp($desktopXS)
    width: calc(100% - 32px * 2)!important
  @include bp($tablet)
    width: calc(100% - 24px * 2)!important
  @include bp($phone)
    top: 72px!important
    width: calc(100% - 12px * 2)!important
body._noHeader .vue-notification-group
  top: 32px!important
  @include bp($phone)
    top: 16px!important

.vue-notification-wrapper
  overflow: visible!important
  pointer-events: none

.vue-notification-group > span, .vue-notification-group > name, .vue-notification-group > div
  display: flex!important
  flex-direction: column
  row-gap: 6px

.d-search-notify
  pointer-events: all
  width: 333px
  margin-left: auto
  @include bp($phone)
    width: 295px

