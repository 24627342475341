.d-breadcrumbs
  display: flex
  align-items: center
  font-weight: 500
  @include font-size-lh(14,18)
  color: $grey50
  &__item
    padding-left: 24px
    background: url("../assets/img/d/arrow_grey50.svg") no-repeat 6px center / 12px
    overflow: hidden
    text-overflow: ellipsis
    @include bp($phone)
      display: none
    &:first-child
      padding: 0
      background: none
  & a
    color: $grey50
    text-decoration: none
    @include transition(color)
    @include hover
      color: $blue-color
  &__back
    display: none
    @include bp($phone)
      display: inline-block
      padding-left: 20px
      text-decoration: none
      position: relative
    &:before
      content: ''
      position: absolute
      left: 4px
      top: 3px
      width: 12px
      height: 12px
      background: url("../assets/img/d/arrow_grey50.svg") no-repeat center / contain
      transform: rotate(180deg)