.d-object-single-utp
  position: relative
  &__sliderTop, &__sliderBottom
    border-radius: 32px
    overflow: hidden
    will-change: transform
    @include bp($tablet)
      border-radius: 0
  &__sliderBottom
    @include bp($tablet)
      overflow: unset
    & .swiper
      @include bp($tablet)
        padding: 0 24px
        margin-left: -24px
        margin-right: -24px
      @include bp($phone)
        padding: 0 12px
        margin-left: -12px
        margin-right: -12px
      & .swiper-slide
        width: calc( (100% / 3) - (64px / 3) )
        @include bp($tablet)
          width: 384px
        @include bp($phone)
          width: 100%
      &._fixed
        margin: 0
        width: calc(100% - (100% / 3) - (64px / 3))
        @include bp($tablet)
          padding: 0 24px
          margin-left: -24px
          margin-right: -24px
          width: auto
          //width: calc( (100% / 2.5) - (64px / 2))
        @include bps($phone)
          padding: 0 12px
          margin-left: -12px
          margin-right: -12px
        & .swiper-slide

          width: calc( (100% / 2) - (32px / 2))
          @include bp($tablet)
            //width: calc( (100% / 2.5) - (32px / 2))
            width: 384px
          @include bp($phone)
            width: 100%

  &__sliderTop
    margin-bottom: 76px
    @include bp($desktopS)
      border-radius: 0
    @include bp($tablet)
      margin-bottom: 64px
    @include bp($phone)
      margin-bottom: 40px
    &.swiper
      @include bp($desktopS)
        padding: 0 24px
        margin-left: -24px
        margin-right: -24px
      @include bp($phone)
        padding: 0 12px
        margin-left: -12px
        margin-right: -12px
  &__subtitle
    font-weight: 600
    @include font-size-lh(36,40)
    color: $pen-color
    margin-bottom: 68px
    @include bp($desktopS)
      margin-bottom: 44px
    @include bp($tablet)
      margin-bottom: 40px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 24px
  &__arrows
    position: absolute
    right: 0
    top: 4px
    @include bp($tablet)
      display: none
    &._text
      top: 4px
      @include bp($desktopS)
        top: 0
    &._flat
      top: 388px
      @include bp($desktopS)
        top: 360px
      &._single
        top: 4px
        @include bp($desktopS)
          top: 0
  &__fixed
    width: calc( (100% / 3) - (64px / 3) )
    height: 100%
    position: absolute
    top: 0
    right: 0
    z-index: 3
    overflow: hidden
    @include bp($tablet)
      display: none