.d-sorter-direction
  display: flex !important
  flex-shrink: 0
  align-items: center
  width: 16px
  height: 12px
  padding: 0
  color: $pen-color
  @include transition(color)
  cursor: pointer
  background: none
  border: none
  position: relative
  @include hover
    color: $blue-color
  &:before, &:after, span
    content: ''
    position: absolute
    left: 0
    height: 2px
    border-radius: 2px
    background: currentColor
    @include transition(width)
  &:before
    top: 0
    width: 16px
  &:after
    bottom: 0
    width: 8px
  span
    display: block !important
    top: 50%
    width: 12px
    margin-top: -1px
  &._asc
    &:before
      width: 8px
    &:after
      width: 16px

  &._line
    width: 14px
    height: 10px
    color: currentColor
    transition: none
    &:before
      width: 14px
    &:after
      width: 6px
    & span
      width: 10px
*[class~="_asc"]
  & .d-sorter-direction._line
    &:before
      width: 6px
    &:after
      width: 14px

