.ba-popup-form
  &.mfp-wrap
    position: fixed
    overflow: auto

  &.mfp-bg
    background: rgba(22,28,36,0.2)
    opacity: 1
  & .mfp-container
    display: flex
    justify-content: flex-end
    padding: 0
    & .ba-form
      display: flex
      overflow: auto
  & .mfp-content
    background: $grey-light
    max-width: 640px
    height: 100%
    margin: 0
    padding: 80px 64px
    padding: 0
    border-radius: 48px 0 0 48px
    display: flex
    align-items: center
    justify-content: center
    @include bp($tablet)
      max-width: 100%
      border-radius: 0
  & .mfp-close
    background-color: #35b8fc
    opacity: 1
    border-radius: 50%
    color: $white-color !important
    transform: translate(-24px, 24px)
    outline: none
    font-size: 0
    background-size: contain
    display: flex
    justify-content: center
    align-items: center
    @include bp($tablet)
      transform: translate(-16px, 16px)
    @include bp($phone)
      width: 32px
      height: 32px
      line-height: 32px
      transform: translate(-8px, 8px)
    &:before
      content: ''
      background: url('../assets/img/icons/close_light.svg') no-repeat
      background-size: contain
      display: block
      width: 16px
      height: 16px