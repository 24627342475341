:root
  --padding: 80px
  @include bp($desktopXS)
    --padding: 64px
  @include bp($tablet)
    --padding: 48px
  @include bp($phone)
    --padding: 24px

*
  box-sizing: border-box
  -webkit-tap-highlight-color: transparent
html
  height: 100%
  &._contacts
    overflow: unset

body.d-blue-ant
  display: block
  flex-direction: column
  position: relative
  width: 100%
  height: 100%
  background: #f2f3f7 url('../assets/img/d/bg_30year.png') repeat center
  font-family: 'Gilroy', Helvetica, Arial, sans-serif
  font-weight: 500
  line-height: 1.2
  color: #8b90a6
  -webkit-overflow-scrolling: touch
  @include bp($phone)
    background-size: 210px auto
  &._noScroll
    overflow: hidden
  @supports (display: flex)
      display: flex


body.mfp-active
  -webkit-overflow-scrolling: auto
  width: 100%
  position: fixed
  overflow: auto

body .mfp-wrap
  position: fixed
  overflow: auto
  top: 0 !important
  -webkit-transform: translateZ(0)
  -webkit-overflow-scrolling: auto !important
body .mfp-bg
  background: rgba($black, 0.6)
  backdrop-filter: blur(20px)
  opacity: 1
.wrap-wrap
  display: flex
  flex-direction: column
  min-height: calc(var(--vh, 1vh) * 100)
.wrapper
  flex-grow: 1

.object-container
  max-width: 1476px
  width: 100%
  margin: 0 auto
  position: relative
  @include bp($tablet)
    padding-left: 32px
    padding-right: 32px
  @include bp($phone)
    padding-left: 22px
    padding-right: 22px


.map__route-geo-button
  position: absolute
  width: 7em
  height: 7em
  bottom: 7.5em
  left: 50%
  margin-left: -3.5em
  cursor: pointer
  border-radius: 4em
  background-color: #35b8fc
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(164 223 254 / 90%), 2.5em 2.5em 5em rgb(53 184 252 / 40%), -3.45em -3.45em 6.9em #fff
  transition: background-color .3s ease,box-shadow .3s ease

.openPopup.map .map__route-help
  position: absolute
  bottom: 20em
  left: 50%
  margin-left: 1em
  padding: 3.5em 4.5em
  background-color: #fff
  border-radius: 4em
  box-shadow: 2em 2em 4em rgb(210 220 233 / 50%)
  transition: visibility 0.4s ease,opacity 0.4s ease,transform 0.4s cubic-bezier(0.42, 0, 0, 1.7)

[data-theme="tooltip"]
  &.tippy-box
    background: $white-color !important
    box-shadow: 0 0 3em rgb(22 28 36 / 5%)
    padding: 16px
    color: $pen-color
    border-radius: 10px
    & .tippy-arrow
      color: $white-color
.hidden-tablet
  @include bp($tablet)
    display: none !important
.ba-page-title
  color: $pen-color
  @include font-size-lh(48,57)
  font-weight: 600
  margin: 0
  @include bp($phone)
    @include font-size-lh(20,24)
.ba-page-container
  width: 100%
  padding: 0 32px
  @include bp($desktop)
    padding: 0 54px
  @include bp($phone)
    padding: 0 16px

.mapsInit [class$="ground-pane"]
  filter:  none !important

/*---*/
.d-main
  display: flex
  flex-direction: column
  padding-top: 128px
  position: relative
  z-index: 2
  flex-grow: 1
  flex-shrink: 0
  flex-basis: auto
  @include bp($phone)
    padding-top: 72px
.d-container
  max-width: 1920px
  width: 100%
  padding: 0 40px
  margin: 0 auto
  @include bp($desktopXS)
    padding: 0 32px
  @include bp($tablet)
    padding: 0 24px
  @include bp($phone)
    padding: 0 12px
.d-page-title
  font-weight: 600
  @include font-size-lh(64,68)
  color: $blue-color
  margin-bottom: 56px
  @include bp($desktopXS)
    @include font-size-lh(52,56)
    margin-bottom: 40px
  @include bp($phone)
    @include font-size-lh(36,40)
    margin-bottom: 24px

h1
  margin: 0

.d-overlay
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: rgba($black, 0.6)
  backdrop-filter: blur(20px)
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  @include bp($phone)
    display: none
  &._show
    opacity: 1
    visibility: visible
    z-index: 99

.simplebar-track
  right: 0 !important
.simplebar-track.simplebar-vertical
  width: 2px !important
.simplebar-scrollbar:before
  left: 0 !important
  right: 0 !important
  background: $blue-color !important
  border-radius: 2px
.simplebar-scrollbar:before
  opacity: 1 !important
.tippy-box[data-theme="genplan-object"]
  background: $white-color
  padding: 0
  overflow: hidden
  border-radius: 12px
  & .tippy-content
    width: 320px !important
    max-width: 100%
    height: auto
    padding: 16px 24px 24px
    border-top: 1px solid $grey20
    color: $grey50

    @include font-size-lh(14,18)
    @include bp($desktopS)
      padding: 12px 16px 16px

.tippy-box[data-theme="office-btn"]
  background: $white-color
  padding: 0
  overflow: hidden
  border-radius: 12px
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
  & .tippy-content
    //width: 320px !important
    @include font-size-lh(16,18)
    max-width: 200px
    text-align: center
    height: auto
    padding: 12px 16px
    color: $blue-color
.tippy-box[data-theme="commercial-map"]
  background: transparent
  padding: 0
  overflow: hidden
  border-radius: 24px
  max-width: 418px !important
  & .tippy-content
    max-width: 100%
    height: auto
    padding: 0
    border: none

.v-enter-from,
.v-leave-to
  opacity: 0

.v-leave-from,
.v-enter-to
  opacity: 1


.fade-enter-active,
.fade-leave-active
  transition: opacity 5s ease

.fade-enter-from,
.fade-leave-to
  opacity: 0
.vfm--fixed
  z-index: 9999999999 !important


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.d-popup-content-wrap
  & .mfp-container
    @include bps($tablet, $phone)
      padding-left: 0
      padding-right: 0

body._popupOpen
  -webkit-overflow-scrolling: auto !important
  -webkit-backface-visibility: hidden


[hidden]
  display: none!important

body._menuOpen, body._popupOpen, body._noScroll
  overflow: hidden

body._menuOpen jdiv, body._popupOpen jdiv, body._noScroll jdiv, body._noJivo jdiv
  z-index: 0!important

body._popupOpen
  -webkit-overflow-scrolling: auto !important
  -webkit-backface-visibility: hidden

/*стили для входа в ЛК*/
#lk-main__wrap
  overflow: hidden
  padding-top: 128px
  @include bp($phone)
    padding-top: 72px
.sn_form1
  width: calc(100% - 24px)

.captcha-info
  font-weight: 400
  @include font-size-lh(14,18)
  & a
    color: currentColor
    @include transition(all)
  &._error
    display: flex
    align-items: center
    &:before
      content: url("../assets/img/d/info.svg")
      display: inline-block
      width: 24px
      height: 24px
      margin-right: 4px


/* СНЕГ */
#snow
  position: fixed
  top: 0
  bottom: 0
  left: 0
  width: 100vw
  max-width: 100vw
  overflow: hidden
  pointer-events: none!important
  z-index: 9999

.roundSmall,
.roundMedium,
.roundLarge
  background: #fff
  border-radius: 50%
  position: absolute
  width: 3px
  height: 3px

.roundMedium
  width: 4px
  height: 4px

.roundLarge
  width: 5px
  height: 5px

.starMedium
  position: absolute
  width: 16px
  height: 16px

.sharpLarge
  position: absolute
  width: 30px
  height: 30px

.d-sticker30years
  position: fixed
  right: 0
  top: 50%
  transform: translateY(-50%)
  width: 92px
  height: 80px
  background: #EC008C
  border: none
  border-radius: 16px 0 0 16px
  filter: drop-shadow(0 4px 20px rgba(54, 24, 125, 0.08))
  display: flex
  align-items: center
  justify-content: center
  z-index: 11
  cursor: pointer
  @include bp($tablet)
    width: 74px
    height: 64px
  & img
    width: 66px
    @include bp($tablet)
      width: 52px
