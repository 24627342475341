.d-docs
  display: flex
  //flex-direction: row-reverse
  //align-items: flex-end
  min-height: 181px
  position: relative
  @include bp($tablet)
    flex-direction: column
    //flex-direction: column
    //align-items: flex-start
  &__col

    &._list
      flex-grow: 1
  &__header
    //position: absolute
    //left: 0
    //top: 0
    max-width: 350px
    @include bp($tablet)
      position: relative
      display: flex
      flex-wrap: wrap
      width: 100%
      max-width: 100%
      align-items: center
      margin-bottom: 32px
    @include bp($phone)
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
      margin-bottom: 16px
    & .d-tabs
      width: 280px
      @include bp($phone)
        width: 100%
      &__buttons
        margin: 0
      &__buttonsInner
        display: flex
      &__button
        width: 50%
  &__title
    @include bp($tablet)
      position: relative
      width: 100%
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 24px
  &__categories
    max-width: 280px
    flex-shrink: 0
    margin-top: 16px
    @include bp($tablet)
      margin: 0 0 0 24px
    @include bp($phone)
      max-width: 100%
      width: 100%
      margin: 16px 0 0
  &__list
    flex-grow: 1
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 32px
    grid-row-gap: 32px
    @include bp($desktopS)
      grid-template-columns: repeat(2, 1fr)
    @include bp($tablet)
      grid-column-gap: 8px
      grid-row-gap: 8px
      width: 100%
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-column-gap: 0
  &__links
    display: flex
    flex-shrink: 0
    flex-direction: column
    align-items: flex-start
    width: 436px
    margin-right: 32px
    margin-top: 64px
    @include bp($desktopXS)
      width: 282px
    @include bp($tablet)
      width: 100%
      margin: 48px 0 0
    @include bp($tabletS)
      margin-top: 56px
    @include bp($phone)
      margin-top: 48px
    &._desktop
      @include bp($tablet)
        display: none
    &._tablet
      display: none
      @include bp($tablet)
        display: flex
  &__link
    font-weight: 600
    @include font-size-lh(16,19)
    color: $pen-color
    text-decoration: none
    margin-top: 16px
    @include transition(color)
    @include bp($phone)
      @include font-size-lh(14,18)
    @include hover
      color: $blue-color
    &:first-child
      margin-top: 0
    &:after
      content: ''
      display: inline-block
      width: 16px
      height: 16px
      margin-left: 4px
      background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
      position: relative
      top: 2px
      @include bp($phone)
        width: 14px
        height: 14px
        margin-left: 2px
        top: 1px

.d-doc
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 24px
  padding: 32px 24px 24px
  position: relative
  @include bp($tablet)
    padding: 24px 172px 24px 24px
  @include bp($phone)
    padding: 24px 72px 24px 16px
  @include hover
    & .d-doc__button
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
      &:after
        transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
        opacity: 1
  &__name
    flex-grow: 1
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(20,24)
  &__bottom
    flex-shrink: 0
    padding: 48px 24px 0
    margin: 24px -24px 0
    border-top: 1px solid $grey20
    @include bp($tablet)
      padding: 0
      margin: 12px 0 0
      border: none
  &__size
    font-weight: 500
    @include font-size-lh(14,24)
    color: $grey40
    padding-left: 32px
    background: url('../assets/img/d/icon_doc.svg') no-repeat left center
  &__sizeFormat
    text-transform: uppercase
  &__button
    position: absolute
    right: 24px
    bottom: 24px
    height: 48px
    padding: 0 32px
    @include bp($tablet)
      bottom: auto
      top: 24px
    @include bp($phone)
      right: 16px
      top: 16px
      width: 48px
      padding: 0
      @include font-size-lh(0,0)
      background: url('../assets/img/d/icon_download_pen.svg') no-repeat center
    &:before
      @include bp($phone)
        display: none
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
#object-docs
  margin-top: 100px