.d-news
  &__list
    display: flex
    flex-direction: column
    margin-top: 8px
    overflow: hidden
    &:first-child
      margin-top: 0
    &-inner
      display: flex
      flex-direction: column
      perspective: 999px
      perspective-origin: center
  &__bottom
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 32px
    @include bp($tabletS)
      flex-direction: column-reverse
    @include bp($phone)
      margin-top: 20px
  &__pager
    @include bp($tabletS)
      margin-top: 40px
    @include bp($phone)
      margin-top: 32px

.d-news-date
  width: 104px
  padding: 5px 0
  background: $blue-color
  border-radius: 8px
  font-weight: 500
  @include font-size-lh(16,22)
  color: $white-color
  text-align: center
.d-news-likes
  display: flex
  align-items: center
  padding: 0
  font-weight: 500
  @include font-size-lh(16,22)
  color: $pen-color
  background: none
  border: none
  outline: none
  & button, & .button
    display: block
    flex-shrink: 0
    background: none
    border: none
    padding: 0
    margin-left: 8px
    cursor: pointer
    &:before
      content: ''
      width: 24px
      height: 24px
      display: inline-block
      mask: url("../assets/img/icons/favorite.svg") no-repeat 50% 50%
      -webkit-mask: url("../assets/img/icons/favorite.svg") no-repeat 50% 50%
      mask-size: cover
      -webkit-mask-size: cover
      background-color: $pen-color
      transition: background-color $transition
    @include hover
      &:before
        background-color: $red
  &._active button,
  &._active .button
    &:before
      mask: url("../assets/img/icons/favorite-filled.svg") no-repeat 50% 50%
      -webkit-mask: url("../assets/img/icons/favorite-filled.svg") no-repeat 50% 50%
      background-color: $red

  & svg
    display: block
    width: 24px
    height: 24px
    fill: $pen-color
    @include transition(fill)
