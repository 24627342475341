.d-flat-price
  display: flex
  align-items: center
  justify-content: space-between
  font-weight: 500
  flex-wrap: wrap
  @include font-size-lh(16,22)
  @include bp($tablet)
    flex-direction: column
    align-items: flex-start
  &__digits
    //
  &__old
    color: $grey50
    text-decoration: line-through
    margin-bottom: 4px
  &__now
    font-weight: 600
    @include font-size-lh(32,36)
    color: $blue-color
    white-space: nowrap
  &__mortgage
    display: flex
    flex-direction: column
    align-items: flex-end
    margin-left: 24px
    color: $pen-color
    @include bp($tablet)
      align-items: flex-start
      margin: 24px 0 0
      order: 3
  &__mortgageInfo, &__mortgageLink
    font-weight: 600
    white-space: nowrap
  &__mortgageLink
    background: none
    border: none
    color: $blue-color
    text-decoration: none
    padding: 0
    margin-top: 4px
    cursor: pointer
    @include transition(color)
    @include hover
      color: $pen-color
  &__disclaimer
    color: $blue-color
    flex-basis: 100%
    flex-shrink: 0
    margin-top: 16px
    letter-spacing: -0.32px
    @include font-size-lh(16,22)
    font-weight: 600
    @include bp($tablet)
      order: 2
