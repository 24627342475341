.d-object-single-genplan
  &__planWrap
    position: relative
    @include bp($phone)
      position: fixed
      left: 0
      top: 0
      height: 100%
      width: 100%
      @include transition(all)
    &._desktop
      @include bp($phone)
        display: none
    &._show
      @include bp($phone)
        opacity: 1
        visibility: visible
        z-index: 25
  &__planInner
    @include bp($tablet)
      padding: 0 24px
      margin: 0 -24px
      overflow: auto
    @include bp($phone)
      height: 100%
      padding: 0
      margin: 0
      position: relative
    &::-webkit-scrollbar
      display: none
  &__plan
    height: 740px
    border-radius: 40px
    overflow: hidden
    will-change: transform
    @include bp($desktopS2)
      height: 560px
    @include bp($tablet)
      //width: 1216px
    @include bp($phone)
      height: 100%
      border-radius: 0
  &__img
    @include objectFit(100%, cover)
  &__compass
    position: absolute
    left: 48px
    bottom: 40px
    z-index: 5
    @include bp($phone)
      left: 20px
      bottom: 12px
  &__disclaimer
    position: absolute
    left: 104px
    bottom: 40px
    z-index: 5
    @include bp($phone)
      left: 56px
      bottom: 12px
      max-width: calc(100% - 68px)
  &__info
    display: none
    @include bp($tablet)
      display: flex
      justify-content: center
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: calc(100% + 16px)
      z-index: 6
    @include bp($phone)
      position: absolute
      left: 50%
      transform: translateX(-50%)
      bottom: 12px
      width: calc(100% - 24px)
      margin: 0
      display: block
      top: unset
    & .d-swipe-info
      white-space: nowrap
      @include bp($phone)
        white-space: normal
  &__mobileButton
    display: none
    @include bp($phone)
      display: flex
      align-items: center
      justify-content: center
      height: 223px
      background: url('../assets/img/d/genplan_320.jpg') no-repeat center / cover
      border-radius: 24px
    & .d-button
      @include bp($phone)
        padding: 0 20px 0 12px
  &__close
    display: none
    @include bp($phone)
      display: flex
      position: absolute
      right: 12px
      top: 12px
      z-index: 11

.d-object-single-genplan-legend
  position: absolute
  left: 24px
  top: 24px
  height: 48px
  padding: 0 20px
  display: flex
  align-items: center
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  z-index: 7
  @include bp($phone)
    left: 12px
    top: 12px
  &__item
    font-weight: 500
    @include font-size-lh(16,22)
    color: $pen-color
    padding-left: 18px
    margin-left: 20px
    position: relative
    &:before
      content: ''
      position: absolute
      left: 0
      top: 4px
      width: 12px
      height: 12px
      background: #5131F5
      border-radius: 50%
    &:first-child
      margin-left: 0
      &:before
        background: $blue-color

.d-object-single-genplan-point
  background: $white-color
  border-radius: 12px
  position: absolute
  z-index: 20
  cursor: pointer
  @include bp($phone)
    border-radius: 8px
  @include hover
    z-index: 30
    & .d-object-single-genplan-point
      &__name
        color: $blue-color
      &__text
        width: 320px
        height: auto
        padding: 16px 24px 24px
        border-top: 1px solid $grey20
        @include font-size-lh(14,18)
        opacity: 1
        visibility: visible
        @include bp($desktopS)
          padding: 12px 16px 16px
    &._premium
      & .d-object-single-genplan-point
        &__name
          color: #5131F5
  &._hover
    & .d-object-single-genplan-point
      &__name
        color: $blue-color
      &__text
        width: 320px
        height: auto
        padding: 16px 24px 24px
        border-top: 1px solid $grey20
        @include font-size-lh(14,18)
        opacity: 1
        visibility: visible
        @include bp($desktopS)
          padding: 12px 16px 16px
    &._premium
      & .d-object-single-genplan-point
        &__name
          color: #5131F5
  &__name
    padding: 16px 24px
    font-weight: 600
    @include font-size-lh(18,21)
    color: $pen-color
    @include transition(all)
    @include bp($desktopS)
      padding: 10px 16px
      @include font-size-lh(16,22)
    @include bp($phone)
      padding: 11px 16px
      @include font-size-lh(14,18)
  &__text
    font-weight: 500
    @include font-size-lh(0,0)
    color: $grey50
    width: 0
    padding: 0
    opacity: 0
    visibility: hidden
    overflow: hidden
    @include transition(all)
    @include bp($tablet)
      display: none

.d-object-single-genplan-point-info
  display: none
  @include bp($tablet)
    padding-bottom: 16px
    display: block
    position: absolute
    left: 50%
    bottom: 16px
    transform: translateX(-50%)
    width: 304px
    z-index: 30
    background: $white-color
    border-radius: 12px
    opacity: 0
    visibility: hidden
    transition: all $transition
  @include bp($phone)
    left: 12px
    bottom: 12px
    transform: none
    width: calc(100% - 24px)
  &._premium
    & .d-object-single-genplan-point-info__name
      color: #5131F5
  &._show
    opacity: 1
    visibility: visible
  &__name
    padding: 12px 56px 14px 16px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
  &__text
    font-weight: 500
    @include font-size-lh(12,14)
    color: $grey50
    padding: 12px 16px 16px
    border-top: 1px solid $grey20
  &__close
    position: absolute
    right: 16px
    top: 12px
    background: none
    border: none
    padding: 0
    & img
      display: block
      width: 24px
  &__btn
    display: none
    @include bp($tablet)
      display: inline-flex
      height: 48px
      width: calc(100% - 32px)
      margin-left: 16px
