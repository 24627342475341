.d-search
  position: relative
  //width: 762px
  width: 100%
  opacity: 1
  visibility: visible
  z-index: 3
  &__text
    width: 100%
    height: 64px
    padding: 0 104px 0 24px
    font-weight: 500
    font-size: 16px
    line-height: 22px
    color: $pen-color
    background: $grey5
    border: none
    border-radius: 12px
    outline: none
    &::placeholder
      color: $grey30
  &__button
    position: absolute
    right: 16px
    top: 8px
    width: 48px
    height: 48px
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    background: none
    border: none
    cursor: pointer
    z-index: 2
    &._clear
      right: 52px
      color: $pen-color
      display: none
      @include transition(color)
      z-index: 1
      @include hover
        color: $red
      @include bp($phone)
        background: $white-color
        border-radius: 8px
      &._active
        display: flex
    & img, & svg
      display: block
      width: 24px
      height: 24px
      fill: currentColor
  &__suggestions
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    padding: 15px 16px
    background: $white-color
    border-radius: 12px
    overflow: hidden
    display: none
    &._open
      display: block
  &__suggestion
    border-radius: 8px
    padding: 9px 8px
    color: $grey50
    @include font-size-lh(16,22)
    @include transition(all)
    &:not(.no-click):hover, &._selected
      background: $grey5
      color: $blue-color
      cursor: pointer
      & span
        color: $blue-color!important
    &._selected
      background: $grey5
      color: $blue-color
      cursor: pointer
      & span
        color: $blue-color!important
    & span
      transition: color $transition
      color: $pen-color
    & .search__suggestion
      padding: 10px 8px
  &__drop
    position: absolute
    left: -4px
    top: calc(100% + 40px)
    width: calc(100% + 8px)
    padding-top: 46px
    display: none
    flex-wrap: wrap
    align-content: flex-start
    &._open
      display: flex
    color: $grey30
    & .d-search__tag
      padding: 13px 20px
      margin: 4px
      background: $grey5
      border-radius: 8px
      font-weight: 500
      @include font-size-lh(16,22)
      color: $pen-color
      text-decoration: none
      position: relative
      @include transition(background)
      cursor: pointer
      @include hover
        background: $white-color
      & i
        color: #9BC6EC
        margin-right: 2px
  &__tagTitle
    position: absolute
    left: 4px
    top: 0
    font-weight: 500
    @include font-size-lh(16,22)
  &__var
    font-weight: 600
    font-size: 16px
    line-height: 19px
    color: $purple
    margin-top: 21px
    &:first-child
      margin-top: 0
    & span
      color: $pen-color
  &__wrapper
    padding-bottom: 16px
    background: $white-color
  &__stations
    display: none
    padding: 0 0 24px 0
    max-height: 400px
    overflow-x: hidden
    overflow-y: auto
    @include bp($tablet)
      max-height: 100%
    &._open
      display: block
  &._mobile
    & .d-search
      &__text
        height: 48px
        padding: 0 52px 0 12px
        background: $white-color
        border-radius: 8px
      &__button
        right: 0
        top: 0
        &._clear
          display: none
          right: 0
          &._active
            display: flex
            z-index: 3
        &[type="submit"]._hidden
          display: none!important
      &__suggestions
        left: -12px
        top: calc(100% + 24px)
        width: calc(100% + 24px)
        height: calc(var(--vh, 1vh) * 100 - 278px)
        min-height: 440px
        padding: 0 12px 12px
        background: $background
        border-radius: 0
      &__suggestion
        border-radius: 0
        padding: 0
        margin-top: 20px
        &:first-child
          margin-top: 0
      &__drop
        left: -12px
        top: calc(100% + 24px)
        width: calc(100% + 24px)
        height: calc(var(--vh, 1vh) * 100 - 278px)
        min-height: 440px
        padding: 34px 8px 8px
        background: $background
        & .d-search__tag
          padding: 13px 20px
          background: $white-color
      &__tagTitle
        left: 12px
        color: $grey50

  &._filter
    & .d-search
      &__text
        height: 48px
        padding: 0 96px 0 16px
        border-radius: 8px
      &__button
        width: 24px
        height: 24px
        right: 16px
        top: 12px
        &._clear
          display: none
          right: 56px
          &._active
            display: flex
            z-index: 3
      &__stations
        display: block
        margin-left: -12px
        margin-right: -12px
        padding-left: 12px
        padding-right: 12px
      &__station
        margin-top: -11px
        user-select: none
        padding: 11px 0 11px 4px
        @include hover
          &:before
            background: $grey5
        &:first-child
          margin-top: 0
        &:before
          content: ''
          position: absolute
          left: -8px
          top: 50%
          transform: translateY(-50%)
          height: 44px
          width: calc(100% + 16px)
          border-radius: 8px
          @include transition(all)
        & .d-checkbox__label
          display: flex
          align-items: flex-start
          justify-content: space-between
          & div:first-child
            margin-right: auto
            @include bp($phoneS2)
              white-space: normal
        & .d-metro
          flex-shrink: 0
          min-width: 40px
          margin: 7px 0 0 4px
          justify-content: flex-end
          &__color
            margin: 0 0 0 auto !important
            & + .d-metro__color
              margin-left: 4px !important
        & .d-metro-way
          flex-shrink: 0
          margin: 3px 0 0 16px

.d-index-filter-item__drop .d-search__button._clear
  @include bp($phone)
    background: unset
