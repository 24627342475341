.d-commercial-list
  display: grid
  grid-template-columns: 316px 1fr repeat(4, 13%)
  grid-column-gap: 24px
  align-items: center
  padding-right: 40px
  background: $white-color
  border-radius: 24px
  position: relative
  @include bp($desktopXS)
    grid-template-columns: 199px repeat(5, 1fr)
  @include bp($tablet)
    grid-template-columns: 183px repeat(5, 1fr)
    grid-column-gap: 16px
    padding-right: 24px
  @include bp($tabletS)
    grid-template-columns: 160px repeat(4, 1fr)
    min-height: 88px
    padding: 0 24px
  @include hover
    & .d-commercial-list
      &__item
        &._img
          & img
            transform: scale(1.1)
        &._name
          color: $blue-color
  &__item
    font-weight: 400
    @include font-size-lh(18,24)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    & .skeleton-field
      width: 88%
      height: 17px
      margin: 0
    &._img
      height: 148px
      margin-right: 56px
      border-radius: 24px
      overflow: hidden
      will-change: transform
      @include bp($desktopXS)
        height: 120px
        margin-right: 16px
      @include bp($tablet)
        margin-right: 0
      @include bp($tabletS)
        display: none
      & img
        @include objectFit(100%, cover)
        @include transition(transform)
      & .skeleton-field
        width: auto
        height: calc(100% - 32px)
        margin: 16px 0 16px 16px
    &._name, &._price
      font-weight: 600
      @include font-size-lh(24,28)
      @include bp($desktopXS)
        @include font-size-lh(20,24)
      @include bp($tablet)
        @include font-size-lh(16,22)
      & .skeleton-field
        height: 32px
        border-radius: 8px
    &._name
      @include transition(color)
    &._price
      color: $blue-color
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 4