.d-company-teams
  &__title
    margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 32px
    @include bp($tablet)
      grid-gap: 24px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-gap: 24px 0
    @include bp($phone)
      grid-row-gap: 12px