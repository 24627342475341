.d-choose-builds-info
  display: flex
  flex-direction: column
  max-width: 356px
  width: 100%
  background: $white-color
  border-radius: 24px
  @include bp($phone)
    max-width: 100%
    border-radius: 0
  &__title
    flex-shrink: 0
    padding: 24px
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    border-bottom: 1px solid $grey20
    @include bp($phone)
      padding: 24px 12px
      @include font-size-lh(20,24)
  &__bottom
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: 24px
    @include bp($phone)
      padding: 24px 12px 16px
  &__content
    margin-bottom: 32px
  &__label
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 12px
  &__list
    display: flex
    overflow: auto
    padding: 0 24px
    margin: 0 -24px
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
      @include bp($tablet)
        display: none
    & .d-checkbox
      flex-shrink: 0
      margin-left: 4px
      &:first-child
        margin-left: 0
  &__facts
    display: grid
    grid-template-columns: repeat(3, auto)
    grid-column-gap: 12px
    justify-content: flex-start
    margin-top: 24px
  &__factsItem
    //
  &__factsLabel
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
  &__factsValue
    font-weight: 600
    @include font-size-lh(18,24)
    color: $pen-color
  &__flats
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    padding-bottom: 24px
    margin-top: 16px
    border-bottom: 1px solid $grey20
  &__flat
    display: flex
    align-items: flex-end
    text-decoration: none
    &:after
      content: ''
      flex-grow: 1
      border-bottom: 1px dashed $grey20
      margin: 0 4px
      position: relative
      top: -3px
      order: 2
  &__flatLabel, &__flatValue
    flex-shrink: 0
    @include font-size-lh(14,18)
  &__flatLabel
    font-weight: 400
    color: $grey50
    order: 1
  &__flatValue
    font-weight: 600
    color: $pen-color
    order: 3
  &__floor
    margin-top: 24px
  &__button
    margin-top: auto
  &__checkbox.swiper-slide
    width: 48px
    height: 48px
    pointer-events: all !important
  &__slider.swiper
    margin: 0
