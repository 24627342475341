.d-index
  &__section
    margin-bottom: 160px
    @include bp($desktopXS)
      margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 72px
    &._top
      position: relative
    &._objects
      margin-top: 56px
      @include bp($desktopS)
         margin-top: 48px
      @include bp($tablet)
         margin-top: 58px
      @include bp($tabletS)
         margin-top: 52px
      @include bp($phone)
         margin-top: 44px
      &._flats
        & .d-result-filter__header
          @include bp($desktopXS)
            margin-bottom: 0
        & .d-index-filter-choose__list
          @include bp($tablet)
            margin-bottom: 26px
          @include bp($phone)
            margin-bottom: 0
        & .d-index-filter__clear._objects
          @include bp($phone)
            margin: 32px 0
        & .d-index-flats__slider._objects
          @include bp($phone)
            margin-bottom: 0
          & + .d-index-flats__result
            @include bp($phone)
              margin-top: 12px
        & .d-index-flats__result
          @include bp($phone)
            margin-top: 0
    &._top
      margin-bottom: 24px
      @include bp($phone)
        margin-bottom: 12px
    &._utp
      margin-bottom: 112px
      @include bp($desktopXS)
        margin-bottom: 88px
      @include bp($tablet)
        width: calc(100% + 48px)
        margin: 0 -24px 96px
      @include bp($phone)
        width: calc(100% + 24px)
        margin: 0 -12px 66px
    &._geography
      @include bp($phone)
        margin: 0
