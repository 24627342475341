.d-aux-rooms-filter
  display: grid
  grid-template-columns: 1fr
  grid-row-gap: 24px
  & .skeleton-field
    height: 64px
    margin: 0
    background: $white-color
    &:before
      display: none
  &__row
    &._row1
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 24px
    &._row2
      display: flex
      align-items: center
      justify-content: space-between
      @include bp($tablet)
        width: calc(50% + 12px)
        margin: -158px 0 0 auto
        position: relative
        z-index: 2
      @include bp($tabletS)
        width: 100%
        margin-top: 0
    &._header
      background-color: $white-color
      padding: 18px 24px!important
      display: flex
      align-items: center
      column-gap: 12px
      position: fixed
      top: 0
      left: 0
      z-index: 3
      border-bottom: 1px solid $grey20
      width: 100%
      @include bp($phone)
        padding: 16px 12px!important
        column-gap: 14px

      .d-aux-rooms-filter__clear
        font-weight: 400
        font-size: 14px
        line-height: 18px
        color: $grey50
        margin-top: 4px
        cursor: pointer
        transition: color 0.3s ease
        @include hover
          color: $blue-color
        @include bp($phone)
          padding-top: 2px

    &._footer
      position: absolute
      left: 24px
      bottom: 0
      width: calc(100% - 48px)
      display: flex
      justify-content: flex-end
      align-items: center
      column-gap: 40px
      padding: 18px 0 !important
      height: 112px
      background-color: $white-color
      z-index: 3
      margin-top: 114px
      &:not(._object)
        border-top: 1px solid $grey20
      @include bp($phone)
        left: 12px
        width: calc(100% - 24px)
        padding: 16px 0 !important
        margin-top: 32px
        border: none!important
        height: 80px

  &__object._active
    display: flex
    flex-direction: column
    row-gap: 24px

  &__title
    color: $blue-color
    font-size: 32px
    line-height: 36px
    font-weight: 600
    @include bp($phone)
      font-size: 24px
      line-height: 28px



  &__save
    min-width: 230px
    @include bp($phone)
      width: 100%



  &._tablet
    display: block
    min-height: 100%
    padding-bottom: 160px
    position: relative
    @include bp($phone)
      padding-bottom: 96px

    .d-aux-rooms-filter__row
      grid-template-columns: 1fr
      padding: 0 24px
      @include bp($phone)
        padding: 0 12px
      &._row2
        flex-direction: column
        align-items: flex-start
        row-gap: 16px


  &__item._price
    @include bp($tablet)
      padding-bottom: 8px
    @include bp($tabletS)
      border-bottom: 1px solid $grey20
    & .d-range-slider
      @include bp($tablet)
        width: calc(50% - 12px)
        margin-top: 4px
      @include bp($tabletS)
        width: 100%

  &__advantages
    display: flex
    column-gap: 4px
    & .skeleton-field
      width: 108px
      height: 48px
      border-radius: 8px
      &:first-child
        width: 128px
  &__advantage
    &.d-checkbox._full
      & .d-checkbox__label
        font-weight: 400
        background: $white-color
        @include hover
          color: $blue-color
      & svg
        width: 16px
        height: 16px
        margin-right: 8px

    &.d-checkbox._full._dark
      & .d-checkbox__label
        background: $grey5

  &__clear
    display: flex
    align-items: center
    background: none
    border: none
    padding: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    @include transition(color)
    @include hover
      color: $blue-color
    & svg
      width: 24px
      height: 24px
      margin-right: 8px
      fill: currentColor
    &[disabled]
      opacity: 0.5
      pointer-events: none
    &.skeleton-field
      width: 171px
      height: 22px

  & .d-index-filter-params
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 0 24px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-gap: 24px 0
  & .d-index-filter-item__head
    @include bp($tablet)
      padding-top: 24px
