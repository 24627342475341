.d-input-file
  position: relative
  &._error
    &:hover
      .d-input__error
        opacity: 1
        visibility: visible
  &__input, &__button, &__name
    width: 100%
    height: 64px
    @include bp($phone)
      height: 48px
  &__input, &__name
    position: absolute
    left: 0
    top: 0
    transition: all $transition
  &__input
    opacity: 0
    z-index: 1
  &__button, &__name
    display: inline-block
    padding: 0 72px 0 24px
    background-color: $white-color
    border: 1px solid $white-color
    border-radius: 12px
    font-weight: 600
    @include font-size-lh(16,64)
    color: $grey50
    @include transition(all)
    @include bp($phone)
      padding: 0 56px 0 16px
      line-height: 48px
    @include hover
      border-color: $blue-color
      color: $blue-color
      & .d-input-file__icon
        color: $blue-color
  &__button
    opacity: 1
    position: relative
    z-index: 4
    cursor: pointer
    &._hide
      opacity: 0
      visibility: hidden
      z-index: 2
    &._error
      color: $red
      border: 1px solid $red
  &__name
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    color: $pen-color
    z-index: 3
    &._show
      opacity: 1
      visibility: visible
  &__icon
    position: absolute
    right: 24px
    top: 50%
    transform: translateY(-50%)
    border: none
    background: none
    padding: 0
    color: $pen-color
    @include transition(color)
    cursor: pointer
    @include bp($phone)
      right: 16px
    @include hover
      color: $blue-color
    & svg
      display: block
      width: 24px
      height: 24px
      fill: currentColor
  &__info
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
    margin-top: 12px
  &:hover
    color: $blue-color
  &__inner
    position: relative
  & .d-input__error
    opacity: 0
    visibility: hidden
    transition: all $transition
    @include bp($tablet)
      opacity: 1
      visibility: visible
  & .progressbar
    --percent: 0
    height: 2px
    width: 52px
    background-color: $white-color
    &__scale
      height: 2px
      width: calc(var(--percent) * 1%)
      background-color: $blue-color