.d-text-popup
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  padding: 72px 0 12px
  background: $background
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  &._show
    opacity: 1
    visibility: visible
    z-index: 100
  &__inner
    max-height: 100%
    padding: 0 12px
    overflow: auto
  &__title
    font-weight: 600
    @include font-size-lh(36,40)
    color: $blue-color
    margin-bottom: 20px
  &__text
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    & p
      margin: 0
      & + p
        margin-top: 16px
  &__links
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-top: 20px
    position: relative
  &__link
    font-weight: 600
    @include font-size-lh(14,18)
    color: $pen-color
    text-decoration: none
    margin-top: 16px
    &:first-child
      margin-top: 0
    &:after
      content: ''
      display: inline-block
      width: 16px
      height: 16px
      margin-left: 4px
      background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
      position: relative
      top: 2px
  &__close
    position: absolute
    right: 12px
    top: 12px