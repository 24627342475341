.d-press-center
  padding-top: 56px
  @include bp($tablet)
    padding-top: 40px
  @include bp($phone)
    padding-top: 28px
  &__inner
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin-bottom: 120px
    position: relative
    z-index: 2
    @include bp($tablet)
      flex-direction: column
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 64px
  &__item
    &._item1
      flex-shrink: 0
      position: sticky
      left: 0
      top: 184px
      width: 256px
      @include transition(top)
      @include bp($tablet)
        position: relative
        top: auto
        width: 100%
        margin-bottom: 40px
      @include bp($phone)
        margin-bottom: 24px
      &._noHeader
        top: 32px
        @include bp($tablet)
          top: 0
    &._item2
      max-width: 1216px
      width: 100%
      margin-left: 132px
      min-height: 440px
      @include bp($desktopXS)
        min-height: 420px
        padding-top: 10px
        margin-left: 56px
      @include bp($tablet)
        padding: 0
        margin: 0
  &__top
    margin-bottom: 40px
    @include bp($tablet)
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 30px
    @include bp($phone)
      display: block
      margin-bottom: 16px
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    white-space: nowrap
    margin-bottom: 40px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
      margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 96px
  &__controls
    @include bp($tablet)
      display: flex
      align-items: center
      justify-content: space-between
    @include bp($phone)
      display: block
      position: relative
  &__selects
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    margin-bottom: 40px
    @include bp($tablet)
      grid-template-columns: repeat(2, 180px)
      grid-column-gap: 8px
      grid-row-gap: 0
      margin-bottom: 0
    @include bp($phone)
      grid-template-columns: repeat(2, 1fr)
    &._monthHide
      @include bp($phone)
        grid-template-columns: 1fr
  &__writeButton
    width: 100%
    @include bp($tablet)
      width: auto
    @include bp($phone)
      position: absolute
      left: 0
      bottom: 136px
      width: 100%
  & .d-tabs
    &__buttons
      margin-bottom: 0
    &__button
      @include bp($phone)
        width: 100%
      &._active
        pointer-events: none
