.d-faq-section
  display: none
  @include bp($tablet)
    display: block
    margin-bottom: 40px
  @include bp($phone)
    margin-bottom: 32px
  &:last-child
    margin-bottom: 0
  &._active
    display: block
  &__name
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    margin-bottom: 40px
    @include bp($desktopXS)
      margin-bottom: 24px
    @include bp($tablet)
      @include font-size-lh(16,22)
      margin-bottom: 16px
  &__list
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px