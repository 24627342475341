.d-choose-params
  padding-top: 56px
  position: relative
  @include bp($desktopXS)
    padding-top: 48px
  @include bp($phone)
    padding-top: 40px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 36px
    @include bp($desktopXS)
      margin-bottom: 26px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($phone)
      display: block
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__buttons
    @include bp($tablet)
      display: none
    &._mobile
      display: none
      @include bp($tablet)
        display: flex
        column-gap: 12px
  &__buttonFilter, &__buttonOnMap
    display: flex
    align-items: center
    justify-content: center
    height: 64px
    padding: 0 24px
    border: none
    border-radius: 12px
    font-weight: 400
    @include font-size-lh(16,22)
    text-decoration: none
    @include bp($phone)
      flex-grow: 1
      height: 48px
    & img
      width: 16px
      margin-right: 8px
  &__buttonFilter
    background: $blue-color
    color: $white-color
    &._fixed
      position: fixed
      bottom: 24px
      left: 50%
      transform: translateX(-50%) translateY(100%)
      z-index: 10
      opacity: 0
      pointer-events: none
      transition: all $transition
      &:hover
        cursor: pointer
      &._show
        opacity: 1
        pointer-events: all
        transform: translateX(-50%) translateY(0)
  &__buttonOnMap
    background: $white-color
    color: $pen-color
  &__filter
    @include bp($tablet)
      display: none
  &__content
    border-top: 1px solid $grey20
    padding-top: 24px
    margin: 32px 0 120px
    position: relative
    @include bp($tablet)
      margin: 30px 0 96px
    @include bp($phone)
      padding-top: 16px
      margin: 16px 0 64px
  &__controls
    display: flex
    align-items: center
    @include bp($tablet)
      flex-wrap: wrap
  &__count
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin: 0 0 0 40px
    @include bp($tablet)
      width: 100%
      margin: 24px 0 0
      order: 4
    @include bp($phone)
      order: 3
    & .skeleton-field
      width: 164px
      height: 22px
      margin: 0
      background: $white-color
      &:before
        display: none
  &__sorter
    @include bp($tablet)
      margin-left: auto
      order: 2
    @include bp($phone)
      width: 100%
      margin: 16px 0 0
      order: 4
    &._hide
      display: none
      @include bp($tablet)
        display: block
    &._line
      margin: 0 0 16px
      @include bp($tablet)
        display: none
  &__switchView
    margin-left: 24px
    @include bp($tablet)
      order: 3
    @include bp($phone)
      margin-left: auto
      order: 2
    &._disabled
      & .d-switch-view
        pointer-events: none
        &__button
          color: $grey30
          &._active
            color: $white-color
        &__buttonBg
          background: $grey30
  &__results
    margin-top: 32px
    overflow: hidden
    @include bp($tablet)
      margin-top: 24px
    &._list
      & .d-choose-params
        &__list
          display: none
          &._list
            display: flex
            flex-direction: column
            row-gap: 8px
            @include bp($phone)
              row-gap: 12px
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-gap: 12px 0
    &._list
      display: none
  &__bottom
    grid-column: -1/1
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 40px
    @include bp($tabletS)
      flex-direction: column-reverse
    @include bp($phone)
      margin-top: 32px
  &__pager
    @include bp($tabletS)
      margin-top: 40px
    @include bp($phone)
      margin-top: 32px
  & .d-tabs__buttons
    margin-bottom: 0
    @include bp($tablet)
      order: 1
  &__tags
    display: flex
    flex-wrap: wrap
    gap: 5px
    margin-top: 24px
    &._projects
      margin-top: 0
      &._show
        border-bottom: 1px solid $grey20
        padding-bottom: 24px
  &__not-empty-result
    color: $red
    margin-left: 40px
    @include font-size-lh(16,22)
    max-width: 400px
    font-weight: 400
    @include bps($tablet)
      max-width: 100%
      order: 5
      margin-left: 0
      margin-top: 24px
    @include bps($phone)
      max-width: 100%
      order: 4
  &__sorter
    margin-left: auto
