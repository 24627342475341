.filter-projects
  &__inner
    display: flex
    flex-wrap: wrap
    gap: 8px
  &__title
    font-size: 16px
    line-height: 22px
    color: $grey50
    margin-bottom: 8px
  &__step
    margin-top: 8px
    &Lfirst-child
      margin-top: 0