.d-commercial-filter
  display: flex
  flex-direction: column
  row-gap: 32px
  position: relative
  &._mobile
    min-height: 100%
    background: $white-color
    padding: 72px 24px 18px 24px
    row-gap: 0
    @include bp($tablet)
      padding-bottom: 136px
    @include bp($phone)
      padding: 60px 12px 128px 12px
  &__title
    display: none
    @include bp($tablet)
      display: flex
      align-items: center
      @include font-size-lh(32,36)
      font-weight: 600
      color: $blue-color
      position: fixed
      background: $white-color
      top: 0
      left: 0
      width: 100%
      z-index: 3
      padding: 18px 0 18px 24px
    @include bp($phone)
      @include font-size-lh(24,28)
      padding: 16px 0 16px 12px
    &:after
      content: ''
      position: absolute
      width: calc(100% + 48px)
      left: -24px
      bottom: 0
      height: 1px
      background: $grey20
      display: block
      @include bp($phone)
        width: calc(100% + 24px)
        left: -12px
  &__row
    &._row1
      display: grid
      grid-template-columns: repeat(3, 14.5%) repeat(2, 19%) 1fr
      grid-column-gap: 24px
      grid-row-gap: 16px
      @include bp($desktopS)
        grid-template-columns: repeat(3, 1fr)
        //grid-gap: 16px 24px
      @include bp($tablet)
        display: flex
        justify-content: space-between
        flex-wrap: wrap
    &._skeleton
      @include bp($tablet)
        display: none
  &__item
    @include bp($tablet)
      width: 100%
    &._range
      @include bp($tablet)
        width: calc(50% - 12px)
      @include bp($phone)
        width: 100%
    & .skeleton-field
      height: 64px
      margin: 0
      background: $white-color
      &:before
        display: none
    &._clear
      align-self: center
      justify-self: flex-end
      @include bp($tablet)
        display: none
  &__actions
    display: flex
    column-gap: 4px
  &__action
    &.d-checkbox._full
      & .d-checkbox__label
        font-weight: 400
        background: $white-color
        @include hover
          color: $blue-color
      & svg
        width: 16px
        height: 16px
        margin-right: 8px
  &__clear
    display: flex
    align-items: center
    background: none
    border: none
    padding: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    @include transition(color)
    @include hover
      color: $blue-color
    & svg
      width: 24px
      height: 24px
      margin-right: 8px
      fill: currentColor
    &[disabled]
      opacity: 0.5
      pointer-events: none
  &__reset-mobile
    color: $grey50
    @include font-size-lh(14,18)
    display: none
    text-decoration: none
    font-weight: 400
    @include bp($phone)
      display: block
      margin-left: 16px
  &__bottom
    display: none
    gap: 8px
    position: fixed
    z-index: 5
    bottom: 0
    left: 0
    width: 100%
    border-top: 1px solid $grey20
    background: $white-color
    padding: 24px
    justify-content: flex-end
    @include bp($tablet)
      display: flex
  &__reset-tablet
    @include bp($phone)
      display: none
  &__view-result
    @include bp($phone)
      width: 100%
  &__name
    display: none
    @include bp($tablet)
      display: block
      margin-bottom: 4px
      @include font-size-lh(16,23)
      color: $grey50
  &__group
    width: 100%
    margin-bottom: -4px
    @include font-size-lh(16,22)
    font-weight: 600
    color: $pen-color