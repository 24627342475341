.d-choose-params._typePage
  & .d-tabs__button
    &._disable
      pointer-events: none

.d-type-page
  display: grid
  grid-template-columns: 592px 1fr
  grid-column-gap: 32px
  padding-top: 94px
  position: relative
  @include bp($desktopS)
    grid-template-columns: 384px 1fr
    padding-top: 78px
  @include bp($tablet)
    position: fixed
    left: 0
    top: 0
    display: block
    width: 100%
    height: 100%
    padding: 0
    background: $background
    overflow: auto
    z-index: 111
  &__back
    position: absolute
    left: 0
    top: 40px
    display: flex
    align-items: center
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    text-decoration: none
    background: none
    border: none
    padding: 0
    @include transition(color)
    cursor: pointer
    @include bp($desktopS)
      top: 32px
    @include hover
      color: $blue-color
    & svg
      width: 16px
      height: 16px
      margin-right: 6px
      transform: rotate(180deg)
      fill: currentColor
  &__item
    &._item1
      @include bp($tablet)
        position: sticky
        top: 0
        z-index: 7
    &._item2
      display: flex
      flex-direction: column
      row-gap: 8px
      @include bp($tablet)
        padding: 22px 24px 40px
      @include bp($phone)
        padding: 16px 12px 24px
        @include transition(padding)
      &._scroll
        @include bp($phone)
          padding-top: 126px
  &__more
    display: flex
    justify-content: center
    margin-top: 32px
    @include bp($phone)
      margin-top: 16px
  &__close
    display: none
    position: fixed
    right: 24px
    top: 24px
    z-index: 10
    @include bp($tablet)
      display: block
    @include bp($phone)
      right: 12px
      top: 12px
    & .d-button
      @include bp($tablet)
        width: 48px
        height: 48px
      @include bp($phone)
        width: 32px
        height: 32px