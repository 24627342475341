.d-burger
  display: block
  width: 20px
  height: 10px
  padding: 0
  background: none
  border: none
  color: $pen-color
  cursor: pointer
  position: relative
  outline: none
  @include bp($phone)
    //
  @include hover()
    color: $blue-color
  &:before, &:after
    content: ''
    position: absolute
    left: 0
    height: 2px
    background: currentColor
    border-radius: 3px
    @include transition(all)
  &:before
    top: 0
    width: 20px
  &:after
    bottom: 0
    width: 12px
  &._active
    height: 12px
    &:before, &:after
      width: 15px
      border-radius: 0
    &:before
      transform: rotate(45deg)
      transform-origin: left top
    &:after
      transform: rotate(-45deg)
      transform-origin: left bottom
