.d-search-popup
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: rgba($black, 0.6)
  backdrop-filter: blur(20px)
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  @include bp($phone)
    display: none
  &._show
    opacity: 1
    visibility: visible
    z-index: 100
  &__content
    max-width: 968px
    width: 100%
    padding: 32px
    margin: 0 auto
    @include bp($tablet)
      padding: 120px 32px
      max-width: 100%
    @include bp($tabletS)
      padding: 112px 24px
  &__close
    position: absolute
    right: 40px
    top: 32px
    @include bp($tablet)
      right: 32px
    @include bp($tabletS)
      right: 24px
      top: 24px