.popup-navsend
  display: flex
  justify-content: center
  & .mfp-content
    width: 640px !important
  &._inside
    width: 100%
  &.mfp-bg
    background-color: rgba(242,243,247,1)
    opacity: 0.9
  & .mfp-close
    @include mfp-close
    transform: translate(48px, -48px)
  &__inner
    background: $grey-light
    //background: red
    border-radius: 40px
    display: flex
    width: 100%
    overflow: hidden
    box-shadow: inset 0.2em 0.2em 0 0 #fff, 0.6em 0.6em 1.2em #d2dce9cc, -0.5em -0.5em 1em #d2dce9cc
  &__qr
    padding: 24px
    background: $white-color
    & img
      display: block
  &__steps
    padding: 24px 32px
  &__step
    flex-direction: column
    justify-content: center
    height: 100%
    display: none
    &._active
      display: flex
  &__title
    font-weight: 700
    @include font-size-lh(18,18)
  &__text
    margin-top: 16px
    & p
      @include font-size-lh(12,18)
  &__links
    display: flex
    flex-direction: column
    margin-top: 8px
  &__link
    @include font-size-lh(12,18)
    margin-bottom: 8px
    color: $brand-color
    text-decoration: underline
    &:hover
      text-decoration: none
    &:last-child
      margin-bottom: 0
  &__form
    //
  &__inp-wrap
    position: relative
  &__inp
    display: block
    position: relative
    width: 100%
    margin: 0
    padding: 16px 24px
    box-sizing: border-box
    border: none
    border-radius: 3rem
    background-color: #edeff3
    box-shadow: inset 1px 1px 2px 0 #d2dce9
    font-size: 18px
    text-align: left
    font-family: 'Gilroy', Helvetica, Arial, sans-serif
    font-weight: 500
    color: #36187d
    transition: opacity .2s ease,visibility .2s ease
    outline: none
    margin-bottom: 24px
    & ~ .error
      color: $error
      position: absolute
      bottom: calc( 100% + 12px)
      left: 50%
      transform: translateX(-50%)
      font-style: normal
      @include font-size-lh(12,14)
      white-space: nowrap
  &__submit
    width: 240px
    height: 60px
    vertical-align: middle
    opacity: 1
    border: none
    border-radius: 30px
    background-color: #8cd651
    box-shadow: inset 0.2rem 0.2rem 0.2rem 0 #e9f7dde6, 1.5rem 1.5rem 3rem #8cd6514d, -2.4rem -2.4rem 4.8rem #ffffff1a
    text-align: center
    color: #fff
    transition: opacity .3s ease,background-color .3s ease,box-shadow .3s ease
    white-space: nowrap
    font-size: 16px
    &:hover
      cursor: pointer
  &__back
    color: $brand-color
    text-decoration: underline
    @include font-size-lh(16,18)
    margin-top: 32px
    position: relative
    padding-left: 24px
    &:before
      content: "←"
      margin-right: 1rem
      transform: translateY(-50%)
      font-weight: 700
      display: block
      position: absolute
      top: 50%
      left: 0
    &:hover
      text-decoration: none