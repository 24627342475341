.d-commercial-purposes
  display: grid
  gap: 12px
  @include bp($tablet)
    display: flex
    flex-wrap: wrap
    gap: 8px
  &__item
    display: flex
    justify-content: space-between
    align-items: center