.offices-contacts-card
  background: #F2F3F7
  border-radius: 20px
  position: absolute
  top: 32px
  left: 32px
  z-index: 1
  padding: 24px 29px 24px 24px
  max-width: 424px
  width: 100%
  max-height: 596px
  &._contacts
    padding: 24px
  &__header
    display: flex
    align-items: center
  &__title
    margin-left: 24px
    @include font-size-lh(24,28)
    color: $brand-color
    font-weight: 600

  &__list
    margin-top: 24px
    &._contacts
      margin-top: 0
  &__preview
    &._hide
      display: none
    &._contacts
      max-height: 548px
      & .simplebar-track.simplebar-vertical
        width: 8px
        right: -18px
      & .simplebar-scrollbar:before
        background: $blue-color
        opacity: 1
        border-radius: 4px