.d-top-slider
  border-radius: 40px
  position: relative
  @include bp($phone)
    height: 420px
    border-radius: 24px
  &._index
    @include bp($phone)
      height: calc(var(--vh, 1vh) * 100 - 84px)
    & .d-top-slider
      &__arrows
        @include bp($desktopXS)
          bottom: 46px
        @include bp($phone)
          bottom: 16px
      &__timer
        left: 50%
        bottom: 56px
        transform: translateX(-50%)
        @include bp($desktopXS)
          bottom: 46px
      &__disclaimer
        @include bp($desktopXS)
          bottom: 8px
        @include bp($phone)
          bottom: 72px
          max-width: calc(100% - 32px)
    & .d-top-slide
      padding-bottom: 94px
      @include bp($desktopXS)
        padding-bottom: 40px
      @include bp($phone)
        align-items: flex-end
        padding-bottom: 150px
      @include bp($phoneXS)
        padding-bottom: 136px
      &__title
        @include font-size-lh(88,96)
        @include bp($desktopXS)
          @include font-size-lh(64,68)
        @include bp($phone)
          @include font-size-lh(32,44)
      &__subtitle
        @include bp($phone)
          @include font-size-lh(24,28)
          font-weight: 500
  &__pagination
    position: absolute
    left: 56px !important
    bottom: auto !important
    top: 56px
    z-index: 5
    @include bp($desktopXS)
      left: 40px !important
      top: 40px
    @include bp($tablet)
      left: 32px !important
      top: 32px
    @include bp($phone)
      left: 16px !important
      top: 16px
  &__arrows
    position: absolute
    right: 56px
    bottom: 56px
    z-index: 5
    @include bp($desktopXS)
      right: 40px
      bottom: 40px
    @include bp($tablet)
      right: 32px
      bottom: 32px
    @include bp($phone)
      right: 16px
      bottom: 16px
  &__timer
    position: absolute
    left: 56px
    bottom: 87px
    width: 128px
    height: 2px
    background: $white-color
    border-radius: 2px
    z-index: 5
    @include bp($desktopXS)
      left: 40px
      bottom: 71px
    @include bp($tablet)
      left: 32px
      bottom: 63px
    @include bp($phone)
      display: none
    &.animate.active:before
      content: ''
      display: block
      height: 2px
      background: $blue-color
      animation: 4s linear 0s infinite timeReady
  &__disclaimer
    position: absolute
    left: 56px
    bottom: 16px
    z-index: 5
    @include bp($desktopXS)
      left: 40px
      bottom: 16px
    @include bp($tablet)
      left: 32px
    @include bp($phone)
      left: 16px
      bottom: 16px
      max-width: calc(100% - 136px)

.d-top-slide
  display: flex
  align-items: center
  padding: 56px
  position: relative
  @include bp($desktopXS)
    padding: 40px
  @include bp($tablet)
    padding: 32px
  @include bp($phone)
    padding: 16px
  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)
    z-index: 2
  &.swiper-slide
    height: 732px
    @include bp($desktopXS)
      height: 572px
    @include bp($tablet)
      height: 520px
    @include bp($phone)
      height: 100%
  &__img
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    z-index: 1
    & > *
      width: 100%
      height: 100%
      object-fit: cover

  &__inner
    max-width: 704px
    font-weight: 600
    color: $white-color
    position: relative
    z-index: 3
    @include bp($tabletS)
      max-width: 510px
    @include bps($phone)
      max-width: 100%
  &__title
    @include font-size-lh(64,68)
    @include bp($tablet)
      @include font-size-lh(52,56)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__subtitle
    @include font-size-lh(32,36)
    margin-top: 34px
    @include bp($desktopXS)
      margin-top: 24px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-top: 16px
    &._mos
      & h2
        @include bp($phone)
          @include font-size-lh(24,28)
  &__more
    position: absolute
    left: 56px
    bottom: 56px
    z-index: 3
    @include bp($desktopXS)
      left: 40px
      bottom: 46px
    @include bp($tablet)
      left: 32px
    @include bp($phone)
      left: 16px
      bottom: 16px

@keyframes timeReady
  0%
    width: 0
  100%
    width: 100%
