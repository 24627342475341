.d-cashback-form
  @include transition(all)
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    margin-bottom: 24px
    @include bp($desktopXS)
      max-width: 532px
      margin-left: auto
      margin-right: auto
      @include font-size-lh(16,22)
  &__row
    display: flex
    flex-wrap: wrap
    margin-bottom: 16px
    @include bp($phone)
      margin-bottom: 12px
    &._agree
      margin: 24px 0 0
      @include bp($phone)
        margin-top: 27px
      & .d-checkbox__label
        font-weight: 400
        white-space: normal
        @include bp($phone)
          @include font-size-lh(14,18)
        & button
          background: none
          border: none
          padding: 0
          color: currentColor
          cursor: pointer
          text-decoration: underline
          text-underline-offset: 3px
          @include transition(color)
          @include hover
            color: $blue-color
      & .captcha-info
        color: $pen-color
        margin: -8px 0
        & a
          @include hover
            color: $blue-color
    &._button
      margin: 26px 0 0
      @include bp($phone)
        margin-top: 27px
  &__item
    width: 100%
    &._half
      width: calc(50% - 16px)
      margin-left: 32px
      @include bp($phone)
        width: 100%
        margin: 12px 0 0
      &:first-child
        margin: 0
  &__button
    width: 100%
  & .d-input
    &_field
      padding-right: 56px
      @include bp($phone)
        padding-right: 48px
    &__error
      right: auto
      left: calc(100% + 12px)
      bottom: calc(50% - 13px)
      transform: translateY(50%)
      width: 185px
      padding: 12px 16px
      background: $white-color
      box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
      border-radius: 12px
      font-weight: 400
      @include font-size-lh(14,18)
      color: $red
      @include bp($tablet)
        position: relative
        left: 0
        bottom: 0
        transform: none
        width: auto
        padding: 0
        margin-top: 4px
        background: none
        box-shadow: none
        border-radius: 0
      &._left
        right: calc(100% + 12px)
        left: auto
        @include bp($tablet)
          right: 0
  &._success
    opacity: 0
    visibility: hidden
    z-index: -1
    @include bp($phone)
      height: 278px
      overflow: hidden