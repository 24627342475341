.d-press-center-error
  display: flex
  align-items: center
  justify-content: center
  min-height: 440px
  height: 100%
  background: $white-color
  border-radius: 32px
  @include bp($desktopXS)
    min-height: 420px
  @include bp($phone)
    min-height: 282px
    border-radius: 16px
  &__content
    display: flex
    flex-direction: column
    align-items: center
    max-width: 410px
    padding-top: 88px
    position: relative
    @include bp($phone)
      padding-top: 84px
    &:before
      content: ''
      position: absolute
      left: 50%
      top: 0
      transform: translateX(-50%)
      width: 64px
      height: 64px
      background: $grey5 url('../assets/img/d/icon_close_red.svg') no-repeat center / 32px
      border-radius: 50%
  &__text
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    @include bp($phone)
      @include font-size-lh(16,22)
  &__button
    margin-top: 24px
    @include bp($phone)
      margin-top: 20px