.d-drop-sorter
  &__item, &__head
    display: flex
    align-items: center
    justify-content: flex-start
    column-gap: 16px
    svg
      flex-shrink: 0
      width: 24px
      height: 24px
      path
        fill: currentColor
    &._asc
      svg
        transform: rotate(180deg)