.d-installment
  &__top
    display: grid
    grid-template-columns: 1fr 436px
    grid-column-gap: 32px
    margin-bottom: 120px
    @include bp($desktopS)
      grid-template-columns: 1fr 356px
    @include bp($desktopXS)
      grid-template-columns: 1fr 280px
      margin-bottom: 96px
    @include bp($tablet)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 24px
      margin-bottom: 80px
    @include bp($phone)
      grid-row-gap: 10px
      margin-bottom: 56px
  &__facts
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 24px
      grid-row-gap: 0
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 12px
    & .d-purchase-fact
      &__text
        max-width: 340px

.d-installment-title
  font-weight: 600
  @include font-size-lh(40,44)
  @include bp($phone)
    @include font-size-lh(32,36)

.d-installment-advantages
  &__title
    margin-bottom: 56px
    @include bp($desktopXS)
      margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 32px
    @include bp($desktopXS)
      grid-template-columns: repeat(2, 1fr)
    @include bp($tablet)
      grid-gap: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 12px
  &__item
    //

.d-installment-advantage
  display: flex
  align-items: center
  background: $white-color
  border-radius: 24px
  min-height: 160px
  height: 100%
  padding: 38px 40px
  @include bp($tablet)
    min-height: 120px
    padding: 20px 24px
  @include bp($phone)
    min-height: 80px
    padding: 16px
  &__icon
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 80px
    aspect-ratio: 1/1
    margin-right: 32px
    background: $grey5
    border-radius: 50%
    @include bp($tablet)
      width: 72px
      margin-right: 16px
    @include bp($phone)
      width: 48px
    & img
      width: 32px
      @include bp($tablet)
        width: 24px
  &__name
    flex-grow: 1
    font-weight: 600
    @include font-size-lh(24,28)
    @include bp($tablet)
      @include font-size-lh(18,24)
    @include bp($phone)
      @include font-size-lh(14,18)