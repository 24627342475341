.d-index-geography
  @include bp($phone)
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: -1
    opacity: 0
    visibility: hidden
    @include transition(all)
    pointer-events: none
  &._show
    z-index: 111
    opacity: 1
    visibility: visible
    pointer-events: all
  &__title
    @include bp($phone)
      display: none
  &__map
    height: 640px
    @include bp($desktopXS)
      height: 560px
    @include bp($tablet)
      height: 560px
    @include bp($tabletS)
      height: 700px
    @include bp($phone)
      height: 100%
  &__close
    display: none
    @include bp($phone)
      position: absolute
      right: 12px
      top: 12px
      display: flex
      z-index: 11

.d-index-geography-map
  height: 100%
  border-radius: 48px
  overflow: hidden
  position: relative
  @include bp($phone)
    border-radius: 0
  &__zoom
    @include bp($phone)
      display: none
  & .d-map__lock-toggle
    @include bp($tablet)
      right: 16px

.d-index-geography-map-point
  position: absolute
  display: flex
  width: 74px
  height: 74px
  background: $blue-color
  border: 4px solid $blue-color
  border-radius: 12px
  @include transition(all)
  @include hover
    width: 306px
    height: 110px
    margin: 0 0 -2px -18px
    cursor: pointer
    & .d-index-geography-map-point
      &__img
        width: 102px
        height: 102px
        &:after
          top: calc(100% + 6px)
          width: 10px
          height: 10px
      &__info
        width: 196px
        padding: 8px 8px 8px 12px
        opacity: 1
  &__img
    width: 66px
    height: 66px
    @include transition(all)
    position: relative
    & img
      @include objectFit(100%, cover)
      border-radius: 8px
    &:after
      content: ''
      position: absolute
      left: 50%
      top: calc(100% + 8px)
      transform: translateX(-50%)
      width: 8px
      height: 8px
      background: $blue-color
      border-radius: 50%
      @include transition(all)
  &__info
    font-weight: 500
    @include font-size-lh(14,18)
    color: $white-color
    width: 0
    padding: 0
    opacity: 0
    overflow: hidden
    @include transition(all)
    @include bp($tablet)
      display: none
    &:after
      content: ''
      position: absolute
      right: 12px
      top: 12px
      width: 6px
      height: 6px
      border-top: 2px solid $white-color
      border-right: 2px solid $white-color
  &__name, &__price
    font-weight: 600
    @include font-size-lh(16,22)
  &__address
    margin: 2px 0 4px
  &._active
    background: $pen-color
    border-color: $pen-color
    & .d-index-geography-map-point
      &__img
        &:after
          background: $pen-color
  &._empty
    @include hover
      width: 74px
      height: 74px
      margin: 0
      cursor: default
      & .d-index-geography-map-point
        &__img
          width: 66px
          height: 66px
          &:after
            top: calc(100% + 8px)
            width: 8px
            height: 8px
        &__info
          width: 0
          padding: 0
          opacity: 0

.d-index-geography-map-point-info
  position: absolute
  left: 50%
  bottom: 0
  transform: translateX(-50%)
  width: 320px
  padding: 16px
  display: flex
  flex-direction: column
  align-items: flex-start
  background: $blue-color
  border-radius: 12px 12px 0 0
  font-weight: 500
  @include font-size-lh(14,18)
  color: $white-color
  opacity: 0
  visibility: hidden
  @include transition(all)
  &._infra
    @include bp($tablet)
      bottom: 72px
      border-radius: 12px
    &._offset
      @include bp($phone)
        bottom: 150px
  &._show
    @include bp($isTouchDevice)
      opacity: 1
      visibility: visible
  &__name
    margin-right: 56px
    font-weight: 600
    @include font-size-lh(18,21)
    color: $white-color
    text-decoration: none
    //padding-right: 28px
    //margin-right: 56px
    //background: url('../assets/img/icons/arrow_light.svg') no-repeat right 1px
    &:after
      content: ''
      position: relative
      top: 4px
      display: inline-block
      width: 20px
      height: 21px
      background: url('../assets/img/icons/arrow_light.svg') no-repeat right top
  &__content
    display: flex
    align-items: flex-end
    margin-top: 12px
  &__price
    flex-shrink: 0
    font-weight: 600
    @include font-size-lh(16,22)
  &__address
    margin-right: 24px
    font-weight: 500
    @include font-size-lh(14,18)
  &__close
    position: absolute
    right: 16px
    top: 16px
    background: none
    border: none
    padding: 0
    cursor: pointer
    & img
      display: block
      width: 24px
