.d-object-tabs-slider
  @include bps($tablet)
    width: calc(100% + 48px)
    margin-left: -24px
  @include bps($phone)
    width: calc(100% + 24px)
    margin-left: -12px
  &__slider.swiper
    position: relative
    display: inline-flex
    align-items: center
    background: $white-color
    border-radius: 12px
    padding: 8px
    max-width: 100%
    @include bps($tablet)
      padding: 0 24px
      border-radius: 0
      width: auto
      //margin-bottom: 24px !important
      background: transparent
      overflow: auto !important
    @include bps($phone)
      padding: 0 12px
    &::-webkit-scrollbar
      display: none
    & .swiper-wrapper
      @include bp($tablet)
        //width: calc(100% + 48px)
        background: $white-color
        width: auto
        max-width: unset
        flex-wrap: nowrap
        padding: 8px !important
        border-radius: 12px
      @include bps($phone)
        padding: 4px !important
  &__slide.swiper-slide
    width: auto !important
  &__item
    width: auto !important
    height: 48px !important
    padding: 0 24px
    display: flex
    align-items: center
    justify-content: center
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    white-space: nowrap
    background: none
    border: 0
    cursor: pointer
    user-select: none
    @include transition(color)
    position: relative
    z-index: 2
    text-decoration: none!Important
    border-radius: 8px
    transition: all $transition
    &._active
      //background: $pen-color
      color: $white-color
      &:hover
        color: $white-color
    @include hover
      color: $blue-color
    @include bp($phone)
      height: 40px !important
      padding: 0 16px
      @include font-size-lh(14,18)
  &__bg
    position: absolute
    top: 0
    height: 48px
    background: $pen-color
    border-radius: 8px
    transition: left $transition, width $transition
    z-index: -1
    @include bps($tablet)
      top: 8px
    @include bp($phone)
      top: 4px
      height: 40px
  &__content
    display: none
    @include bps($tablet)
      width: calc(100% - 48px)
      margin-left: 24px
    @include bps($phone)
      width: calc(100% - 24px)
      margin-left: 12px
    &._active
      display: block