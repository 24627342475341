.flat-furnish-calc-counter
  display: flex
  align-items: center
  gap: 8px
  margin-top: 8px
  &__btn
    width: 32px
    height: 32px
    border-radius: 8px
    border: 1px solid $pen-color
    display: flex
    align-items: center
    justify-content: center
    transition: all $transition
    background: transparent
    &:hover
      cursor: pointer
      background: $pen-color
      & svg
        fill: $white-color
    & svg
      fill: $pen-color
      transition: all $transition
    &._disabled
      pointer-events: none
      opacity: 0.5
  &__input
    width: 32px
    height: 32px
    background: $grey5
    border: none
    outline: none
    text-align: center
    padding: 0 4px
    color: $pen-color
    @include font-size-lh(16,22)