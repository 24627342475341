.ba-button
  border: none
  outline: none
  padding: 0 24px
  box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.7), inset 2px 1px 0px #FEFEFE
  border-radius: 46px
  height: 55px
  text-decoration: none
  color: $pen-color
  white-space: nowrap
  @include font-size-lh(16,19)
  width: 100%
  font-weight: 600
  transition: all $transition
  @include bp($phone)
    @include font-size-lh(14,17)
  &:hover
    cursor: pointer
    background: $blue-color
    color: $white-color
  &._active
    background: $blue-color
    color: $white-color