.d-countdown
  position: absolute
  right: 56px
  top: 56px
  width: 244px
  background: $white-color
  border-radius: 24px
  font-weight: 600
  z-index: 11
  &:not([data-end-date])
    width: 230px
  @include bp($desktopXS)
    right: 48px
    top: 40px
    width: 208px
    border-radius: 20px
  @include bp($tablet)
    right: 32px
    top: 32px
  @include bp($phone)
    right: auto
    left: 16px
    top: 64px
    width: 264px // calc(100% - 32px)
    border-radius: 12px
    display: flex
    align-items: center
    column-gap: 8px
    &:not([data-end-date])
      width: 164px
      top: 16px
      right: 16px
      left: unset
  &__time
    display: flex
    column-gap: 12px
    justify-content: center
    background: $blue-color url("../assets/img/d/countdown_bg.png") no-repeat center
    border-radius: 24px
    padding: 10px 0 6px
    margin-bottom: 6px
    @include bp($desktopXS)
      column-gap: 9px
      border-radius: 20px
      padding: 6px 0
      margin-bottom: 8px
    @include bp($phone)
      flex-shrink: 0
      column-gap: 6px
      background-size: auto 48px
      border-radius: 12px
      width: 152px
      padding: 1px 0 5px
      margin-bottom: 0
    &._notify
      margin: 0!important
      padding: 24px
      color: $white-color
      font-weight: 600
      @include font-size-lh(20,24)
      background-size: cover
      @include bp($phone)
        width: 164px
        @include font-size-lh(14,16)
        padding: 16px
  &__item
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    width: 63px
    @include bp($desktopXS)
      width: 54px
    @include bp($phone)
      width: 44px
    &:first-child
      &:before
        display: none
    &:before
      content: ':'
      position: absolute
      right: 100%
      bottom: 0
      width: 12px
      @include font-size-lh(40,44)
      color: rgba($white-color, .5)
      text-align: center
      animation: 1s ease-in-out 0s infinite dotAnimate
      @include bp($desktopXS)
        width: 9px
        font-size: 32px
      @include bp($phone)
        width: 6px
        @include font-size-lh(24,26)
        letter-spacing: -.02em
  &__label
    @include font-size-lh(14,18)
    color: $white-color
    @include bp($desktopXS)
      font-size: 12px
  &__digit
    @include font-size-lh(40,44)
    color: $white-color
    margin-top: 2px
    @include bp($desktopXS)
      font-size: 32px
    @include bp($phone)
      @include font-size-lh(24,26)
      letter-spacing: -.02em
      margin-top: -2px
  &__title
    margin-bottom: 10px
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($phone)
      margin-bottom: 0
      @include font-size-lh(14,16)
      text-align: left

@keyframes dotAnimate
  0%
    opacity: 1
  40%
    opacity: 1
  50%
    opacity: 0
  90%
    opacity: 0
  100%
    opacity: 1
