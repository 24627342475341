.d-textarea
  position: relative
  &__field
    width: 100%
    height: 120px
    padding: 20px 24px
    background: $white-color !important
    border: 1px solid $white-color
    border-radius: 12px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color !important
    outline: none
    @include transition(all)
    resize: none
    @include bp($phone)
      height: 144px
    @include hover
      border-color: $blue-color
      color: $blue-color !important
      &::placeholder
        color: $blue-color
    &::placeholder
      color: $grey50
