.d-aux-rooms-popup
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  background: $background
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  &._show
    opacity: 1
    visibility: visible
    z-index: 99
  & .simplebar-content
    display: flex
    min-height: 100%
  & .d-select__list .simplebar-content
    display: block
    min-height: unset
  &__inner
    flex-grow: 1
    display: flex
    flex-direction: column
    max-width: 1920px
    width: 100%
    padding: 40px
    margin: 0 auto
    @include bp($desktopXS)
      padding: 32px
    @include bp($tablet)
      padding: 24px
    @include bp($phone)
      padding: 76px 12px 16px
  &__top, &__bottom
    flex-shrink: 0
  &__top
    display: flex
    @include bp($phone)
      flex-direction: column
      align-items: flex-start
  &__center
    flex-grow: 1
    height: calc(100vh - 64px - 24px - 40px * 2)
    display: grid
    grid-template-columns: 1fr 607px
    grid-template-rows: auto 1fr auto
    background: $white-color
    border-radius: 40px
    padding: 32px 24px 32px 32px
    margin-top: 24px
    @include bp($desktopS)
      height: calc(100vh - 64px - 24px - 32px * 2)
    @include bp($desktopXS)
      grid-template-columns: 1fr 448px
      border-radius: 32px
      height: calc(100vh - 64px - 16px - 24px * 2)
      padding: 24px
      margin-top: 16px
    @include bp($tablet)
      grid-template-columns: 1fr
      height: auto
      padding-bottom: 32px
      margin: 24px -24px 0
    @include bp($phone)
      border-radius: 16px
      padding: 16px 12px 24px
      margin: 24px -12px 0
  &__bottom
    margin-top: 40px
    @include bp($phone)
      margin-top: 32px
  &__selects
    display: flex
    @include bp($phone)
      display: block
      width: 100%
  &__select
    width: 216px
    height: 64px
    background: $white-color
    border-radius: 12px
    @include bp($phone)
      width: 100%
      height: 48px
  &__legend
    display: flex
    align-items: center
    column-gap: 20px
    height: 64px
    padding: 0 20px
    margin-left: 28px
    background: $white-color
    border-radius: 12px
    @include bp($phone)
      justify-content: center
      column-gap: 16px
      width: 100%
      height: 48px
      margin: 16px 0 0
  &__legendItem
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    padding-left: 18px
    position: relative
    @include bp($phone)
      @include font-size-lh(12,14)
    &:before
      content: ''
      position: absolute
      left: 0
      top: 5px
      width: 12px
      height: 12px
      background: $pen-color
      border-radius: 50%
      box-sizing: border-box
      @include bp($phone)
        top: 1px
    &._free
      &:before
        background: $blue-color
    &._busy
      &:before
        background: none
        border: 1px solid $grey40
    & span
      @include bp($phone)
        display: none
      &.phone
        display: none
        @include bp($phone)
          display: block
  &__schemeWrap
    grid-row: 1 / 3
    padding-right: 112px
    position: relative
    @include bp($desktopXS)
      padding-right: 108px
    @include bp($tablet)
      grid-row: unset
      height: 496px
      padding: 24px 0
    @include bp($phone)
      height: 440px
      padding: 24px 12px
      margin: 0 -12px
      overflow: hidden
    &:before
      content: ''
      position: absolute
      right: 0
      top: -32px
      width: 1px
      height: calc(100% + 64px)
      background: $grey20
      @include bp($desktopXS)
        top: -24px
        height: calc(100% + 48px)
      @include bp($tablet)
        right: -24px
        top: auto
        bottom: 0
        width: calc(100% + 48px)
        height: 1px
      @include bp($phone)
        display: none
  &__zoom
    position: absolute
    right: 24px
    top: 50%
    transform: translateY(-50%)
    @include bp($tablet)
      display: none
  &__swipe
    position: absolute
    bottom: 24px
    left: 50%
    transform: translateX(-50%)
    z-index: 100
    display: none
    max-width: 393px
    width: calc(100% - 24px)
    @include bp($tablet)
      display: block
    & .d-swipe-info
      font-weight: 400
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
  &__infoTop
    position: relative
    padding: 8px 116px 32px 32px
    margin-bottom: 32px
    @include bp($desktopXS)
      padding: 0 116px 24px 24px
      margin-bottom: 24px
    @include bp($tablet)
      padding-left: 0
      margin-bottom: 0
    @include bp($phone)
      position: static
      padding: 0
    &:before
      content: ''
      position: absolute
      left: 0
      bottom: 0
      width: calc(100% + 24px)
      height: 1px
      background: $grey20
      @include bp($tablet)
        left: -24px
        width: calc(100% + 48px)
      @include bp($phone)
        display: none
  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(24,28)
  &__advantages
    display: flex
    gap: 4px
    margin-top: 16px
    @include bp($desktopXS)
      margin-top: 12px
    @include bp($phone)
      margin-top: 8px
  &__info
    display: flex
    padding-left: 32px
    position: relative
    @include bp($desktopXS)
      padding-left: 24px
    @include bp($tablet)
      padding: 24px 0 0
    @include bp($phone)
      padding: 0
  &__icons
    position: absolute
    right: 0
    top: 0
    display: flex
    column-gap: 8px
    z-index: 11
    @include bp($phone)
      right: auto
      left: 12px
      top: 12px
    & .d-button
      &._flatIcon
        @include bp($phone)
          width: 48px
          height: 48px
          border: 1px solid $pen-color
      &__tooltip
        left: auto
        right: 0
        transform: none
    & .d-share
      @include bp($phone)
        left: 0
        bottom: auto
        top: calc(100% + 8px)
        transform: none
  &__close
    position: absolute
    right: 40px
    top: 40px
    @include bp($desktopXS)
      right: 32px
      top: 32px
    @include bp($tablet)
      right: 24px
      top: 24px
    @include bp($phone)
      right: 12px
      top: 12px

