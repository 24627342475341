.d-flat-mortgage
  &__content
    display: grid
    grid-template-columns: 1fr 592px
    grid-column-gap: 32px
    grid-row-gap: 32px
    @include bp($desktopS)
      grid-template-columns: 1fr 384px
    @include bp($tablet)
      grid-template-columns: 1fr
      grid-column-gap: 0
    @include bp($phone)
      grid-row-gap: 24px
    &._info
      display: block!important

  &__list
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    align-content: start
    @include bp($tablet)
      padding-top: 8px
      order: 2
    @include bp($phone)
      grid-row-gap: 12px
      padding-top: 16px
  &__calc
    @include bp($tablet)
      order: 1
  &__more
    background: none
    border: none
    padding: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
    @include transition(color)
    cursor: pointer
    @include bp($tablet)
      order: 3
    @include hover
      color: $pen-color
  & .d-sorter-line
    @include bp($phone)
      display: none
  & .d-sorter
    display: none
    @include bp($phone)
      display: block
      justify-self: start
      margin-bottom: 12px

.d-flat-mortgage-empty
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background: $white-color
  border-radius: 40px
  @include bp($tablet)
    padding: 40px
    margin-top: 8px
    order: 2
  @include bp($phone)
    padding: 24px 16px
    margin-top: 16px
    border-radius: 24px
  &:before
    content: ''
    width: 64px
    height: 64px
    padding: 0
    background: $grey5 url('../assets/img/d/icon_refresh.svg') no-repeat center / 32px
    border: 0
    border-radius: 50%
  &__text
    max-width: 590px
    margin: 24px 0
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    @include bp($phone)
      margin: 20px 0
      font-weight: 500
      @include font-size-lh(16,22)
  &._error
    &:before
      background-image: url('../assets/img/d/icon_close_red.svg')
