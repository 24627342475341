.ba-header
  background: #F2F3F7
  position: relative
  z-index: 35
  transition: transform $transition
  position: fixed
  top: 0
  left: 0
  width: 100%
  //@include bp($tablet)
  //  position: static

  &._menuOpen
    @include bp($tablet)
      background: transparent
    & .ba-header-top
      @include bp($tablet)
        border-bottom-color: transparent
      &__info, &__icons
        @include bp($tablet)
          opacity: 0
          visibility: hidden
  &._hide
    transform: translateY(-100%)
  &._fixed
    @include bp($tablet)
      position: fixed
      top: 0
      transform: translateY(0)
  &__inner
    //max-width: 1680px
    width: 100%
    padding: 0 32px
    margin: 0 auto
    @include bp($desktop)
      padding: 0 54px
    @include bp($phone)
      padding: 0 16px
  &__top
    border-bottom: 1px solid #DDDEE2
    @include bp($tablet)
      border: none
  & button
    padding: 0
    border: none
    cursor: pointer

.ba-header-top
  position: relative
  &__inner
    height: 114px
    display: flex
    align-items: center
    justify-content: space-between
    @include bp($phone)
      height: 64px
  &__left, &__right
    width: auto
    display: flex
    align-items: center
    @include bp($desktop)
      width: 25%
  &__left
    justify-content: flex-start
  &__right
    justify-content: flex-end
  &__burger
    margin-right: 42px
    @include bp($tablet)
      display: none
    &._tablet
      display: none
      margin: 0 0 0 24px
      @include bp($tablet)
        display: block
      @include bp($phone)
        margin-left: 16px
  &__logo
    & img
      display: block
      @include bp($phone)
        width: 107px
  &__menu
    display: flex
    align-items: center
    margin: 0 auto 0 auto
    position: relative
    z-index: 2
    @include bp($desktop)
      margin: 0
    @include bp($tablet)
      margin-left: 6.2%
    @include bp($tablet)
      display: none
    & a
      color: $pen-color
      text-decoration: none
      margin: 0 20px
      @include font-size-lh(16,19)
      transition: opacity $transition
      @include bp($hover)
        &:hover
          opacity: .6
  &__search
    position: absolute
    left: 50%
    top: 50%
    width: 45.36%
    transform: translate(-50%, -50%)
    z-index: 1
    @include bp($tablet)
      width: 48.36%
      transform: translate(-55%, -50%)
    @include bp($tablet)
      display: none
    &._show
      z-index: 3
  &__searchButton
    position: absolute
    right: 0
    top: 0
    &:hover
      cursor: pointer
    & img
      pointer-events: none
  &__info
    display: flex
    flex-direction: column
    align-items: flex-end
    transition: all $transition
  &__phone
    font-weight: 600
    font-size: 16px
    line-height: 19px
    color: $pen-color
    text-decoration: none
  &__time
    font-weight: 500
    font-size: 12px
    line-height: 15px
    color: #6E768D
    text-align: right
    @include bp($tablet)
      display: none
  &__callMe
    font-weight: 600
    font-size: 14px
    line-height: 17px
    color: $brand-color
    background: none
    border: none
    border-bottom: 1px solid $brand-color
    padding: 0
    margin-top: 6px
    cursor: pointer
    @include bp($tablet)
      display: none
  &__icons
    display: flex
    align-items: center
    transition: all $transition
    @include bp($phone)
      display: none
  &__icon
    margin-left: 16px
    &._favorite
      position: relative
      & span
        text-decoration: none
        color: $white-color
        background: $brand-color
        position: absolute
        top: 0
        right: 0
        width: 16px
        height: 16px
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        @include font-size-lh(8,8)

.ba-header-icon
  display: flex
  align-items: center
  justify-content: center
  width: 50px
  height: 50px
  padding: 0
  background: #F2F3F7
  box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.7), inset 2px 1px 0 #FEFEFE
  border: none
  border-radius: 50%

body.contacts
  & .ba-header__top
    border-bottom: none