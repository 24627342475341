.d-progress-popup
  display: flex
  width: calc(100% - 80px)
  margin: auto
  height: 848px
  border-radius: 40px
  overflow: hidden
  position: relative
  &:after
    content: ''
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
    height: 200px
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    pointer-events: none
    z-index: 2
    display: none
    @include bp($tablet)
      display: block
    @include bp($phone)
      height: 320px
  @include bp($desktopS)
    height: 698px
  @include bp($tablet)
    width: 100%
    height: 100%
    border-radius: 0
  @include bp($phone)
    //height: 90%
  &__close
    position: absolute
    top: 40px
    right: 40px
    z-index: 2
    @include bp($tablet)
      top: 34px
      right: 32px
    @include bp($phone)
      top: 12px
      right: 12px
  &__content
    width: 624px
    background: $white-color
    padding: 56px 40px
    position: relative
    display: flex
    flex-direction: column
    overflow: hidden
    //& div
    //  overflow-y: auto
    @include bp($tablet)
      padding: 38px 40px
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      border-radius: 40px 40px 0 0
      z-index: 11
      transform: translateY(150%)
      transition: transform $transition
      max-height: 90%
      height: 48%
    @include bp($phone)
      padding: 20px 0px 20px 16px
      border-radius: 24px 24px 0 0
      height: 90%
    &._show
      @include bp($tablet)
        transform: translateY(0)
        display: flex
        flex-direction: column
  &__title
    @include font-size-lh(36,40)
    color: $pen-color
    font-weight: 600
    position: relative
    margin-bottom: 40px
    @include bp($tablet)
      @include font-size-lh(32,36)
      margin-bottom: 38px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 16px
    &:after
      content: ''
      width: calc(100% + 80px)
      height: 1px
      background: $grey20
      position: absolute
      bottom: -40px
      left: -40px
      @include bp($phone)
        width: calc(100% + 32px)
        left: -16px
        bottom: -16px
  &__text
    margin-top: 40px
    @include font-size-lh(16,22)
    font-weight: 600
    color: $grey50
    flex-grow: 1
    height: auto
    overflow: hidden
    width: calc(100% + 20px)
    position: relative
    display: flex
    @include bp($tablet)
      width: 100%
    @include bp($phone)
      margin-top: 16px
    & .simplebar-scrollable-y
      width: 100%
    & .simplebar-track.simplebar-vertical
      @include bps($phone)
        right: 12px !important
    &_scrollarea
      width: 100%
  &__scroll
    max-height: 100%
    height: 100%
    width: 100%
    min-height: 100%
    padding-right: 20px
    //overflow-x: hidden
    @include bp($tablet)
      padding-right: 0
    @include bps($phone)
      padding-right: 24px
  &__slider
    height: 100%
    width: calc(100% - 624px)
    @include bp($tablet)
      width: 100%
      height: 100%
    &._full
      width: 100%
  &__more
    display: none
    @include bp($tablet)
      display: block
      position: absolute
      bottom: 32px
      right: 183px
      z-index: 3
    @include bp($phone)
      bottom: 12px
      right: unset
      left: 12px
  &__close-content
    position: absolute
    top: 32px
    right: 32px
    display: none
    z-index: 4
    @include bp($tablet)
      display: flex
    @include bp($phone)
      top: 16px
      right: 16px
      width: 32px !important
      height: 32px !important
  &__overlay
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.6)
    backdrop-filter: blur(20px)
    z-index: 7
    opacity: 0
    visibility: hidden
    transition: all $transition
    &._show
      opacity: 1
      visibility: visible
