.d-aux-rooms
  padding-top: 56px
  @include bp($phone)
    padding-top: 40px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 44px
    @include bp($desktopXS)
      margin-bottom: 22px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($phone)
      flex-direction: column
      align-items: flex-start
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__filter
    @include bp($tablet)
      display: none
  &__content
    border-top: 1px solid $grey20
    padding-top: 24px
    margin: 24px 0 120px
    @include bp($tablet)
      margin: 22px 0 96px
    @include bp($phone)
      padding-top: 16px
      margin: 24px 0 64px
  &__controls
    display: flex
    align-items: center
    @include bp($tablet)
      flex-wrap: wrap
    & .d-aux-rooms__tags
      display: none
      @include bp($phone)
        display: flex
  &__buttonFilter
    align-items: center
    justify-content: center
    height: 64px
    padding: 0 24px
    background: $blue-color
    border: none
    border-radius: 12px
    font-weight: 400
    @include font-size-lh(16,22)
    color: $white-color
    text-decoration: none
    order: 1
    @include bp($tablet)
      display: flex
      margin-right: auto
    @include bp($phone)
      width: 142px
      height: 48px
      order: unset
    & img
      width: 16px
      margin-right: 8px
    &._showFilter
      cursor: pointer
      position: sticky
      bottom: 24px
      width: 127px
      height: 64px
      z-index: 98
      left: 50%
      animation: slideUp 0.5s normal forwards ease-in-out
      @include bp($phone)
        bottom: 16px
        width: 119px
        height: 48px
  &__count
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-right: auto
    order: 1
    @include bp($tablet)
      width: 100%
      margin-top: 24px
      order: 4
    @include bp($phone)
      order: unset
  &__sorter
    width: 350px
    order: 2
    @include bp($tablet)
      margin-left: auto
      //order: 2
    @include bp($phone)
      width: 100%
      margin: 24px 0 0
      order: unset //4
    &._hide
      display: none
      @include bp($tablet)
        display: block
    &._line
      width: auto
      margin: 0 0 16px
      order: unset
      @include bp($tablet)
        display: none
  &__switchView
    margin-left: 24px
    order: 3
    @include bp($tablet)
      //order: 3
    @include bp($phone)
      order: unset
  &__results
    margin-top: 40px
    @include bp($desktopS)
      margin-top: 32px
    @include bp($tablet)
      margin-top: 24px
    &._list
      & .d-aux-rooms
        &__list
          &._list
            display: flex
            flex-direction: column
            row-gap: 8px
            @include bp($phone)
              row-gap: 12px
        &__bottom
          margin-top: 32px
          @include bp($phone)
            margin-top: 20px
    & .d-aux-rooms__tags
      @include bp($phone)
        display: none
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-gap: 12px 0
    &._list
      & .d-aux-rooms__bottom
        @include bp($phone)
          margin-top: 20px
  &__button
    height: 64px
    margin-top: 40px
    @include bp($phone)
      height: 48px
      margin-top: 32px
    @include transition(all)
    &:empty
      height: 0
      margin-top: 0
  &__bottom
    grid-column: -1/1
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 8px
    @include bp($tablet)
      margin-top: 16px
    @include bp($tabletS)
      flex-direction: column-reverse
    @include bp($phone)
      margin-top: 20px
  &__pager
    @include bp($tabletS)
      margin-top: 40px
    @include bp($phone)
      margin-top: 32px
  & .d-tabs
    &__buttons
      margin: 0
  &__tags
    margin-bottom: 40px
    @include bp($desktopS)
      margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 24px
    @include bp($phone)
      margin: 24px 0 0
  &__result-hot-found, &__result-error
    display: flex
    flex-direction: column
    row-gap: 24px
    align-items: center
    justify-content: center
    width: 100%
    min-height: 224px
    @include bp($desktopXS)
      padding: 100px 65px
    @include bp($phone)
      min-height: 240px
      padding: 24px 16px
      border-radius: 24px
    &._objects
      margin-bottom: 64px
      @include bp($desktopXS)
        padding: 64px 65px
        margin-bottom: 0
      @include bp($phone)
        padding: 24px 16px
        margin-bottom: 8px

    &_icon
      height: 64px
      width: 64px
      &._reset
        background: transparent url("../assets/img/icons/reset.svg") no-repeat center
      &._reload
        background: transparent url("../assets/img/icons/close-red.svg") no-repeat center

    &_text
      max-width: 591px
      text-align: center
      color: $pen-color
      font-weight: 600
      @include font-size-lh(20,24)
      @include bp($phone)
        max-width: 470px
        @include font-size-lh(16,22)

    &_button
      white-space: nowrap


@keyframes slideUp
    from
      opacity: 0
      transform: translateY(100%) translateX(-50%)
    to
      opacity: 1
      transform: translateY(0) translateX(-50%)
