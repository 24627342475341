.d-object-single-utp-flat
  background: $white-color
  border-radius: 24px
  position: relative
  display: flex
  flex-direction: column
  @include hover
    & .d-object-single-utp-flat__button
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
      &:after
        transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
        opacity: 1
  &._fixed
    overflow: hidden
  &:before
    content: ''
    position: absolute
    right: 28px
    top: 28px
    width: 6px
    height: 6px
    border-top: 2px solid $pen-color
    border-right: 2px solid $pen-color
    z-index: 2
    @include bp($phone)
      right: 24px
      top: 24px
  &.swiper-slide
    //width: calc( (100% / 3) - (64px / 3) )
    width: calc( (100% / 2) - (32px / 2))
    height: auto
    @include bp($desktopS)
      //width: 384px
    @include bp($tablet)
      width: 384px
    @include bp($phone)
      width: 284px
    &._fixed
      display: none
      @include bp($tablet)
        display: flex
  &__top
    min-height: 132px
    padding: 0 40px
    border-bottom: 1px solid $grey20
    display: flex
    flex-direction: column
    justify-content: center
    font-weight: 600
    @include bp($desktopS)
      min-height: 100px
      padding: 32px 32px 8px
      justify-content: flex-start
    @include bp($phone)
      min-height: 96px
      padding: 24px 16px 8px
  &__title
    @include font-size-lh(32,36)
    color: $blue-color
    position: relative
    @include bp($desktopS)
      @include font-size-lh(24,28)
    @include bp($phone)
      max-width: 90%
      @include font-size-lh(20,24)
    &:before
      display: none
      position: absolute
      left: 0
      top: 2px
      padding: 5px 16px
      border-radius: 8px
      font-weight: 500
      @include font-size-lh(16,22)
      color: $white-color
      @include bp($desktopS)
        top: -2px
  &__subtitle
    @include font-size-lh(20,24)
    color: $pen-color
    margin-top: 8px
    display: none
    @include bp($desktopS)
      @include font-size-lh(16,22)
      margin-top: 6px
    @include bp($phone)
      @include font-size-lh(14,18)
  &__middle
    padding: 40px
    @include bp($desktopS)
      padding: 16px 32px 24px
    @include bp($phone)
      padding: 16px
  &__img
    height: 304px
    margin-bottom: 40px
    @include bp($desktopS)
      height: 228px
      margin-bottom: 24px
    @include bp($phone)
      height: 160px
      margin-bottom: 16px
    & img
      @include objectFit(100%, contain)
  &__params
    display: inline-grid
    grid-template-columns: repeat(2, auto)
    grid-column-gap: 40px
    @include bp($phone)
      grid-column-gap: 32px
  &__paramLabel
    font-weight: 500
    @include font-size-lh(18,21)
    color: $grey50
    margin-bottom: 4px
    @include bp($desktopS)
      @include font-size-lh(16,22)
    @include bp($phone)
      @include font-size-lh(14,18)
  &__paramValue
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($desktopS)
      @include font-size-lh(20,24)
    @include bp($phone)
      @include font-size-lh(18,21)
  &__bottom
    display: flex
    align-items: center
    justify-content: space-between
    padding: 40px
    border-top: 1px solid $grey20
    @include bp($desktopS)
      flex-grow: 1
      flex-direction: column
      align-items: flex-start
      padding: 24px 32px 32px
    @include bp($phone)
      padding: 16px
  &__count
    font-weight: 600
    @include font-size-lh(18,21)
    color: $blue-color
    @include bp($phone)
      @include font-size-lh(16,22)
  &__button
    @include bp($desktopS)
      width: 100%
      margin-top: 24px
    @include bp($phone)
      margin-top: 12px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
  &._vip
    & .d-object-single-utp-flat
      &__title
        padding-left: 72px
        @include bp($desktopS)
          padding-left: 68px
        @include bp($phone)
          @include font-size-lh(24,28)
        &:before
          content: 'VIP'
          display: block
          background: $red
      &__subtitle
        display: block
  &._crown
    & .d-object-single-utp-flat
      &__title
        padding-left: 44px
        @include bp($phone)
          @include font-size-lh(24,28)
        &:before
          content: ''
          background: url('../assets/img/d/crown.svg') no-repeat center / contain
          width: 32px
          height: 32px
          display: block
          padding: 0