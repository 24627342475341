.d-aux-rooms-card
  height: 100%
  padding: 32px
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 32px
  position: relative
  @include transition(box-shadow)
  @include bp($desktopXS)
    padding: 24px
    border-radius: 24px
  @include bp($phone)
    padding: 16px
  @include hover
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
  & .skeleton-field
    margin: 0
  &__top
    display: flex
    align-items: center
    justify-content: space-between
  &__name
    flex-grow: 1
    margin-right: 16px
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(20,24)
    @include bp($phone)
      font-size: 18px
    &.skeleton-field
      height: 38px
      margin-right: 32px
  &__buttons
    flex-shrink: 0
    display: flex
    column-gap: 8px
    @include bp($desktopXS)
      column-gap: 12px
    & .skeleton-field
      width: 38px
      height: 38px
      @include bp($desktopXS)
        margin-left: -4px
  &__button
    &.d-button._flatIcon
      z-index: 2
      @include bp($desktopXS)
        width: 24px
        height: 24px
        border: none
        @include hover
          background: none
          color: $blue-color
    & .d-button__tooltip
      left: auto
      right: 0
      transform: none
  &__info
    display: flex
    flex-direction: column
    padding-top: 32px
    margin: 24px 0
    position: relative
    @include bp($desktopXS)
      padding-top: 16px
      margin-bottom: 16px
    @include bp($phone)
      margin-top: 16px
    &:before
      content: ''
      position: absolute
      left: -32px
      top: 0
      width: calc(100% + 64px)
      height: 1px
      background: $grey20
      @include bp($desktopXS)
        left: -24px
        width: calc(100% + 48px)
      @include bp($phone)
        left: -16px
        width: calc(100% + 32px)
  &__advantages
    display: flex
    gap: 4px
    margin-bottom: 24px
    position: relative
    @include bp($tablet)
      margin-bottom: 16px
    & .d-flat-action
      &__name
        position: relative
        z-index: 2
      @include bp($phone)
        padding: 3px 12px
        font-weight: 400
        @include font-size-lh(14,18)
        border-radius: 6px
  &__bottom
    display: flex
    align-items: flex-end
    justify-content: space-between
    margin-top: auto
    @include bp($phone)
      display: block
  &__price
    &.skeleton-field
      width: 121px
      height: 38px
      @include bp($desktopXS)
        width: 92px
  &__order
    width: 187px
    height: 48px
    z-index: 2
    @include bp($desktopXS)
      width: 163px
      padding: 0 24px 0 16px
    @include bp($phone)
      width: 100%
      margin-top: 14px
    &._full
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 1
      border: none
      background: none
      cursor: pointer
    &.skeleton-field
      width: 196px
      height: 38px
      @include bp($desktopXS)
        width: 161px
      @include bp($phone)
        width: 100%
        margin-top: 12px
  & .d-flat-info
    @include bp($desktopXS)
      grid-row-gap: 4px
    @include bp($tablet)
      flex-grow: 1
    &__row
      &:first-child
        @include bp($phone)
          display: flex
    &__label
      font-weight: 400
      @include bp($desktopXS)
        @include font-size-lh(14,18)
    &__value
      z-index: 2
      @include bp($desktopXS)
        @include font-size-lh(16,22)
    &__info
      &:hover
        & .d-flat-info__tooltip
          @include bp($isTouchDevice)
            opacity: 0
            visibility: hidden
      & button
        width: 100%
        height: 100%
        background: none
        border: none
        cursor: pointer
    &__tooltip
      @include bp($isTouchDevice)
        width: 300px
        padding-right: 44px
        text-align: left
        white-space: normal
      @include bp($phone)
        width: 250px
      & img
        display: none
        @include bp($isTouchDevice)
          display: block
          position: absolute
          right: 12px
          top: 12px
          width: 24px
      &._show
        @include bp($isTouchDevice)
          opacity: 1 !important
          visibility: visible !important
  & .d-flat-price
    &__digits
      @include bp($phone)
        display: flex
        flex-direction: row-reverse
        align-items: center
    &__old
      font-weight: 600
      @include font-size-lh(18,24)
      margin-bottom: 4px
      @include bp($desktopXS)
        @include font-size-lh(16,20)
        margin-bottom: 0
      @include bp($phone)
        margin-left: 12px
    &__now
      @include font-size-lh(24,28)
      @include bp($desktopXS)
        @include font-size-lh(20,24)
  &._auxRoomSkeleton
    padding-top: 39px
    @include bp($desktopXS)
      padding-top: 24px
    & .d-aux-rooms-card
      &__info
        display: flex
        flex-direction: column
        row-gap: 15px
        padding: 0
        margin-top: 69px
        @include bp($desktopXS)
          row-gap: 11px
          margin-top: 27px
        &:before
          display: none
        & .skeleton-field
            height: 17px
