.d-company-about-projects
  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $blue-color
    margin-bottom: 32px
    @include bp($desktopXS)
      margin-bottom: 24px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 16px
  &__text
    max-width: 740px
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    margin-bottom: 40px
    @include bp($desktopXS)
      max-width: 590px
      @include font-size-lh(16,22)
    @include bp($phone)
      max-width: 100%
      margin-bottom: 24px
  &__arrows
    position: absolute
    right: 0
    bottom: calc(100% + 40px)
    @include bp($tabletS)
      display: none
  &__slider
    position: relative
    & .swiper
      @include bp($tabletS)
        padding: 0 24px
        margin: 0 -24px
      @include bp($phone)
        padding: 0 12px
        margin: 0 -12px
  &__slide
    &.swiper-slide
      width: calc(50% - 16px)
      height: 480px
      border-radius: 32px
      overflow: hidden
      will-change: transform
      @include bp($desktopXS)
        height: 364px
      @include bp($tablet)
        width: calc(50% - 12px)
      @include bp($tabletS)
        width: 476px
      @include bp($phone)
        width: 284px
        height: 296px
        border-radius: 24px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)
      z-index: 2
    & img
      @include objectFit(100%, cover)
  &__name
    position: absolute
    left: 56px
    bottom: 56px
    font-weight: 600
    @include font-size-lh(52,56)
    color: $white-color
    z-index: 3
    @include bp($desktopXS)
      left: 40px
      bottom: 40px
      @include font-size-lh(36,40)
    @include bp($tablet)
      left: 32px
      bottom: 32px
      @include font-size-lh(32,36)
    @include bp($phone)
      left: 16px
      bottom: 24px
      @include font-size-lh(20,24)
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 4