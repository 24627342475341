@mixin prefix($name, $params...) {
  @each $prefix in '-webkit-', '-moz-', '-ms-', '-o-', '' {
    $_params: ();
    @each $param_item in $params {
      @if (str-index(inspect($param_item), 'transform') == 1) {
        $_params: append($_params, unquote($prefix) + $param_item, comma);
      } @else {
        $_params: append($_params, $param_item, comma);
      }
    }
    #{$prefix+$name}: $_params;
  }
}

@mixin anim($time, $params...) {
  $easing: ease;
  $_params: ();
  @each $param_item in $params {
    $_params: append($_params, $param_item $time $easing, comma);
  }
  @include prefix(transition, $_params);
}

@mixin scale($scale) {
  @include prefix(transform, scale($scale));
}


@mixin v-align-pseudo {
  content: '';
  display: inline-block;
  position: relative;
  width: 0;
  height: 100%;
  vertical-align: middle;
}


@mixin translateY($position: -50%) {
  @include prefix(transform, translateY($position));
}


@mixin translateYI($position: -50%) {
  @include prefix(transform, translateY($position), !important);
}

