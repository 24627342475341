.d-flat-card
  background: $white-color
  border-radius: 32px
  max-width: 592px
  width: 100%
  display: flex
  flex-direction: column
  @include transition(box-shadow)
  position: relative
  @include bp($desktopXS)
    border-radius: 24px
  @include bp($phone)
    border-radius: 24px
  & .skeleton-field
    padding: 0
    border-radius: 8px
  &__top
    min-height: 144px
    padding: 32px 104px 8px 32px
    border-bottom: 1px solid $grey20
    position: relative
    @include bp($desktopXS)
      min-height: 122px
      padding: 24px 24px 16px
    @include bp($phone)
      min-height: 110px
      padding: 16px
  &__content
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 32px
    @include bp($desktopXS)
      padding: 16px 24px 24px
    @include bp($phone)
      padding: 16px 16px 24px
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    display: block
    text-decoration: none
    margin-bottom: 12px
    @include bp($desktopXS)
      @include font-size-lh(20,24)
      margin-right: 54px
    @include bp($phone)
      font-size: 18px
      margin-right: 24px
    & span
      @include bp($desktopXS)
        display: none
      &.mobile
        display: none
        @include bp($desktopXS)
          display: inline
    &.skeleton-field
      width: 80%
      height: 38px
      margin: 0 0 12px
      @include bp($phone)
        height: 28px
        margin-bottom: 9px
  &__sticker
    font-style: normal
    &._new
      display: inline-flex !important
      align-items: center
      height: 30px
      padding: 0 8px
      margin-left: 6px
      border: 1px solid $blue-color
      border-radius: 15px
      font-weight: 700
      @include font-size-lh(16,22)
      background: linear-gradient(180deg, #FE5285 0%, #FE404F 100%)
      background-clip: text
      -webkit-text-fill-color: transparent
      text-fill-color: transparent
      color: #FE404F
      text-transform: uppercase
      position: relative
      top: -2px
      z-index: 4
      cursor: default
      @include bp($desktopXS)
        height: 20px
        padding: 0 4px
        border-radius: 10px
        @include font-size-lh(12,16)
      @include bp($phone)
        height: 16px
        border-radius: 8px
        @include font-size-lh(10,12)
        margin-left: 4px
        top: -3px
  &__position
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    @include bp($phone)
      @include font-size-lh(14,18)
    & .skeleton-field
      width: 75%
      height: 18px
      margin: 0
  &__object
    font-weight: 600
    @include font-size-lh(18,24)
    color: $blue-color
    margin-bottom: 2px
    @include bp($desktopXS)
      @include font-size-lh(16,22)
      margin-right: 54px
    @include bp($phone)
      margin-right: 0
    &.skeleton-field
      width: 45%
      height: 18px
      margin: 0 0 8px
      @include bp($phone)
        margin-bottom: 4px
  &__favorites
    position: absolute
    right: 32px
    top: 32px
    z-index: 3
    @include bp($desktopXS)
      right: 24px
      top: 24px
    @include bp($phone)
      right: 16px
      top: 16px
    & .d-button__tooltip
      left: auto
      right: -16px
      transform: none
  &__img
    height: 264px
    margin-bottom: 32px
    position: relative
    @include bp($desktopXS)
      height: 214px
      margin-bottom: 16px
    @include bp($phone)
      height: 186px
    & .d-flat-card__img_black-friday
      position: absolute
      top: 0
      left: 0
      width: 68px
      height: 87px
      @include bp($tablet)
        display: none
    & img
      @include objectFit(100%, contain)
    & .skeleton-field
      width: 240px
      height: 100%
      margin: 0 auto
      @include bp($desktopXS)
        width: 196px
      @include bp($phone)
        width: 170px
        border-radius: 12px
  &__actions
    display: flex
    flex-wrap: wrap
    min-height: 32px
    @include bp($phone)
      min-height: 24px
    & .d-flat-action
      position: relative
      z-index: 3
      @include bp($phone)
        font-size: 14px
        padding: 1px 12px
        border-radius: 6px
      & + .d-flat-action
        margin-left: 4px
      &__toolp
        left: -16px
        width: 288px
        @include bp($tabletS)
          width: 248px
        @include bp($phone)
          width: 200px
  &__info
    display: flex
    align-items: flex-end
    justify-content: space-between
    font-weight: 600
    @include font-size-lh(24,28)
    margin-top: 8px
    @include bp($desktopXS)
      margin-top: 12px
    @include bp($phone)
      margin-top: 8px
  &__square
    color: $pen-color
    padding-top: 26px
    @include bp($phone)
      @include font-size-lh(20,24)
    &.skeleton-field
      width: 122px
      height: 38px
      margin: 0
      @include bp($desktopXS)
        width: 92px
      @include bp($phone)
        height: 28px
  &__price
    color: $blue-color
    &.skeleton-field
      width: 196px
      height: 38px
      margin: 0
      @include bp($desktopXS)
        width: 162px
      @include bp($phone)
        height: 28px
    &._price-request
      font-size: 20px
    &._request
      @include bp($phoneXS)
        font-size: 20px
  &__priceOld
    @include font-size-lh(20,24)
    color: $grey50
    text-decoration: line-through
    text-align: right
    margin-bottom: 2px
    @include bp($phone)
      @include font-size-lh(16,22)
      margin: 0
  &__buttons
    margin-top: 16px
    position: relative
    z-index: 3
    @include bp($phone)
      margin-top: 8px
    & .d-button
      width: 100%
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
  &._similar
    display: block
    height: 100%
    text-decoration: none
    & .d-flat-card
      &__title
        @include font-size-lh(24,28)
        @include bp($phone)
          @include font-size-lh(18,24)
        & span
          display: inline
          @include bp($phone)
            display: none
          &.mobile
            display: none
            @include bp($phone)
              display: inline
      &__favorites
        & .d-button__tooltip
          left: auto
          right: 0
          transform: none

  &._choose
    @include hover
      box-shadow: 0 4px 30px rgba(54, 24, 125, 0.12)
    & .d-flat-card
  //
  &._skeleton
    & .d-flat-card
      &__top
        min-height: 0
        border: none
        padding-bottom: 0
        margin-bottom: 54px
        @include bp($desktopXS)
          margin-bottom: 25px
        @include bp($phone)
          margin-bottom: 42px
      &__content
        padding-top: 0
      &__img
        margin-bottom: 76px
        @include bp($desktopXS)
          margin-bottom: 60px
        @include bp($phone)
          margin-bottom: 54px


  &._type
    @include hover
      box-shadow: 0 4px 30px rgba(54, 24, 125, 0.12)
      & .d-flat-card__count
        color: $white-color
        &:before
          border-top-color: $white-color
          border-right-color: $white-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
    & .d-flat-card__object
      @include font-size-lh(18,24)
      @include bps($phone)
        @include font-size-lh(16,22)
    &._single
      @include bps($tablet)
        border-radius: 0 0 32px 32px
      @include hover
        & .d-flat-card__count
          color: $grey50
      @include bps($tablet)
        max-width: 100%
        display: grid
        grid-template-columns: 254px 1fr
      @include bps($phone)
        grid-template-columns: 1fr
      & .d-flat-card__content
        @include bps($tablet)
          padding-right: 16px
        @include bps($phone)
          padding: 8px 12px 24px 12px
        &._short
          //height: 0
          //overflow: hidden
      & .d-flat-card__top
        @include bps($tablet)
          order: 2
          min-height: unset
          padding-left: 16px
          padding-bottom: 32px
          border: none
        @include bps($phone)
          order: unset
          padding: 12px 12px 8px 12px
        &._short
          @include bps($phone)
            padding: 12px 12px 24px 12px
      & .d-flat-card__img
        @include bps($tablet)
          margin-bottom: 0
          max-height: 152px
          max-width: 206px
        @include bps($phone)
          margin-left: auto
          margin-right: auto
      & .d-flat-card__bottom
        @include bps($tablet)
          display: none
        @include bps($phone)
          display: flex
          flex-direction: row
          margin-top: 16px
        &._type-single
          display: none
          @include bps($tablet)
            display: flex
            justify-content: flex-start
            align-items: flex-start
          @include bps($phone)
            display: none
        & .d-flat-card__info
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          @include bps($tablet)
            justify-content: flex-start
            margin-top: 12px
          @include bps($phone)
            margin-top: 0
      & .d-flat-card__title
        @include bps($phone)
          margin-bottom: 8px
      & .d-flat-card__count
        display: none
        @include bps($tablet)
          border: none
          flex-shrink: 0
          @include font-size-lh(16,22)
          font-weight: 400
          color: $grey50
          width: auto
          height: auto
          margin-top: 12px
          padding: 0
          display: block
          &:before, &:after
            display: none
        @include bps($phone)
          @include font-size-lh(14,18)
          margin-top: 0
      & .d-flat-card__link
        display: none
      & .d-flat-card__price,
      & .d-flat-card__square
        @include bps($phone)
          @include font-size-lh(14,18)
  &._type, &._typeSkeleton, &._typePage
    & .d-flat-card
      &__content
        padding-bottom: 40px
        @include bp($desktopXS)
          padding-bottom: 24px
        @include bp($phone)
          padding-bottom: 16px
      &__img
        @include bp($desktopXS)
          margin-bottom: 24px
        @include bp($phone)
          margin-bottom: 16px
      &__bottom
        display: flex
        align-items: flex-end
        justify-content: space-between
        @include bp($desktopXS)
          flex-direction: column
        &._type-single
          display: none
      &__info
        display: block
        margin: 0
        @include bp($desktopXS)
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          @include font-size-lh(20,24)
        @include bp($phone)
          font-size: 18px
      &__square
        padding: 0
        margin-bottom: 8px
        @include bp($desktopXS)
          margin: 0 8px 0 0
        @include bp($phone)
          font-size: 18px
        &.skeleton-field
          width: 120px
          @include bp($desktopXS)
            width: 76px
            height: 28px
      &__count
        width: 202px
        height: 48px
        padding: 0 16px
        margin-left: 16px
        @include bp($desktopXS)
          margin: 16px 0 0
        @include bp($phone)
          width: 100%
        &.skeleton-field
          width: 186px
          height: 48px
          margin: 0
          @include bp($phone)
            width: 100%
      &__link
        z-index: 3
  &._typeSkeleton
    & .d-flat-card
      &__top
        min-height: 0
        border: none
        padding-bottom: 0
        margin-bottom: 54px
        @include bp($desktopXS)
          margin-bottom: 25px
        @include bp($phone)
          margin-bottom: 32px
      &__content
        padding-top: 0
      &__img
        @include bp($phone)
          margin-bottom: 22px
      &__info
        @include bp($phone)
          margin-bottom: 8px
      &__price
        &.skeleton-field
          width: 162px
          @include bp($desktopXS)
            width: 120px
            height: 28px
  &._typePage
    @include bp($tablet)
      max-width: 100%
      min-height: 216px
      padding: 32px 96px 32px 262px
      justify-content: center
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
      border-radius: 0 0 32px 32px
      @include bp($phone)
        min-height: 0
        padding: 12px 12px 24px
        border-radius: 0 0 24px 24px
    & .d-flat-card
      &__top
        @include bp($tablet)
          min-height: 0
          padding: 0
          border: none
      &__content
        @include bp($tablet)
          flex-grow: 0
          padding: 0
          margin-top: 12px
        @include bp($phone)
          height: 186px
          margin-top: 16px
          @include transition(all)
          overflow: hidden
        &._hide
          @include bp($phone)
            height: 0
            margin: 0
          & .d-flat-card__count
            @include bp($phone)
              opacity: 0
      &__title, &__object
        @include bp($tablet)
          margin-right: 0
        @include bp($phone)
          margin-right: 60px
      &__title
        @include bp($phone)
          margin-bottom: 8px
      &__img
        @include bp($tablet)
          position: absolute
          left: 24px
          top: 32px
          width: 206px
          height: 152px
          margin: 0
        @include bp($phone)
          position: relative
          left: 0
          top: 0
          margin: 0 auto 16px
      &__info, &__square
        @include bp($phone)
          @include font-size-lh(14,18)
      &__info
        @include bp($tablet)
          justify-content: flex-start
      &__square
        margin-right: 24px
        @include bp($phone)
          margin-right: 16px
      &__count
        font-weight: 400
        @include font-size-lh(16,22)
        color: $grey50
        width: auto
        height: auto
        padding: 0
        margin-top: 12px
        @include transition(opacity)
        display: none
        @include bp($tablet)
          display: block
        @include bp($phone)
          position: absolute
          right: 12px
          bottom: 24px
          margin: 0
          @include font-size-lh(14,18)
  &._favorites
    display: flex
    flex-direction: column
    & .d-flat-card
      &__content
        flex-grow: 1
      &__price
        &._request
          @include bp($phone)
            font-size: 20px
