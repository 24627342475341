.map-geo-button
  position: absolute
  bottom: 16px
  left: 50%
  transform: translateX(-50%)
  background: $blue-color
  width: 64px
  height: 64px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  z-index: 3
  &:hover
    cursor: pointer
    & .map-geo-button__help
      opacity: 1
      visibility: visible
      transform: translateY(-50%) scale(1)
  &__help
    position: absolute
    background: $white-color
    border-radius: 10px
    padding: 20px
    left: 100%
    top: 50%
    transition: visibility $transition, opacity $transition, transform $transition
    transform: translateY(-50%) scale(0.5)
    margin-left: 16px
    white-space: nowrap
    color: $pen-color
    opacity: 0
    visibility: hidden