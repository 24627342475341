.d-index-filter-choose-item
  display: flex
  align-items: center
  height: 48px
  padding: 0 12px 0 20px
  //margin: 3px 3px 0 0
  background: $grey20
  border-radius: 8px
  font-weight: 500
  @include font-size-lh(16,22)
  color: $pen-color
  flex-shrink: 0
  &._disabled
    padding: 0 20px 0 20px
    & .d-index-filter-choose-item__del
      display: none
  &__del
    background: none
    border: none
    padding: 0
    color: $pen-color
    @include transition(color)
    cursor: pointer
  & svg
    display: block
    width: 24px
    height: 24px
    margin-left: 8px
    fill: currentColor
    @include transition(color)
    @include bp($phone)
      width: 16px
      height: 16px
    @include hover
      color: $red