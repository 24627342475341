.d-object-single-location-legend
  position: absolute
  left: 40px
  top: 32px
  z-index: 11
  @include transition(all)
  &._desktop
    display: flex
    max-height: 676px
    height: auto
    @include bp($desktopS)
      flex-shrink: 0
      position: relative
      left: 0
      top: 0
      max-height: none
      height: 492px
      margin-right: 12px
    @include bp($tablet)
      display: none
  &._mobile
    display: none
    @include bp($tablet)
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      width: 100%
      max-height: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
  &._show
    @include bp($tablet)
      opacity: 1
      visibility: visible
      z-index: 25
  &__bg, &__close
    display: none
    @include bp($tablet)
      display: block
    @include bp($phone)
      display: none
  &__bg
    z-index: 1 !important
  &__close
    position: absolute
    right: 24px
    top: 24px
    z-index: 3
  &__inner
    width: 380px
    max-height: 100%
    padding: 32px 32px 24px
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 24px
    z-index: 11
    display: flex
    flex-direction: column
    @include bp($desktopS)
      width: 364px
      height: 100%
      padding: 24px
    @include bp($tablet)
      width: 380px
      max-height: 676px
      height: auto
      padding: 32px
      margin: auto
      position: relative
      z-index: 3
    @include bp($phone)
      display: flex
      flex-direction: column
      width: 100%
      max-height: 100%
      height: 100%
      padding: 16px 12px 24px
      border-radius: 0
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    margin-bottom: 16px
    @include bp($phone)
      display: none
    &._mobile
      display: none
      @include bp($phone)
        display: flex
        align-items: center
        justify-content: space-between
        padding-bottom: 16px
        position: relative
      &:before
        content: ''
        position: absolute
        left: -12px
        bottom: 0
        width: calc(100% + 24px)
        height: 1px
        background: $grey20
  &__back, &__clear
    background: none
    border: none
    padding: 0
  &__back
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    padding-left: 40px
    position: relative
    &:before
      content: ''
      position: absolute
      left: 2px
      top: 50%
      margin-top: -10px
      width: 20px
      height: 20px
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
      transform: rotate(180deg)
  &__clear
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
  &__tabs
    @include bp($phone)
      margin-bottom: auto
  & .d-tabs__buttons
    @include bp($desktopS)
      margin-bottom: 12px
    @include bp($phone)
      display: none
    & .d-tabs__button
      width: 50%
  &__list
    height: 100%
    padding: 0 12px
    margin: 0 -12px
    flex-grow: 1
    overflow: auto
    @include bp($tablet)
      max-height: 480px
    @include bp($phone)
      max-height: 100%
      padding: 0 6px
      margin: 0 -6px
  &__item
    margin-bottom: 4px
    &:last-child
      margin-bottom: 0
  &__button
    display: none
    @include bp($tablet)
      display: block
      margin-top: 24px
    & .d-button
      width: 100%
      @include bp($phone)
        display: none
      &._mobile
        display: none
        @include bp($phone)
          display: flex
.d-object-single-location-legend-item
  position: relative
  &__input
    position: absolute
    left: 0
    top: 0
    width: 0
    height: 0
    opacity: 0
    &:checked~.d-object-single-location-legend-item__label
      background: $blue-color
      color: $white-color
      & svg
        fill: $white-color
  &__label
    display: flex
    align-items: center
    font-weight: 500
    @include font-size-lh(16,22)
    color: $pen-color
    padding: 8px 16px 8px 8px
    @include transition(background)
    border-radius: 8px
    cursor: pointer
    @include bp($desktopS)
      padding: 4px 16px 4px 8px
    @include bp($tablet)
      padding: 8px 16px 8px 8px
    @include hover
      background: $grey5
    &._lock
      pointer-events: none
  &__icon
    margin-right: 16px
    & svg
      display: block
      width: 24px
      height: 24px
      fill: $blue-color
      @include transition(fill)
  &__value
    margin-left: auto