.d-company-history-item
  display: flex
  max-width: 1372px
  width: 100%
  min-height: 272px
  background: $white-color
  border-radius: 32px
  transform-origin: top
  transform: rotateX(0deg) scaleY(1)
  @include transition(all)
  @include bp($desktopXS)
    min-height: 232px
    border-radius: 24px
  @include bp($phone)
    display: block
  &__img
    flex-shrink: 0
    width: 436px
    @include bp($desktopXS)
      width: 320px
    @include bp($phone)
      width: 100%
      height: 200px
    & img
      @include objectFit(100%, cover)
      border-radius: 32px
      @include bp($desktopXS)
        border-radius: 24px
    & .skeleton-field
      width: 420px
      height: 240px
      margin: 16px 0 16px 16px
      @include bp($desktopXS)
        width: 304px
        height: 200px
      @include bp($phone)
        width: auto
        height: 184px
        margin: 16px 16px 0 16px
  &__content
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 32px
    font-weight: 400
    @include font-size-lh(18,24)
    color: $grey50
    @include bp($desktopXS)
      padding: 24px 24px 24px 32px
      @include font-size-lh(16,22)
    @include bp($phone)
      padding: 16px 16px 24px
      @include font-size-lh(14,18)
  &__year
    font-weight: 600
    @include font-size-lh(52,56)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(36,40)
    @include bp($phone)
      @include font-size-lh(32,36)
    &.skeleton-field
      width: 227px
      height: 48px
      margin: 0 0 28px
      @include bp($desktopXS)
        margin-bottom: 16px
      @include bp($phone)
        width: 135px
        margin-bottom: 24px
  &__fact
    font-weight: 600
    @include font-size-lh(36,40)
    color: $blue-color
    margin-top: 16px
    @include bp($desktopXS)
      @include font-size-lh(32,36)
    @include bp($phone)
      @include font-size-lh(20,24)
      margin-top: 12px
    &.skeleton-field
      width: 85%
      height: 40px
      margin: 0 0 4px
      @include bp($desktopXS)
        width: 92%
        margin-bottom: -8px
      @include bp($tabletS)
        width: 100%
      @include bp($phone)
        margin-bottom: 16px
  &__note
    margin-top: 24px
    @include bp($phone)
      margin-top: 8px
    & .skeleton-field
      width: 418px
      height: 16px
      margin: 0
      @include bp($tabletS)
        width: 90%
      @include bp($phone)
        width: 100%
      & + .skeleton-field
        width: 249px
        margin-top: 8px
        @include bp($phone)
          width: 60%

  &._hidden
    transform: rotateX(-90deg)
    min-height: 0!important
    opacity: 0
    pointer-events: none
    transition-delay: 0s


@for $i from 1 through 10
  .d-company-history-item:not(._hidden):nth-child(#{$i})
    transition-delay: calc(0.1s * ($i - 1))