.offices-contacts-item-mobile
  background: #F2F3F7
  box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  border-radius: 20px
  padding: 24px
  margin-bottom: 16px
  position: relative
  padding-right: 40px
  @include bp($phone)
    max-width: calc(100% - 16px)
    //margin-left: 16px
    //margin-right: 16px
  &:after
    content: ''
    width: 20px
    height: 20px
    background: url('../assets/img/icons/arrow_dark.svg') no-repeat
    background-size: contain
    position: absolute
    top: 26px
    right: 16px
    display: none
    @include bp($tablet)
      display: block
  &:last-child
    margin-bottom: 0
  &._contacts
    padding: 24px 40px 24px 16px
  &__title
    @include font-size-lh(16,24)
    font-weight: 600
    color: $pen-color
  &__address
    @include font-size-lh(14,22)
    font-weight: 600
    color: $dark-grey
    opacity: 0.7
    margin-top: 4px