.d-bank
  display: grid
  grid-template-columns: 164px 1fr 10% 18% 18%
  grid-column-gap: 64px
  background: $white-color
  border-radius: 24px
  min-height: 112px
  padding: 20px 0
  position: relative
  cursor: pointer
  width: 100%
  justify-self: center
  transform-origin: top
  transform: rotateX(0deg) scaleY(1)
  @include transition(all)
  //transition: all 0.1s ease
  @include bp($desktopS)
    grid-template-columns: 146px 1fr 9% 15% 18%
    grid-column-gap: 16px
  @include bp($desktopXS)
    grid-template-columns: 146px 1fr 10% 19% 20%
  @include bp($tablet)
    grid-template-columns: 146px 1fr 13% 19% 19%
    grid-column-gap: 24px
  @include bp($tabletS)
    grid-template-columns: 146px 1fr 11% 21% 20%
    grid-column-gap: 8px
  @include bp($phone)
    grid-template-columns: 16% 36% 1fr
    grid-column-gap: 12px
    grid-row-gap: 12px
    padding: 24px 16px
  @include hover
    box-shadow: 0 4px 16px rgba($black, .12)
  &__item
    align-self: center
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($desktopS)
      @include font-size-lh(20,24)
    @include bp($phone)
      font-weight: 600
      @include font-size-lh(18,22)
    &:first-child
      padding: 0 16px 0 40px
      @include bp($tablet)
        padding-left: 32px
      @include bp($tabletS)
        padding-right: 0
      @include bp($phone)
        grid-column: 1/-1
        padding: 0
    &:last-child
      padding-right: 24px
      @include bp($tablet)
        //padding-right: 32px
      @include bp($phone)
        padding-right: 0
    &._program
      font-weight: 500
      @include font-size-lh(16,22)
      @include bp($desktopS)
        @include font-size-lh(12,14)
      @include bp($tabletS)
        padding-right: 8px
      @include bp($phone)
        font-weight: 500
        grid-column: 1/-1
        padding: 0 0 20px
        @include font-size-lh(14,18)
        position: relative
      &:after
        content: ''
        display: none
        position: absolute
        left: -16px
        bottom: 4px
        width: calc(100% + 32px)
        height: 1px
        background: $grey20
        @include bp($phone)
          display: block
  &__logo
    display: block
    width: 108px
    height: 32px
    object-fit: contain
    object-position: left center
    @include bp($desktopS)
      width: 90px
    @include bp($phone)
      width: 108px
      height: 24px
  &__label
    display: none
    @include bp($phone)
      display: block
      font-weight: 500
      @include font-size-lh(12,14)
      color: $grey50
      margin-bottom: 4px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    cursor: pointer
  &._purchase
    grid-template-columns: 168px 1fr 7% 16% 14% 11% 88px
    grid-column-gap: 24px
    margin-bottom: 8px
    @include bp($desktopS)
      grid-template-columns: 168px 1fr 6% 12% 13% 11% 88px
    @include bp($desktopXS)
      grid-template-columns: 120px 1fr 8% 10% 13% 11% 64px
    @include bp($tablet)
      grid-template-columns: 96px 1fr 10% 11.5% 13% 11% 72px
      grid-column-gap: 20px
    @include bp($tabletS)
      grid-template-columns: 100px 1fr 8% 10.5% 13.5% 13% 64px
      grid-column-gap: 16px
      padding: 18px 0
    @include bp($phone)
      grid-template-columns: 44% 1fr
      grid-column-gap: 16px
      grid-row-gap: 12px
      padding: 16px 16px 24px
    & .d-bank
      &__item
        font-weight: 600
        @include font-size-lh(18,24)
        @include bp($desktopXS)
          @include font-size-lh(14,18)
        &._item1
          padding-left: 32px
          @include bp($desktopXS)
            padding-left: 24px
            padding-right: 0
          @include bp($tablet)
            padding-left: 16px
          @include bp($phone)
            padding: 0 0 4px
        &._item7
          padding-right: 24px
          @include bp($desktopXS)
            padding-right: 16px
          @include bp($tablet)
            padding-right: 24px
          @include bp($tabletS)
            padding-right: 16px
          @include bp($phone)
            position: absolute
            right: 16px
            top: 16px
            padding: 0
        &._program
          font-weight: 600
          @include font-size-lh(16,22)
          padding: 0
          @include bp($desktopXS)
            @include font-size-lh(14,18)
          @include bp($phone)
            padding-top: 16px
          &:after
            bottom: 100%
      &__logo
        width: 112px
        height: 40px
        @include bp($desktopXS)
          width: 96px
        @include bp($tablet)
          width: 80px
        @include bp($phone)
          width: 96px
          height: 48px
      &__name
        font-weight: 400
        color: $grey50
        margin-bottom: 4px
        @include bp($phone)
          font-weight: 500
          @include font-size-lh(12,14)
      &__count
        display: flex
        align-items: center
        justify-content: center
        width: 64px
        aspect-ratio: 1/1
        padding: 0
        background: $grey5
        border: none
        border-radius: 12px
        font-weight: 400
        @include font-size-lh(16,22)
        color: $pen-color
        cursor: pointer
        @include transition(color)
        position: relative
        z-index: 5
        @include bp($desktopXS)
          width: 48px
        @include hover
          color: $blue-color
      &__button
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: none
        border: none
        cursor: pointer
        z-index: 2
    & .skeleton-field
      margin: 0
    &._hidden
      transform: rotateX(-90deg) scaleY(0)
      height: 0!important
      min-height: 0!important
      padding: 0!important
      margin: 0!important
      opacity: 0
      pointer-events: none
      @include bp($phone)
        transform: scaleY(0) scaleX(0)



@for $i from 1 through 99
  .d-bank:not(._hidden):nth-child(#{$i})
    transition-delay: calc(0.1s * ($i - 1))
