.d-send-pdf
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  opacity: 0
  visibility: hidden
  z-index: -1
  pointer-events: none
  @include transition(all)
  @include bp($phone)
    align-items: flex-end
  &._show
    opacity: 1
    visibility: visible
    z-index: 100
  &__inner, &__close
    pointer-events: auto
  &__inner
    @include bp($phone)
      position: relative
      width: 100%
  &__content
    display: flex
    flex-direction: column
    row-gap: 16px
    width: 527px
    padding: 40px
    background: $white-color
    border-radius: 40px
    position: relative
    z-index: 2
    @include bp($phone)
      width: 100%
      padding: 24px 16px 16px
      border-radius: 24px 24px 0 0
    &._hide
      display: none
  &__title
    font-weight: 600
    white-space: nowrap
    @include font-size-lh(36,40)
    color: $blue-color
    @include bp($phone)
      @include font-size-lh(20,24)
  &__text
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(14,18)
  &__form
    display: flex
    flex-direction: column
    row-gap: 16px
    margin-top: 8px
    @include bp($phone)
      margin-top: 4px
    & .d-send-pdf__button
      @include bp($phone)
        margin-top: 4px
  &__button
    width: 100%
    margin-top: 8px
    @include bp($phone)
      margin-top: 16px
  &__close
    position: fixed
    right: 40px
    top: 40px
    z-index: 101
    @include bp($phone)
      position: absolute
      right: 16px
      top: 16px
    &.d-button
      @include bp($phone)
        width: 32px
        height: 32px
  & .d-overlay
    display: none !important
    @include bp($phone)
      display: block !important
      z-index: 1
