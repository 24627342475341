.d-company-award
  display: grid
  grid-template-columns: 272px 1fr
  background: $white-color
  border-radius: 32px
  overflow: hidden
  position: relative
  transform-origin: top
  transform: rotateX(0deg) scaleY(1)
  min-height: 252px
  @include transition(all)
  @include bp($desktopXS)
    grid-template-columns: 200px 1fr
  @include bp($tablet)
    display: block
    min-height: unset
  @include bp($phone)
    border-radius: 24px
  @include hover
    cursor: pointer
  &._skeleton
    pointer-events: none
    & .d-company-award
      &__photo
        border: none
        @include bp($tablet)
          height: 87px
        @include bp($phone)
          height: 160px
      &__note
        @include bp($tablet)
          min-height: 136px
        @include bp($phone)
          min-height: 0
  &__logo
    display: flex
    align-items: center
    justify-content: center
    @include bp($tablet)
      position: absolute
      left: 24px
      top: 24px
    @include bp($phone)
      left: 50%
      top: 16px
      transform: translateX(-50%)
    & img
      @include objectFit(172px, contain)
      @include bp($desktopXS)
        width: 148px
        height: 148px
      @include bp($tablet)
        width: 132px
        height: 132px
      @include bp($phone)
        width: 104px
        height: 104px
    & .skeleton-field
      width: 209px
      height: 204px
      margin: 0
      @include bp($desktopXS)
        width: 136px
        height: 136px
      @include bp($tablet)
        width: 96px
        height: 96px
        margin: 10px 0 0 12px
      @include bp($phone)
        margin: 8px 0 0
  &__content
    padding-right: 272px
    border-left: 1px solid $grey20
    @include bp($desktopXS)
      padding-right: 200px
    @include bp($tablet)
      padding: 0
      border: none
    &._full
      padding-right: 0
  &__info
    padding: 40px 40px 24px
    @include bp($desktopXS)
      padding: 32px 32px 24px
    @include bp($tablet)
      min-height: 132px + 24px + 16px
      padding: 50px 24px 16px 172px
    @include bp($phone)
      min-height: 0
      padding: 136px 16px 16px
    &._notext
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      @include bp($tablet)
        min-height: unset
        padding: 50px 24px 50px 172px
      @include bp($phone)
        padding: 136px 16px 16px
  &__name
    font-weight: 600
    @include font-size-lh(36, 40)
    color: $pen-color
    @include bp($tablet)
      @include font-size-lh(32, 36)
    @include bp($phone)
      @include font-size-lh(24, 28)
      text-align: center
    &.skeleton-field
      width: calc(100% - 66px)
      height: 40px
      margin: 0
      @include bp($phone)
        width: 100%
        height: 32px
        border-radius: 8px
  &__objects
    display: flex
    flex-wrap: wrap
    gap: 8px
    margin-top: 12px
    @include bp($phone)
      justify-content: center
    & .d-flat-action
      cursor: default
      font-weight: 400
    &.skeleton-field
      width: 170px
      height: 40px
      margin: 16px 0 0
      @include bp($tablet)
        height: 32px
        margin-top: 8px
        border-radius: 8px
      @include bp($phone)
        width: 106px
        margin: 8px auto 0
  &__note
    padding: 24px 40px 40px
    border-top: 1px solid $grey20
    font-weight: 400
    @include font-size-lh(18, 24)
    @include bp($desktopXS)
      padding: 24px 32px 32px
    @include bp($tablet)
      min-height: 136px + 24px + 24px
      padding: 24px 240px 24px 24px
      position: relative
    @include bp($phone)
      min-height: 0
      padding: 16px 16px 192px
      @include font-size-lh(14, 18)
    &._nophoto
      @include bp($tablet)
        padding: 24px 24px 24px 24px
    &._notext
      padding: 0
      border: none
      @include bp($tablet)
        min-height: 0
    &._photo
      &._notext
        border-top: 1px solid $grey20
    &._photo
      @include bp($tablet)
        min-height: 136px + 24px + 24px
    & .skeleton-field._note
      width: calc(100% - 66px)
      height: 16px
      margin: 0 0 8px
      @include bp($tablet)
        width: calc(100% - 36px)
      @include bp($phone)
        width: 100%
      &:last-child
        width: 249px
        margin: 0
        @include bp($phone)
          width: 44%
  &__photo
    position: absolute
    right: 0
    top: 0
    width: 272px
    height: 100%
    border-left: 1px solid $grey20
    @include bp($desktopXS)
      width: 200px
    @include bp($tablet)
      right: 24px
      top: 24px
      height: 136px
      border: 1px solid $grey20
      border-radius: 16px
      overflow: hidden
    @include bp($phone)
      right: 16px
      top: auto
      bottom: 16px
      width: calc(100% - 32px)
      height: 160px
    & img
      @include objectFit(100%, cover)
    & .skeleton-field
      width: auto
      height: calc(100% - 32px)
      margin: 16px 16px 16px 0
      @include bp($tablet)
        height: 100%
        margin: 0

  &._hidden
    transform: rotateX(-90deg)
    opacity: 0
    pointer-events: none
    transition-delay: 0s


@for $i from 1 through 100
  .d-company-award:not(._hidden):nth-child(#{$i})
    transition-delay: calc(0.1s * ($i - 1))