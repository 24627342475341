.d-favorites-notes
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 24px
  max-width: 590px
  width: 100%
  padding-top: 64px + 64px + 24px
  margin: auto
  font-weight: 600
  @include font-size-lh(20,24)
  color: $pen-color
  text-align: center
  position: relative
  @include bp($desktopS)
    padding-top: 48px + 64px + 24px
  @include bp($phone)
    row-gap: 20px
    max-width: 400px
    padding-top: 24px + 64px + 24px
    @include font-size-lh(16,20)
  &:before
    content: ''
    position: absolute
    left: 50%
    top: 64px
    transform: translateX(-50%)
    width: 64px
    height: 64px
    background: $white-color url('../assets/img/d/icon_broken_heart.svg') no-repeat center / 32px
    border-radius: 50%
    @include bp($desktopS)
      top: 48px
    @include bp($phone)
      top: 24px
  &._error
    &:before
      background-image: url('../assets/img/d/icon_close_red.svg')