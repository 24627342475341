.d-type-single
  margin-top: 32px
  @include bps($tablet)
    margin-top: 0
  &__back
    @include font-size-lh(16,22)
    font-weight: 600
    color: $pen-color
    position: relative
    padding-left: 24px
    @include bps($tablet)
      display: none
    &:hover
      cursor: pointer
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 20px
      height: 100%
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center
      transform: rotate(180deg)
  &__inner
    display: grid
    grid-template-columns: 592px 1fr
    gap: 32px
    margin-top: 24px
    @include bps($desktopS)
      grid-template-columns: 384px 1fr
    @include bps($tablet)
      grid-template-columns: 1fr
      gap: 22px
      margin-top: 0
      padding-top: 232px
    @include bps($phone)
      gap: 16px
      padding-top: 0
      //padding-top: 328px
    &._short
      //@include bps($phone)
      //  padding-top: 260px
  &__list
    display: flex
    flex-direction: column
    align-items: center
    @include bps($tablet)
      padding: 0 24px
    @include bps($phone)
      padding: 0 12px
  &__result
    width: 100%
    gap: 8px
    display: grid
    margin-top: -30px
    @include bp($desktopS)
      margin-top: 0
  &__card
    @include bps($tablet)
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
      border-radius: 0 0 32px 32px
      position: fixed
      top: 0
      left: 0
      width: 100%
      z-index: 10
      transition: all $transition
    @include bps($phone)
      position: static
      box-shadow: none
  &__fixed
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 0 0 32px 32px
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 10
    transform: translateY(-100%)
    transition: all $transition
    &._show
      @include bps($phone)
        transform: translateY(0)
  &__more
    margin: 32px auto 0
    @include bps($tablet)
      margin: 40px auto 40px
    @include bps($phone)
      margin: 24px auto 24px