.d-not-only-flats
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 32px
    @include bp($tablet)
      display: flex
      padding: 0 24px
      margin: 0 -24px
      overflow: auto
    @include bp($phone)
      padding: 0 12px
      margin: 0 -12px
      grid-column-gap: 12px
    &::-webkit-scrollbar
      display: none
  &._twoItems
    & .d-not-only-flats
      &__list
        grid-template-columns: repeat(2, 1fr)
        @include bp($tablet)
          display: grid
          grid-column-gap: 24px
          padding: 0
          margin: 0
          overflow: unset
        @include bp($tabletS)
          grid-template-columns: 1fr
          grid-column-gap: 0
          grid-row-gap: 16px
    & .d-not-only-flats-item
      @include bp($desktopXS)
        height: 418px
      @include bp($tablet)
        width: 100%
      @include bp($phone)
        height: auto
      &__img
        @include bp($tablet)
          width: 260px
        @include bp($tabletS)
          width: 282px
        @include bp($phone)
          width: 240px
      &__top
        @include bp($phone)
          height: 255px
      &__bottom
        @include bp($desktopXS)
          height: 128px
          flex-direction: row
          align-items: center
        @include bp($phone)
          height: auto
          padding: 24px 16px 16px
          flex-direction: column
          align-items: flex-start
      &__note
        max-width: 345px
      &__button
        @include bp($desktopXS)
          margin: 0 0 0 20px
        @include bp($phone)
          margin: 16px 0 0
  &._flat
    & .d-not-only-flats-item:last-child
      display: none


.d-not-only-flats-item
  width: 100%
  height: 480px
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 32px
  color: $pen-color
  position: relative
  @include bp($desktopXS)
    height: 504px
  @include bp($tablet)
    flex-shrink: 0
    width: 592px
    height: 480px
  @include bp($phone)
    width: 284px
    height: 426px
    border-radius: 24px
  @include hover
    & .d-not-only-flats-item__button
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
      &:after
        transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
        opacity: 1
  &__top
    flex-grow: 1
    padding: 40px
    position: relative
    @include bp($phone)
      padding: 24px 16px
  &__bottom
    height: 144px
    padding: 0 40px
    border-top: 1px solid $grey20
    display: flex
    flex-shrink: 0
    align-items: center
    justify-content: space-between
    @include bp($desktopXS)
      height: 214px
      padding: 24px 40px 40px
      flex-direction: column
      align-items: flex-start
    @include bp($tablet)
      height: 144px
      padding: 0 40px
      flex-direction: row
      align-items: center
    @include bp($phone)
      height: 170px
      padding: 24px 16px 16px
      flex-direction: column
      align-items: flex-start
  &__img
    position: absolute
    right: 0
    bottom: 12px
    width: 377px //63.7%
    @include bp($desktopXS)
      bottom: 8px
      width: 260px
    @include bp($tablet)
      bottom: 12px
      width: 377px
    @include bp($phone)
      bottom: 0
      width: 253px
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    position: relative
    z-index: 2
    @include bp($phone)
      @include font-size-lh(32,36)
  &__note
    font-weight: 500
    @include font-size-lh(16,22)
  &__button
    flex-shrink: 0
    margin-left: 20px
    @include bp($desktopXS)
      margin: 20px 0 0
    @include bp($tablet)
      margin: 0 0 0 20px
    @include bp($phone)
      width: 100%
      margin: 16px 0 0
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
  &._favorites
    @include bp($desktopXS)
      width: 100%
      height: 418px
    @include bp($phone)
      height: 404px
    & .d-not-only-flats-item
      &__img
        @include bp($desktopXS)
          bottom: 0
          width: 286px
        @include bp($phone)
          width: 252px
      &__bottom
        column-gap: 16px
        @include bp($desktopXS)
          height: 128px
          padding: 24px 40px 40px
          flex-direction: row
          align-items: center
        @include bp($phone)
          height: auto
          padding: 24px 16px 16px
          flex-direction: column
          align-items: flex-start
          gap: 16px 0
      &__note
        max-width: 340px
        @include bp($phone)
          max-width: 100%
      &__button
        @include bp($desktopXS)
          margin: 0