.d-invest
  display: flex
  @include bp($desktopXS)
    display: block
  &__col
    &._left
      display: flex
      flex-shrink: 0
      flex-direction: column
      justify-content: space-between
      width: 592px
      margin-right: 32px
      @include bp($desktopS)
        width: 484px
      @include bp($desktopXS)
        flex-direction: row
        align-items: flex-end
        width: 100%
        margin: 0 0 40px
      @include bp($tabletS)
        flex-direction: column
      @include bp($phone)
        margin-bottom: 24px
    &._right
      flex-grow: 1
      background: $white-color
      border-radius: 32px
      @include bp($phone)
        border-radius: 24px
  &__title
    margin-bottom: 0
    @include bp($desktopXS)
      max-width: 400px
      width: 100%
    @include bp($tabletS)
      max-width: 100%
  &__subtitle
    font-weight: 600
    @include font-size-lh(40,44)
    color: $pen-color
    padding: 40px
    border-bottom: 1px solid $grey20
    @include bp($phone)
      @include font-size-lh(32,36)
      padding: 24px 16px
  &__calc
    //
  &__note
    max-width: 488px
    width: 100%
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    @include bp($tabletS)
      max-width: 100%
      margin-top: 32px
    @include bp($phone)
      margin-top: 24px

.d-invest-calc
  display: flex
  padding: 40px
  @include bp($tabletS)
    flex-direction: column
  @include bp($phone)
    padding: 24px 16px 16px
  &__col
    &._left
      flex-shrink: 0
      max-width: 552px
      width: 100%
      @include bp($tablet)
        max-width: 520px
      @include bp($tabletS)
        max-width: 100%
    &._right
      margin-left: 72px
      @include bp($desktopS)
        margin-left: 60px
      @include bp($desktopXS)
        margin-left: 72px
      @include bp($tablet)
        margin-left: 24px
      @include bp($tabletS)
        display: flex
        align-items: flex-end
        justify-content: space-between
        margin: 48px 0 0
      @include bp($phone)
        display: block
        margin-top: 32px
  &__item
    line-height: 0
    margin-top: 30px
    @include bp($tabletS)
      margin: 0 0 0 32px
    @include bp($phone)
      margin: 24px 0 0
    &:first-child
      margin: 0
  &__label
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 12px
    @include bp($tabletS)
      margin-bottom: 20px
    @include bp($phone)
      margin-bottom: 4px
  &__value
    font-weight: 600
    @include font-size-lh(40,64)
    color: $blue-color
    white-space: nowrap
    margin: auto 0
    @include bp($tabletS)
      line-height: 44px
    @include bp($phone)
      @include font-size-lh(32,36)
  &__button
    @include bp($phone)
      width: 100%
    &.skeleton-button
      width: 279px
      @include bp($phone)
        width: 100%

.d-invest-calc-empty
  @include bp($tabletS)
    display: flex
    align-items: flex-end
  @include bp($phone)
    flex-direction: column
    align-items: center
  &__text
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    @include bp($phone)
      max-width: 340px
      @include font-size-lh(16,22)
      text-align: center
    & div
      @include bp($tabletS)
        display: inline
  &__button
    margin-top: 56px
    @include bp($desktopS)
      margin-top: 32px
    @include bp($desktopXS)
      margin-top: 56px
    @include bp($tablet)
      margin-top: 32px
    @include bp($tabletS)
      flex-shrink: 0
      margin: 0 0 0 40px
    @include bp($phone)
      margin: 20px 0 0
  &._error
    & .d-invest-calc-empty
      &__text
        & div
          @include bp($tablet)
            display: inline
      &__button
        margin-top: 80px
        @include bp($desktopS)
          margin-top: 56px
        @include bp($desktopXS)
          margin-top: 80px
        @include bp($tablet)
          margin-top: 56px
        @include bp($tabletS)
          flex-shrink: 0
          margin: 0 50px 0 30px
        @include bp($phone)
          margin: 20px 0 0