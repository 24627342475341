@font-face
  font-family: 'Gilroy'
  src: url('../assets/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'), url('../assets/fonts/gilroy/Gilroy-Regular.woff') format('woff')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'), url('../assets/fonts/gilroy/Gilroy-Medium.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Gilroy'
  src: url('../assets/fonts/gilroy/Gilroy-SemiBold.woff') format('woff')
  font-weight: 600
  font-style: normal
@font-face
  font-family: 'Gilroy'
  src: url('../assets/fonts/gilroy/Gilroy-Bold.woff2') format('woff2'), url('../assets/fonts/gilroy/Gilroy-Bold.woff') format('woff')
  font-weight: 700
  font-style: normal
