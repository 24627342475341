.d-favorites-pdf-info
  display: flex
  align-items: center
  justify-content: space-between
  background: $white-color
  border-radius: 24px
  max-width: 904px
  width: 100%
  padding: 12px
  margin-top: 24px
  position: relative
  z-index: 1
  @include bp($desktopS)
    max-width: 744px
  @include bp($desktopXS)
    max-width: 592px
  @include bp($tablet)
    max-width: 100%
  @include bp($phone)
    flex-direction: column
    row-gap: 16px
    margin-top: 20px
  &__item
    display: flex
    &._item1
      flex-direction: column
      justify-content: center
      row-gap: 4px
      min-height: 56px
      padding-left: 68px
      position: relative
      font-weight: 400
      @include font-size-lh(16,22)
      color: $grey50
      @include bp($phone)
        row-gap: 6px
        width: 100%
      &:before
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 56px
        height: 56px
        background: $grey5 url('../assets/img/d/icon_pdf.svg') no-repeat center
        border-radius: 50%
    &._item2
      column-gap: 8px
      padding: 0 4px
      @include bp($phone)
        padding: 4px 0
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(16,22)

  &__size, &__title
    &.skeleton-field
      margin: 0
  &__size.skeleton-field
    width: 120px
    height: 22px
  &__title.skeleton-field
    width: 205px
    height: 24px


  &._line, &._block
    margin-top: 0
    @include bp($tablet)
      max-width: 568px
    @include bp($phone)
      max-width: 100%
    & + .d-flat-list
      margin-top: 24px
  &._block
    margin-top: 8px
