.d-object-contacts-scheme-popup
  max-width: 1216px
  display: flex
  flex-direction: column
  align-items: center
  &__img
    max-width: 100%
    max-height: 757px
    object-fit: contain
  &__buttons
    display: flex
    margin-top: 24px
  &__btn
    border-radius: 8px
    background: $white-color
    padding: 13px 20px
    outline: none
    border: none
    font-weight: 400
    color: $pen-color
    @include font-size-lh(16,22)
    text-decoration: none
    margin-right: 12px
    transition: all $transition
    display: flex
    align-items: center
    &:last-child
      margin-right: 0
    &:hover
      cursor: pointer
      background: $pen-color
      color: $white-color
      & svg
        fill: $white-color
    & svg
      width: 16px
      height: 16px
      fill: $pen-color
      transition: all $transition
      margin-right: 8px