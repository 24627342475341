.d-accordion
  background: $white-color
  border-radius: 32px
  color: $pen-color
  @include bp($tablet)
    border-radius: 24px
  @include bp($phone)
    border-radius: 16px
  &._open
    & .d-accordion
      &__head
        color: $blue-color
      &__icon
        & svg
          opacity: 0
          visibility: hidden
          transform: rotate(90deg)
          & + svg
            opacity: 1
            visibility: visible
            transform: translate(-50%,-50%)
  &__head
    display: flex
    align-items: center
    min-height: 112px
    padding: 32px 104px 32px 40px
    font-weight: 600
    @include font-size-lh(24,28)
    cursor: pointer
    position: relative
    @include bp($tablet)
      min-height: 96px
      padding: 24px 104px 24px 32px
    @include bp($phone)
      min-height: 64px
      padding: 16px 56px 16px 16px
      @include font-size-lh(16,22)
    @include hover
      & .d-accordion__icon
        background: $pen-color
        & svg
          fill: $white-color
  &__icon
    position: absolute
    right: 32px
    top: 32px
    width: 48px
    height: 48px
    border: 1px solid $pen-color
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    @include transition(background)
    @include bp($tablet)
      right: 24px
      top: 24px
    @include bp($phone)
      right: 16px
      top: 16px
      width: 32px
      height: 32px
      border-radius: 8px
    & svg
      width: 24px
      height: 24px
      fill: $pen-color
      @include transition(all)
      & + svg
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        opacity: 0
        visibility: hidden
  &__text
    display: none
    padding: 32px 80px 32px 40px
    border-top: 1px solid $grey20
    font-weight: 400
    @include font-size-lh(20,26)
    @include bp($desktopS)
      padding-right: 32px
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($tablet)
      padding: 24px 24px 24px 32px
    @include bp($phone)
      padding: 16px
      @include font-size-lh(14,18)
  & p, & ul
    margin: 0
  & ul
    padding: 0
    & li
      list-style: none
      padding-left: 24px
      position: relative
      &:before
        content: ''
        position: absolute
        left: 10px
        top: 10px
        width: 4px
        height: 4px
        background: currentColor
        border-radius: 50%
        @include bp($desktopXS)
          top: 9px
        @include bp($phone)
          top: 7px
          width: 3px
          height: 3px
  & a
    color: $blue-color
    text-decoration: none
    text-underline-offset: 4px
    @include transition(all)
    @include hover
      text-decoration: underline
    &[target=_blank]
      &:after
        content: ''
        display: inline-block
        width: 16px
        height: 16px
        margin-left: 4px
        background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
        position: relative
        top: 2px