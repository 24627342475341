.d-vacancy-form
  display: grid
  grid-template-columns: 1fr
  grid-gap: 24px 0
  @include bp($tablet)
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px
  @include bp($phone)
    grid-template-columns: 1fr
    grid-gap: 24px 0
  &._success
    @include bp($tablet)
      grid-template-columns: 1fr
  &__item
    &:last-child, &._agree
      @include bp($tabletS)
        grid-column: span 2
      @include bp($phone)
        grid-column: auto
    &._agree
      align-self: center
      & .d-checkbox__label
        font-weight: 400
        white-space: normal
        & a
          color: $pen-color
          text-decoration: underline
          text-decoration-thickness: 1px
          text-underline-offset: 3px
          @include transition(all)
          @include hover
            text-decoration-color: transparent
    & .captcha-info
      color: $pen-color
      margin: 16px 0 -8px
      & a
        @include hover
          color: $blue-color
    & .d-input__field
      @include bp($phone)
        padding: 0 16px
    & .d-input
      &__error
        left: auto
        right: calc(100% + 12px)
        bottom: 50%
        transform: translateY(50%)
        //width: 185px
        white-space: nowrap
        padding: 12px 16px
        background: $white-color
        box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
        border-radius: 12px
        font-weight: 400
        @include font-size-lh(14,18)
        color: $red
        @include bp($tablet)
          position: relative
          left: 0
          bottom: 0
          transform: none
          width: auto
          padding: 0
          margin-top: 4px
          background: none
          box-shadow: none
          border-radius: 0
        &._left
          right: calc(100% + 12px)
          left: auto
          @include bp($tablet)
            right: 0
  &__agree
    & .d-checkbox__label
      white-space: pre-wrap
  &__button
    width: 100%
    &._success
      margin-top: 347px
      @include bp($tablet)
        margin-top: 24px
  &__success
    @include font-size-lh(24,28)
    font-weight: 600
    color: $pen-color
    max-width: 592px
    margin: 0 auto
    @include bp($tablet)
      max-width: 368px
      @include font-size-lh(18,24)
    @include bp($phone)
      max-width: 100%