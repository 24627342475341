.d-result-filter
  &__params
    width: 268px
  &__buttons-wrap
    display: flex
    justify-content: space-between
    position: relative
    @include bp($phone)
      flex-direction: column
    &:after
      content: ''
      width: 100%
      height: 1px
      background: $grey20
      position: absolute
      bottom: 0
      left: 0
      z-index: 2
      display: none
      @include bp($tablet)
        display: block
      @include bp($phone)
        display: none
  &__buttons
    display: flex
    column-gap: 8px
    &._tablet
      margin-bottom: 16px
      column-gap: 12px
      @include bp($phone)
        margin-bottom: 54px
      &._left
        @include bp($phone)
          margin-bottom: 28px
          width: 100%
  &__show,
  &__map
    @include bp($phone)
      width: calc(50% - 6px)
  &__header
    position: relative
    display: none
    @include bp($tablet)
      display: block
      margin-bottom: 26px