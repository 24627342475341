.d-regional-map
  position: relative
  &__lock-toggle
    position: absolute
    display: flex
    align-items: center
    padding: 13px 22px
    color: $pen-color
    @include font-size-lh(0, 0)
    right: 40px
    bottom: 40px
    background-color: $white-color
    border-radius: 8px
    box-shadow: 0 4px 20px rgba($black, 0.08)
    cursor: pointer
    white-space: nowrap
    height: 48px
    width: 22px + 12px+ 22px
    overflow: hidden
    user-select: none
    transition: all .3s ease
    text-align: left
    z-index: 0
    @include bp($desktopS)
      right: 32px
      bottom: 32px
    @include bp($desktopXS)
      right: 24px
      bottom: 24px
    @include bp($tablet)
      right: 24px
      bottom: 28px
    @include bp($tabletS)
      right: 24px
      bottom: 24px
    @include bp($phone)
      display: none

    &:before, &:after
      content: ''
      display: block
      position: absolute
      left: 22px
      top: 0
      width: 12px
      height: 100%
      background-color: $white-color
      background-repeat: no-repeat
      background-position-x: left
      background-position-y: center
      background-size: 12px 13.33px
      transition: opacity .3s ease

    &:before
      background-image: url(../assets/img/icons/icon_lock.svg)
      opacity: 0

    &:after
      background-image: url(../assets/img/icons/icon_unlock.svg)
      opacity: 1

    &._locked
      @include font-size-lh(16, 22)
      padding: 13px 22px 13px 44px
      width: fit-content

      &:after
        opacity: 0

      &:before
        opacity: 1

  &__close
    display: none
    @include bp($phone)
      position: absolute
      right: 12px
      top: 12px
      z-index: 11

  &__show-button
    position: absolute
    top: 50%
    left: 50%
    transform: translateY(-50%) translateX(-50%)
    background: $white-color
    color: $pen-color
    border: none
    outline: none
    display: flex
    align-items: center
    justify-content: center
    height: 48px
    padding: 21px 20px 21px 16px
    column-gap: 8px
    border-radius: 8px
    white-space: nowrap
    &:before
      content: url('../assets/img/icons/icon_pin.svg')
      margin-top: 6px

.d-regional-geography._show
  .d-regional-map__show-button
    display: none
  .d-regional-map__close
    display: flex
  .d-regional-map-container
    opacity: 1
    pointer-events: all

.d-regional-map-container
  width: 100%
  height: 640px
  border-radius: 48px
  overflow: hidden
  @include bp($desktopXS)
    height: 560px
  @include bp($tablet)
    height: 560px
  @include bp($tabletS)
    height: 700px
  @include bp($phone)
    height: 100vh
    border-radius: 0
    opacity: 0
    pointer-events: none
  & .ymaps-2-1-79-copyright
    margin-right: 2% !important

  & .ymaps-2-1-79-map-copyrights-promo
    left: 3% !important

