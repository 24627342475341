.d-checkbox
  position: relative
  &._error
    & .d-checkbox__label
      &:before
        border-color: $red
  &__input
    position: absolute
    left: 0
    top: 0
    width: 0
    height: 0
    opacity: 0
    &:checked~.d-checkbox__label:before
      background-color: $blue-color
      background-size: contain
      border-color: $blue-color
    &:checked~.d-checkbox__label svg
      fill: $white-color !important
  &__label
    display: block
    padding-left: 30px
    font-weight: 500
    user-select: none
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    position: relative
    white-space: nowrap
    @include hover
      &:before
        border-color: $blue-color
    @include bp($phone)
      white-space: normal
    &:before
      content: ''
      position: absolute
      left: 0
      top: 2px
      width: 14px
      height: 14px
      background: url('../assets/img/d/check_white_inside.svg') no-repeat center / 0
      border: 2px solid $grey20
      border-radius: 2px
      transition: background-size .3s ease, border-color .3s ease

    & svg
      height: 12.67px
      margin-right: 9.33px
      fill: $blue-color

    &._inline
      display: inline-block
    &._disabled
      pointer-events: none
      opacity: 0.5
  &._hover
    & .d-checkbox
      &__label
        @include hover
          color: $blue-color

  &._grow
    flex-grow: 1

  &._full
    @include hover
      & .d-checkbox__label
        color: $blue-color
    & .d-checkbox
      &__input
        &:checked~.d-checkbox__label
          background: $blue-color
          color: $white-color !important
      &__label
        display: flex
        align-items: center
        justify-content: center
        height: 48px
        padding: 0 20px
        background: $grey5
        border-radius: 8px
        @include transition(all)
        &:before
          display: none
    &._cross
      & .d-checkbox
        &__label
          &:after
            content: ''
            flex-shrink: 0
            width: 0
            height: 16px
            background: url('../assets/img/d/icon_close.svg') no-repeat center / contain
            @include transition(width)
        &__input
          &:checked~.d-checkbox__label
            column-gap: 8px
            &:after
              width: 16px
  &._full._icon
    & .d-checkbox
      &__label
        display: inline-flex
    &._action
      & svg
        fill: #F87700
      & .d-checkbox
        &__input
          &:checked~.d-checkbox__label
            background: #F87700
    &._charging
      & svg
        fill: $green
      & .d-checkbox
        &__input
          &:checked~.d-checkbox__label
            background: $green
    &._apartments
      & svg
        fill: $green
      & .d-checkbox
        &__input
          &:checked~.d-checkbox__label
            background: $green
  &._disabled
    pointer-events: none
    opacity: 0.5
    & .d-checkbox__label
      pointer-events: none
  &._disabled &__label
      cursor: default
  &__close
    width: 16px
    height: 16px !important
    fill: $white-color
    margin-left: 8px
    margin-right: 0 !important

  &.d-tabs__button._active .d-checkbox__label
    color: $white-color!important
    background: transparent!important
