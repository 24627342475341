.d-map-infra-point
  background: $blue-color
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
  border-radius: 50%
  width: 48px
  height: 48px
  display: flex
  justify-content: center
  align-items: center
  position: relative
  transition: all
  transform: translate(-50%,-50%)
  &._inside
    background: #5131F5
  &__img
    display: flex
    & svg
      fill: $white-color
  &._mobile
    @include bp($phone)
      width: auto
      height: auto
      & .d-map-infra-point__title,
      & .d-map-infra-point__close
        opacity: 1
        visibility: visible
        display: block
  &._hover
    background: $pen-color
    & .d-map-infra-point__title
      opacity: 1
      visibility: visible
      @include bp($phone)
        opacity: 0
        visibility: hidden
  &._active
    background: $pen-color
    & .d-map-infra-point__title
      padding-right: 44px
    & .d-map-infra-point__title,
    & .d-map-infra-point__close
      opacity: 1
      visibility: visible
      display: block


  &__title
    min-width: 185px
    position: absolute
    color: $blue-bright
    text-align: center
    background: $white-color
    box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    font-weight: 500
    font-size: 16px
    line-height: 22px
    padding: 12px 16px
    left: 50%
    transform: translate(-50%)
    bottom: calc(100% + 8px)
    opacity: 0
    visibility: hidden
    transition: all $transition
    @include bp($tablet)
      padding-right: 44px
    @include bp($phone)
      width: 244px
  &__close
    width: 24px
    height: 24px
    padding: 0
    outline: none
    background: transparent
    border: none
    transition: all $transition
    display: none
    position: absolute
    transform: translateY(-50%)
    top: 50%
    right: 12px
.tippy-box[data-theme="infra-point"]
  background: $white-color
  min-width: 185px
  color: $blue-bright
  text-align: center
  background: $white-color
  box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  font-weight: 500
  font-size: 16px
  line-height: 22px
  padding: 12px 16px
  bottom: calc(100% + 8px)
  transition: all $transition
  & .tippy-content
    padding: 0