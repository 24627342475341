.d-video-popup
  & .mfp-container
    padding: 0
  & .mfp-content
    width: 100%
    max-width: 100% !important
    height: 100% !important
    //height: 100% !important
    overflow: hidden
  & .mfp-close
    position: absolute !important
    right: 51px !important
    top: 42px !important
    width: 64px !important
    height: 64px !important
    display: flex
    justify-content: center
    align-items: center
    background: $blue-color
    border-radius: 12px
    color: $white-color
    z-index: 99
    padding-right: 0 !important
    opacity: 1
    @include bp($tablet)
      right: 32px !important
      top: 34px !important
    @include bp($phone)
      width: 48px !important
      height: 48px !important
      right: 12px !important
      top: 14px !important
    &._panorama
      right: 19px !important
      top: 40px !important
      //overflow: hidden
      @include bp($hover)
        &:hover
          &:before
            opacity: 1
            visibility: visible
      @include bp($tablet)
        top: 32px !important
        right: 20px !important
      @include bp($phone)
        top: 12px !important
        right: 12px !important
      &:before
        content: 'Закрыть панораму'
        font-family: 'Gilroy', Helvetica, Arial, sans-serif
        white-space: nowrap
        height: 64px
        padding: 0 20px
        background: $blue-color
        //background: red
        color: $grey5
        font-weight: 600
        @include font-size-lh(16,22)
        display: flex
        align-items: center
        position: absolute
        right: calc(100% - 15px)
        border-radius: 12px 0 0 12px
        top: 0
        opacity: 0
        visibility: hidden
        transition: all $transition
        z-index: 1
        @include bp($phone)
          height: 48px
          padding: 0 12px
        @include bp($isTouchDevice)
          opacity: 1
          visibility: visible