.d-cashback-sms-form
  display: flex
  flex-direction: column
  align-items: center
  //position: absolute
  //top: 0
  //left: 50%
  //transform: translateX(-50%)
  //opacity: 0
  //visibility: hidden
  //transition: all $transition
  //width: 100%
  &._show
    opacity: 1
    visibility: visible
  &__title
    color: $pen-color
    @include font-size-lh(20,24)
    text-align: center
    margin-top: 16px
    font-weight: 600
    @include bp($phone)
      @include font-size-lh(16,22)
      margin-top: 0px
  &__inputs
    display: flex
    margin-top: 72px
    position: relative
    @include bp($phone)
      margin-top: 32px
      flex-wrap: wrap
      justify-content: center
  &__error
    position: absolute
    left: calc(100% - 12px)
    top: 0
    z-index: 1
    color: $error
    @include font-size-lh(14,18)
    background: #FFFFFF
    box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    padding: 12px 16px
    height: 60px
    min-width: 185px
    @include bp($phone)
      position: static
      margin-top: 12px
      height: auto
      width: auto
  &__try
    @include font-size-lh(16,22)
    margin-top: 16px
    @include bp($phone)
      text-align: center
    & span
      @include bp($phone)
        display: block
    &._disabled
      color: $grey50
    &._link
      color: $blue-color
      text-decoration: none
      &:hover
        text-decoration: underline
  &__inp
    width: 91px
    height: 64px
    margin-right: 24px
    border: 1px solid transparent
    border-radius: 12px
    outline: none
    padding: 12px
    text-align: center
    transition: all $transition
    @include font-size-lh(38,40)
    color: $pen-color
    font-weight: 600
    @include bp($phone)
      width: 62px
      height: 48px
      margin-right: 16px
      @include font-size-lh(24,28)
    &:last-child
      margin-right: 0
    &._complete
      color: $blue-bright
      border: 1px solid $blue-bright
    &._error
      border: 1px solid $error
  &__btn
    width: 100%
    max-width: 700px
    margin-top: 40px