.d-commercial-card
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 32px
  position: relative
  @include bp($desktopXS)
    border-radius: 24px
  @include hover
    & .d-commercial-card
      &__img
        & img
          transform: scale(1.1)
      &__name
        color: $blue-color
  &__target
    position: absolute
    left: 32px
    top: 32px
    z-index: 2
    @include bp($desktopXS)
      left: 16px
      top: 16px
  &__img
    flex-shrink: 0
    height: 334px
    border-radius: 32px
    overflow: hidden
    will-change: transform
    @include bp($desktopXS)
      height: 200px
      border-radius: 24px
    & img
      @include objectFit(100%, cover)
      @include transition(transform)
    & .skeleton-field
      width: auto
      height: calc(100% - 16px)
      margin: 16px 16px 0
  &__content
    flex-grow: 1
    display: flex
    flex-direction: column
    padding: 24px 40px 40px
    @include bp($desktopXS)
      padding: 16px 24px 24px
  &__name
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    @include transition(color)
    @include bp($desktopXS)
      @include font-size-lh(24,28)
    &.skeleton-field
      width: 64% //328px
      height: 38px
      margin: 0
      border-radius: 8px
      @include bp($phone)
        width: 84%
        height: 32px
  &__address
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    min-height: 44px
    margin: 16px 0 24px
    @include bp($desktopXS)
      @include font-size-lh(14,18)
      margin: 8px 0 16px
    & .skeleton-field
      width: 100%
      height: 17px
      margin: 0
      @include bp($phone)
        margin-top: 4px
      &:last-child
        width: 74%
        margin-top: 4px
  &__bottom
    display: flex
    align-items: flex-end
    justify-content: space-between
    margin-top: auto
    font-weight: 600
  &__square, &__price
    &.skeleton-field
      height: 38px
      margin: 0
      border-radius: 8px
      @include bp($phone)
        height: 32px
  &__square
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(20,24)
    &.skeleton-field
      width: 24% //121px
  &__price
    @include font-size-lh(32,36)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(20,24)
    &.skeleton-field
      width: 38% //196px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 4
  &._map
    //position: absolute
    //left: calc(100% + 16px)
    //bottom: 20px
    width: 418px
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 24px
    @include bp($tablet)
      position: fixed
      left: 50%
      bottom: 0
      transform: translateX(-50%)
      width: 720px
      border-radius: 24px 24px 0 0
      z-index: 10
    @include bp($phone)
      width: 100%
    &:before
      content: ''
      position: absolute
      transition: all $transitionSlow
      right: 20px
      top: 20px
      width: 6px
      height: 6px
      border-top: 2px solid $pen-color
      border-right: 2px solid $pen-color
      z-index: 2
      @include bp($tablet)
        display: none
    & .d-commercial-card
      &__content
        padding: 24px
      &__name
        color: $pen-color
        @include bp($tablet)
          margin-right: 56px
        @include bp($phone)
          @include font-size-lh(20,24)
      &__address
        @include font-size-lh(16,22)
        @include bp($tablet)
          margin-bottom: 8px
        @include bp($phone)
          @include font-size-lh(14,18)
          max-width: calc(100% - 24px - 24px)
          margin-bottom: 24px
      &__square, &__price
        @include font-size-lh(24,28)
        @include bp($phone)
          @include font-size-lh(20,24)
      &__price
        @include bp($tablet)
          margin-left: 16px
      &__bottom
        @include bp($tablet)
          justify-content: flex-start
        @include bp($phone)
          flex-wrap: wrap
          justify-content: space-between
      &__link
        position: relative
        width: auto
        height: 48px
        margin-left: auto
        display: none
        @include bp($tablet)
          display: flex
        @include bp($phone)
          width: 100%
          margin: 24px 0 0
      &__close
        position: absolute
        right: 24px
        top: 24px
        width: 32px
        height: 32px
        border-radius: 8px
        z-index: 2
        display: none
        @include bp($tablet)
          display: flex