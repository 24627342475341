.d-map
  position: relative
  &._commercial
    height: 100%
    & .d-map-container
      height: 100%
      border-radius: 0
  &__lock-toggle
    position: absolute
    display: flex
    align-items: center
    padding: 13px 22px
    color: $pen-color
    @include font-size-lh(0, 0)
    right: 40px
    bottom: 40px
    background-color: $white-color
    border-radius: 8px
    box-shadow: 0 4px 20px rgba($black, 0.08)
    cursor: pointer
    white-space: nowrap
    height: 48px
    width: 22px + 12px+ 22px
    overflow: hidden
    user-select: none
    transition: all .3s ease
    text-align: left
    z-index: 0
    @include bp($desktopS)
      right: 32px
      bottom: 32px
    @include bp($desktopXS)
      right: 24px
      bottom: 24px
    @include bp($tablet)
      right: 24px
      bottom: 28px
    @include bp($tabletS)
      right: 24px
      bottom: 24px
    @include bp($phone)
      display: none

    &:before, &:after
      content: ''
      display: block
      position: absolute
      left: 22px
      top: 0
      width: 12px
      height: 100%
      background-color: $white-color
      background-repeat: no-repeat
      background-position-x: left
      background-position-y: center
      background-size: 12px 13.33px
      transition: opacity .3s ease

    &:before
      background-image: url(../assets/img/icons/icon_lock.svg)
      opacity: 0

    &:after
      background-image: url(../assets/img/icons/icon_unlock.svg)
      opacity: 1

    &._locked
      @include font-size-lh(16, 22)
      padding: 13px 22px 13px 44px
      width: 234px

      &:after
        opacity: 0

      &:before
        opacity: 1


.d-map-point-national
  width: 8px
  position: relative

  &:after
    content: ''
    display: block
    background: $blue-color
    width: 8px
    height: 8px
    border-radius: 50%
    transition: all 0.3s ease-in-out
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.15)

  &__name
    position: absolute
    bottom: calc(74px + 24px)
    background: $blue-color
    color: $white-color
    font-weight: 500
    padding: 5px 12px
    border-radius: 8px
    @include font-size-lh(16, 22)
    opacity: 0
    transform: translateX(calc(-50% + 6px))
    pointer-events: none
    transition: opacity 0.3s ease

  &__img
    position: absolute
    background: $blue-color
    padding: 4px
    width: 74px
    height: 74px
    border-radius: 12px
    bottom: calc(100% + 8px)
    left: 50%
    transform: translateX(-50%)
    transition: all 0.3s ease-in-out
    & img
      width: 100%
      height: 100%
      border-radius: 10px
      object-fit: cover

  &._hover
    & .d-map-point-national__name
      opacity: 1
      pointer-events: all

.d-map-point
  width: 8px
  position: relative

  &:after
    content: ''
    display: block
    background: $blue-color
    width: 8px
    height: 8px
    border-radius: 50%
    transition: all 0.3s ease-in-out
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.15)

  &._hover
    &:after
      transform: scale(1.25)

    & .d-map-point__img
      width: 110px
      height: 110px
      border-radius: 12px 0 0 12px

    & .d-map-point-info
      height: 110px
      opacity: 1

  &._active
    &:after
      background: $pen-color
      @include bp($phone)
        background: $blue-color
    & .d-map-point__img
      background: $pen-color

  &._contacts
    & .d-map-point__img
      width: 48px
      height: 48px
      border-radius: 50%
      @include font-size-lh(20,24)
    @include hover
      &:after
        background: $pen-color
      & .d-map-point__img
        background: $pen-color
      & .d-map-point-info
        height: auto
    &._hover,
    &._active
      @include bp($phone)
        & .d-map-point__img
          background: $blue-color
        & .d-map-point-info
          opacity: 0
  &._route
    &:after
      background: red
    & .d-map-point__img
      width: 48px
      height: 48px
      border-radius: 50%
      background: red
  &._commercial._hover
    &:after
      background: $pen-color
    & .d-map-point__img
      background: $pen-color
      width: 74px
      height: 74px
      border-radius: 12px
    & .d-map-point-info
      opacity: 0
  &__num
    color: $white-color
    font-weight: 600
    height: 100%
    display: flex
    justify-content: center
    align-items: center
  &__img
    background: $blue-color
    padding: 4px
    width: 74px
    height: 74px
    border-radius: 12px
    position: absolute
    bottom: calc(100% + 8px)
    left: 50%
    transform: translateX(-50%)
    transition: all 0.3s ease-in-out
    & img
      width: 100%
      height: 100%
      border-radius: 10px
      object-fit: cover
  &._projects
    & .d-map-point-info
      position: absolute
      left: 50%
      bottom: 99px
      transform: translateX(-50%)
      height: auto
      min-width: 0
      padding: 5px 12px
      border-radius: 8px
      @include font-size-lh(16,22)
      white-space: nowrap
    &:hover
      &:after
        transform: none
      & .d-map-point__img
        width: 74px
        height: 74px
        border-radius: 12px
      & .d-map-point-info
        height: auto
        opacity: 1

  &._projects._hover
    &:after
      transform: none
    & .d-map-point__img
      width: 74px
      height: 74px
      border-radius: 12px
    & .d-map-point-info
      height: auto
      opacity: 1




.d-map-point-info
  background: $blue-color
  color: $white-color
  position: absolute
  transition: all 0.3s ease-in-out
  height: 74px
  min-width: 196px
  left: 100%
  opacity: 0
  bottom: 0
  font-weight: 500
  font-size: 14px
  line-height: 18px
  padding: 12px
  pointer-events: none
  border-radius: 0 12px 12px 0
  z-index: 1
  display: flex
  flex-direction: column
  &:before
    content: ''
    position: absolute
    right: 12px
    top: 12px
    width: 6px
    height: 6px
    border-top: 2px solid $white-color
    border-right: 2px solid $white-color
    z-index: 2
    @include bp($tablet)
      display: none
  &._contacts
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 8px)
    box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
    background: $white-color
    border-radius: 12px
    height: auto
    min-height: unset
    padding: 12px 16px
    color: $blue-bright
    min-width: 185px
    //opacity: 1
    &:before
      display: none
    & .d-map-point-info__name
      margin-right: 0
      padding-right: 0
      @include font-size-lh(14,18)
      font-weight: 400
      white-space: unset
      text-align: center
  &__name
    font-weight: 600
    @include font-size-lh(18,21)
    text-decoration: none
    margin-right: 32px
    white-space: nowrap
    @include bp($tablet)
      padding-right: 28px

  &__price
    font-weight: 600
    font-size: 16px
    line-height: 22px
    white-space: nowrap
    margin-top: auto

.d-map-container
  width: 100%
  height: 640px
  border-radius: 48px
  overflow: hidden
  @include bp($desktopXS)
    height: 560px
  @include bp($tablet)
    height: 560px
  @include bp($tabletS)
    height: 700px
  @include bp($phone)
    height: 100%
    border-radius: 0
  & .ymaps-2-1-79-copyright
    margin-right: 2% !important

  & .ymaps-2-1-79-map-copyrights-promo
    left: 3% !important


.tippy-box[data-theme="objects-map"]
  height: fit-content
  min-width: 0
  padding: 0
  border-radius: 8px
  white-space: nowrap
  background: transparent

.tippy-box[data-theme="objects-map"] .tippy-content
  padding: 0 !important
  height: fit-content
  width: fit-content

.tippy-box[data-theme="objects-map"] .tippy-content .d-map-point-info
  position: relative
  opacity: 1
  height: fit-content
  min-width: 0
  padding: 5px 12px
  border-radius: 8px
  transform: unset
  left: unset
  &:before
    display: none

