.d-cashback-success
  display: flex
  flex-direction: column
  align-items: center
  max-width: 590px
  width: 100%
  padding-top: 88px
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  z-index: -1
  opacity: 0
  visibility: hidden
  @include transition(all)
  @include bp($phone)
    max-width: 440px
    padding-top: 84px
    top: auto
    bottom: 0
    transform: translateX(-50%)
  &:before
    content: ''
    position: absolute
    left: 50%
    top: 0
    transform: translateX(-50%)
    width: 64px
    height: 64px
    background: $white-color url('../assets/img/d/check_fast.svg') no-repeat center
    border-radius: 50%
  &._error
    &:before
      background: $white-color url('../assets/img/d/error.svg') no-repeat center
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    margin-bottom: 24px
    @include bp($phone)
      @include font-size-lh(16,22)
      margin-bottom: 20px
    & button
      display: inline-block
      background: none
      border: none
      border-bottom: 1px solid $pen-color
      padding: 0
      font-weight: 600
      @include font-size-lh(20,23)
      color: currentColor
      cursor: pointer
      @include transition(all)
      @include bp($phone)
        @include font-size-lh(16,21)
      @include hover
        color: $blue-color
        border-color: $blue-color
  &._show,
  &._error
    opacity: 1
    visibility: visible
    z-index: 3