.d-popup-content-wrap
  &.mfp-bg
    background: rgba(0, 0, 0, 0.6)
    backdrop-filter: blur(10px)
    opacity: 1
  & .mfp-content
    @include bp($tablet)
      height: 100%
.d-popup-content
  background: $white-color
  max-width: 720px
  margin: 32px auto
  padding: 32px
  border-radius: 40px
  position: relative
  @include bp($tablet)
    margin: 0
    min-height: 100%
    max-width: 100%
    width: 100%
    border-radius: 0
  @include bp($phone)
    padding: 12px
  & .utp-title
    @include font-size-lh(20,24)
    margin: 0 0 32px 0
    font-weight: 600
    color: $blue-color
    @include bp($tablet)
      max-width: calc(100% - 80px)
    @include bp($phone)
      max-width: calc(100% - 60px)
      margin: 0 0 14px 0
      @include font-size-lh(18,24)
  & div>*:first-child
    @include bp($tablet)
      max-width: calc(100% - 80px)
    @include bp($phone)
      max-width: calc(100% - 60px)
  &__text
    color: $pen-color
    @include font-size-lh(16,22)
    & p
      @include font-size-lh(16,22)

  &__close
    position: absolute
    right: 32px
    top: 32px
    @include bp($phone)
      top: 12px
      right: 12px
  & .mfp-close
    display: none
    