.burger
  display: block
  width: 23px
  height: 23px
  padding: 0
  background: none
  border: none
  cursor: pointer
  position: relative
  @include bp($phone)
    width: 21px
    height: 14px
  &:before, &:after, & span
    content: ''
    position: absolute
    left: 0
    width: 23px
    height: 3px
    background: $brand-color
    border-radius: 3px
    transition: all $transition
    @include bp($tablet)
      left: auto
      right: 0
    @include bp($phone)
      width: 21px
      height: 2px
      border-radius: 2px
  &:before
    top: 0
  &:after
    bottom: 0
  & span
    top: 50%
    transform: translateY(-50%)
  &._active
    @include bp($phone)
      height: 21px
    &:before, &:after
      width: 29px
      @include bp($tablet)
        width: 30px
      @include bp($phone)
        width: 27px
    &:before
      transform: rotate(45deg)
      transform-origin: left top
      @include bp($tablet)
        transform: rotate(-45deg)
        transform-origin: right top
    &:after
      transform: rotate(-45deg)
      transform-origin: left bottom
      @include bp($tablet)
        transform: rotate(45deg)
        transform-origin: right bottom
    & span
      width: 0