.d-purchase-about
  display: flex
  flex-direction: column
  height: 100%
  background: $blue-color url('../assets/img/d/purchase_about_bg.png') no-repeat -256px -256px
  border-radius: 32px
  color: $white-color
  position: relative
  overflow: hidden
  @include bp($desktopXS)
    background-position: -256px -326px
  @include bp($tablet)
    min-height: 424px
  @include bp($phone)
    background-position: -416px -356px
    border-radius: 24px
  &:after
    content: ''
    position: absolute
    left: 0
    bottom: 144px
    width: 100%
    height: 1px
    background: rgba($white-color, .5)
    z-index: 1
    @include bp($phone)
      bottom: 80px
  &__title, &__content
    padding: 40px
  &__title
    flex-shrink: 0
    //border-bottom: 1px solid rgba($white-color, .5)
    @include bp($desktopXS)
      padding: 32px 40px 24px
    @include bp($phone)
      padding: 24px 16px
  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    flex-grow: 1
    padding-top: 0
    position: relative
    z-index: 3
    @include bp($desktopS)
      padding-right: 294px
    @include bp($desktopXS)
      padding: 0 198px 40px 40px
    @include bp($tablet)
      padding-right: 40px
    @include bp($tabletS)
      padding-right: 250px
    @include bp($phone)
      padding: 0 12px 16px 16px
  &__text
    max-width: 552px
    width: 100%
    margin-bottom: 80px
    font-weight: 400
    @include font-size-lh(20,26)
    @include bp($desktopXS)
      max-width: 498px
      @include font-size-lh(16,22)
    @include bp($tablet)
      max-width: 524px
    @include bp($phone)
      max-width: 100%
      margin-bottom: 135px
  &__button
    margin-top: auto
    @include bp($phone)
      margin: auto auto 0
  &__img
    position: absolute
    right: 0
    bottom: 0
    width: 442px
    z-index: 2
    @include bp($desktopS)
      right: -93px
    @include bp($desktopXS)
      right: -77px
      width: 338px
    @include bp($tablet)
      right: 30px
    @include bp($tabletS)
      right: 0
    @include bp($phone)
      right: 50%
      transform: translateX(50%)
      width: 174px
  &._capital
    & .d-purchase-about
      &__content
        @include bp($desktopXS)
          padding-right: 522px
        @include bp($tablet)
          padding-right: 612px
        @include bp($tabletS)
          padding-right: 360px
        @include bp($phone)
          padding-right: 16px
      &__img
        right: 40px
        width: 517px
        @include bp($desktopS)
          right: 48px
        @include bp($desktopXS)
          right: 32px
          width: 411px
        @include bp($tablet)
          right: 40px
          width: 379px
        @include bp($tabletS)
          right: 32px
          width: 325px
        @include bp($phone)
          right: 50%
          bottom: -78px
          width: 283px
  &._trade-in
    & .d-purchase-about
      &__text
        max-width: 450px
        @include bp($desktopXS)
          max-width: 360px
        @include bp($tablet)
          max-width: 338px
        @include bp($phone)
          max-width: 100%
      &__img
        right: 72px
        width: 446px
        @include bp($desktopXS)
          right: 0
          width: 372px
        @include bp($tablet)
          right: 0
          width: 334px
        @include bp($tabletS)
          right: 0
          width: 334px
        @include bp($phone)
          right: 50%
          bottom: -82px
          width: 236px