.flat-card-chess
  background: $white-color
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
  border-radius: 24px
  padding: 24px 0
  position: relative
  @include bp($tablet)
    display: flex
    border-radius: 24px 24px 0 0
  @include bp($phone)
    width: 100%
    min-height: 100%
    border-radius: 0
  &__inner
    position: relative
    display: flex
    flex-direction: column
    @include bp($tablet)
      width: 100%
    &:before
      content: ''
      width: 1px
      height: calc(100% + 48px)
      position: absolute
      left: 0
      top: -24px
      display: block
      background: $grey20
  &__sticker
    font-style: normal
    &._new
      display: inline-flex !important
      align-items: center
      height: 30px
      padding: 0 8px
      margin-left: 6px
      border: 1px solid $blue-color
      border-radius: 15px
      font-weight: 700
      @include font-size-lh(16,22)
      background: linear-gradient(180deg, #FE5285 0%, #FE404F 100%)
      background-clip: text
      -webkit-text-fill-color: transparent
      text-fill-color: transparent
      color: #FE404F
      text-transform: uppercase
      position: relative
      top: -2px
      @include bp($desktopXS)
        height: 20px
        padding: 0 4px
        border-radius: 10px
        @include font-size-lh(12,16)
      @include bp($phone)
        height: 16px
        border-radius: 8px
        @include font-size-lh(10,12)
        margin-left: 4px
        top: -3px
  &__top
    padding: 0 24px 16px
    border-bottom: 1px solid $grey20
    @include bp($phone)
      padding: 0 12px 16px
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    @include bp($phone)
      max-width: calc(100% - 84px)
    & span.mobile
      display: none
      @include bp($phone)
        display: inline
    & span:not(.mobile)
      @include bp($phone)
        display: none

  &__actions
    display: flex
    margin-top: 8px
    flex-wrap: wrap
    gap: 4px
  &__action
    white-space: nowrap
  &__img
    height: 246px
    display: flex
    align-items: center
    justify-content: center
    padding: 16px 24px
    @include bp($phone)
      padding: 16px 16px 32px
    &._column
      @include bp($tablet)
        display: none
      @include bp($phone)
        display: flex
    &._row
      display: none
      @include bp($tablet)
        display: flex
        width: 271px
        flex-shrink: 0
      @include bp($phone)
        display: none
    & img
      width: 100%
      max-width: 100%
      max-height: 100%
      object-fit: contain
  &__project
    padding: 0 24px
    color: $blue-color
    font-weight: 600
    @include font-size-lh(16,22)
    @include bp($tablet)
      margin-top: 16px
    @include bp($phone)
      padding: 0 12px
  &__info
    padding: 0 24px
    color: $grey50
    font-weight: 400
    @include font-size-lh(16,22)
    margin-bottom: 2px
    @include bp($phone)
      padding: 0 12px
  &__bottom
    margin-top: 12px
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding: 0 24px
    @include bps($phone)
      padding: 0 12px
  &__area
    color: $pen-color
    font-weight: 600
    @include font-size-lh(24,28)
    @include bp($phone)
      @include font-size-lh(20,24)
  &__price-wrap
    display: flex
    flex-direction: column
    align-items: flex-end
  &__price
    color: $blue-color
    font-weight: 600
    @include font-size-lh(24,28)
    &._old
      margin-bottom: 2px
      text-decoration-line: line-through
      color: $grey50
      @include font-size-lh(20,24)
      @include bp($tablet)
        @include font-size-lh(16,22)
    &._price-request
      font-size: 20px
  &__btn
    display: none
    @include bp($tablet)
      display: inline-flex
      margin-left: auto
      margin-top: 27px
      margin-right: 16px
    @include bp($phone)
      margin: 24px 12px 0 12px
      width: calc(100% - 24px)
  &__close
    position: absolute
    right: 16px
    top: 16px
    z-index: 2
    width: 32px !important
    height: 32px !important
    border-radius: 8px
    display: none
    @include bp($tablet)
      display: inline-flex
    @include bp($phone)
      width: 48px !important
      height: 48px !important
      border-radius: 12px
      right: 12px
      top: 12px
