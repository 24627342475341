.d-mortgage
  display: flex
  @include bp($tablet)
    display: block
  &._info
    align-items: center
    justify-content: center
    background: $white-color
    border-radius: 40px
    min-height: 628px
    padding: 100px 65px
    @include bp($desktopXS)
      min-height: 448px
    @include bp($phone)
      min-height: 310px
      padding: 24px 16px
      border-radius: 24px
  &__item
    &._item1
      flex-shrink: 0
      width: 436px
      margin-right: 32px
      @include bp($desktopS)
        width: 356px
      @include bp($desktopXS)
        width: 312px
      @include bp($tablet)
        width: 100%
        margin: 0 0 40px
    &._item2
      display: flex
      flex-direction: column
      align-items: center
      flex-grow: 1
  &__list
    width: 100%
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    @include transition(margin)
    @include bp($phone)
      grid-row-gap: 12px
    &[data-bank]._open:not(:nth-child(2))._top
      margin-top: 8px
    &._purchase
      perspective: 999px
      perspective-origin: center
      grid-row-gap: 0
  &__more
    width: 100%
    margin-bottom: 24px
    transform-origin: top
    transform: rotateX(0deg)
    transition: all 0.3s ease 0.3s, color 0.3s ease 0s, background-color 0.3s ease 0s
    &._hidden
      transform: rotateX(-90deg)
      height: 0!important
      min-height: 0!important
      padding: 0!important
      margin: 0!important
      opacity: 0
      pointer-events: none
      transition-delay: 0s
  & .d-sorter-line
    margin-bottom: 16px
    @include bp($desktopXS)
      margin-bottom: 4px
    @include bp($tablet)
      margin-bottom: 22px
    @include bp($tabletS)
      margin-bottom: 8px
    @include bp($phone)
      display: none
  & .d-sorter
    display: none
    @include bp($phone)
      display: block
      justify-self: start
      margin-bottom: 12px
    & + div
      margin-top: 0
  & .d-flat-mortgage-empty
    width: 100%
    height: 100%



.tippy-box[data-theme="mortgage"]
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  width: 185px
  padding: 8px
  color: $blue-bright
  @include font-size-lh(14, 18)
  font-weight: 400

.tippy-box[data-theme="mortgage"] .tippy-content
  padding: 0 !important
  text-align: center
