.d-projects-map-about
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 32px
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  overflow: hidden
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  @include bp($tablet)
    flex-direction: row
    border-radius: 24px 24px 0 0
  @include bp($phone)
    flex-direction: column
    height: 100%
    border-radius: 0
  &._show
    opacity: 1
    visibility: visible
    z-index: 11
  &__top
    flex-shrink: 0
    height: 264px
    border-radius: 32px
    position: relative
    overflow: hidden
    @include bp($tablet)
      width: 288px
      height: auto
      border-radius: 24px 24px 0 0
    @include bp($phone)
      width: 100%
      height: 248px
      border-radius: 0
    &:after
      content: ''
      position: absolute
      left: 0
      bottom: 0
      width: 100%
      height: 128px
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)
      z-index: 2
      @include bp($phone)
        height: 162px
  &__img
    @include objectFit(100%, cover)
  &__actions, &__title, &__close
    position: absolute
    z-index: 3
  &__actions
    left: 24px
    top: 24px
    display: flex
    column-gap: 6px
    @include bp($tablet)
      left: 16px
      top: 16px
    & .d-flat-action
      @include bp($phone)
        font-size: 14px
        padding: 1px 12px
        border-radius: 6px
  &__title
    left: 24px
    bottom: 26px
    font-weight: 600
    color: $white-color
    @include bp($tablet)
      left: 16px
      bottom: 16px
  &__name
    @include font-size-lh(32,36)
    @include bp($tablet)
      @include font-size-lh(24,28)
  &__price
    @include font-size-lh(16,22)
    margin-top: 8px
  &__content
    width: 100%
    max-height: 337px
    padding: 16px 0 24px
    overflow: auto
    @include bp($tablet)
      max-height: none
      padding-bottom: 16px
      overflow: unset
    @include bp($phone)
      max-height: calc(100% - 248px)
      overflow: auto
    &::-webkit-scrollbar
      display: none
    & .simplebar-track
      right: 6px !important
  &__contentInner
    padding: 0 24px
    color: $pen-color
    @include bp($tablet)
      padding: 0 16px
  &__info
    @include bp($tablet)
      min-height: 48px
    @include bp($phone)
      min-height: 0
  &__address
    font-weight: 600
    @include font-size-lh(18,24)
    @include bp($tablet)
      @include font-size-lh(16,22)
      margin-right: 64px
    @include bp($phone)
      margin-right: 0
  &__metro
    display: flex
    flex-wrap: wrap
    margin: 4px 0 0 -12px
    & .d-metro, & .d-metro-way
      @include font-size-lh(14,18)
      color: $grey50
      margin: 4px 0 0 12px
      @include bp($tablet)
        @include font-size-lh(12,14)
        margin-top: 0
    & .d-metro-way
      @include bp($tablet)
        margin-top: 4px
  &__facts
    margin-top: 16px
    @include bp($tablet)
      margin-top: 8px
    @include bp($phone)
      margin-top: 16px
  &__bottom
    display: flex
    flex-direction: column
    padding-top: 16px
    margin-top: 16px
    position: relative
    @include bp($tablet)
      flex-direction: row
      align-items: flex-end
    @include bp($phone)
      display: block

    &:before
      content: ''
      position: absolute
      left: -24px
      top: 0
      width: calc(100% + 48px)
      height: 1px
      background: $grey20
      @include bp($tablet)
        left: -16px
        width: calc(100% + 32px)
  &__text
    display: -webkit-box
    width: 100%
    font-weight: 400
    @include font-size-lh(14,18)
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    @include bp($tablet)
      @include font-size-lh(12,14)
    & p, & ul, & ol
      display: inline
      padding: 0
      margin: 0
  &__more
    height: 48px
    padding: 0 36px 0 32px
    margin: 12px 0 0 auto
    @include bp($tablet)
      flex-shrink: 0
      margin: 0 0 0 12px
    @include bp($phone)
      width: 100%
      margin: 24px 0 0
  &__close
    right: 24px
    top: 24px
    width: 32px !important
    height: 32px
    border-radius: 8px
    @include bp($tablet)
      right: 16px
      top: 16px
      width: 48px !important
      height: 48px
      border-radius: 12px