.d-regional
  &__top-slide
    border-radius: 40px
    height: 560px
    overflow: hidden
    position: relative
    padding: 56px
    @include bp($tablet)
      border-radius: 32px
      padding: 40px
    @include bp($phone)
      border-radius: 24px
      padding: 16px
      height: 440px
    &:before, a
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.12) 100%)
      z-index: 2
    & a
      z-index: 4


  &__top-inner
    position: relative
    height: 100%
    max-width: 631px
    color: $white-color
    font-weight: 600
    z-index: 3
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    row-gap: 20px
    pointer-events: none
    @include bp($desktopXS)
      row-gap: 32px
    @include bp($phone)
      row-gap: 16px
      justify-content: flex-start
      padding-top: 16px
    &_title
      @include font-size-lh(88,96)
      @include bp($desktopXS)
        @include font-size-lh(64,68)
      @include bp($phone)
        @include font-size-lh(52,56)
    &_subtitle
      @include font-size-lh(52,56)
      @include bp($desktopXS)
        @include font-size-lh(40,44)
      @include bp($phone)
        @include font-size-lh(24,28)
    &_more
      position: absolute
      left: 56px
      bottom: 56px
      z-index: 5
      pointer-events: all
      @include bp($tablet)
        left: 40px
        bottom: 40px
      @include bp($phone)
        left: 16px
        right: 16px
        bottom: 16px
  &__section
    margin-bottom: 160px
    @include bp($desktopXS)
      margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 72px
    &._geography
      margin-bottom: 0
    &._objects
      margin-top: 34px
      @include bp($tablet)
         margin-top: 24px
    &._top
      margin-bottom: 24px
      @include bp($phone)
        margin-bottom: 12px
    &._utp
      margin-bottom: 136px
      @include bp($desktopS)
        margin-bottom: 120px
      @include bp($tablet)
        margin-bottom: 96px
      @include bp($phone)
        width: calc(100% + 24px)
        margin: 0 -12px 64px
    &._easyToBuy
      margin-bottom: 136px
      @include bp($desktopS)
        margin-bottom: 120px
      @include bp($tablet)
        margin-bottom: 96px
      @include bp($phone)
        margin-bottom: 64px

    &._geography
      @include bp($phone)
        position: relative
        z-index: 999999
        margin: 0


.tippy-box[data-theme="regional"]
  background: $white-color
  padding: 0
  overflow: hidden
  border-radius: 12px
  & .tippy-content
    width: fit-content
    max-width: 340px
    padding: 12px 16px
    color: $blue-bright
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    @include font-size-lh(16, 22)
