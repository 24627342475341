.d-flat-similar
  position: relative
  &__slider
    &.swiper
      border-radius: 40px
      will-change: transform
      padding-top: 24px
      margin-top: -24px
      @include bp($desktopS)
        border-radius: 0
        padding-left: 40px
        padding-right: 40px
        margin-left: -40px
        margin-right: -40px
      @include bp($desktopXS)
        padding-left: 32px
        padding-right: 32px
        margin-left: -32px
        margin-right: -32px
      @include bp($tablet)
        padding-left: 24px
        padding-right: 24px
        margin-left: -24px
        margin-right: -24px
      @include bp($phone)
        padding-left: 12px
        padding-right: 12px
        margin-left: -12px
        margin-right: -12px
  &__slide
    &.swiper-slide
      width: 592px
      height: auto
      @include bp($desktopXS)
        width: 485px
      @include bp($phone)
        width: 284px
  &__arrows
    position: absolute
    right: 0
    top: 4px
    @include bp($desktopS)
      top: 0
    @include bp($phone)
      display: none