.d-flat-to-favorites
  position: fixed
  right: -100%
  top: 40px
  width: 333px
  padding: 20px 16px
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 16px
  font-weight: 600
  @include font-size-lh(16,22)
  opacity: 0
  visibility: hidden
  z-index: -1
  @include transition(all)
  @include bp($desktopXS)
    top: 32px
  @include bp($phone)
    width: 296px
    padding: 12px
  &._show
    right: 40px
    opacity: 1
    visibility: visible
    z-index: 111
    @include bp($desktopXS)
      right: 32px
    @include bp($tablet)
      right: 24px
    @include bp($phone)
      right: 12px
  &__label
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
    margin-bottom: 12px
  &__title
    color: $pen-color
  &__price
    color: $blue-color
  &__close
    position: absolute
    right: 20px
    top: 20px
    padding: 0
    background: none
    border: none
    color: $pen-color
    @include transition(color)
    cursor: pointer
    @include hover
      color: $blue-color
    & svg
      display: block
      width: 24px
      height: 24px
      fill: currentColor