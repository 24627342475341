.d-news-item
  display: flex
  height: 190px
  background: $white-color
  border-radius: 32px
  position: relative
  width: 100%
  justify-self: center
  transform-origin: top
  transform: rotateX(0deg) scaleY(1)
  margin-bottom: 8px
  transition: all $transition
  @include bp($desktopXS)
    height: 158px
  @include bp($tabletS)
    height: 236px
  @include bp($phone)
    display: block
    height: auto
    border-radius: 16px
    margin-bottom: 12px
  @include hover
    & .d-news-item
      &__image img
        transform: scale(1.07)
      &__name
        color: $blue-color
      &__more
        color: $white-color
        &:before
          border-top-color: $white-color
          border-right-color: $white-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
  &__date
    position: absolute
    left: 24px
    top: 24px
    z-index: 2
    @include bp($phone)
      left: 16px
      top: 16px
  &__image
    flex-shrink: 0
    width: 320px
    height: 100%
    border-radius: 32px
    overflow: hidden
    @include bp($desktopXS)
      width: 280px
    @include bp($phone)
      width: 100%
      height: 224px
      border-radius: 16px
    & img
      @include objectFit(100%, cover)
      @include transition(transform)
    & .skeleton-field
      height: 100%
      margin: 0
  &__content
    flex-grow: 1
    padding: 40px 210px 40px 40px
    color: $pen-color
    opacity: 1
    @include bp($desktopXS)
      padding: 24px 194px 24px 24px
    @include bp($tabletS)
      padding-top: 56px
      padding-right: 24px
    @include bp($phone)
      padding: 16px 16px 80px 16px
  &__source
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
    margin-bottom: 8px
    @include bp($desktopXS)
      margin-bottom: 10px
    @include bp($phone)
      margin-bottom: 8px
    &.skeleton-field
      width: 170px
      height: 17px
      margin: 0 0 16px
  &__name
    max-height: 108px
    -webkit-line-clamp: 3
    display: -webkit-box
    -webkit-box-orient: vertical
    overflow: hidden
    font-weight: 600
    @include font-size-lh(32,36)
    @include transition(color)
    @include bp($desktopXS)
      max-height: 84px
      @include font-size-lh(24,28)
    @include bp($phone)
      max-height: none
      -webkit-line-clamp: unset
      overflow: unset
      @include font-size-lh(16,22)
    & .skeleton-field
      margin: 0
      max-width: 700px
      width: 100%
      height: 38px
      @include bp($desktopXS)
        max-width: 414px
      @include bp($tabletS)
        max-width: 324px
      @include bp($phone)
        max-width: 100%
      & + .skeleton-field
        max-width: 350px
        margin-top: 5px
        @include bp($desktopXS)
          max-width: 260px
        @include bp($tabletS)
          max-width: 200px
        @include bp($phone)
          max-width: 80%
  &__likes, &__more
    position: absolute
    right: 40px
    @include bp($desktopXS)
      right: 24px
    &.skeleton-field
      margin: 0
  &__likes
    top: 40px
    z-index: 5
    @include bp($desktopXS)
      top: 24px
    @include bp($phone)
      right: auto
      top: auto
      left: 16px
      bottom: 28px
    & .d-news-likes
      @include bp($phone)
        flex-direction: row-reverse
      & button, & .button
        @include bp($phone)
          margin: 0 8px 0 0
    &.skeleton-field
      width: 38px
      height: 38px
      @include bp($phone)
        bottom: 16px
  &__more
    bottom: 40px
    height: 48px
    padding: 0 36px 0 32px
    @include bp($desktopXS)
      bottom: 24px
    @include bp($phone)
      right: 16px
      bottom: 16px
    &.skeleton-field
      width: 154px
      height: 48px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
  &._smi
    height: 218px
    @include bp($desktopXS)
      height: 208px
    @include bp($tabletS)
      height: 236px
    @include bp($phone)
      height: auto
    & .d-news-item
      &__content
        @include bp($desktopXS)
          padding-right: 24px
        @include bp($tabletS)
          padding-top: 24px
        @include bp($phone)
          padding-top: 16px
      &__source
        @include bp($desktopXS)
          margin-right: 96px
        @include bp($phone)
          margin-right: 0
        & + .d-news-item__name
          padding-right: 0
      &__name
        padding-right: 72px
        @include bp($desktopXS)
          max-height: 56px
          -webkit-line-clamp: 2
        @include bp($tabletS)
          max-height: 84px
          -webkit-line-clamp: 3
        @include bp($phone)
          max-height: none
          padding: 0
          -webkit-line-clamp: unset


  &._skeleton
    & .d-news-item
      &__image
        padding: 20px 0 20px 20px
        @include bp($phone)
          padding: 16px 16px 0
      &__name
        max-height: none
  //.skeleton-field

  &._hidden
    transform: rotateX(-90deg)
    height: 0!important
    min-height: 0!important
    padding: 0!important
    margin: 0!important
    pointer-events: none
    transition-delay: 0s
    & .d-news-item__content
      opacity: 0


@for $i from 1 through 99
  .d-news-item:not(._hidden):nth-child(#{$i})
    transition-delay: calc(0.1s * ($i - 1))
