.object-header-mobile
  display: none
  @include bps($tablet, $isTouchDevice)
    opacity: 0
    visibility: hidden
    height: 88px
    background: #FDFDFD
    box-shadow: 0px 4px 16px rgba(201, 212, 225, 0.4)
    position: fixed
    width: 100%
    left: 0
    top: 0
    z-index: 23
    transform: translateY(-100%)
    display: flex
    align-items: center
    padding: 0 32px
    transition: opacity $transition, transform $transition
  @include bp($phone)
    height: 48px
    padding: 0 16px
  &._show
    opacity: 1
    transform: translateY(0)
    visibility: visible
  &._offset
    transform: translateY(114px)
    @include bp($phone)
      transform: translateY(64px)
  &__name
    position: relative
    font-weight: 600
    text-transform: uppercase
    @include font-size-lh(32, 38)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(18, 24)
    &:before
      content: ''
      width: 12px
      height: 12px
      position: absolute
      left: calc(100% + 4px)
      top: 50%
      transform: translateY(-50%) rotate(90deg)
      background: url('../assets/img/icons/arrow.svg') no-repeat
      background-size: contain
      margin-left: 4px
      display: none
      @include bp($phone)
        display: block
    &._active
      &:before
        transform: translateY(-50%) rotate(270deg)
  &__menu
    position: absolute
    top: 100%
    left: 0
    width: 100%
    padding: 24px 16px
    background: $white-color
    box-shadow: 0px 4px 24px #DDE5EF
    border-radius: 0 0 32px 32px
    opacity: 0
    visibility: hidden
    transition: opacity $transition
    &._show
      opacity: 1
      visibility: visible
