.d-flat-list
  background: $white-color
  border-radius: 24px
  width: 100%
  min-height: 128px
  padding: 22px 0
  display: grid
  grid-template-columns: 160px 8% 10% 7% 7% 6% 7% 1fr 1fr 88px
  grid-column-gap: 40px
  align-items: center
  color: $pen-color
  @include transition(box-shadow)
  position: relative
  @include bp($desktopS)
    grid-column-gap: 24px
  @include bp($desktopXS)
    min-height: 104px
    padding: 12px 0
    grid-template-columns: 144px 9% 11% 6% 6% 5% 9% 10% 1fr 64px
    grid-column-gap: 16px
  @include bp($tablet)
    min-height: 132px
    padding: 16px 0
    grid-template-columns: 150px 13% 13% 11% 10% 10% 1fr
    grid-column-gap: 20px
    align-items: end
  @include bp($tabletS)
    grid-template-columns: 150px 10% 10% 8% 6% 11% 1fr
  @include bp($phone)
    display: none
    border-radius: 16px
    padding: 0
  @include hover
    box-shadow: 0 4px 30px rgba(54, 24, 125, 0.12)
  &__favorite
    display: flex
    @include bp($desktopXS)
      border: none
      width: 24px !important
      height: 24px !important
      & svg
        display: block
        color: $pen-color !important
      @include hover
        &:hover
          background: transparent !important
          & svg
            color: $red !important
            fill: $red !important
    &._type-single
      @include bp($desktopS)
        border: none
        width: 24px
        height: 24px
        color: $pen-color
      @include hover
        color: $blue-color
        background: unset
        & svg
          fill: currentColor
  &._type-single
    grid-template-columns: 144px 9% 11% 6% 6% 5% 9% 10% 1fr 64px
    grid-column-gap: 16px
    min-height: 104px
    padding: 12px 0
    @include bp($desktopS)
      min-height: 132px
      padding: 16px 0
      grid-template-columns: 150px repeat(5, 12%) 1fr
      grid-column-gap: 20px
      align-items: end
    @include bp($desktopXS)
      grid-template-columns: 150px 10% 11% 10% 8% 10% 1fr
    @include bp($tablet)
      grid-template-columns: 150px 12% 12% 12% 11% 11% 1fr
    @include bp($tabletS)
      grid-template-columns: 150px 10% 10% 9% 6% 11% 1fr
    @include bp($phone)
      padding: 0
    & .d-flat-list
      &__item
        @include font-size-lh(16,22)
        @include bp($desktopS)
          text-align: left
          white-space: nowrap
        &._item1
          padding-left: 24px
          & img
            display: block
            width: 104px
            height: 78px
            @include bp($desktopS)
              width: 118px
              height: 88px
            @include bp($phone)
              width: 48px
              height: 36px
        &._item2
          & small
            @include font-size-lh(16,22)
            @include bp($desktopS)
              display: none
        &._item3, &._item9, &._item10
          @include bp($desktopS)
            position: absolute
            top: 16px
            padding-top: 0
        &._item3
          @include bp($desktopS)
            left: 170px
            top: 16px
            white-space: normal
        &._item9
          @include bp($desktopS)
            right: 74px
            top: 20px
        &._item10
          @include bp($desktopS)
            right: 20px
            top: 20px
        &._item8
          @include bp($desktopS)
            padding-right: 20px
            text-align: right
      &__label
        @include bp($desktopS)
          display: block
      &__object
        @include font-size-lh(16,22)
        @include bp($phone)
          @include font-size-lh(14,18)
        & small
          @include font-size-lh(14,18)
          @include bp($desktopS)
            top: calc(100% + 2px)
          @include bp($tablet)
            top: 0
            margin-top: 2px
          @include bp($phone)
            @include font-size-lh(12,16)
      &__price
        @include font-size-lh(16,22)
        @include bp($phone)
          @include font-size-lh(18,24)
        &._request
          @include bp($phone)
            display: block
          & span
            @include bp($desktopS)
              display: block
              font-weight: 400
              @include font-size-lh(14,18)
              color: $grey50
            @include bp($phone)
              display: inline
              font-weight: inherit
              font-size: inherit
              line-height: inherit
              color: currentColor
      &__priceOld
        @include font-size-lh(14,22)
        @include bp($phone)
          @include font-size-lh(12,20)
  //&._type-single
    min-height: 132px
    padding: 16px 0
    grid-template-columns: 150px 13% 13% 11% 10% 10% 1fr
    grid-column-gap: 20px
    align-items: end
    @include bp($desktopXS)
      min-height: 126px
      grid-template-columns: 154px 11% 10% 10% 8% 10% 1fr
      grid-column-gap: 16px
    @include bps($tablet)
      grid-template-columns: 150px repeat(5, 10%) 1fr
    @include bps($tabletS)
      grid-template-columns: 150px 10% 10% 8% 6% 11% 1fr
    @include bp($phone)
      min-height: 0
      padding: 0
    & .d-flat-list
      &__label
        display: block
      &__item
        text-align: left
        white-space: nowrap
        &._item3, &._item9, &._item10
          position: absolute
          padding-top: 0
          top: 16px
        &._item3
          left: 170px
          top: 16px
          white-space: normal
        &._item8
          padding-right: 20px
          text-align: right
        &._item9
          right: 88px
          top: 20px
        &._item10
          right: 24px
          top: 24px
        &._item2
          & span
            @include font-size-lh(16,22)
            & small
              display: none
  &__sticker
    display: flex
    align-items: center
    font-style: normal
    &._new
      position: absolute
      left: 0
      top: 0
      height: 30px
      padding: 0 8px
      background: $white-color
      border: 1px solid $blue-color
      border-radius: 15px
      font-weight: 700
      z-index: 4
      cursor: default
      @include font-size-lh(16,22)
      text-transform: uppercase
      @include bp($desktopXS)
        height: 20px
        padding: 0 4px
        border-radius: 10px
        @include font-size-lh(12,16)
      @include bp($phone)
        height: 16px
        border-radius: 8px
        @include font-size-lh(10,12)
      & span
        background: linear-gradient(180deg, #FE5285 0%, #FE404F 100%)
        background-clip: text
        -webkit-text-fill-color: transparent
        text-fill-color: transparent
        color: #FE404F
  &__item
    font-weight: 400
    @include font-size-lh(20,26)
    text-align: center
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($tablet)
      text-align: left
      white-space: nowrap
    @include bp($phone)
      font-weight: 600
      @include font-size-lh(14,18)
      margin-left: 20px
      position: relative
    &:before
      @include bp($phone)
        content: ''
        position: absolute
        left: -12px
        top: 50%
        transform: translateY(-50%)
        width: 4px
        height: 4px
        background: $grey30
        border-radius: 50%
    &:first-child
      @include bp($phone)
        margin: 0
      &:before
        display: none
    &._item1
      padding-left: 32px
      @include bp($desktopXS)
        padding-left: 24px
      @include bp($tablet)
        align-self: center
      & img
        display: block
        width: 128px
        height: 96px
        object-fit: contain
        @include bp($desktopXS)
          width: 104px
          height: 78px
        @include bp($tablet)
          width: 118px
          height: 88px
    &._item1, &._item2, &._item3, &._item9, &._item10
      text-align: left
    &._item3, &._item9, &._item10
      @include bp($tablet)
        position: absolute
        top: 16px
        padding-top: 0
    &._item3
      @include bp($tablet)
        left: 170px
        top: 16px
        white-space: normal
    &._item9
      @include bp($tablet)
        right: 88px
        top: 17px
    &._item10
      z-index: 3
      @include bp($tablet)
        right: 20px
        top: 20px
    &._item8
      @include bp($tablet)
        padding-right: 20px
        text-align: right
    &._item2
      & span small
        @include font-size-lh(20,26)
        @include bp($desktopXS)
          @include font-size-lh(16,22)
        @include bp($tablet)
          display: none
  & .d-button._favorite
    @include bp($phone)
      margin-left: 16px
    & .d-button__tooltip
      left: auto
      right: -16px
      transform: none
  &__label
    display: none
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
    @include bp($tablet)
      display: block
  &__object
    font-weight: 600
    @include font-size-lh(20,24)
    position: relative
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($tablet)
      max-width: 260px
      height: 42px
      display: flex
      flex-direction: column
      justify-content: center
    @include bp($tabletS)
      //max-width: 150px
    @include bp($phone)
      height: auto
      margin-top: 2px
      font-weight: 400
      @include font-size-lh(14,18)
    & small
      position: absolute
      left: 0
      top: calc(100% + 4px)
      font-weight: 400
      white-space: nowrap
      @include font-size-lh(16,22)
      @include bp($desktopXS)
        @include font-size-lh(14,18)
      @include bp($tablet)
        position: relative
        top: 0
        margin-top: 2px
      @include bp($phone)
        @include font-size-lh(12,16)
        margin-top: 0
        display: none
  &__price
    display: inline-block
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    position: relative
    white-space: nowrap
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($phone)
      display: flex
      flex-direction: row-reverse
      align-items: center
      justify-content: flex-end
      @include font-size-lh(18,24)
    &._request
      white-space: normal
      @include bp($phone)
        display: block
      & span
        @include bp($tablet)
          display: block
          font-weight: 400
          @include font-size-lh(14,18)
          color: $grey50
        @include bp($phone)
          display: inline
          font-weight: inherit
          font-size: inherit
          line-height: inherit
          color: currentColor
  &__priceOld
    position: absolute
    right: 0
    bottom: calc(100% + 4px)
    @include font-size-lh(18,24)
    color: $grey50
    text-decoration: line-through
    text-align: right
    white-space: nowrap
    @include bp($desktopXS)
      bottom: 100%
      @include font-size-lh(14,22)
    @include bp($phone)
      position: relative
      bottom: auto
      margin-left: 8px
      @include font-size-lh(12,20)
  &__actions
    display: none
    align-items: center
    flex-wrap: wrap
    gap: 4px
    min-height: 32px
    @include bp($tabletS)
      display: flex
      justify-content: flex-end
      gap: 0 12px
      max-width: 316px
    @include bp($phone)
      gap: 0 8px
      min-height: 0
    & .d-flat-action
      position: relative
      z-index: 3
      //&._black-friday-bage, &._black-friday
        @include bp($tabletS)
          margin-right: 12px
        @include bp($phone)
          margin-right: 0
      &__toolp
        left: 50%
        transform: translateX(-50%)
        width: 288px
        white-space: normal
        @include bp($tablet)
          left: auto
          right: 0
          transform: none
    & img
      @include bp($tablet)
        position: relative
        z-index: 2
      @include bp($phone)
        width: 16px

      & + img
        @include bp($phone)
          margin-left: 8px
    &._desktop
      display: flex
      @include bp($tabletS)
        display: none
  &__buttons
    width: 246px
    position: relative
    z-index: 3
    @include bp($desktopXS)
      width: 164px
    @include bp($tablet)
      width: 100%
    @include bp($phone)
      position: absolute
      left: 0
      bottom: 0
      padding: 0 16px 16px
    & .d-button
      width: 100%
      & em
        font-style: normal
        @include bp($desktopXS)
          display: none
        @include bp($tablet)
          display: inline
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
  &._mobile
    display: none
    @include bp($phone)
      display: block
      min-height: 0
    & .d-flat-list
      &__top
        padding: 16px 80px 12px 16px
        position: relative
      &__bottom
        display: flex
        align-items: center
        border-top: 1px solid $grey20
        padding: 12px 16px 16px
      &__img
        position: absolute
        right: 16px
        top: 20px
        width: 48px
        height: 35px
        & img
          @include objectFit(100%, contain)
      &__items
        display: flex
        align-items: center
        margin-right: auto
      &__link
        clip-path: polygon(50% 0%, 100% 0%, 100% 57%, 85% 57%, 85% 100%, 32% 100%, 0% 100%, 0% 43%, 0% 0%)
  &._skeleton
    grid-template-columns: 160px 8% calc(37% + 40px * 4) 1fr 1fr 88px
    @include bp($desktopS)
      grid-template-columns: 160px 8% calc(37% + 24px * 4) 1fr 1fr 88px
    @include bp($desktopXS)
      grid-template-columns: 144px 9% calc(37% + 16px * 4) 10% 1fr 64px
    @include bp($tablet)
      grid-template-columns: 150px calc(57% + 20px * 4) 1fr
    @include bp($tabletS)
      grid-template-columns: 150px calc(45% + 20px * 4) 1fr
    & .skeleton-field
      height: 42px
      margin: 0
      @include bp($tablet)
        height: 32px
    & .d-flat-list
      &__item
        &._item1
          & .skeleton-field
            width: 128px
            height: 96px
            @include bp($desktopXS)
              width: 104px
              height: 78px
            @include bp($tablet)
              width: 118px
              height: 88px
        &._item2
          & .skeleton-field
            @include bp($tablet)
              border-radius: 8px
        &._item3
          & .skeleton-field
            width: 96%
            @include bp($tablet)
              width: 194px
              height: 48px
        &._item4
          @include bp($tablet)
            padding-right: 20px
          & .skeleton-field
            @include bp($tablet)
              border-radius: 8px
        &._item6
          @include bp($tablet)
            position: absolute
            right: 20px
            top: 20px
          & .skeleton-field
            width: 48px
            height: 48px
            @include bp($tablet)
              width: 38px
              height: 38px
    &._mobile
      & .skeleton-field
        border-radius: 8px
      & .d-flat-list
        &__img
          width: 56px
          height: 56px
        &__price
          width: 120px
          height: 28px
        &__object
          width: 194px
          height: 16px
          margin-top: 16px
          margin-bottom: 12px
        &__bottom
          align-items: flex-end
          justify-content: space-between
          padding-top: 0
          border: none
        &__items
          width: 168px
          height: 16px
      & .d-button
        width: 24px
        height: 24px
        padding: 0

  &._type
    grid-template-columns: 208px 11% 12% 11% 11% 1fr 250px
    @include bp($desktopXS)
      grid-template-columns: 184px 11% 13% 9% 12% 1fr 226px
    @include bp($tablet)
      grid-template-columns: 150px 17% 17% 1fr 218px
    @include bp($tabletS)
      grid-template-columns: 150px 13% 10% 1fr 218px
    @include hover
      & .d-flat-list__count
        color: $white-color
        &:before
          border-top-color: $white-color
          border-right-color: $white-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
    & .d-flat-list
      &__item
        text-align: left
        &._item2
          &._item2
            & span small
              @include font-size-lh(20,26)
              @include bps($desktopXS)
                @include font-size-lh(16,22)
              @include bp($phone)
                @include font-size-lh(14,18)
        &._item6
          @include bp($tablet)
            position: absolute
            right: 16px
            top: 16px
            width: 202px
      &__price
        &._request
        @include bp($phone)
          display: block
        & span
          display: inline
          font-weight: inherit
          font-size: inherit
          line-height: inherit
          color: currentColor
      &__count
        width: 202px
        height: 48px
        padding: 0 16px
        @include bp($phone)
          width: auto
          height: auto
          padding: 0 24px 0 0
          font-weight: 400
          @include font-size-lh(14,20)
          background: url('../assets/img/d/arrow_pen.svg') no-repeat right center / 16px 16px
        & span
          @include bp($phone)
            color: $blue-color
    &._mobile
      & .d-flat-list
        &__info
          font-weight: 600
          @include font-size-lh(18,24)
          color: $blue-color
          margin-bottom: 2px
        &__bottom
          padding-top: 14px
          align-items: flex-end
          justify-content: space-between
        &__price
          @include font-size-lh(14,18)
          color: $pen-color

  &._typeSkeleton
    grid-template-columns: 208px 11% calc(34% + 40px * 2) 1fr 250px
    @include bp($desktopS)
      grid-template-columns: 208px 11% calc(34% + 24px * 2) 1fr 250px
    @include bp($desktopXS)
      grid-template-columns: 184px 11% calc(34% + 16px * 2) 1fr 226px
    @include bp($tablet)
      grid-template-columns: 150px 1fr 218px //150px 17% 17% 1fr 218px
    & .skeleton-field
      height: 42px
      margin: 0
      @include bp($tablet)
        height: 32px
        border-radius: 8px
    & .d-flat-list
      &__item
        &._item1
          & .skeleton-field
            width: 128px
            height: 96px
            @include bp($tablet)
              border-radius: 12px
        &._item2
          & .skeleton-field
            @include bp($tablet)
              width: 90%
        &._item3
          & .skeleton-field
            @include bp($tablet)
              width: 194px
              height: 48px
        &._item4
          @include bp($tablet)
            padding-right: 16px
          & .skeleton-field
            @include bp($tablet)
              height: 48px
        &._item5
          padding-right: 48px
          @include bp($desktopXS)
            padding-right: 24px
          @include bp($tablet)
            position: absolute
            right: 106px
            top: 16px
            padding-right: 0
            & .skeleton-field
              @include bp($tablet)
                width: 112px
                height: 32px
    &._mobile
      & .skeleton-field
        border-radius: 8px
      & .d-flat-list
        &__img
          width: 56px
          height: 56px
        &__info
          width: 120px
          height: 28px
        &__object
          width: 194px
          height: 16px
          margin-top: 16px
          margin-bottom: 12px
        &__bottom
          align-items: flex-end
          justify-content: space-between
          padding-top: 0
          border: none
        &__price
          width: 166px
          height: 16px
        &__count
          width: 24px
          height: 24px

  &._typePage
    grid-template-columns: 144px 9% 11% 6% 6% 5% 9% 10% 1fr 64px
    grid-column-gap: 16px
    @include bp($desktopS)
      min-height: 132px
      padding: 16px 0
      grid-template-columns: 150px repeat(5, 12%) 1fr
      grid-column-gap: 20px
      align-items: end
    @include bp($desktopXS)
      grid-template-columns: 150px 10% 11% 10% 8% 10% 1fr
    @include bp($tablet)
      grid-template-columns: 150px 12% 12% 12% 11% 11% 1fr
    @include bp($tabletS)
      grid-template-columns: 150px 10% 10% 9% 6% 11% 1fr
    @include bp($phone)
      padding: 0
    & .d-flat-list
      &__item
        @include font-size-lh(16,22)
        @include bp($desktopS)
          text-align: left
          white-space: nowrap
        &._item1
          padding-left: 24px
          & img
            display: block
            width: 104px
            height: 78px
            @include bp($tablet)
              width: 118px
              height: 88px
        &._item3, &._item9, &._item10
          @include bp($desktopS)
            position: absolute
            top: 16px
            padding-top: 0
        &._item3
          @include bp($desktopS)
            left: 170px
            top: 16px
            white-space: normal
        &._item9
          @include bp($desktopS)
            right: 88px
            top: 20px
        &._item10
          @include bp($desktopS)
            right: 20px
            top: 20px
        &._item8
          @include bp($desktopS)
            padding-right: 20px
            text-align: right
      &__label
        @include bp($desktopS)
          display: block
      &__object
        @include font-size-lh(16,22)
        @include bp($phone)
          @include font-size-lh(14,18)
        & small
          @include font-size-lh(14,18)
          @include bp($desktopS)
            top: calc(100% + 2px)
          @include bp($tablet)
            top: 0
            margin-top: 2px
          @include bp($phone)
            @include font-size-lh(12,16)
      &__price
        @include font-size-lh(16,22)
        @include bp($phone)
          @include font-size-lh(18,24)
      &__priceOld
        @include font-size-lh(14,22)
        @include bp($phone)
          @include font-size-lh(12,20)
  &._favorites
    grid-template-columns: 160px 6% 9% 6% 5% 4% 5% 1fr 1fr 48px -20px + 246px + 40px
    @include bp($desktopS)
      grid-template-columns: 160px 6% 9% 6% 5% 3% 5% 1fr 1fr 48px -4px + 246px + 40px
    @include bp($desktopXS)
      grid-template-columns: 144px 7% 11% 6% 5% 4% 6% 9% 1fr 24px -4px + 164px + 24px
    @include bp($tablet)
      grid-template-columns: 150px 13% 13% 11% 10% 10% 1fr
      padding-bottom: 88px
    @include bp($tabletS)
      grid-template-columns: 150px 10% 10% 8% 6% 11% 1fr
    @include bp($phone)
      padding-bottom: 80px - 20px
    & .d-flat-list
      &__actions
        //min-height: 32px
        @include bp($tabletS)
          max-width: 326px
        //@include bp($phone)
          min-height: 0
        & .d-flat-action
          @include bp($desktopXS)
            font-size: 14px
      &__item
        &._item9
          @include bp($tablet)
            right: 58px
            top: 18px
        &._item11
          margin-left: -20px
          padding-right: 40px
          @include bp($desktopS)
            margin-left: -4px
          @include bp($desktopXS)
            padding-right: 24px
          @include bp($tablet)
            position: absolute
            left: 0
            bottom: 0
            width: 100%
            padding: 0 24px 24px
            margin-left: 0
      &__price
        &._request
          @include bp($phone)
            display: block
          & span
            @include bp($tablet)
              display: block
              font-weight: 400
              @include font-size-lh(14,18)
              color: $grey50
            @include bp($phone)
              display: inline
              font-weight: inherit
              font-size: inherit
              line-height: inherit
              color: currentColor
      &__favorite
        display: flex
