.d-builds-info
  display: flex
  flex-direction: column
  max-width: 536px
  min-width: 480px
  width: 100%
  padding: 20px
  background: $white-color
  border-radius: 24px
  font-weight: 500
  @include font-size-lh(12,14)
  color: $pen-color
  @include bp($phone)
    max-width: 100%
    min-width: unset
    padding: 24px 12px 12px
    border-radius: 0
    height: 100%
    overflow-y: auto
  & .d-choose-builds-info__list
    padding: 0
    margin: 0
    overflow: unset
  & .d-choose-builds-info__slider
    padding: 0 20px
    margin: 0 -20px !important
    @include bp($phone)
      padding: 0 12px
      margin: 0 -12px !important
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    margin-bottom: 4px
    @include bp($phone)
      @include font-size-lh(20,24)
      padding-right: 80px
      margin-bottom: 3px
  &__numbers
    @include font-size-lh(14,18)
    color: $grey50
  &__about
    margin-top: 12px
  &__facts
    padding: 16px 20px 0
    margin: 16px -20px 0
    border-top: 1px solid $grey20
    display: grid
    grid-template-columns: repeat(4, auto)
    grid-column-gap: 17px
    justify-content: flex-start
    @include bp($phone)
      padding: 16px 12px 0
      margin: 16px -12px 0
      grid-column-gap: 8px
  &__factsItem
  //
  &__factsLabel
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
  &__factsValue
    font-weight: 600
    @include font-size-lh(18,24)
    color: $pen-color
  &__flats
    margin-top: 16px
    display: flex
    flex-direction: column
    flex-wrap: wrap
    //grid-template-columns: repeat(2, 1fr)
    column-gap: 16px
    row-gap: 8px
    grid-column-gap: 16px
    align-items: flex-start
    margin-bottom: 16px
    min-height: 30px
    max-height: 70px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
    &._oneCol
      grid-template-columns: 1fr
      grid-column-gap: 0
    &._long
      max-height: 97px
      @include bp($phone)
        max-height: unset
  &__sections
    @include bp($tablet)
      margin-bottom: 24px
  &__flatsCol
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    @include bp($phone)
      margin-top: 8px
    &:first-child
      @include bp($phone)
        margin-top: 0
  &__flat
    display: flex
    align-items: flex-end
    text-decoration: none
    justify-content: space-between
    width: calc(50% - 16px)
    &:after
      content: ''
      flex-grow: 1
      border-bottom: 1px dashed $grey20
      margin: 0 4px
      position: relative
      top: -3px
      order: 2
  &__flatLabel, &__flatValue
    flex-shrink: 0
    @include font-size-lh(14,18)
  &__flatLabel
    font-weight: 400
    color: $grey50
    //text-decoration: underline
    order: 1
    @include bp($phone)
      text-decoration: none
  &__flatValue
    font-weight: 600
    color: $pen-color
    order: 3
  &__bottom
    display: flex
    justify-content: flex-end
    padding: 0 20px
    margin: 0 -20px 0
    @include bp($phone)
      padding: 0 12px
      margin: 0 -12px 0
  &__button.d-button
    @include bps($tablet)
      height: 48px
    @include bp($phone)
      width: 100%
  & p, & ul, & ol
    margin: 0
  & ul, & ol
    padding: 0
    & li
      list-style-position: inside

  & p._empty
    @include font-size-lh(18,24)
    color: $blue-color

.d-popup-simple__content .d-builds-info__flats
  @include bp($phone)
    min-height: unset
    max-height: unset

.d-popup-simple__content .d-builds-info__flat
  @include bp($phone)
    width: 100%

