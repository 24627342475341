.d-object-contacts-qr-popup
  display: flex
  padding: 40px
  border-radius: 40px
  width: 720px
  min-height: 280px
  background: $white-color
  @include bp($phone)
    width: 100%
    max-width: 100%
  &__body
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
  &__img
    width: 200px
    height: 200px
    margin-right: 42px
    flex-shrink: 0
    & img
      max-width: 100%
  &__title
    color: $blue-color
    font-weight: 600
    @include font-size-lh(36,40)
  &__text
    font-weight: 400
    @include font-size-lh(16,22)
    margin-top: 16px
  &__buttons
    display: flex
    margin-top: 32px
    &._success
      margin-top: auto
      width: 100%
    & .d-button
      margin-right: 8px
      padding: 0 32px
      &:last-child
        margin-right: 0
  &__form
    width: 100%
    & .captcha-info
      color: $pen-color
      margin-top: 24px
      & a
        @include hover
          color: $blue-color
  &__inp
    width: 100%
    & .d-input__field
      background: $grey5  !important
  &__submit
    margin-top: 24px
    width: 100%
  &__success-btn
    width: 100%