.d-projects
  padding-top: 56px
  @include bp($desktopS)
    padding-top: 48px
  @include bp($tablet)
    padding-top: 58px
  @include bp($tabletS)
    padding-top: 52px
  @include bp($phone)
    padding-top: 44px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 36px
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    margin: 0
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__buttonParams
      width: 268px
      &._mobile
        display: none
        @include bp($tablet)
          display: flex
          position: absolute
          bottom: calc(100% + 32px)
          right: 0
        @include bp($phone)
          position: static
          margin-bottom: 0
          margin-top: -38px
          width: 100%
  &__map
    margin-bottom: 40px
