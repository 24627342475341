.d-infra-map-outside
  height: 740px
  max-width: 870px
  width: 100%
  //width: 1166px
  position: relative
  //background-size: contain
  @include bp($desktopS)
    min-height: 492px
    height: auto
  &__point
    position: absolute