.d-contacts
  margin-top: 56px
  @include bp($tablet)
    margin-top: 66px
  @include bp($phone)
    margin-top: 40px
  &__header
    display: flex
    align-items: center
    width: 100%
    justify-content: space-between
    margin-bottom: 40px
    @include bp($desktopS)
      margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 48px
      padding-bottom: 40px
      border-bottom: 1px solid $grey30
    @include bp($tabletS)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 20px
      flex-direction: column
      align-items: flex-start
      padding-bottom: 16px
  &__buttons
    margin-left: auto
    @include bp($phone)
      margin-top: 16px
    &._desktop
      @include bp($tablet)
        display: none
    &._tablet
      display: none
      @include bp($tablet)
        display: flex
      @include bp($phone)
        flex-direction: column
        margin-left: 0
        width: 100%
        margin-top: 24px
  &__title
    margin-bottom: 0
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__list
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 32px
    @include bp($desktopS)
      grid-template-columns: 1fr 1fr
    @include bp($tablet)
      gap: 8px
    @include bp($tabletS)
      grid-template-columns: 1fr
  &__btn
    margin-right: 8px
    @include bp($phone)
      width: 100%
      margin-bottom: 8px
      margin-right: 0
    &:last-child
      margin-right: 0
      @include bp($phone)
        margin-bottom: 0
  &__fixed-btn
    position: fixed
    bottom: 24px
    left: 50%
    transform: translate(-50%, 100%)
    width: 212px
    z-index: 2
    opacity: 0
    visibility: hidden
    transition: all $transition
    @include bp($phone)
      bottom: 12px
      width: calc(100% - 24px)
    &._show
      @include bp($tablet)
        opacity: 1
        visibility: visible
        transform: translate(-50%, 0)