.d-choice-language
  width: 188px
  padding: 0 56px 0 16px
  position: relative
  cursor: pointer
  user-select: none
  @include bp($phone)
    width: 120px
    padding-right: 46px
  &._open
    &:after
      transform: translateY(-50%) rotate(-90deg)
  &._open .d-choice-language__list
    display: flex
    transform: translateY(0)
    opacity: 1
    pointer-events: all
    transition: transform 0.3s ease, opacity 0.3s ease 0.1s
  &:after
    content: ''
    position: absolute
    right: 16px
    top: 50%
    transform: translateY(-50%) rotate(90deg)
    width: 24px
    height: 24px
    background: url('../assets/img/d/arrow_pen.svg') no-repeat center
    transition: transform 0.3s ease
    @include bp($phone)
      right: 12px
  & img
    width: 20px
  &__current
    display: flex
    align-items: center
    column-gap: 8px
    width: 100%
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    & span
      @include bp($phone)
        display: none
      &.mobile
        display: none
        @include bp($phone)
          display: block
  &__list
    display: flex
    position: absolute
    left: 0
    top: calc(100% + 16px)
    width: 100%
    padding: 16px 8px
    background: $white-color
    box-shadow: 0 4px 20px rgba($black, .08)
    border-radius: 12px
    flex-direction: column
    row-gap: 12px
    transform: translateY(-50%)
    opacity: 0
    transition: transform 0.3s ease 0.2s, opacity 0.3s ease
    pointer-events: none
    @include bp($phone)
      top: calc(100% + 8px)
  &__item
    display: flex
    align-items: center
    column-gap: 8px
    width: 100%
    padding: 0 8px
    border-radius: 4px
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    text-decoration: none
    @include hover
      background: $grey5
    & span
      @include bp($phone)
        display: none
      &.mobile
        display: none
        @include bp($phone)
          display: block
    &._active
      background: $grey5
