.d-investment-advantages
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-gap: 32px
  @include bp($desktopXS)
    grid-template-columns: repeat(3, 1fr)
  @include bp($tablet)
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px
  @include bp($phone)
    grid-template-columns: 1fr
    grid-gap: 12px 0

.d-investment-advantages-item
  position: relative
  overflow: hidden
  cursor: default
  @include hover
    &:after
      transform: rotate(135deg)
    & .d-investment-advantages-item
      &__content
        transform: rotateX(180deg)
        transition: transform 0.5s
  &._active
    @include bp($tablet)
      &:after
        transform: rotate(135deg)
      & .d-investment-advantages-item
        &__content
          transform: rotateX(180deg)
          transition: transform 0.5s
  &:after
    content: ''
    position: absolute
    right: 20px
    top: 50%
    margin-top: -12px
    width: 24px
    height: 24px
    background: url('../assets/img/d/icon_plus.svg') no-repeat center
    @include transition(transform)
    display: none
    z-index: 5
    @include bp($tablet)
      display: block
    @include bp($phone)
      right: 12px
  &__content
    transition: transform .5s
    transform-style: preserve-3d
    transform-origin: 50% 50%
    border-radius: 16px
    //transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
  &__front, &__back
    display: flex
    align-items: center
    background: $white-color
    border-radius: 24px
    backface-visibility: hidden
    color: $pen-color
  &__front
    padding: 20px
    transform: rotateY(0deg)
    @include bp($tablet)
      padding-right: 60px
    @include bp($phone)
      padding: 32px 48px 32px 12px
  &__back
    position: absolute
    width: 100%
    height: 100%
    transform: rotateY(180deg) rotateZ(180deg)
    transform-origin: 50% 0
    bottom: -100%
  &__icon
    flex-shrink: 0
    display: flex
    align-items: center
    justify-content: center
    width: 80px
    height: 80px
    margin-right: 16px
    background: $grey5
    border-radius: 50%
    @include bp($tablet)
      width: 72px
      height: 72px
    @include bp($phone)
      width: 48px
      height: 48px
    & img
      width: 32px
      @include bp($tablet)
        width: 24px
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    @include bp($tablet)
      font-size: 18px
  &__text
    position: absolute
    left: 0
    top: 0 //100%
    width: 100%
    height: 100%
    padding: 12px 20px
    display: flex
    align-items: center
    //background: $white-color
    font-weight: 400
    @include font-size-lh(12,14)
    z-index: 3
    //opacity: 0
    @include transition(all)
    @include bp($tablet)
      padding-right: 60px
    @include bp($phone)
      padding-right: 48px
    & ul
      padding: 0
      & li
        list-style: none
        padding-left: 8px
        position: relative
        &:before
          content: ''
          width: 4px
          height: 4px
          position: absolute
          top: 6px
          border-radius: 50%
          left: 0
          background: $pen-color