.d-news-single
  padding-top: 8px
  @include bp($phone)
    padding-top: 12px
  &__breadcrumbs
    max-width: 452px
    margin-bottom: 34px
    @include bp($phone)
      display: none
    &._mobile
      display: none
      @include bp($phone)
        display: block
        margin-bottom: 24px
    & .d-breadcrumbs__item
      white-space: nowrap
      &:last-child
        overflow: hidden
        text-overflow: ellipsis
  &__section
    margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 56px
    &._inner
      display: flex
      align-items: flex-start
      column-gap: 190px
      @include bp($desktopS)
        column-gap: 130px
      @include bp($desktopXS)
        column-gap: 104px
      @include bp($tablet)
        flex-direction: column
        align-items: center
        padding: 0 24px
        margin-right: -24px
        margin-left: -24px
        overflow: hidden
      @include bp($phone)
        padding: 0 12px
        margin-right: -12px
        margin-left: -12px
    &._moreNews
      @include bp($desktopXS)
        margin-bottom: 104px
      @include bp($tablet)
        margin-bottom: 80px
      @include bp($phone)
        margin-bottom: 64px
  &__item
    &._item1
      flex-shrink: 0
      width: 592px
      position: sticky
      left: 0
      top: 136px
      @include transition(top)
      @include bp($desktopS)
        width: 486px
      @include bp($desktopXS)
        width: 416px
      @include bp($tablet)
        width: 100%
        position: relative
        top: 0
      &._noHeader
        top: 32px
        @include bp($tablet)
          top: 0
    &._item2
      flex-grow: 1
      max-width: calc(100% - 592px - 190px)
      width: 100%
      padding-top: 55px
      @include bp($desktopS)
        max-width: calc(100% - 486px - 130px)
      @include bp($desktopXS)
        max-width: calc(100% - 416px - 104px)
      @include bp($tablet)
        max-width: 720px
        padding: 0
        margin: 40px 0 0
      @include bp($phone)
        max-width: 425px
        margin-top: 24px
  &__top
    display: flex
    align-items: center
    margin-bottom: 24px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 16px
  &__likes
    margin: 0 auto 0 40px
    @include bp($desktopXS)
      margin-left: 24px
    @include bp($tablet)
      margin-left: 32px
    @include bp($phone)
      margin-left: 16px
    & .d-news-likes
      flex-direction: row-reverse
      & button, & .button
        margin: 0 8px 0 0
  &__source, &__share
    display: flex
    align-items: center
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    text-decoration: none
    @include transition(color)
    position: relative
    @include bp($desktopXS)
      @include font-size-lh(14,18)
    @include hover
      color: $blue-color
    & > svg
      width: 24px
      height: 24px
      margin-left: 8px
      fill: currentColor
  &__source
    @include bp($phone)
      display: none
  &__source
    &._mobile
      display: none
      @include bp($phone)
        display: flex
        justify-content: center
        margin: 16px auto 0
  &__share
    cursor: pointer
    margin-left: 40px
    @include bp($desktopXS)
      margin-left: 16px
    & .d-share
      @include bp($desktopXS)
        left: auto
        right: 0
        transform: none
      @include bp($phone)
        bottom: auto
        top: calc(100% + 8px)
      & .d-share__tooltip
        @include bp($tablet)
          bottom: auto
          top: calc(100% + 2px)
  &__title, & h1
    font-weight: 600
    @include font-size-lh(40,44)
    color: $blue-color
    margin: 0
    @include bp($desktopXS)
      @include font-size-lh(32,36)
    @include bp($tablet)
      max-width: 720px
      width: 100%
      margin: 0 auto
      text-align: center
    @include bp($phone)
      @include font-size-lh(24,28)
  &__titleImage
    display: block
    object-fit: cover
    width: 100%
    height: 360px
    margin-top: 40px
    border-radius: 32px
    @include bp($desktopXS)
      height: 270px
      margin-top: 24px
    @include bp($tablet)
      height: 420px
      margin-top: 32px
    @include bp($phone)
      height: 240px
      margin-top: 16px
      border-radius: 24px
  &__bottom
    display: none
    @include bp($tablet)
      display: flex
      align-items: center
      margin-top: 32px
    @include bp($phone)
      margin-top: 24px
    & .d-news-single__likes
      margin-left: 0
    & .d-news-single__source
      display: flex
    & .d-news-single__share
      margin-left: 32px
      @include bp($phone)
        margin-left: 16px
      & .d-share
        @include bp($phone)
          top: auto
          bottom: calc(100% + 8px)