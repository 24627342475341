.d-metro
  display: inline-flex
  align-items: center
  margin-left: 20px
  position: relative
  &:first-child
    margin: 0
  &__name
    display: inline-flex
    align-items: center
  &__color
    width: 16px
    height: 8px
    margin-right: 4px
    border-radius: 4px
    &._yellow
      background: #FFE710
    &._green1
      background: #6FBCBA
    &._green2
      background: #79FF10
    &._pink
      background: #FFB4E1

  &__drive, &__walk
    margin-left: 15.5px
    display: inline-flex
    align-items: center
