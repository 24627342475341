.offices-contacts-item-mobile-popup
  background: $white-color
  height: 100%
  display: flex
  flex-direction: column
  overflow-y: auto
  & .mapsInit__zoom-buttons
    display: none
  &__map
    height: 68%
    //height: auto
    background: #f1f1f1
    flex-shrink: 1
    flex-grow: 1

  &__bottom
    padding: 32px
    min-height: 324px
    //height: 32%
    flex-shrink: 0