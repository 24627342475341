.d-choose-filter
  display: flex
  flex-direction: column
  row-gap: 32px
  position: relative
  & .skeleton-field
    background: $white-color
    &:before
      display: none
  &._mobile
    background: $white-color
    min-height: 100%
    @include bp($tablet)
      row-gap: 0
      padding: 72px 24px 138px
    @include bp($phone)
      padding: 60px 12px 104px
  &__row
    &._row1
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-column-gap: 24px
      @include bp($desktopXS)
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 24px
      @include bp($tablet)
        grid-template-columns: 1fr
        grid-gap: 0
    &._row2
      display: flex
      align-items: center
      justify-content: space-between
      @include bp($desktopXS)
        position: absolute
        left: calc(100% / 3 - 48px / 3 + 24px)
        bottom: 8px
        width: calc( 100% - (100% / 3 - 48px / 3 + 24px))
      @include bp($tablet)
        grid-template-columns: 1fr
        width: 100%
        position: static
  &__title
    display: none
    @include bp($tablet)
      display: flex
      align-items: center
      @include font-size-lh(32,36)
      font-weight: 600
      color: $blue-color
      position: fixed
      background: $white-color
      top: 0
      left: 0
      width: 100%
      z-index: 3
      padding: 18px 0 18px 24px
    @include bp($phone)
      @include font-size-lh(24,28)
      padding: 16px 12px 17px
    &:after
      content: ''
      position: absolute
      width: calc(100% + 48px)
      left: -24px
      bottom: 0
      height: 1px
      background: $grey20
      display: block
      @include bp($phone)
        width: calc(100% + 24px)
        left: -12px
  &__reset-mobile
    color: $grey50
    @include font-size-lh(14,18)
    //display: none
    text-decoration: none
    font-weight: 400
    margin-left: 12px
    @include bp($tablet)
      margin-top: 4px
    @include bp($phone)
      display: block
      margin: 6px 0 0 14px
  &__item
    &.skeleton-field
      height: 64px
      margin: 0
    &._rooms
      @include bp($tablet)
        display: none
    &._price
      @include bp($tablet)
        display: none
    &._disabled
      opacity: 0.5
      pointer-events: none
  &__actions
    display: flex
    column-gap: 4px
    & .skeleton-field
      width: 128px
      height: 48px
      margin: 0
    & .skeleton-field:nth-child(2)
      width: 109px
    & .skeleton-field:nth-child(3)
      width: 165px
    &._desktop
      @include bp($tablet)
        display: none
    &._mobile
      display: none
      @include bp($tablet)
        display: flex
        flex-wrap: wrap
        gap: 4px
        margin-bottom: 24px
  &__action
    &.d-checkbox._full
      & .d-checkbox__label
        font-weight: 400
        background: $white-color
        @include bp($tablet)
          background: $grey5
        @include hover
          color: $blue-color
      & svg
        width: 16px
        height: 16px
        margin-right: 8px
  &__clear
    display: flex
    align-items: center
    background: none
    border: none
    padding: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    @include transition(color)
    @include hover
      color: $blue-color
    &._desktop
      @include bp($tablet)
        display: none
    &._mobile
      @include bps($phone)
        display: none
    &._popup
      font-weight: 400
      @include font-size-lh(14,18)
      color: $grey50
      margin-top: 4px
    & svg
      width: 24px
      height: 24px
      margin-right: 8px
      fill: currentColor
    &[disabled]
      opacity: 0.5
      pointer-events: none
    &.skeleton-field
      width: 172px
      height: 24px
      margin: 0
  &__bottom
    display: none
    gap: 8px
    position: absolute
    z-index: 5
    bottom: 0
    left: 0
    width: 100%
    border-top: 1px solid $grey20
    //background: $white-color
    padding: 24px
    justify-content: flex-end
    @include bp($tablet)
      display: flex
      gap: 40px
    @include bp($phone)
      border: none
      padding: 16px 12px
  &__reset-tablet
    @include bp($phone)
      display: none
  &__view-result
    @include bp($phone)
      width: 100%
  &__rooms
    display: none
    @include bps($tablet)
      display: block
      margin-bottom: 16px
  &__checkboxes
    display: flex
    flex-wrap: wrap
    gap: 4px
    & .d-checkbox
      @include bps($tablet)
        flex-grow: 1
      @include bps($phone)
        flex-grow: 0
        max-width: 48px
      &._full
        & .d-checkbox__label
          @include bps($tablet)
            height: 64px
          @include bps($phone)
            height: 48px

.tippy-box[data-theme="choose-filter"], .tippy-box[data-theme="choose-filter-touch"]
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  //width: 288px
  width: fit-content
  min-height: 44px
  padding: 12px 16px
  color: $blue-bright
  @include font-size-lh(16, 22)
  font-weight: 400
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 4px

.tippy-box[data-theme="choose-filter-touch"]
  //width: fit-content
  @include bp($phone)
    padding: 12px
    @include font-size-lh(12, 14)
  &:after
    align-self: flex-start
    content: url('../assets/img/d/icon_close_pen.svg')
    width: 20px
    height: 20px

.tippy-box[data-theme="choose-filter"] .tippy-content, .tippy-box[data-theme="choose-filter-touch"] .tippy-content
  padding: 0 !important