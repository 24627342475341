.d-popup
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  max-width: 720px
  width: 100%
  border-radius: 40px
  @include transition(all)
  @include bp($phone)
    left: 0
    top: 0
    transform: none
    max-width: 100%
    height: 100%
    background: $background
    border-radius: 0

  &__close
    position: absolute
    right: 32px
    top: 32px
    z-index: 5
    @include bp($phone)
      right: 12px
      top: 12px
