.d-awards-popup
  //position: fixed
  left: 0
  top: 0
  //width: 100% // фикс незакрывающегося попапа
  height: 100%
  @include transition(all)
  pointer-events: none !important
  & *
    pointer-events: auto
  &._show
    opacity: 1
    visibility: visible
    z-index: 100
  &._oneSlide
    & .d-awards-popup
      &__pagination, &__arrows
        display: none
      &__note
        @include bp($phone)
          left: 12px
          bottom: 12px
          max-width: 422px
          width: calc(100% - 24px)
  &__bg
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgba($black, 0.6)
    backdrop-filter: blur(20px)
    z-index: 1
  &__slider
    max-width: 1504px
    width: calc(100% - 80px)
    height: 100%
    padding: 40px 0 !important
    margin: 0 auto
    z-index: 2 !important
    @include bp($desktopXS)
      width: calc(100% - 176px*2)
    @include bp($tablet)
      width: calc(100% - 64px)
      //height: calc(100% - 240px)
      padding: 120px 0 !important
      margin: 0 auto
    @include bp($phone)
      width: calc(100% - 24px)
      //height: calc(100% - 168px)
      padding: 84px 0 !important
      margin: 0 auto
  &__slide
    //
    & img
      @include objectFit(100%, contain)
  &__close, &__pagination, &__arrows, &__note
    position: absolute !important
    z-index: 5
  &__close, &__arrows
    right: 40px
    @include bp($tablet)
      right: 32px
    @include bp($phone)
      right: 12px
  &__close
    top: 40px
    @include bp($tablet)
      top: 32px
    @include bp($phone)
      top: 12px
  &__pagination, &__arrows
    bottom: 40px !important
    @include bp($tablet)
      bottom: 32px !important
    @include bp($phone)
      bottom: 12px !important
  &__pagination
    left: 40px !important
    @include bp($tablet)
      left: 32px !important
      bottom: auto !important
      top: 32px !important
    @include bp($phone)
      left: 12px !important
      top: 12px !important
  &__note
    display: flex
    max-width: 630px
    left: 130px
    bottom: 40px
    @include bp($tablet)
      left: 32px
      bottom: 32px
      max-width: 422px
    @include bp($phone)
      left: 12px
      bottom: 12px
      max-width: calc(100% - 128px)
    & .d-swipe-info button
      @include bp($phone)
        margin-left: 8px
