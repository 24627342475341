.d-mortgage-info
  min-height: 248px
  display: flex
  flex-direction: column
  row-gap: 24px
  align-items: center
  justify-content: center

  &._index
    min-height: 422px

  &._flat
    background-color: white
    border-radius: 40px
    width: 100%
    min-height: 628px
    @include bp($desktopXS)
      min-height: 488px
      padding: 100px 65px
    @include bp($phone)
      min-height: 310px
      padding: 24px 16px
      border-radius: 24px

  &__icon
    background: transparent url("../assets/img/icons/upload.svg") no-repeat center
    height: 64px
    width: 64px

  &__text
    max-width: 591px
    text-align: center
    color: $pen-color
    font-weight: 600
    @include font-size-lh(20,24)
    & a
      white-space: nowrap
