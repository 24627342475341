.d-range-slider
  font-weight: 500
  @include font-size-lh(16,22)
  color: $grey50
  &._pb
    padding-bottom: 8px
  &._commercial
    & .d-range-slider
      &__content
        background: $white-color
        @include bp($tablet)
          background: $grey5
  &__title
    margin-bottom: 12px
    @include bp($tablet)
      font-weight: 400
    @include bp($phone)
      margin-bottom: 8px
  &__content
    display: flex
    background: $grey5
    border-radius: 12px
    position: relative
  &._white &__content
    background: $white-color
  &__col
    display: flex
    align-items: center
    justify-content: space-between
    width: 50%
    height: 64px
    padding: 0 24px
    @include bp($phone)
      height: 48px
      padding: 0 16px
    @include hover
      & .d-range-slider
        &__value
          color: $blue-color
    &._left
      & .d-range-slider__label
        @include bp($phone)
          display: none
    &._right
      border-left: 1px solid $grey20
      @include bp($phone)
        width: 63%
  &:not(._wide) .d-range-slider__col._left
    @include bp($phone)
      width: 37%
  &__col div
    user-select: none
  &__value
    flex-grow: 1
    background: none
    border: none
    outline: none
    width: 70%
    padding: 0
    margin-left: 8px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    @include transition(color)
  &__label
    flex-shrink: 0
    margin-left: 8px
  &__line
    position: absolute
    margin-left: 26px
    bottom: 0
    max-width: calc(100% - 56px)
    height: 1px
    background: $blue-color
    @include bp($phone)
      margin-left: 22px
      max-width: calc(100% - 44px)
  &__bullet
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: 16px
    height: 16px
    background: $white-color
    border: 6px solid $blue-color
    border-radius: 50%
    @include transition(all)
    cursor: pointer
    @include hover
      background: $blue-color
    &._left
      left: 0
    &._right
      left: 100%
  &._moreParams
    margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
