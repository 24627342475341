.d-object-progress-cameras
  width: 100% // фикс незакрывающегося попапа
  height: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  pointer-events: none !important
  padding: 40px
  @include bp($desktopS)
    padding: 32px
  @include bp($tablet)
    padding: 24px 24px 0
    //display: flex
    //justify-content: center
  @include bp($phone)
    //display: block
    padding: 12px
  & *
    pointer-events: auto
  & .d-tabs
    @include bp($tablet)
      display: flex
      flex-direction: column-reverse
      align-items: center
      justify-content: center
    @include bp($phone)
      width: 100%
      //padding: 0 24px
  & .d-tabs__buttons
    @include bp($phone)
      width: calc(100% + 0px)
      padding: 0 12px
  & .d-tabs__content
    justify-content: center
    //@include bp($phone)
    //  width: 69%
    &._active
      display: flex
      @include bp($tablet)
        margin-top: auto
        margin-bottom: auto
  & .d-tabs__buttonsInner
    @include bp($phone)
      display: inline-flex
      width: auto
  &__iframe
    width: 1216px
    height: 680px
    //margin-top: 76px
    //border-radius: 40px
    overflow: hidden
    @include bp($desktopS)
      //margin-top: 24px
    @include bp($tablet)
      width: 720px
      height: 402px
      //margin-bottom: 52px
      //margin-top: auto
    @include bp($phone)
      aspect-ratio: 16/9
      width: 100%
      max-width: 100%
      height: auto
      //margin-bottom: 0
  &__content
    display: flex
    justify-content: center
    width: 100%
    padding: 52px 0 52px + 64px
    margin: auto
    @include bp($tablet)
      //margin-top: 52px
    @include bp($phone)
      //margin-top: 88px
      padding-bottom: 52px + 48px + 64px
  &__header
    display: flex
    position: relative
    @include bp($phone)
      justify-content: center
      width: 100%
      margin-top: 64px
  &__select
    width: 480px
    //width: 100%
    @include bp($phone)
      width: 100%
  &__share
    flex-shrink: 0
    width: 64px
    padding: 0
    margin-left: 24px
    overflow: unset
    z-index: 10
    @include bp($phone)
      width: 48px
      position: absolute
      left: 0
      bottom: calc(100% + 16px)
      margin: 0
    @include hover
      background: $blue-color
      border-color: $blue-color
    &:before, &:after
      display: none
    & > img
      width: 24px
    & .d-share
      left: calc(100% + 8px)
      bottom: 50%
      transform: translateY(50%)
      &__item
        &.share-tg
          @include bp('(min-width: 768px)')
            display: none
  &__close
    position: absolute
    right: 40px
    top: 40px
    z-index: 5
    @include bp($desktopS)
      right: 32px
      top: 32px
    @include bp($tablet)
      top: 24px
      right: 24px
    @include bp($phone)
      top: 12px
      right: 12px
