.d-menu-links
  display: grid
  grid-template-columns: repeat(5, 1fr)
  grid-column-gap: 94px
  @include bp($desktopS)
  //grid-column-gap: 96px
  @include bp($desktopXS)
    grid-column-gap: 64px
  @include bp($tablet)
    grid-template-columns: repeat(3, 1fr)
    grid-row-gap: 40px
  @include bp($tabletS)
    grid-column-gap: 40px
  &__item
    display: flex
    flex-direction: column
    align-items: flex-start
    white-space: nowrap
    &:first-child
      margin-top: 0
    &._item1, &._item3
      @include bp($desktopXS)
        white-space: normal
    &._item1
      @include bp($tablet)
        order: 1
    &._item2
      @include bp($tablet)
        order: 4
    &._item3
      @include bp($desktopXS)
        min-width: 204px
      @include bp($tablet)
        min-width: 0
        order: 2
    &._item4
      margin-top: 8px
      @include bp($tablet)
        margin-top: 0
        order: 5
    &._item5
      @include bp($tablet)
        order: 3
    &._item6
      @include bp($tablet)
        order: 6
  &__section
    font-weight: 600
    @include font-size-lh(20,24)
    color: $blue-color
    margin: 16px 0
    &:first-child
      margin: 0 0 16px !important
    &[href]
      text-decoration: none
      @include transition(color)
      @include hover()
        color: $pen-color
      &:after
        display: none
    & + .d-menu-links__section
      margin-top: 8px
  &__link
    font-weight: 500
    @include font-size-lh(16,22)
    color: $pen-color
    text-decoration: none
    margin-bottom: 8px
    @include transition(color)
    @include hover()
      color: $blue-color
    &:last-child
      margin-bottom: 0 !important
    &._wrap
      white-space: normal

  &._header
    grid-template-columns: repeat(3, auto)
    grid-gap: 56px
    @include bp($desktopS)
      grid-gap: 40px
    @include bp($desktopXS)
      grid-column-gap: 40px
      grid-row-gap: 40px
    @include bp($tablet)
      grid-template-columns: repeat(3, 192px)
      grid-gap: 32px
    @include bp($tabletS)
      grid-template-columns: repeat(3, calc(100% / 3 - 24px*2/3))
      grid-column-gap: 24px
    @include bp($phone)
      display: block
    & .d-menu-links
      &__item
        width: 100%
        white-space: nowrap
        &._item1
          order: 1
          @include bp($tablet)
            white-space: normal
          & .d-menu-links__section
            @include bp($phone)
              border: none
        &._item2
          order: 4
          @include bp($tablet)
            white-space: normal
        &._item3
          min-width: 254px
          order: 2
          @include bp($tablet)
            min-width: 0
            white-space: normal
        &._item4
          margin: 0
          order: 5
          @include bp($tablet)
            white-space: normal
        &._item5
          order: 3
        &._item6
          order: 6
        &._hide
          @include bp($phone)
            display: none
        &._active
          & .d-menu-links
            &__section
              @include bp($phone)
                padding-left: 32px
                border-top: none
                border-bottom: 1px solid $grey20
                margin-bottom: 16px !important
              &:after
                @include bp($phone)
                  right: auto
                  left: 0
                  transform: rotate(180deg)
            &__link
              display: block
      &__section
        @include font-size-lh(32,36)
        @include bp($desktopXS)
          @include font-size-lh(24,28)
        @include bp($tablet)
          margin-top: 8px
        @include bp($phone)
          @include font-size-lh(18,21)
          width: 100%
          padding: 14px 0
          margin: 0 !important
          border-top: 1px solid $grey20
          position: relative
        &:after
          @include bp($phone)
            content: ''
            position: absolute
            right: 0
            top: 0
            width: 20px
            height: 100%
            background: url('../assets/img/d/arrow_pen.svg') no-repeat center
        & + .d-menu-links__section
          margin-top: 40px
          @include bp($desktopS)
            margin-top: 24px
          @include bp($tablet)
            margin-top: 16px
      &__link
        font-weight: 600
        @include font-size-lh(20,24)
        margin-bottom: 12px
        @include bp($phone)
          @include font-size-lh(16,22)
          margin: 0 32px 16px
          display: none

