.object-map-popup
  top: 0 !important
  & .mfp-container
    padding: 0
  & .mfp-content
    height: 100%
  & .mfp-close
    background-color: $white-color
    box-shadow: -1.01266px -1.01266px 16.2025px #FFFFFF, 10.1266px 10.1266px 20.2532px rgba(210, 220, 233, 0.8), inset 1.01266px 1.01266px 0px rgba(255, 255, 255, 0.9)
    opacity: 1
    border-radius: 50%
    color: $white-color !important
    transform: translate(-24px, 24px)
    outline: none
    display: flex
    justify-content: center
    align-items: center
    font-size: 0
    width: 48px
    height: 48px
    &:before
      content: ''
      width: 16px
      height: 16px
      display: block
      background: url('../assets/img/icons/close.svg') no-repeat
      background-size: contain
    @include bp($tablet)
      transform: translate(-16px, 16px)