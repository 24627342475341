.d-select
  position: relative
  user-select: none
  &._contacts
    & .d-select__head
      color: $grey50
      &._active
        color: $pen-color
  &._commercial
    width: 100%
    & .d-select__head
      background: $grey5
  &._open
    & .d-select
      &__head:after
        transform: rotate(-90deg)

      &__drop
        opacity: 1
        visibility: visible

  &__head
    display: flex
    align-items: center
    height: 64px
    padding: 0 64px 0 24px
    background: $white-color
    border-radius: 12px
    font-weight: 600
    @include font-size-lh(16, 22)
    color: $pen-color
    cursor: pointer
    position: relative
    @include transition(color)
    @include bp($phone)
      height: 48px
    @include bp($phoneXS)
      padding: 0 48px 0 12px
    @include hover
      color: $blue-color
    & span
      width: 100%
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      & br
        display: none
    &:after
      content: ''
      position: absolute
      right: 22px
      top: 50%
      margin-top: -10px
      width: 20px
      height: 20px
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
      transform: rotate(90deg)
      @include bp($phoneXS)
        right: 12px

  &._error .d-select__head
    color: $error
    border: 1px solid $error

  &__drop
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 12px
    padding: 15px 6px 15px 16px
    z-index: 22
    opacity: 0
    visibility: hidden
    will-change: opacity

  &__list
    max-height: 200px
    padding-right: 10px

  &__item
    padding: 9px 8px
    margin-bottom: 2px
    font-weight: 500
    @include font-size-lh(16, 22)
    color: $pen-color
    cursor: pointer
    border-radius: 8px
    @include transition(all)
    @include hover
      background: $grey5
      color: $blue-color

    &:last-child
      margin: 0

    &._active
      background: $grey5
      border-radius: 8px

    &._disable
      background: $grey5
      color: $grey_shadow
      pointer-events: none

  &._grey
    .d-select
      &__head, &__drop
        background: $grey5
      &__item
        @include hover
          background: $white-color
        &._active
          background: $white-color
  &__icon
    width: 24px
    height: 24px
    margin-right: 16px
    @include bp($phoneXS)
      margin-right: 8px
    &._asc
      transform: scaleY(-1)

// Dropdown Menu Animation
.dropdown-enter-active,
.dropdown-leave-active
  transition: all $transitionSlow


.dropdown-enter,
.dropdown-leave-to
  opacity: 0
