.d-trade-in
  &__top
    display: grid
    grid-template-columns: 1fr 436px
    grid-column-gap: 32px
    @include bp($desktopS)
      grid-template-columns: 1fr 356px
    @include bp($desktopXS)
      grid-template-columns: 1fr 280px
    @include bp($tablet)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 24px
    @include bp($phone)
      grid-row-gap: 12px
  &__facts
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 24px
      grid-row-gap: 0
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 12px
  &._buyback
    & .d-trade-in
      &__top
        @include bp($desktopXS)
          grid-template-columns: 1fr 296px
        @include bp($tablet)
          grid-template-columns: 1fr
      &__facts
        @include bp($tabletS)
          grid-column-gap: 16px
    & .d-purchase-about
      @include bp($phone)
        min-height: 390px
      &__img
        right: 140px
        width: 587px
        @include bp($desktopXS)
          right: 32px
          width: 489px
        @include bp($tablet)
          right: -58px
          width: 435px
        @include bp($phone)
          right: 50%
          bottom: 0
          width: 268px
    & .d-purchase-fact._trade-in .d-purchase-fact
      &__title
        @include bp($desktopS)
          padding: 24px 24px 0
        @include bp($phone)
          padding: 16px 24px
      &__text
        margin-top: auto
        @include bp($desktopS)
          padding: 0 24px 24px
        @include bp($tablet)
          margin-top: 0
        @include bp($phone)
          padding: 16px 24px
  &._wholesale-discount
    & .d-trade-in
      &__top
        @include bp($desktopXS)
          grid-template-columns: 1fr 296px
        @include bp($tablet)
          grid-template-columns: 1fr
      &__facts
        @include bp($tabletS)
          grid-column-gap: 16px
    & .d-purchase-about
      &__text
        max-width: 500px
        @include bp($tabletS)
          max-width: 340px
        @include bp($phone)
          max-width: 100%
          margin-bottom: 158px
      &__img
        right: 148px
        width: 414px
        @include bp($desktopXS)
          right: 48px
          width: 345px
        @include bp($tablet)
          right: 25px
          width: 305px
        @include bp($phone)
          right: 50%
          bottom: -49px
          width: 195px
    & .d-purchase-fact._trade-in
      @include bp($tablet)
        min-height: 126px
      @include bp($phone)
        min-height: 142px
      & .d-purchase-fact
        &__title
          @include bp($desktopS)
            padding: 24px 24px 0
          @include bp($phone)
            min-height: 72px
            padding: 16px 24px
        &__text
          margin-top: auto
          @include bp($desktopS)
            padding: 0 24px 24px
          @include bp($phone)
            padding: 16px 24px