.d-national
  display: flex
  flex-direction: column
  &__inner
    margin-bottom: 160px
    @include bp($desktopXS)
      margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 64px
  &__utp
    margin-top: 24px
    @include bp($phone)
      margin: 12px -12px 0
      overflow: hidden
    & .d-regional-utp
      @include bp($phone)
        overflow: visible
  &__geography, &__projects
    margin-top: 160px
    @include bp($desktopXS)
      margin-top: 120px
    @include bp($tablet)
      margin-top: 96px
    @include bp($phone)
      margin-top: 64px
  &__projects
    display: flex
    flex-direction: column
    row-gap: 160px
    @include bp($desktopXS)
      row-gap: 120px
    @include bp($tablet)
      row-gap: 96px
    @include bp($phone)
      row-gap: 64px
  & .d-still-questions
    margin-top: auto