.d-investment-calc
  display: grid
  grid-template-columns: 1fr
  grid-gap: 32px 0
  @include bp($tablet)
    grid-template-columns: 1fr 336px
    grid-gap: 0 32px
  @include bp($tabletS)
    grid-template-columns: 1fr 230px
    grid-gap: 0 24px
  @include bp($phone)
    grid-template-columns: 1fr
    grid-gap: 32px 0
  &__row
    //
  &__label, & .d-range-slider-single__title
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 4px
  &__value
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    margin-top: 8px
    @include bp($tablet)
      line-height: 64px
      margin-top: 0
    @include bp($phone)
      line-height: 28px
    &.skeleton-field
      max-width: 210px
      width: 100%
      height: 28px
      margin-bottom: 0
      @include bp($tablet)
        margin-top: 20px
      @include bp($phone)
        margin-top: 0
  & .d-range-slider
    &__title
      font-weight: 400
    &__value
      min-width: 42px