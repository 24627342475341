.d-map-infra-cluster
  background: $blue-color
  color: $white-color
  font-weight: 500
  font-size: 16px
  line-height: 22px
  height: 32px
  width: 32px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%