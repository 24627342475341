.d-choose-filter-params
  display: grid
  grid-template-columns: 1fr
  grid-row-gap: 24px
  &._params
    padding-top: 14px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px
      padding-top: 8px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 16px
      padding-top: 0
  &__row
    position: relative
    &._advantages
      @include bp($tablet)
        grid-column: span 2
      @include bp($phone)
        grid-column: unset
    &._price
      display: none
      padding-top: 24px
      @include bp($tablet)
        display: block
        grid-column: span 2
      @include bp($phone)
        grid-column: unset
      &:after
        content: ''
        position: absolute
        width: calc(100%)
        left: 0
        top: 0
        height: 1px
        background: $grey20
        display: block
        @include bp($phone)
          width: calc(100%)
          left: 0
    &._other
      //padding-top: 24px
      //margin-top: 8px
      @include bp($tablet)
        padding-top: 24px
        margin-top: 8px
      @include bp($phone)
        margin-top: 16px
      &:after
        @include bp($tablet)
          content: ''
          position: absolute
          width: calc(100%)
          left: 0
          top: 0
          height: 1px
          background: $grey20
          display: block
        @include bp($phone)
          width: calc(100%)
          left: 0
  &__label
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 12px
  &__checkboxes
    display: flex
    overflow-x: auto
    flex-wrap: wrap
    gap: 4px
    @include bp($phone)
      max-width: calc(100vw - 12px)
    &._rooms
      & .d-checkbox
        flex-shrink: 0
        min-width: 74.83px
        width: max-content
        //flex-grow: 1
        flex-basis: calc(25% - 4px)
        @include bp($tablet)
          min-width: 59px
          flex-grow: 0
          flex-basis: calc(20% - 4px)
        @include bp($phone)
          min-width: 46px

        &__label
          @include bp($tablet)
            height: 64px
          @include bp($phone)
            height: 48px
  &__title
    font-weight: 600
    user-select: none
    @include font-size-lh(16,22)
    color: $pen-color
    white-space: nowrap
    margin-bottom: 16px
    &._params
      color: $grey50
      font-weight: 400
    &._other
      display: none
      @include bp($tablet)
        display: block