.offices-contacts-card-item
  position: relative
  &:after
    content: ''
    display: block
    margin-top: 16px
    background: rgba(155, 161, 178, 0.25)
    box-shadow: 0px 1px 0px #FFFFFF
    height: 1px
  &:hover
    cursor: pointer
    & .offices-contacts-card-item__name
      color: $brand-color
    & .offices-contacts-card-item__arrow svg
      fill: $brand-color
  & + .offices-contacts-card-item
    margin-top: 16px
  &._contacts
    &:last-child
      &:after
        display: none
  &__name
    @include font-size-lh(16,24)
    font-weight: 600
    color: $pen-color
    transition: color $transition
    max-width: calc(100% - 44px)
  &__address
    @include font-size-lh(14,22)
    font-weight: 600
    color: $dark-grey
    margin-top: 4px
    max-width: calc(100% - 44px)
  &__arrow
    position: absolute
    top: 18px
    right: 0
    & svg
      fill: $pen-color
      transition: fill $transition