.d-swipe-info
  display: flex
  align-items: flex-start
  background: $white-color
  border-radius: 12px
  padding: 12px
  font-weight: 500
  @include font-size-lh(16,22)
  color: $pen-color
  @include bp($phone)
    @include font-size-lh(12,14)
  & img, & button
    flex-shrink: 0
  & img
    display: block
    width: 24px
    margin-right: 8px
  & button
    background: none
    border: none
    padding: 0
    margin-left: 16px
    & img
      margin-right: 0
  & div
    flex-grow: 1