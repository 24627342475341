.d-company-teams-popup
  display: flex
  flex-direction: column
  max-width: 720px
  width: 100%
  max-height: 500px
  padding: 32px 0
  background: $white-color
  border-radius: 40px
  position: relative
  margin: auto
  @include transition(all)
  @include bp($phone)
    position: fixed
    left: 0
    top: 0
    transform: none
    max-width: 100%
    max-height: 100%
    height: 100%
    padding: 12px 0
    border-radius: 0
  &__top
    flex-shrink: 0
    padding: 0 116px 24px 32px
    margin-bottom: 24px
    border-bottom: 1px solid $grey20
    @include bp($phone)
      padding: 0 12px 16px
      margin-bottom: 16px
  &__content
    flex-grow: 1
    padding: 0 24px 0 32px
    position: relative
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    overflow: hidden
    @include bp($phone)
      //height: 100%
      padding: 0 6px 0 12px
      overflow: auto
    &::-webkit-scrollbar
      @include bp($phone)
        //display: none
  & .simplebar-wrapper
    @include bp($phone)
      height: 100%
  &__contentInner
    max-height: 290px
    height: 100%
    padding-right: 8px
    overflow: auto
    @include bp($phone)
      max-height: none
      padding-right: 6px
      overflow: hidden
    & *
      padding: 0
      margin: 0
      & + *
        margin-top: 12px
    & ul li
      list-style: none
      padding-left: 16px
      position: relative
      &:before
        content: ''
        position: absolute
        left: 0
        top: 8px
        width: 8px
        height: 8px
        background: $blue-color
        border-radius: 50%
    & .simplebar-mask, & .simplebar-track
      margin: 0
  &__close
    position: absolute
    right: 32px
    top: 32px
    z-index: 2
    @include bp($phone)
      right: 12px
      top: 12px