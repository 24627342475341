.d-aux-rooms-popup-scheme
  display: flex
  height: 100%
  overflow: hidden
  @include bp($phone)
    padding: 0 12px
    margin: 0 -12px
    overflow: auto
  &__inner
    height: 100%
    width: auto
    flex-grow: 1
    position: relative
    & img, & svg
      @include objectFit(100%, contain)
  &__popup
    width: 296px
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 16px
    & .d-aux-rooms-line
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
      cursor: default
      &__size
        padding: 0 !important
      &__sizeInfo
        display: none
      &__button
        &.d-button._flatIcon
          pointer-events: none

.tippy-box[data-theme="d-aux-rooms-popup"]
  max-width: 296px !important
  background: transparent
  padding: 0
.tippy-box[data-theme="d-aux-rooms-popup"] .tippy-content
  padding: 0

g#SELECTION path, g#SELECTION polygon
  outline: none!important

g#SELECTION path._avail, g#SELECTION polygon._avail
  fill: $blue-color
  opacity: 0.8
  cursor: pointer


g#SELECTION path._avail:hover, g#SELECTION polygon._avail:hover
  fill: #5131F5
  opacity: 0.8


g#SELECTION path._selected, g#SELECTION polygon._selected
  fill: #5131F5
  opacity: 0.8
