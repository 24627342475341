// media bp
$phone360-:   '(max-width: 359px)'
$phoneXS:   '(max-width: 374px)'
$phoneS2:    '(max-width: 515px)'
$phoneS:    '(max-width: 639px)'
$phone:     '(max-width: 767px)'
$tabletS:   '(max-width: 1023px)'
$tablet:    '(max-width: 1279px)'
$desktopXS: '(max-width: 1599px)'
$desktopS:  '(max-width: 1919px)'
$desktopS2:  '(max-width: 1800px)'
$desktop :  '(min-width: 1920px)'
$hover:     '(hover: hover) and (pointer: fine)' /* mouse, touch pad */
$isTouchDevice: '(pointer: coarse)'

@mixin bp($point: null)
  @media #{$point}
    @content
@mixin bps($mq...)
  @each $point in $mq
    @media #{$point}
      @content


