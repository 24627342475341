.d-object-single-easy-to-buy-map-info-route
  margin-bottom: 24px
  @include bp($phone)
    margin-bottom: 20px
  &__suggest
    position: relative
    & svg
      position: absolute
      right: 26px
      top: 22px
      width: 24px
      height: 24px
      fill: $pen-color
      @include bp($phone)
        top: 14px
        right: 18px
  &__suggest-input
    width: 100%
    height: 64px
    background: $grey5
    padding: 21px 64px 21px 24px
    outline: none
    border: none
    border-radius: 8px
    margin-bottom: 24px
    color: $pen-color
    @include font-size-lh(16, 22)
    @include bp($phone)
      margin-bottom: 0
      height: 48px
      padding: 13px 64px 13px 16px
    &::placeholder
      color: $grey30
      opacity: 1
    &:-ms-input-placeholder
      color: $grey30
    &::-ms-input-placeholder
      color: $grey30

.ymaps-2-1-79-search__suggest
  border: none
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
  border-radius: 12px
  top: 8px
  padding: 18px 0px !important
  &-highlight
    color: $blue-color
    font-weight: 400 !important
  &-item
    padding: 6px 15px !important
    &_selected_yes
      background: $grey-light !important

.ymaps-2-1-79-suggest-item
  font-family: 'Gilroy', Helvetica, Arial, sans-serif
  @include font-size-lh(16,22)
  color: $pen-color
  padding: 0
