.d-contacts-form-popup
  width: 100%
  position: relative
  @include bp($phone)
    height: 100%
  & .d-callMe__form
    @include bp($phone)
      height: 100%
      display: flex
      flex-direction: column
  & .d-callMe__top
    @include bp($phone)
      flex-shrink: 0
  & .d-callMe__content
    @include bp($phone)
      flex-grow: 1
      align-content: flex-start
  &__close
    position: absolute
    right: 32px
    top: 32px
    z-index: 10
    @include bp($phone)
      top: 12px
      right: 12px