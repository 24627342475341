.d-object-single-about-facts
  display: grid
  grid-template-columns: repeat(3, 157px)
  grid-column-gap: 24px
  grid-row-gap: 24px
  @include bp($tablet)
    grid-template-columns: repeat(3, auto)
  @include bp($phone)
    grid-template-columns: repeat(2, auto)
    grid-column-gap: 36px
    grid-row-gap: 16px
  &__label
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
    margin-top: 8px
    @include bp($tablet)
      margin-top: 4px
  &__value
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($tablet)
      @include font-size-lh(20,24)
    @include bp($phone)
      @include font-size-lh(18,21)
  &._projects
    grid-template-columns: repeat(3, auto)
    grid-column-gap: 32px
    justify-content: start
    @include bp($phone)
      grid-column-gap: 16px
    & .d-object-single-about-facts
      &__label
        font-weight: 400
        @include font-size-lh(12,14)
        margin-top: 4px
      &__value
        @include font-size-lh(16,22)
        @include bp($tablet)
          @include font-size-lh(14,18)