// colors
$black:       #000000
$white-color: #ffffff
$brand-color: #0196E3
$blue-color:  #35B8FC
$blue-bright: #007AFF
$dark-grey:   #6D758D
$grey:        #8b90a6
$pen-color:   #36187D
$purple:      #ABA3BE
$tag-color:   #ea2396
$grey_shadow: #d2dce9
$background:  #f2f3f7
$grey-light:  #f2f3f7
$grey5:       #F5F6FB
$grey20:      #E0E1E6
$grey30:      #D1D3DB
$grey40:      #AFB1BF
$grey50:      #8C90A4
$error:       #f00
$red:         #F54751
$form-color:  #edeff3
$submit-color: #8cd651
$green:       #11BC7E
$transition: 0.3s ease
$transitionSlow: 0.5s ease



// legacy

// legacy
