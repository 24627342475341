.search
  position: relative
  //width: 762px
  width: 100%
  opacity: 0
  visibility: hidden
  z-index: -1
  transition: opacity $transition
  &._show
    opacity: 1
    visibility: visible
    z-index: 3
  &__text
    width: 100%
    height: 52px
    padding: 0 50px 0 26px
    font-weight: 500
    font-size: 16px
    line-height: 19px
    color: $pen-color
    background: #F2F3F7
    box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.7), inset 2px 1px 0 #FEFEFE
    border: none
    border-radius: 25px
    outline: none
    -webkit-appearance: none !important
  &__button
    position: absolute
    right: 0
    top: 0
    width: 50px
    height: 50px
    display: flex
    align-items: center
    justify-content: center
    background: none
    border: none
    border-radius: 50%
    z-index: 2
  &__suggestions
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    background: $white-color
    overflow: hidden
    border-radius: 16px
    display: none
    &._open
      display: block
  &__suggestion
    background: $white-color
    transition: background-color $transition, color $transition
    padding: 10px 8px
    color: $purple
    @include font-size-lh(16,19)

    &:not(.no-click):hover, &._selected
      color: $blue-color
      background: $grey5
      border-radius: 8px
      cursor: pointer
      & span
        color: $blue-color!important
    &._selected
      background: $grey5
      color: $blue-color
      cursor: pointer
      & span
        color: $blue-color!important
    & span
      transition: background-color $transition, color $transition
      color: $pen-color
  &__drop
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    padding: 12px
    display: none
    flex-wrap: wrap
    background: #F2F3F7
    box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.7), inset 2px 1px 0 #FEFEFE
    border-radius: 32px
    @include bp($tablet)
      border-radius: 16px
    &._list
      padding: 10px 32px
      flex-direction: column
    &._open
      display: flex
  &__var
    font-weight: 600
    font-size: 16px
    line-height: 19px
    color: $purple
    margin-top: 21px
    &:first-child
      margin-top: 0
    & span
      color: $pen-color
  &__tag
    padding: 11px 20px 11px 20px
    margin: 8px
    background: $white-color
    border-radius: 22px
    font-weight: 600
    font-size: 16px
    line-height: 19px
    color: $brand-color
    text-decoration: none
    position: relative
    transition: background-color $transition
    &:hover
      cursor: pointer
      background: rgba(255,255,255,0.7)
    & i
      color: #9BC6EC
      margin-right: 2px

.d-search._mobile .d-search__suggestions .search__suggestion
  padding: 10px calc(var(--padding) / 2)!important
  background: unset!important
