.d-flat
  padding-top: 8px
  //overflow: hidden
  @include bp($phone)
    padding-top: 4px
  &__breadcrumbs
    margin-bottom: 32px
    @include bp($phone)
      display: none
    & .d-breadcrumbs__item
      @include bp($tablet)
        white-space: nowrap
      &:last-child
        @include bp($tablet)
          overflow: hidden
          text-overflow: ellipsis
  &__section
    margin-bottom: 160px
    @include bp($desktopS)
      margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 72px
    &._top
      @include bp($desktopS)
        margin-bottom: 106px
      @include bp($tablet)
        margin-bottom: 96px
      @include bp($phone)
        margin: 0 -12px 72px
    &._furnish
      margin-top: -120px
      @include bps($desktopS)
        margin-top: -74px
      @include bps($tablet)
        margin-top: -72px
      @include bps($phone)
        margin-top: -56px
