.ba-contacts-card
  background: $grey-light
  box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  border-radius: 20px
  padding: 24px 16px
  display: flex
  flex-direction: column
  min-height: 226px
  position: relative
  &._open
    & .ba-contacts-card__button span:before
      transform: rotate(90deg)
    & .ba-contacts-card__button-text
      &._close
        display: none
      &._open
        display: flex

  &__inner
    height: 100%
    display: flex
    flex-direction: column
    &._close
      //
    &._open
      display: none
  &__name
    color: $pen-color
    @include font-size-lh(16,24)
    font-weight: 600
  &__address
    margin-bottom: 36px
  &__address,
  &__time
    color: $dark-grey
    @include font-size-lh(14,20)
    margin-top: 4px
    font-weight: 600
  &__time
    max-width: calc(100% - 60px)
  &__phone
    @include font-size-lh(20,28)
    font-weight: 600
    color: $brand-color
    margin-top: auto
    max-width: calc(100% - 60px)
  &__button
    margin-top: auto
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    right: 16px
    bottom: 24px
    border-radius: 50%
    flex-shrink: 0
    background: $grey-light
    border: none
    padding: 0
    box-shadow: 11.6667px 11.6667px 23.3333px rgba(210, 220, 233, 0.8), -7.4179px -6.2243px 6.70845px rgba(255, 255, 255, 0.6), inset 1.66667px 1.66667px 0.833333px rgba(255, 255, 255, 0.8)
    &:hover
      cursor: pointer
