.flat-furnish-calc
  background: $white-color
  border-radius: 40px
  padding: 40px
  display: grid
  grid-template-columns: 1fr
  row-gap: 32px
  @include bp($tablet)
    padding: 32px
  @include bps($phone)
    padding: 12px
    border-radius: 24px
    width: calc(100% + 24px)
    margin-left: -12px

  &__row
    display: grid
    position: relative
    grid-template-columns: 1fr 584px
    @include bps($desktopS)
      grid-template-columns: 1fr 465px
    @include bp($tablet)
      grid-template-columns: 1fr
      row-gap: 32px

    @include bps($phone)
      row-gap: 24px
    &:last-child
      &:before
        content: ""
        display: block
        position: absolute
        background: $grey20
        left: -40px
        top: 0
        width: calc(100% + 80px)
        height: 1px
        @include bps($tablet)
          width: calc(100% + 64px)
          left: -32px
        @include bps($phone)
          width: calc(100% + 24px)
          left: -12px
    &._top
      & .flat-furnish-calc__col
        &:first-child
          padding-right: 40px
          padding-left: 224px
          @include bps($desktopS)
            padding-left: 0px
          @include bp($tabletS)
            padding-right: 0
          &:before
            content: ""
            display: block
            position: absolute
            background: $grey20
            right: 0
            top: -40px
            width: 1px
            height: calc(100% + 72px)
            @include bp($tablet)
              display: none
        &:last-child
          padding-left: 40px
          @include bp($tablet)
            padding-left: 0
    &._bottom
      @include bps($tablet)
        grid-template-columns: 1fr 1fr
      @include bps($phone)
        grid-template-columns: 1fr
        gap: 20px
      & .flat-furnish-calc__col
        &:first-child
          padding-right: 40px
          padding-left: 224px
          @include bps($desktopS)
            padding-left: 0px
          @include bp($tabletS)
            padding-right: 0
  &__col
    position: relative


  &__title
    @include font-size-lh(32,28)
    font-weight: 600
    color: $pen-color
    margin-bottom: 24px
    @include bps($desktopS)
      @include font-size-lh(24,28)
  &__content
    max-width: 665px
    margin: 0 auto
    @include bps($tablet)
      max-width: 100%
  &__info
    //display: grid
    //row-gap: 16px
    //grid-template-columns: 1fr
    display: table
    border-collapse: separate
    border-spacing: 20px 16px
    @include bps($phone)
      display: grid
      row-gap: 16px
      grid-template-columns: 1fr

  &__item
    display: table-row
    @include bps($phone)
      display: grid
      grid-template-columns: 1fr
    //&._label
      //grid-template-columns: 158px 1fr
      //@include bps($phone)
      //  grid-template-columns: 1fr
  &__label
    color: $grey50
    @include font-size-lh(14,18)
    font-weight: 600
    display: table-cell
    @include bps($phone)
      display: block
  &__text
    color: $pen-color
    @include font-size-lh(14,18)
    font-weight: 400
    display: table-cell
    @include bps($phone)
      display: block
  &__options
    display: grid
    grid-template-columns: 1fr
    row-gap: 16px
    & .d-checkbox__label
      white-space: pre-wrap
      font-weight: 400
      @include font-size-lh(14,18)
  &__option
    position: relative
    padding-right: 40px
  &__actions
    padding: 32px 0 0 40px
    display: flex
    @include bps($tablet)
      flex-direction: column
      max-width: 240px
      margin-left: auto
      padding-left: 0
    @include bps($phone)
      margin-left: 0
      max-width: 100%
      padding-top: 0
  &__price-wrap
    padding: 32px 40px 0 0
    display: flex
    column-gap: 126px
    max-width: 665px
    margin: 0 auto
    @include bps($tablet)
      max-width: 100%
      display: grid
      grid-template-columns: 1fr
      row-gap: 28px
    @include bps($phone)
      row-gap: 12px
      padding-top: 24px
  &__price
    &._opts
      & .flat-furnish-calc__price-value
        @include font-size-lh(32,36)
        margin-top: 8px
        @include bps($tablet)
          margin-top: 4px
  &__price-value
    color: $blue-color
    font-weight: 600
    @include font-size-lh(24,28)
    margin-top: 15px
    @include bps($tablet)
      margin-top: 4px
  &__eye
    width: 24px
    height: 24px
    background: transparent
    outline: none
    border: none
    padding: 0
    position: absolute
    top: 0
    right: 0
    &:hover
      cursor: pointer
      & svg
        fill: $pen-color
    & svg
      width: 100%
      height: 100%
      transition: all $transition
      fill: $blue-color
