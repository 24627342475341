.d-object-header-mobile
  display: none
  @include bp($tablet)
    display: block
    opacity: 0
    visibility: hidden
    width: 100%
    padding: 0 24px
    position: fixed
    left: 0
    top: 0
    z-index: -1
    transform: translateY(-100%)
    @include transition(all)
  @include bp($phone)
    padding: 0 12px
  &._show
    opacity: 1
    transform: translateY(12px)
    visibility: visible
    z-index: 24
  &._offset
    @include bp($tablet)
      transform: translateY(112px)
    @include bp($phone)
      transform: translateY(72px)
  &__inner
    height: 72px
    padding: 12px 12px 12px 24px
    display: flex
    align-items: center
    justify-content: space-between
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 16px
    @include bp($phone)
      height: 48px
      border-radius: 12px
  &__name
    position: relative
    font-weight: 600
    text-transform: uppercase
    @include font-size-lh(36, 40)
    color: $pen-color
    @include bp($phone)
      width: 100%
      @include font-size-lh(24, 28)
    &:before
      content: ''
      width: 20px
      height: 20px
      position: absolute
      left: calc(100% + 20px)
      top: 50%
      transform: translateY(-50%) rotate(90deg)
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
      display: none
      @include bp($tablet)
        display: block
      @include bp($phone)
        left: auto
        right: 0
    &._active
      &:before
        transform: translateY(-50%) rotate(270deg)
  &__menu
    position: absolute !important
    top: calc(100% + 8px)
    left: 24px
    width: calc(100% - 48px)
    padding: 24px
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 12px
    opacity: 0
    visibility: hidden
    transition: opacity $transition
    @include bp($phone)
      left: 12px
      width: calc(100% - 24px)
      padding: 24px 16px
      display: flex
      flex-direction: column
    &._show
      opacity: 1
      visibility: visible
    & .d-object-header-mobile__button
      display: none
      @include bp($phone)
        display: flex
  &__links
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-top: 24px
    margin-top: 24px
    position: relative
    @include bp($phone)
      padding-top: 16px
      margin-top: 16px
    &:before
      content: ''
      position: absolute
      left: -24px
      top: 0
      width: calc(100% + 48px)
      height: 1px
      background: $grey20
      @include bp($phone)
        left: -16px
        width: calc(100% + 32px)
    &._empty
      padding-top: 0
      padding-bottom: 0
      margin-top: 0
      @include bp($phone)
        margin-top: 16px
      &:before
        display: none
        @include bps($phone)
          display: block
  &__link
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
    text-decoration: none
    margin-top: 16px
    @include bp($phone)
      @include font-size-lh(12,14)
      margin-top: 12px
    &:first-child
      margin-top: 0
    &:after
      content: ''
      display: inline-block
      width: 16px
      height: 16px
      margin-left: 4px
      background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
      position: relative
      top: 2px
      @include bp($phone)
        width: 14px
        height: 14px
        margin-left: 2px
  &__button
    height: 48px
    @include bp($phone)
      display: none
      width: 100%
      margin-top: 24px
