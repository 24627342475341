.d-object-single-progress-slider
  position: relative
  & .swiper
    border-radius: 24px
    will-change: transform
    @include bp($tablet)
      border-radius: 0
      padding: 0 24px
      margin: 0 -24px
    @include bp($phone)
      padding: 0 12px
      margin: 0 -12px
  &__slide
    display: flex
    align-items: flex-end
    border-radius: 24px
    overflow: hidden
    will-change: transform
    position: relative
    @include hover
      .d-object-single-progress-slider__img
        transform: scale(1.05)
    &:after
      content: ''
      width: 100%
      height: 149px
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
      border-radius: 0px 0px 32px 32px
      display: block
      position: absolute
      bottom: 0
      left: 0
      pointer-events: none
      z-index: 2
    &.swiper-slide
      width: 592px
      height: 420px
      @include bp($phone)
        width: 284px
        height: 312px
      &._full
        @include bp($tablet)
          width: 100%
        & .d-object-single-progress-slider__img
          width: 100%
          @include bp($tablet)
            width: 100%
  &__img
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    transition: all $transitionSlow
    z-index: 1
    @include bp($tablet)
      width: auto
      min-width: 100%
  &__text
    font-weight: 600
    width: 100%
    @include font-size-lh(32,36)
    color: $white-color
    padding: 40px
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
    position: relative
    z-index: 5
    @include bp($phone)
      @include font-size-lh(24,28)
      padding: 24px 16px
    & small
      display: block
      margin-top: 12px
      font-weight: 500
      @include font-size-lh(18,21)
      @include bp($phone)
        @include font-size-lh(16,22)
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 5
  &__arrows
    position: absolute
    right: 0
    bottom: calc(100% + 65px)
    @include bp($tablet)
      display: none
  &__count
    background: rgba(0, 0, 0, 0.3)
    backdrop-filter: blur(2px)
    border-radius: 8px
    position: absolute
    bottom: 40px
    right: 40px
    z-index: 5
    color: $white-color
    @include font-size-lh(14,22)
    padding: 5px 16px
    font-weight: 500
    @include bp($desktopS)
      top: 40px
      left: 40px
      bottom: unset
      right: unset
    @include bp($phone)
      right: 16px
      bottom: 24px
      left: unset
      top: unset