.d-company-vacancy
  display: flex
  justify-content: space-between
  @include bp($tablet)
    display: block
  &__col
    max-width: 592px
    width: 100%
    @include bp($desktopXS)
      max-width: calc(50% - 16px)
    @include bp($tablet)
      max-width: 100%
    &._col1
      flex-shrink: 0
      @include bp($desktopXS)
        flex-shrink: unset
    &._col2
      margin-left: 184px
      @include bp($desktopXS)
        margin: 0
      @include bp($tablet)
        margin-top: 40px
      @include bp($phone)
        margin-top: 32px
  &__info
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    margin-top: 40px
    @include bp($tablet)
      @include font-size-lh(20,24)
      max-width: 720px
      width: 100%
      margin-top: 32px
    @include bp($phone)
      font-size: 18px
      margin-top: 24px