.d-flat-info
  display: grid
  grid-template-columns: 1fr
  grid-row-gap: 9px
  &._commercial
    @include bp($phone)
      margin-bottom: 20px
    & .d-flat-info
      &__value
        flex-shrink: 0
  &__row
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    row-gap: 4px
    justify-content: space-between
    &:before
      content: ''
      flex-grow: 1
      display: block
      min-width: 4px
      height: 1px
      margin: 0 4px 4px
      border-bottom: 1px dashed $grey20
      order: 2
    &:first-child
      @include bp($phone)
        display: none
  &__label
    flex-shrink: 0
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    order: 1
  &__value
    //flex-shrink: 0
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: right
    order: 3
    @include bp($tablet)
      @include font-size-lh(18,24)
    & a
      display: flex
      align-items: center
      color: $blue-color
      text-decoration: none
      @include transition(color)
      @include hover
        color: $pen-color
      & svg
        flex-shrink: 0
        width: 16px
        height: 16px
        margin-left: 4px
        fill: currentColor
    &._info
      padding-right: 26px
      position: relative
      &._notip
        padding-right: 0

  &__info
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    width: 24px
    height: 24px
    background: url('../assets/img/d/icon_info_new.svg') no-repeat center
    cursor: pointer
    &:hover
      & .d-flat-info__tooltip
        opacity: 1
        visibility: visible
  &__tooltip
    position: absolute
    right: 0
    bottom: calc(100% + 6px)
    padding: 12px 16px
    background: $white-color
    border-radius: 12px
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    font-weight: 400
    @include font-size-lh(16,22)
    color: $blue-bright
    white-space: nowrap
    opacity: 0
    visibility: hidden
    @include transition(all)

.d-aux-rooms-popup-info__params
  .d-flat-info
    &__row
      &:first-child
        @include bp($phone)
          display: flex

