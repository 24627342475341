.d-owners-card
  font-weight: 400
  @include font-size-lh(16,22)
  @include bp($phone)
    display: flex
    align-items: center
  &__name
    display: flex
    align-items: center
    justify-content: center
    width: 86px
    height: 54px
    margin-bottom: 16px
    background-color: #BC874C
    background-image: url('../assets/img/d/owners_card_bg.png')
    background-repeat: no-repeat
    background-position: left top
    background-size: 86px auto
    border-radius: 8px
    @include bp($phone)
      flex-shrink: 0
      margin: 0 12px 0 0
    &._silver
      background-color: #8E9A9A
    &._gold
      background-color: #EBBC00
    &._platinum
      background-color: #C3CBD3
  &__info
    @include bp($desktopXS)
      @include font-size-lh(12,14)

.d-owners-card-discounts
  display: grid
  grid-template-columns: 42.42% 1fr
  grid-column-gap: 20px
  margin: -4px 0 12px
  &__value
    font-weight: 600
    @include font-size-lh(24,28)
  &__label
    font-weight: 400
    @include font-size-lh(12,14)
    margin-top: 2px