.d-index-flats
  &__filter
    @include bp($tablet)
      height: calc(var(--vh, 1vh) * 100)!important
      position: relative
      background: $white-color
  &__filter:not(._popup)
    margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px

    &._objects
      height: unset!important

  &__top._objects
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 36px
  &__result
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 32px
    grid-row-gap: 32px
    margin-bottom: 32px
    @include bp($tablet)
      grid-column-gap: 24px
      grid-row-gap: 24px
      margin-bottom: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 12px
      margin-bottom: 12px
      margin-top: 24px
    &._empty
      @include bp($tabletS)
        grid-template-columns: 1fr
        grid-column-gap: 0
  &__exclusive, &__slider
    margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 24px
  &__slider
    &._objects
      @include bp($phone)
        margin-bottom: -84px
  &__more
    display: flex
    justify-content: center
    margin-top: 40px
    @include bp($phone)
      margin-top: 24px
  @include bp($tablet)
    position: relative

  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 56px
    @include bp($desktopXS)
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 24px
  &__title
    font-weight: 600
    @include font-size-lh(64,68)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(52,56)
    @include bp($phone)
      @include font-size-lh(36,40)

  &__title._objects
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0
    @include font-size-lh(52,56)
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
