.d-regional-objects
  &__result
      display: grid
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 32px
      grid-row-gap: 32px
      margin-bottom: 32px
      @include bp($tablet)
        grid-column-gap: 24px
        grid-row-gap: 24px
        margin-bottom: 24px
      @include bp($phone)
        grid-template-columns: 1fr
        grid-column-gap: 0
        grid-row-gap: 12px
        margin-bottom: 12px
      &._empty
        @include bp($tabletS)
          grid-template-columns: 1fr
          grid-column-gap: 0

  &__exclusive
    margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 24px


    &__title._objects
      display: flex
      align-items: center
      justify-content: space-between
      @include font-size-lh(52,56)
      @include bp($desktopS)
        @include font-size-lh(40,44)
      @include bp($phone)
        @include font-size-lh(36,40)
