#genplan
  width: 100%
  height: 100%
.d-object-genplan
  position: relative
  width: 100%
  height: 100%
  &__svg
    position: absolute
    width: 100%
    height: 100%
    left: 0
    right: 0
    z-index: 10
    top: 0
    & svg
      width: 100%
      height: 100%
  &__path
    fill-opacity: 0.2
    fill: $blue-color
    stroke-width: 3
    outline: none
    stroke: $blue-color
    stroke-opacity: 1
    transition: all $transition
    &._hover
      fill-opacity: 0.68
    &:hover
      fill-opacity: 0.68
      cursor: pointer
    &._premium
      stroke: #5131F5
      fill: #5131F5