.ba-menu
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: calc(var(--vh, 1vh) * 100)
  padding-top: 147px
  background: #F2F3F7
  z-index: -99
  opacity: 0
  visibility: hidden
  transition: opacity $transition
  @include bp($desktop)
    padding-top: 180px
  @include bp($tablet)
    padding-top: 107px
  @include bp($phone)
    padding-top: 62px
  &._open
    opacity: 1
    visibility: visible
    z-index: 34
  & a
    text-decoration: none
  &__wrap
    height: 100%
    overflow: auto
  &__inner
    width: fit-content
    padding: 0 32px 46px
    margin: 0 auto
    position: relative
    @include bp($desktop)
      padding-bottom: 96px
    @include bp($tablet)
      width: 100%
      padding-bottom: 90px
    @include bp($tabletS)
      padding-bottom: 100px
    @include bp($phone)
      padding: 0 16px 72px
  &__search, &__info
    display: none
    margin-bottom: 48px
    @include bp($phone)
      margin-bottom: 32px
    @include bp($tablet)
      display: flex
  &__info
    flex-direction: column
    align-items: flex-start
    font-weight: 600
    font-size: 16px
    line-height: 19px
    color: $pen-color
  &__phone
    font-size: 24px
    line-height: 29px
    color: $pen-color
    margin-top: 16px
  &__links
    display: grid
    grid-template-columns: 293px 253px 240px
    grid-column-gap: 48px
    grid-row-gap: 32px
    font-weight: 600
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px
      margin-top: -32px
    @include bp($tabletS)
      display: block
      grid-column-gap: 0
      margin-top: 32px
  &__item
    display: flex
    flex-direction: column
    align-items: flex-start
    @include bp($tablet)
      width: 100%
      margin-top: 32px
    &._item1
      order: 1
      @include bp($tablet)
        order: 1
    &._item2
      order: 2
      @include bp($tablet)
        order: 3
    &._item3
      order: 3
      @include bp($tablet)
        order: 1
    &._item4
      order: 4
      @include bp($tablet)
        order: 2
    &._item5
      order: 5
      @include bp($tablet)
        order: 2
    &._item6
      order: 6
      @include bp($tablet)
        order: 3
  &__section
    font-size: 28px
    line-height: 33px
    color: $brand-color
    @include bp($tablet)
      font-size: 32px
      line-height: 38px
    @include bp($phone)
      font-size: 20px
      line-height: 24px
    & + .menu__section
      margin-top: 32px
  &__link
    font-size: 20px
    line-height: 24px
    color: $pen-color
    margin-top: 16px
    transition: color $transition
    @include bp($tablet)
      font-size: 24px
      line-height: 29px
    @include bp($phone)
      font-size: 16px
      line-height: 19px
    @include bp($hover)
      &:hover
        color: $brand-color
  &__col
    display: flex
    flex-direction: column
  &__social
    position: absolute
    left: 32px
    bottom: 41px
    display: flex
    align-items: center
    @include bp($desktop)
      bottom: 91px
    @include bp($tablet)
      bottom: 30px
    @include bp($tabletS)
      bottom: 40px
    @include bp($phone)
      left: 16px
      bottom: 16px
    & a
      margin-right: 16px
    & img
      display: block
  &__icons
    position: fixed
    right: 32px
    bottom: 30px
    display: none
    @include bp($tablet)
      display: block
    @include bp($tabletS)
      bottom: 40px
    @include bp($phone)
      right: 16px
      bottom: 16px
  &__icon
    margin-top: 16px
    &._favorite
      position: relative
      & span
        text-decoration: none
        color: $white-color
        background: $brand-color
        position: absolute
        top: 0
        right: 0
        width: 16px
        height: 16px
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        @include font-size-lh(8,8)
