.plans
  width: 1280px
  height: 800px
  margin: auto
  position: relative
  z-index: 1
  &__img
    max-height: 100%
    max-width: 100%
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    object-fit: contain
    z-index: 1

  &__svg
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 2
    & svg
      max-height: 100%
      max-width: 100%
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 3
      & path
        opacity: 1
        fill: rgb(87, 183, 234)
        fill-opacity: 0.2
        stroke: rgb(87, 183, 234)
        stroke-opacity: 1
        stroke-width: 3
        &:hover
          fill-opacity: 0.5

.tippy-box[data-theme="plans"]
  max-width: 588px !important
  background: transparent
  padding: 0