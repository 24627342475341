.d-button
  display: inline-flex
  align-items: center
  justify-content: center
  height: 64px
  padding: 0 40px
  background: none
  border: 1px solid $white-color
  border-radius: 12px
  font-weight: 600
  @include font-size-lh(16, 22)
  color: $white-color
  text-decoration: none
  white-space: nowrap
  position: relative
  overflow: hidden
  transition: all $transitionSlow
  cursor: pointer
  will-change: transform
  @include hover
    border-color: $pen-color
    &:after
      transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
      opacity: 1
  @include bp($phone)
    height: 48px
    padding: 0 36px 0 32px
    border-radius: 8px
  &:before, &:after
    content: ''
    position: absolute
    transition: all $transitionSlow
  &:before
    right: 12px
    top: 12px
    width: 6px
    height: 6px
    border-top: 2px solid $white-color
    border-right: 2px solid $white-color
    z-index: 2
  &:after
    left: 50%
    top: 50%
    transform: translate3d(-50%,-50%,0) scale3d(0,0,1)
    width: 140%
    height: 0
    padding-top: 140%
    background: $pen-color
    border-radius: 50%
    opacity: 0
    z-index: 1
  & img, & svg, & span
    position: relative
    z-index: 3
  & img
    width: 16px
  &__tooltip
    position: absolute !important
    left: 50%
    bottom: calc(100% + 8px)
    transform: translateX(-50%)
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    padding: 12px 16px
    font-weight: 400
    @include font-size-lh(16,22)
    color: $blue-bright
    opacity: 0
    visibility: hidden
    @include transition(all)
    z-index: 3
    &._share
      padding: 8px
  &._small
    height: 48px
    padding: 0 36px 0 32px
  &._disable
    opacity: .4
    pointer-events: none
    &:before, &:after, span
      pointer-events: none
  &._dark
    border-color: $pen-color
    color: $pen-color
    @include hover
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
    &:before
      border-top-color: $pen-color
      border-right-color: $pen-color
  &._blue
    background: $blue-color
    border: none
  &._white
    background: $white-color
    border: none
    color: $pen-color
    @include hover
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
    &:before
      border-top-color: $pen-color
      border-right-color: $pen-color
  &._grey
    background: $grey5
    border: none
    color: $pen-color
    @include hover
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
    &:before
      border-top-color: $pen-color
      border-right-color: $pen-color
  &._close
    width: 64px
    padding: 0
    @include bp($phone)
      width: 48px
    &:before
      display: none
    & img
      display: block
      width: 24px
      position: relative
      z-index: 3
    @include hover
      &:after
        background: $blue-color
    &._small
      width: 48px
    &._blue
      @include hover
        &:after
          background: $pen-color
  &._slider
    width: 64px
    padding: 0
    justify-content: center
    @include bp($phone)
      width: 48px
    &:before
      display: none
    & span
      position: absolute
      top: 0
      width: 1px
      height: 100%
      z-index: 1
      &:before, &:after
        content: ''
        position: absolute
        left: 0
        width: 1px
        height: 16px
        background: currentColor
      &:before
        top: 0
      &:after
        bottom: 0
    &._prev
      border-right: none
      border-radius: 12px 0 0 12px
      & span
        right: 0
      & svg
        transform: rotate(180deg)
    &._next
      border-left: none
      border-radius: 0 12px 12px 0
      & span
        left: 0
    & svg
      width: 20px
      height: 20px
      fill: currentColor
      position: relative
      z-index: 2
    &.swiper-button-disabled
      opacity: .4
      pointer-events: none
    &._dark
      color: $pen-color
      border-color: $pen-color
      @include hover
        color: $white-color
  &._withImg
    padding: 0 8px
    &:before
      display: none
    & img
      margin: 0 8px
    &._left
      padding: 0 40px 0 32px
      @include bp($phone)
        padding: 0 28px 0 24px
      & img
        margin: 0 8px 0 0
    &._right
      padding: 0 32px 0 40px
      @include bp($phone)
        padding: 0 24px 0 28px
      & img
        margin: 0 0 0 8px
  &._border
    background: transparent
    border: 1px solid $white-color
    color: $white-color
    &:before
      border-top-color: $white-color
      border-right-color: $white-color

  &._flatIcon
    width: 48px
    height: 48px
    padding: 0
    border-color: $pen-color
    color: $pen-color
    @include transition(all)
    overflow: unset
    @include bp($phone)
      width: 24px
      height: 24px
      border: none
    @include hover
      border-color: $blue-color
      background: $blue-color
      color: $white-color
      & .d-button__tooltip
        opacity: 1
        visibility: visible
    &:hover
      & .d-share
        opacity: 1
        visibility: visible
    &:not(.skeleton-field):before
      display: none
    &:after
      display: none
    & svg
      width: 24px
      height: 24px
      fill: currentColor
    &.skeleton-field
      border: none !important
      pointer-events: none
      margin: 0
    &._text
      @include bp($tablet)
        width: auto
        padding: 0 36px 0 32px
      &:before
        @include bp($tablet)
          display: block
          border-color: $pen-color
      & svg
        @include bp($tablet)
          display: none
      & span
        display: none
        @include bp($tablet)
          display: block
    &._favorite
      &:before
        display: block
        right: auto
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        width: 24px
        height: 24px
        background: url('../assets/img/d/icon_heart_full.svg') center
        border: none
        opacity: 0
        z-index: 1
      & svg
        fill: currentColor
        position: relative
        z-index: 2
      &._active
        &:before
          opacity: 1
          z-index: 2
        & svg
          opacity: 0
          z-index: 1
    &._favorites
      @include bp($phone)
        width: 48px
        height: 48px
        border: 1px solid $pen-color

    &._favorites[data-social-share-list]
      position: relative
      z-index: 1
