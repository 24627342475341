.d-switcher
  display: flex
  align-items: center
  &._disabled
    & label
      pointer-events: none
      background: $grey20
  &__text
    color: $grey40
    @include font-size-lh(16,22)
    font-weight: 600
    transition: all $transition
    @include bp($phone)
      @include font-size-lh(14,18)
    &._active
      pointer-events: none
      color: $pen-color
  &:not(._disabled) .d-switcher__text
    cursor: pointer
    @include hover
      color: $blue-color
  &__button
    margin: 0 14px
  & input[type=checkbox]
    height: 0
    position: absolute
    width: 0
    visibility: hidden
    &:checked + label
      &:after
        left: calc(100% - 5px - 10px)
  & label
    cursor: pointer
    text-indent: -9999px
    width: 36px
    height: 24px
    background: $blue-color
    display: block
    border-radius: 16px
    position: relative
    transition: background-color $transition
    @include hover
      background: $pen-color
    &:after
      content: ''
      position: absolute
      top: 7px
      left: 6px
      width: 10px
      height: 10px
      background: #fff
      border-radius: 50%
      transition: 0.3s
