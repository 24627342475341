.d-commercial-object-photo
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  border-radius: 24px
  overflow: hidden
  will-change: transform
  @include bp($tablet)
    height: 460px
    position: relative
  @include bp($phone)
    height: 372px
    border-radius: 16px
  &._single
    @include bp($phone)
      height: 372px + 72px
      padding-top: 72px
    & .d-commercial-object-photo
      &__img
        object-fit: contain
  &._popup
    border-radius: 0
    @include bp($tablet)
      height: 100%
    @include bp($phone)
      height: 100%
    & .d-commercial-object-photo
      object-fit: cover
      &__slide
        @include bp($tablet)
          display: flex
          justify-content: center
          align-items: center
      &__img
        @include bp($tablet)
          width: auto
          max-width: 100%
          max-height: 100%
          height: auto
          object-fit: contain
  &__slider
    width: 100%
    height: 100%
  &__slide
    position: relative
    &:after
      content: ''
      position: absolute
      left: 0
      bottom: 0
      width: 100%
      height: 200px
      background: linear-gradient(0, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)
      z-index: 2
      @include bp($desktopXS)
        height: 158px
      @include bp($phone)
        height: 120px
  &__img
    width: 100%
    height: 100%
    object-fit: cover
  &__pagination, &__disclaimer, &__arrows
    position: absolute
    z-index: 3
  &__disclaimer, &__arrows
    bottom: 40px
    @include bp($desktopXS)
      bottom: 24px
    @include bp($phone)
      bottom: 16px
  &__pagination
    left: 40px !important
    top: 40px
    bottom: auto !important
    @include bp($desktopXS)
      left: 24px !important
      top: 16px
    @include bp($tablet)
      top: 24px
    @include bp($phone)
      left: 16px !important
      top: 16px
  &__disclaimer
    left: 40px
    @include bp($desktopXS)
      left: 24px
    @include bp($phone)
      left: 16px
      max-width: calc(100% - 136px)
  &__arrows
    right: 40px
    @include bp($desktopXS)
      right: 24px
    @include bp($phone)
      right: 16px