.d-menu-promo
  width: 100%
  height: 100%
  border-radius: 40px
  position: relative
  overflow: hidden
  @include bp($tablet)
    border-radius: 24px
  @include hover
    & .d-menu-promo
      &__bg
        transform: scale(1.05)
      &__more
        border-color: $pen-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    z-index: 1
    transition: all $transitionSlow
  &__content
    display: flex
    flex-direction: column
    align-items: flex-start
    height: 100%
    padding: 40px
    position: relative
    z-index: 3
    @include bp($desktopXS)
      padding: 32px
  &__tag
    background: $white-color
    border-radius: 8px
    padding: 5px 16px
    font-weight: 500
    @include font-size-lh(16, 22)
    color: $pen-color
    white-space: nowrap
  &__name
    font-weight: 600
    @include font-size-lh(64, 68)
    color: $white-color
    margin: auto 0 40px
    @include bp($desktopXS)
      @include font-size-lh(40, 44)
      margin-bottom: 32px
    @include bp($tabletS)
      max-width: 444px
      margin-bottom: 0
  &__more
    margin-left: auto
    @include bp($tabletS)
      position: absolute
      right: 32px
      bottom: 32px
      z-index: 1
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 5