.d-m-capital
  &__top, &__use
    margin-bottom: 120px
    @include bp($desktopXS)
      margin-bottom: 96px
    @include bp($tablet)
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 56px
  &__top
    //min-height: 576px
    display: grid
    grid-template-columns: 1fr 436px
    grid-column-gap: 32px
    @include bp($desktopS)
      grid-template-columns: 1fr 356px
    @include bp($desktopXS)
      grid-template-columns: 1fr 280px
    @include bp($tablet)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 24px
    @include bp($phone)
      grid-row-gap: 12px
  &__facts
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px
      grid-row-gap: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 12px

.d-m-capital-use
  display: flex
  align-items: flex-start
  @include bp($tablet)
    display: block
  &__item
    &._item1
      flex-shrink: 0
      width: 436px
      margin-right: 32px
      @include bp($desktopS)
        width: 364px
        margin-right: 24px
      @include bp($desktopXS)
        width: 384px
        margin-right: 32px
      @include bp($tablet)
        display: flex
        justify-content: space-between
        width: 100%
        margin: 0 0 40px
      @include bp($tabletS)
        margin-bottom: 46px
      @include bp($phone)
        display: block
        margin-bottom: 32px
    &._item2
      flex-grow: 1
      padding: 48px 0 60px
      background: $white-color
      border-radius: 32px
      @include bp($desktopXS)
        padding: 40px 0
      @include bp($tablet)
        padding: 32px 0
      @include bp($phone)
        padding: 24px 0
        border-radius: 24px
  &__title
    margin-bottom: 40px
    @include bp($desktopXS)
      margin-bottom: 36px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($phone)
      margin-bottom: 12px
    &.d-purchase-title
      @include bp($desktopXS)
        @include font-size-lh(36,40)
      @include bp($phone)
        @include font-size-lh(32,36)
  &__note
    font-weight: 400
    @include font-size-lh(18,24)
    color: $grey50
    @include bp($tablet)
      width: calc(50% - 12px)
      @include font-size-lh(16,22)
    @include bp($phone)
      width: 100%

.d-m-capital-use-variant
  display: flex
  border-top: 1px solid $grey20
  padding: 40px 40px 0
  margin-top: 48px
  @include bp($desktopXS)
    margin-top: 40px
  @include bp($tablet)
    padding: 32px 32px 0
    margin-top: 32px
  @include bp($phone)
    display: block
    padding: 24px 16px 0
    margin-top: 24px
  &:first-child
    align-items: center
    border: none
    padding-top: 0
    margin: 0
  &__title
    flex-shrink: 0
    width: 436px
    margin-right: 32px
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    @include bp($desktopS)
      width: 202px
      margin-right: 24px
    @include bp($desktopXS)
      width: 142px
    @include bp($tablet)
      width: 278px
    @include bp($tabletS)
      width: 130px
    @include bp($phone)
      width: 100%
      margin: 0 0 16px
  &__text
    max-width: 708px
    width: 100%
    font-weight: 400
    @include font-size-lh(18,24)
    @include bp($phone)
      @include font-size-lh(16,22)
    & p
      margin: 0
      & + p
        margin-top: 24px
    & .note
      @include font-size-lh(14,18)
      color: rgba($dark-grey, .6)
      margin-top: 8px !important

.d-m-capital-conditions
  display: flex
  align-items: flex-start
  @include bp($desktopXS)
    display: block
  &__item
    &._item1
      flex-shrink: 0
      width: 436px
      margin-right: 32px
      @include bp($desktopS)
        width: 364px
        margin-right: 24px
      @include bp($desktopXS)
        display: flex
        width: 100%
        margin: 0 0 48px
      @include bp($tablet)
        justify-content: space-between
        margin-bottom: 40px
      @include bp($phone)
        display: block
        margin-bottom: 24px
    &._item2
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 32px
      flex-grow: 1
      @include bp($tablet)
        grid-template-columns: 1fr
        grid-column-gap: 0
        grid-row-gap: 8px
      @include bp($phone)
        grid-row-gap: 12px
  &__title
    margin-bottom: 40px
    @include bp($desktopXS)
      flex-shrink: 0
      width: 432px
      margin: 0 24px 0 0
    @include bp($tablet)
      width: auto
      margin: 0
    @include bp($phone)
      margin-bottom: 12px
    &.d-purchase-title
      @include bp($desktopXS)
        @include font-size-lh(36,40)
      @include bp($phone)
        @include font-size-lh(32,36)
  &__note
    font-weight: 400
    @include font-size-lh(18,24)
    color: $grey50
    @include bp($tablet)
      width: calc(50% - 12px)
      @include font-size-lh(16,22)
    @include bp($phone)
      width: 100%

.d-m-capital-condition
  display: flex
  flex-direction: column
  height: 100%
  background: $white-color
  border-radius: 32px
  @include bp($tablet)
    min-height: 118px
    flex-direction: row
    align-items: center
    border-radius: 24px
  @include bp($phone)
    display: block
  &__date
    border-top: 1px solid $grey20
    padding: 40px 20px 48px 40px
    margin-top: auto
    font-weight: 600
    @include font-size-lh(40,44)
    @include bp($tablet)
      display: flex
      align-items: center
      width: calc(50% + 12px)
      height: 100%
      padding: 24px
      margin: 0
      border-top: none
      border-left: 1px solid $grey20
      @include font-size-lh(24,28)
    @include bp($tabletS)
      width: 278px
      padding-left: 32px
    @include bp($phone)
      width: 100%
      height: auto
      padding: 24px 16px
      border-top: 1px solid $grey20
      border-left: none
  &__text
    padding: 40px
    font-weight: 400
    @include font-size-lh(18,24)
    @include bp($desktopS)
      padding-bottom: 16px
    @include bp($tablet)
      width: calc(50% - 12px)
      padding: 32px
      @include font-size-lh(14,18)
    @include bp($tabletS)
      width: calc(100% - 278px)
    @include bp($phone)
      width: 100%
      padding: 24px 16px
      @include font-size-lh(16,22)
