.d-still-questions
  padding: 0 152px 0 64px
  border-radius: 40px
  position: relative
  will-change: transform
  @include bp($desktopS)
    padding-right: 64px
  @include bp($desktopXS)
    padding: 0 40px
  @include bp($tablet)
    padding: 80px 40px
  @include bp($phone)
    padding: 40px 16px 16px
    border-radius: 24px
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    border-radius: 40px
    z-index: 1
    @include bp($phone)
      border-radius: 24px
  &__inner
    height: 458px
    color: $white-color
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    z-index: 2
    @include bp($tablet)
      max-width: 640px
      width: 100%
      height: auto
      margin: 0 auto
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
  &__top
    font-weight: 600
  &__title
    @include font-size-lh(64,68)
    @include bp($desktopXS)
      @include font-size-lh(52,56)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__subtitle
    @include font-size-lh(24,28)
    margin-top: 24px
    @include bp($desktopXS)
      max-width: 400px
    @include bp($tablet)
      margin-top: 32px
    @include bp($phone)
      @include font-size-lh(20,24)
      margin-top: 16px
  &__form
    width: 628px
    flex-shrink: 0
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 24px
    grid-row-gap: 24px
    margin-left: 56px
    position: relative
    overflow: visible
    @include bp($desktopXS)
      width: 568px
    @include bp($tablet)
      width: 100%
      margin: 56px 0 0
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      margin-top: 24px
  &__item
    &._agree
      &:not(._national)
        grid-column: span 2
        font-weight: 400
        @include font-size-lh(14,18)
        @include bp($desktopS)
          margin-bottom: -8px
        @include bp($phone)
          grid-column: 1
      &._national
        position: relative
        @include font-size-lh(16,18)
        @include bp($phone)
          margin-bottom: -8px
          font-size: 14px
        & .captcha-info
          position: absolute
          left: 0
          top: calc(100% + 24px)
          width: 200%
          margin: 0
          @include bp($phone)
            position: relative
            top: 0
            width: auto
            margin-top: 12px

      & a, & span
        cursor: pointer
        text-decoration: underline
        text-underline-offset: 3px
        text-decoration-thickness: 1px
        color: $white-color
        @include transition(color)
        @include hover
          color: $blue-color
      & .captcha-info
        margin-top: 12px
    &._tip
      position: absolute
      bottom: 0
      transform: translateY(100%)
      font-weight: 400
      padding-top: 56px
      @include font-size-lh(16,22)
      @include bp($tablet)
        padding-top: 25px
      @include bp($phone)
        position: unset
        transform: unset
        padding-top: 0
        @include font-size-lh(14,18)
  &__button
    width: 100%

  &__inner[data-mod="national"]
    .d-still-questions__form._footer
      @include bp($phone)
        display: flex
        flex-direction: column
        row-gap: 24px
    .captcha-info
      margin-bottom: 12px

