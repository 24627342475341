.d-arrows-slider
  display: flex
  width: 127px
  color: $white-color
  @include bp($phone)
    width: 95px
  &__arrow
    margin-left: -1px
    position: relative
    @include hover
      z-index: 2
    &:first-child
      margin-left: 0
    &.swiper-button-lock
      display: none