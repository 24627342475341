.d-booklets
  padding-top: 56px
  position: relative
  @include bp($desktopXS)
    padding-top: 48px
  @include bp($phone)
    padding-top: 40px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 26px
    @include bp($phone)
      flex-direction: column
      align-items: flex-start
      margin-bottom: 32px
      row-gap: 0
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__controls
    min-width: 256px
    @include bp($phone)
      width: 100%
    &-wrapper
      background: $white-color
      border-radius: 12px
      width: 100%
      height: 64px
      @include bp($phone)
        height: 48px

  &__content
    position: relative
    margin: 0 0 120px
    @include bp($tablet)
      margin: 0 0 96px
    @include bp($phone)
      margin: 0 0 64px

  &__section
    border-top: 1px solid $grey20
    padding: 48px 0
    @include bp($phone)
      padding: 32px 0
    &-title
      color: $pen-color
      margin: 0 0 32px 0
      @include font-size-lh(36,40)
      font-weight: 600
      @include bp($tablet)
        margin-bottom: 24px
      @include bp($phone)
        @include font-size-lh(32,36)
    &-content
      display: grid
      grid-template-columns: repeat(4, calc((100% - 32px * 3) / 4))
      align-items: stretch
      gap: 32px
      @include bp($desktopXS)
        grid-template-columns: repeat(3, calc((100% - 32px * 2) / 3))
      @include bp($tablet)
        grid-template-columns: repeat(2, calc((100% - 24px) / 2))
        gap: 24px
      @include bp($phone)
        grid-template-columns: 1fr
        gap: 12px

  &__item
    width: 100%
    border-radius: 24px
    overflow: hidden
    background: $white-color
    text-decoration: none
    &-cover
      display: block
      aspect-ratio: 16 / 9
      width: 100%
      overflow: hidden
      & > img, & > picture
        width: 100%
        height: 100%
        object-fit: cover
        transition: transform 500ms ease
      &.skeleton-field
        height: auto
        margin: 0
        border-radius: unset
    &:hover
      .d-booklets__item-cover > img
        transform: scale(1.2)

    &-bottom
      padding: 16px 24px
      display: flex
      flex-direction: column
      row-gap: 8px

    &-title.skeleton-field, &-info.skeleton-field
      height: 24px
      margin: 0

    &-title
      display: block
      color: $pen-color
      @include font-size-lh(20,24)
      font-weight: 600
      &.skeleton-field
        width: 300px
        @include bp($phone)
          width: 248px

    &-info
      display: flex
      align-items: center
      gap: 8px
      color: $grey40
      @include font-size-lh(14,18)
      font-weight: 400
      &:not(.skeleton-field):before
        content: url(../assets/img/icons/document.svg)
        width: 24px
        height: 24px
      &.skeleton-field
        width: 150px
        @include bp($phone)
          width: 124px
