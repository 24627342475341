.d-company-teams-item
  display: flex
  min-height: 240px
  padding: 16px
  background: $white-color
  border-radius: 32px
  transform-origin: top
  transform: rotateX(0deg) scaleY(1)
  @include transition(all)
  @include bp($desktopXS)
    border-radius: 24px
  @include bp($phone)
    flex-direction: column
    align-items: center
    padding-bottom: 24px
  @include hover
    cursor: pointer
    & .d-company-teams-item__more
      background: $pen-color
      color: $white-color
  &._skeleton
    pointer-events: none
  &__img
    flex-shrink: 0
    width: 208px
    height: 208px
    border-radius: 24px
    overflow: hidden
    @include bp($desktopXS)
      border-radius: 16px
    & img
      @include objectFit(100%, cover)
    &.skeleton-field
      margin: 0
  &__content
    flex-grow: 1
    padding: 92px 16px 0 40px
    position: relative
    @include bp($desktopXS)
      padding: 84px 0 0 16px
    @include bp($phone)
      width: 100%
      padding: 16px 44px 0 0
  &__name
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(24,28)
    @include bp($phone)
      @include font-size-lh(20,24)
    &.skeleton-field
      width: 336px
      height: 38px
      margin: 0
      @include bp($desktopXS)
        width: 232px
      @include bp($tabletS)
        width: 336px
      @include bp($phone)
        width: calc(100% - 20px)
        height: 32px
        border-radius: 8px
  &__post
    font-weight: 400
    @include font-size-lh(16,22)
    color: $blue-color
    margin-top: 8px
    &.skeleton-field
      width: 171px
      height: 17px
      margin: 12px 0 0
      @include bp($phone)
        margin-top: 8px
  &__more
    position: absolute
    right: 16px
    top: 16px
    width: 48px
    height: 48px
    border: 1px solid $pen-color
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    color: $pen-color
    @include transition(all)
    @include bp($desktopXS)
      right: 0
      top: 0
    @include bp($phone)
      top: 16px
      width: 32px
      height: 32px
      border-radius: 8px
    & svg
      width: 24px
      height: 24px
      fill: currentColor
    &.skeleton-field
      margin: 0
      border: none
      @include bp($phone)
        border-radius: 8px
  &._hidden
    transform: rotateX(-90deg)
    min-height: 0!important
    opacity: 0
    pointer-events: none
    transition-delay: 0s
  &._popup
    min-height: auto
    padding: 0
    cursor: default
    @include bp($phone)
      align-items: flex-start
    & .d-company-teams-item
      &__img
        width: 96px
        height: 96px
        border-radius: 16px
      &__content
        padding: 0 0 0 24px
        @include bp($phone)
          padding: 16px 0 0
      &__name
        @include font-size-lh(36,40)
        @include bp($phone)
          @include font-size-lh(20,24)
      &__post
        @include font-size-lh(18,24)
        @include bp($phone)
          @include font-size-lh(16,22)

@for $i from 1 through 20
  .d-company-teams-item:not(._hidden):nth-child(#{$i})
    transition-delay: calc(0.1s * ($i - 1))