.d-menu
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: calc(var(--vh, 1vh) * 100)
  padding-top: 128px
  background: #F2F3F7
  z-index: -99
  opacity: 0
  visibility: hidden
  transition: opacity $transition
  @include bp($phone)
    padding: 72px 0 126px
  &._open
    opacity: 1
    visibility: visible
    z-index: 34
  & a
    text-decoration: none
  &__wrap
    height: 100%
    overflow: auto
  &__inner
    display: flex
    justify-content: space-between
    max-width: 1920px
    width: 100%
    padding: 142px 120px 175px
    margin: 0 auto
    position: relative
    @include bp($desktopS)
      padding: 86px 40px 120px
    @include bp($desktopXS)
      padding: 51px 32px 95px
    @include bp($tablet)
      padding: 16px 24px 40px
    @include bp($tabletS)
      flex-direction: column
      align-items: center
      padding: 16px 24px 24px
    @include bp($phone)
      padding: 8px 12px 24px
  &__search
    display: none
    @include bp($phone)
      display: flex
      width: 100%
      margin-bottom: 10px
  &__links
    position: relative
    @include bp($tabletS)
      width: 100%
  &__button
    display: none
    position: absolute
    left: 12px
    bottom: 66px
    width: calc(100% - 24px)
    @include bp($phone)
      display: block
    & .d-button
      width: 100%
  &__social
    position: absolute
    right: 0
    bottom: 4px
    display: flex
    align-items: center
    @include bp($desktopXS)
      bottom: -32px
    @include bp($tablet)
      bottom: -8px
    @include bp($tabletS)
      bottom: -68px
    @include bp($phone)
      display: none
      right: 50%
      bottom: 16px
      transform: translateX(50%)
    &._mobile
      display: none
      @include bp($phone)
        display: block
    & .d-share
      z-index: 7
      @include bp($tabletS)
        transform: translateX(-90%)
      @include bp($phone)
        transform: translateX(-50%)
  &__promo
    max-width: 668px
    width: 100%
    min-height: 515px
    margin-left: 55px
    @include bp($desktopS)
      max-width: 615px
    @include bp($desktopXS)
      min-height: 488px
      margin-left: 80px
    @include bp($tablet)
      min-height: 520px
      margin-left: 24px
    @include bp($tabletS)
      max-width: 100%
      min-height: 280px
      height: 280px
      margin: 88px 0 0
    @include bp($phone)
      display: none
