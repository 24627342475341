.d-object-single-easy-to-buy-step
  display: flex
  background: $white-color
  border-radius: 24px
  padding: 40px
  position: relative
  @include bp($desktopS)
    padding: 24px
  @include bp($phone)
    padding: 12px 16px 16px 12px
  &__link
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
  &__icon
    display: flex
    flex-shrink: 0
    align-items: center
    justify-content: center
    background: $grey5
    border-radius: 50%
    width: 80px
    height: 80px
    margin-right: 32px
    @include bp($desktopS)
      width: 64px
      height: 64px
      margin-right: 16px
    @include bp($phone)
      width: 48px
      height: 48px
    & img
      width: 32px
      @include bp($desktopS)
        width: 26px
      @include bp($phone)
        width: 20px
  &__info
    flex-grow: 1
  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    @include bp($desktopS)
      @include font-size-lh(24,28)
    @include bp($phone)
      @include font-size-lh(20,24)
  &__text
    font-weight: 400
    @include font-size-lh(18,21)
    color: $grey50
    margin-top: 20px
    @include bp($desktopS)
      font-weight: 500
      @include font-size-lh(16,22)
      margin-top: 12px