.d-social__share {
  position: relative;

  &:hover {

    .d-social__share-button-icon {
      opacity: 0.7;
      @include scale(0.7);
    }

    .d-social__share-list .d-social__share-buttons-list .d-social__share-button {
      visibility: visible;
      opacity: 1;
      @include translateY(0);
      @include prefix(transition, visibility .4s ease, opacity .4s ease, transform .4s cubic-bezier(0.42, 0, 0, 2));
    }

  }

  .d-social__share-buttons-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .d-social__share-button {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    cursor: pointer;
    opacity: 0;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    @include prefix(transition, visibility .4s ease, opacity .4s ease, transform .4s ease);

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        bottom: calc($i * 100% + ($i - 1) * 10px);
        @include translateY($i* 100%);
      }
    }

    &.share-vk {
      @include sprite-vk-blue-main($after: true);
    }

    &.share-facebook {
      @include sprite-facebook-blue-main($after: true);
    }

    &.share-twitter {
      @include sprite-twitter-blue-main($after: true, $font-size: 1.2em);
    }

    &:hover {

      &:after {
        @include scale(1.3);
      }

    }

    &:after {
      @include anim(.3s, transform);
    }

  }

}

.d-social__share-button-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: currentColor;
  @include prefix(transition, visibility .4s ease, opacity .4s ease, transform .4s cubic-bezier(0.42, 0, 0, 2));
}
