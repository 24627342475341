.d-social
  display: flex
  column-gap: 16px
  &__item
    background: none
    border: none
    padding: 0
    color: $blue-color
    cursor: pointer
    width: 28px
    height: 28px
    @include transition(all)
    position: relative
    @include hover()
      color: $pen-color
    & svg
      display: block
      width: 28px
      height: 28px
      fill: currentColor

    &:empty:after
      display: block
      content: ''
      background-color: currentColor
      width: 28px
      height: 28px
      mask-size: cover

    &:empty._icon-vk
      mask: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.2995 19.2827C25.239 19.1814 24.8649 18.3686 23.0648 16.6978C21.1803 14.9483 21.4334 15.2324 23.7033 12.2073C25.0857 10.365 25.6384 9.23991 25.4654 8.75894C25.3009 8.30047 24.2841 8.42141 24.2841 8.42141L20.9032 8.4411C20.9032 8.4411 20.6529 8.40735 20.4658 8.51845C20.2844 8.62815 20.1677 8.88129 20.1677 8.88129C20.1677 8.88129 19.6319 10.3073 18.9174 11.5196C17.4112 14.0778 16.8079 14.2128 16.5618 14.0539C15.9894 13.684 16.1329 12.5659 16.1329 11.7728C16.1329 9.29335 16.5083 8.25968 15.4001 7.99247C15.0317 7.90387 14.7617 7.84481 13.8208 7.83496C12.6142 7.82231 11.5917 7.83918 11.0137 8.12186C10.6284 8.31031 10.3316 8.73081 10.5131 8.75472C10.7367 8.78425 11.243 8.89114 11.5116 9.25679C11.8589 9.72792 11.8463 10.7883 11.8463 10.7883C11.8463 10.7883 12.046 13.7065 11.3808 14.0693C10.9237 14.3183 10.2979 13.8106 8.95482 11.4887C8.26711 10.3003 7.74676 8.98536 7.74676 8.98536C7.74676 8.98536 7.64691 8.74066 7.4683 8.60986C7.25172 8.45095 6.94795 8.40032 6.94795 8.40032L3.73302 8.42001C3.73302 8.42001 3.25065 8.43407 3.07344 8.64362C2.91593 8.83066 3.06079 9.216 3.06079 9.216C3.06079 9.216 5.57816 15.1044 8.42744 18.0732C11.0418 20.7945 14.0093 20.6159 14.0093 20.6159H15.3537C15.3537 20.6159 15.7602 20.5709 15.9669 20.3473C16.1582 20.142 16.1511 19.7566 16.1511 19.7566C16.1511 19.7566 16.1244 17.9523 16.9626 17.6865C17.7881 17.4249 18.8485 19.4304 19.9722 20.2024C20.8216 20.7861 21.4672 20.6581 21.4672 20.6581L24.4725 20.6159C24.4725 20.6159 26.0448 20.5189 25.2995 19.2827Z' /%3E%3C/svg%3E%0A")

    &:empty._icon-youtube, &:empty._icon-yt
      mask: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.02137 6C5.08571 6 2.70703 7.68566 2.70703 9.76471V18.2353C2.70703 19.7601 3.98644 21.0735 5.82652 21.6654C6.49563 21.8805 7.23828 22 8.02137 22H19.9809C22.9166 22 25.2953 20.3143 25.2953 18.2353V9.76471C25.2953 7.68566 22.9166 6 19.9809 6H8.02137ZM12.1188 17.6682L17.7659 14L12.1188 10.3318V17.6682Z' /%3E%3C/svg%3E%0A")

    &:empty._icon-dzen
      mask: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 6C8.23858 6 6 8.23858 6 11V17C6 19.7614 8.23858 22 11 22H17C19.7614 22 22 19.7614 22 17V11C22 8.23858 19.7614 6 17 6H11ZM20.1181 14.0005C16.7391 14.0005 14.0005 16.7391 14.0005 20.1181C14.0005 16.7391 11.2619 14.0005 7.88281 14.0005C11.2619 14.0005 14.0005 11.2619 14.0005 7.88281C14.0005 11.2619 16.7391 14.0005 20.1181 14.0005Z' /%3E%3C/svg%3E%0A")

    &:empty._icon-telegram, &:empty._icon-tg
      mask: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.3749 13.1173C8.74359 10.7782 12.3236 9.23619 14.1148 8.49114C19.2292 6.3639 20.2919 5.99437 20.9846 5.98217C21.1369 5.97949 21.4776 6.01724 21.6982 6.19628C21.8845 6.34746 21.9358 6.55168 21.9603 6.69502C21.9848 6.83835 22.0154 7.16487 21.9911 7.42C21.714 10.332 20.5147 17.3988 19.9046 20.6603C19.6465 22.0404 19.1382 22.5031 18.6461 22.5484C17.5766 22.6468 16.7645 21.8416 15.7287 21.1626C14.1078 20.1001 13.1921 19.4387 11.6188 18.4019C9.80053 17.2037 10.9792 16.5452 12.0154 15.4689C12.2866 15.1872 16.9987 10.9012 17.0899 10.5124C17.1013 10.4638 17.1119 10.2826 17.0042 10.1868C16.8965 10.0911 16.7376 10.1239 16.6229 10.1499C16.4604 10.1868 13.871 11.8983 8.85486 15.2843C8.11988 15.789 7.45416 16.0349 6.8577 16.022C6.20015 16.0078 4.93528 15.6502 3.99498 15.3446C2.84166 14.9697 1.92503 14.7715 2.00485 14.1348C2.04642 13.8031 2.5031 13.464 3.3749 13.1173Z' /%3E%3C/svg%3E%0A")

    &:empty._icon-share
      mask: url("data:image/svg+xml,%3Csvg width='26' height='28' viewBox='0 0 26 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.9176 11.1824C17.4912 11.1823 17.0695 11.0929 16.6797 10.9199C16.29 10.7468 15.9409 10.494 15.6548 10.1778L10.0427 13.3772C10.1354 13.6672 10.1849 13.9692 10.1896 14.2736C10.1855 14.5756 10.136 14.8752 10.0427 15.1624L15.6548 18.3618C16.1447 17.8366 16.8025 17.4989 17.5148 17.4071C18.227 17.3152 18.949 17.4749 19.5561 17.8586C20.1632 18.2423 20.6172 18.826 20.8399 19.5087C21.0625 20.1915 21.0398 20.9306 20.7756 21.5984C20.5115 22.2662 20.0224 22.8209 19.3929 23.1665C18.7635 23.5122 18.033 23.6273 17.3278 23.4919C16.6225 23.3565 15.9866 22.979 15.5299 22.4248C15.0732 21.8705 14.8244 21.1742 14.8264 20.456C14.8286 20.2109 14.8608 19.9669 14.9222 19.7296L9.24442 16.4916C8.80855 16.9147 8.25766 17.2001 7.66057 17.312C7.06347 17.4238 6.44665 17.3573 5.88714 17.1207C5.32763 16.8841 4.85025 16.4878 4.51462 15.9815C4.17899 15.4751 4 14.8811 4 14.2736C4 13.6661 4.17899 13.0721 4.51462 12.5658C4.85025 12.0594 5.32763 11.6632 5.88714 11.4266C6.44665 11.1899 7.06347 11.1234 7.66057 11.2353C8.25766 11.3472 8.80855 11.6325 9.24442 12.0557L14.9222 8.81764C14.8608 8.58031 14.8286 8.33636 14.8264 8.09121C14.8264 7.47983 15.0077 6.88217 15.3473 6.37383C15.687 5.86548 16.1698 5.46927 16.7346 5.23531C17.2995 5.00134 17.921 4.94012 18.5206 5.0594C19.1203 5.17867 19.6711 5.47308 20.1034 5.9054C20.5357 6.33771 20.8301 6.88851 20.9494 7.48815C21.0687 8.08778 21.0075 8.70932 20.7735 9.27417C20.5395 9.83901 20.1433 10.3218 19.635 10.6615C19.1266 11.0011 18.529 11.1824 17.9176 11.1824Z' /%3E%3C/svg%3E%0A")

a.d-social__item
  @include hover()
    transform: scale(1.3)


[data-social-share-list]
  outline: none!important
[data-social-share-list]:hover .d-share, [data-social-share-list]:focus .d-share
  opacity: 1
  visibility: visible

//.d-flat-top [data-social-share-list]:hover .d-share, .d-flat-top [data-social-share-list]:focus .d-share
.d-flat-top [data-social-share-list] .d-share
  @include bp($phone)
    bottom: -250%
    transform: translateX(-60%)
