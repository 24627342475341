.d-visual-genplan-marker
  width: 48px
  height: 48px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  font-weight: 400
  @include font-size-lh(20,26)
  color: $white-color
  position: absolute
  background: $blue-color
  transition: all $transition
  z-index: 20

  @include bp($tabletS)
    pointer-events: all
  @include bp($phone)
    width: 40px
    height: 40px
    @include font-size-lh(18,24)
  @include hover
    //background: $pen-color
    cursor: pointer

.d-visual-genplan-eye
  cursor: pointer
  width: 32px
  height: 32px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  z-index: 20
  transition: background-color $transition
  background-color: $background
  @include bp($phone)
    width: 24px
    height: 24px
  & svg
    object-fit: contain
    *
      transition: fill $transition
      fill: $pen-color
  @include hover
    background-color: #36187D
    & svg
      *
        fill: $background
