.d-popup-online-booking
  display: flex
  flex-direction: column
  background: $background
  border-radius: 40px
  padding: 38px 32px 32px
  @include bp($phone)
    height: 100%
    padding: 12px 12px 24px
    border-radius: 0
    overflow: auto
  &__title
    font-weight: 600
    @include font-size-lh(32, 36)
    color: $blue-color
    margin: 0 96px 30px 0
    @include bp($phone)
      @include font-size-lh(24,28)
      margin: 0 72px 24px 0
  &__text
    font-weight: 400
    @include font-size-lh(16, 22)
    color: $pen-color
    margin-bottom: 16px
    @include bp($phone)
      margin-bottom: 24px
    & ul
      padding: 0
      margin: 0
      & li
        list-style: none
        padding-left: 16px
        margin-top: 4px
        position: relative
        &:first-child
          margin: 0
        &:before
          content: ''
          position: absolute
          left: 0
          top: 6px
          width: 8px
          aspect-ratio: 1/1
          background: $pen-color
          border-radius: 50%
        & ul li
          padding-left: 24px
          margin: 0
          &:before
            left: 10px
            top: 9px
            width: 4px
  &__button
    display: flex
    justify-content: flex-end
    margin-top: auto
    & .d-button
      @include bp($phone)
        width: 100%