.d-company-about-facts
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 550px
  padding: 56px
  color: $white-color
  position: relative
  @include bp($desktopXS)
    height: 400px
    padding: 40px
  @include bp($tablet)
    padding: 32px
  @include bp($phone)
    padding: 24px 20px
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    border-radius: 32px
    z-index: 1
    @include bp($phone)
      border-radius: 24px
  &__text, &__digits
    position: relative
    z-index: 2
  &__text
    font-weight: 600
    @include font-size-lh(32,36)
    max-width: 520px
    @include bp($desktopXS)
      @include font-size-lh(24,28)
      max-width: 400px
    @include bp($phone)
      @include font-size-lh(20,24)
  &__digits
    display: flex
    column-gap: 80px
    @include bp($desktopS)
      column-gap: 40px
    @include bp($desktopXS)
      column-gap: 68px
    @include bp($tablet)
      column-gap: 64px
    @include bp($tabletS)
      column-gap: 24px
    @include bp($phone)
      flex-direction: column
      row-gap: 24px
      column-gap: 0
  &__digitsLabel
    font-weight: 400
    @include font-size-lh(20,26)
    margin-top: 8px
    @include bp($desktopXS)
      margin-top: 4px
    @include bp($tablet)
      @include font-size-lh(16,22)
    @include bp($phone)
      @include font-size-lh(14,18)
  &__digitsValue
    display: flex
    align-items: flex-end
    font-weight: 600
    @include font-size-lh(64,68)
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($tablet)
      @include font-size-lh(36,40)
    @include bp($phone)
      @include font-size-lh(32,36)
    & small
      @include font-size-lh(36,40)
      margin-left: 16px
      position: relative
      bottom: 6px
      @include bp($desktopXS)
        @include font-size-lh(20,24)
        margin-left: 8px
        bottom: 4px
      @include bp($tablet)
        @include font-size-lh(16,22)
        margin-left: 4px
      @include bp($phone)
        bottom: 2px