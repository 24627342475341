.d-visual-genplan
  width: 100%
  height: 752px
  height: 920px
  position: relative
  border-radius: 40px
  overflow: hidden
  margin-top: 32px
  &._floors
    margin-top: 0
    //height: 100%
  &._floor
    border-radius: 0
    margin-top: 0
    height: 100%
  @include bp($desktopS)
    height: 560px
    height: 800px
  @include bp($tablet)
    //height: 482px !important
  @include bp($tabletS)
    //height: 690px !important
    border-radius: 32px
  @include bp($phone)
    height: 424px !important
    border-radius: 24px
  &__svg
    position: absolute
    width: 100%
    height: 100%
    left: 0
    right: 0
    z-index: 10
    top: 0
    & svg
      width: 100%
      height: 100%
  &__path
    fill-opacity: 0.2
    fill: $blue-color
    stroke-width: 4
    outline: none
    stroke: $blue-color
    stroke-opacity: 1
    transition: all $transition
    &._hover
      fill-opacity: 0.5
    &._empty
      fill: $grey40
      stroke: $grey40
    &:hover
      fill-opacity: 0.5
      cursor: pointer
    &._premium
      stroke: #5131F5
      fill: #5131F5
    &._floor
      stroke-opacity: 0
  &__compass
    position: absolute
    left: 48px
    bottom: 40px
    z-index: 20
    pointer-events: none
    max-width: 40px
    max-height: 40px
    object-fit: contain
    @include bps($tablet)
      bottom: 32px
    @include bps($phone)
      left: 20px
      bottom: 12px
  &__swipe
    position: absolute
    display: none
    @include bp($tabletS)
      bottom: 24px
      left: 50%
      transform: translateX(-50%)
      z-index: 100
      display: block
      max-width: 360px
      width: 100%
    @include bp($phone)
      width: calc(100% - 24px)
  &__disclaimer
    bottom: 40px
    left: 104px
    position: absolute
    z-index: 2
    @include bps($tablet)
      bottom: 32px
    @include bp($phone)
      left: unset
      right: 48px
      bottom: 16px
      max-width: calc(100% - 144px)
      width: auto
      text-align: center
      right: 50%
      transform: translateX(50%)

  &._2d
    @include bp($phone)
      width: 100vw
      height: 424px
      margin-left: calc(var(--padding) / 2 * -1)


  &__zoom
    width: 100%
    height: 100%
    position: relative
    & img, & svg
      @include objectFit(100%, contain)

.pinch-scroll-zoom__content
  transition: transform 0.1s ease-out
