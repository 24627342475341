.d-cookie
  position: fixed
  left: 50%
  bottom: 16px
  transform: translateX(-50%)
  max-width: 736px
  width: 100%
  padding: 16px 16px 16px 24px
  background: $white-color
  box-shadow: 0 4px 20px rgba($black, .08)
  border-radius: 24px
  display: flex
  align-items: center
  column-gap: 18px
  z-index: 111
  opacity: 0
  visibility: hidden
  @include transition(all)
  @include bp($phone)
    bottom: 0
    max-width: 100%
    padding: 12px
    border-radius: 16px 16px 0 0
    flex-direction: column
    gap: 16px 0
  &._show
    opacity: 1
    visibility: visible
  &__text
    font-weight: 400
    @include font-size-lh(14,18)
    color: $pen-color
    & a
      color: $pen-color
      text-decoration-color: $pen-color
      text-underline-offset: 3px
      @include transition(text-decoration-color)
      @include hover
        text-decoration-color: transparent
  &__button
    flex-shrink: 0
    width: 142px
    @include bp($phone)
      width: 100%