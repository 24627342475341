.ba-marker-object
  @include font-size-lh(18,21)
  font-weight: 600
  position: absolute
  width: 12px
  height: 12px
  background-color: #0196e3
  //border: 1px solid #fff
  border-radius: 50%
  color: #fff
  z-index: 100
  transition: all $transition
  & .ba-object-item__marker-bubble
    visibility: hidden
    opacity: 0
    position: absolute
    bottom: 100%
    left: 50%
    transform: translateX(-50%)
    margin-bottom: 0.5rem
    background-color: #0196e3
    padding: 1.2rem 3rem 1rem 3rem
    border-radius: 2rem
    line-height: 1
    white-space: nowrap
    transition: all $transition

  &.hover
    background-color: #36187d
    & .ba-object-item__marker-bubble
      visibility: visible
      opacity: 1
      background-color: #36187d

