.d-owners
  padding-top: 56px
  @include bp($tablet)
    padding-top: 40px
  @include bp($phone)
    padding-top: 28px
  &__inner
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin-bottom: 120px
    @include bp($tablet)
      flex-direction: column
      align-items: center
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 64px
  &__item
    &._item1
      flex-shrink: 0
      position: sticky
      left: 0
      top: 184px
      width: 572px
      @include transition(top)
      @include bp($desktopS)
        width: 485px
      @include bp($desktopXS)
        width: 384px
      @include bp($tablet)
        position: relative
        top: auto
        width: 100%
        margin-bottom: 40px
      @include bp($phone)
        margin-bottom: 24px
      &._noHeader
        top: 80px
        @include bp($desktopXS)
          top: 32px
        @include bp($tablet)
          top: 0
    &._item2
      max-width: 1216px
      width: 100%
      margin-left: 52px
      @include bp($desktopS)
        margin-left: 32px
      @include bp($tablet)
        max-width: 720px
        padding: 0
        margin: 0
      &._showISButton
        & .d-tabs
          &__content
            @include bp($tablet)
              padding-bottom: 1px
              margin-top: -64px
              margin-bottom: 96px
            @include bp($phone)
              margin-top: -48px
              margin-bottom: 76px
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    margin-bottom: 40px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
      margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 24px
    @include bp($phone)
      @include font-size-lh(36,40)
  &__note
    font-weight: 600
    @include font-size-lh(18,24)
    color: $pen-color
    margin-bottom: 50px
    @include bp($desktopXS)
      @include font-size-lh(16,22)
      margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 24px
  &__content
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    & p, & ul, & ol
      margin: 0
      & + p, & + h2, & + h3
        margin-top: 24px
      & + ul, & + ol
        margin-top: 12px
      & + .half-margin
        margin-top: 12px
    & h1, & h2, & h3
      font-weight: 600
      margin: 0
    & h1
      @include font-size-lh(32,36)
      margin-bottom: 40px
      @include bp($tablet)
        margin-bottom: 32px
      @include bp($phone)
        @include font-size-lh(24,28)
        margin-bottom: 24px
    & h2
      @include font-size-lh(24,28)
      margin-bottom: 24px
      @include bp($desktopXS)
        @include font-size-lh(20,24)
    & h3
      @include font-size-lh(20,24)
      margin-bottom: 12px
      @include bp($desktopXS)
        @include font-size-lh(16,22)
    & ul
      padding: 0
      & li
        list-style: none
        padding-left: 30px
        position: relative
        @include bp($desktopXS)
          padding-left: 24px
        &:before
          content: ''
          position: absolute
          left: 13px
          top: 10px
          width: 4px
          height: 4px
          background: currentColor
          border-radius: 50%
          @include bp($desktopXS)
            left: 10px
    & b, & strong
      font-weight: 600
    & a
      color: $blue-color
      text-decoration: underline
      text-decoration-thickness: 1px
      text-underline-offset: 3px
      text-decoration-color: rgba($blue-color, 0)
      @include transition(text-decoration-color)
      @include hover
        text-decoration-color: rgba($blue-color, 1)
      &[target='_blank'], &.download
        display: inline
        &:after
          content: ''
          display: inline-block
          width: 16px
          height: 16px
          margin-left: 2px
          background-repeat: no-repeat
          background-position: center
          background-size: contain
          position: relative
          top: 2px
      &[target='_blank']
        &:after
          background-image: url('../assets/img/d/icon_link.svg')
      &.download
        &:after
          background-image: url('../assets/img/d/icon_download_blue.svg')
      &[href^='tel:']
        text-decoration: none
    & .glow
      color: $blue-color
  &__contacts
    font-weight: 600
    @include font-size-lh(20,24)
    color: $blue-color
    margin-top: 24px
    & span
      display: block
      font-weight: 400
      @include font-size-lh(16,22)
      color: $grey50
      margin-bottom: 8px
  &__buttons
    display: flex
    justify-content: center
    position: sticky
    left: 12px
    top: 100vh
    width: 100%
    height: 0
    overflow: hidden
    z-index: 11
    opacity: 0
    visibility: hidden
    @include transition(all)
    &._show
      @include bp($tablet)
        top: calc(100% - 96px)
        height: 64px
        opacity: 1
        visibility: visible
      @include bp($phone)
        top: calc(100% - 64px)
        height: 48px
  &__choose
  //
  &__up
    position: absolute
    right: 0
    bottom: 0
    width: 64px
    padding: 0
    @include bp($phone)
      width: 48px
    &:before, &:after
      display: none
    & img
      display: block
      transform: rotate(-90deg)
  &__close
    display: none
    position: fixed
    right: 24px
    top: 24px
    @include bp($phone)
      right: 12px
      top: 12px
  & .d-tabs
    &__buttons
      margin: 0
      @include transition(opacity)
      @include bp($tabletS)
        padding: 0 24px
        margin: 0 -24px
      @include bp($phone)
        margin: 0 -12px 0 calc((var(--padding) / 2) * -1)
        padding: 0 12px 0 calc(var(--padding) / 2)
        width: 100vw
        overflow-x: auto
        scroll-behavior: smooth
      &._show
        @include bp($tablet)
          position: fixed
          left: 0
          right: 0
          top: 0
          height: 100%
          padding: 0
          margin: 0
          display: flex
          align-items: center
          justify-content: center
          background: rgba(0, 0, 0, 0.6)
          backdrop-filter: blur(20px)
          opacity: 1 !important
          z-index: 111
        & .d-tabs
          @include bp($tablet)
            &__buttonsInner
              max-width: 402px
              width: calc(100% - 24px)
              flex-direction: column
            &__button
              width: 100%
              &._active
                background: $pen-color
                border-radius: 8px
                color: $white-color
            &__buttonBg
              display: none
        & .d-owners__close
          @include bp($tablet)
            display: flex
    &__buttonsInner
      flex-direction: column
      align-items: flex-start
      background: none
      padding: 0
      @include bp($tablet)
        display: inline-flex
        flex-direction: row
        background: $white-color
        width: auto
        padding: 8px
      @include bp($phone)
        width: fit-content
        padding: 4px
    &__button
      height: auto
      padding: 0
      margin-bottom: 16px
      @include font-size-lh(20,24)
      color: $grey50
      @include bp($desktopXS)
        margin-bottom: 8px
        @include font-size-lh(14,18)
      @include bp($tablet)
        height: 48px
        padding: 0 16px
        margin-bottom: 0
        @include font-size-lh(16,22)
        color: $pen-color
      @include bp($phone)
        height: 40px
        @include font-size-lh(14,18)
      @include hover
        color: $blue-color
      &._active
        color: $pen-color
        @include bp($tablet)
          color: $white-color
      & span
        @include bp($tablet)
          display: none
        &.tablet
          display: none
          @include bp($tablet)
            display: block
    &__buttonBg
      display: none
      @include bp($tablet)
        display: block
    &__content
      @include transition(margin)