.d-index-about
  padding-top: 74px
  @include bp($desktopS)
    padding-top: 66px
  @include bp($desktopXS)
    padding-top: 50px
  @include bp($tablet)
    padding-top: 36px
  @include bp($phone)
    padding-top: 16px
  &__top
    margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 24px
    @include bp($phone)
      margin-bottom: 14px
  &__bottom
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-column-gap: 32px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px
      grid-row-gap: 24px
    @include bp($phone)
      display: flex
      grid-column-gap: 12px
      grid-row-gap: 0
      padding: 0 0 0 12px
      margin: 0 -12px
      overflow-x: auto
      overflow-y: hidden
    &:after
      content: ''
      display: none
      flex-shrink: 0
      width: 1px
      height: 1px
      margin-left: -1px
      @include bp($phone)
        display: block
    &::-webkit-scrollbar
      @include bp($phone)
        display: none

.d-index-about-top
  height: 508px
  padding: 80px
  display: flex
  align-items: flex-end
  justify-content: flex-end
  position: relative
  @include bp($desktopXS)
    height: 420px
    padding: 40px
  @include bp($tablet)
    height: 460px
    padding-top: 144px
    align-items: flex-start
  @include bp($tabletS)
    justify-content: center
  @include bp($phone)
    height: 400px
    padding: 64px 16px 16px
  &__title
    position: absolute
    left: 40px
    top: 40px
    padding: 5px 16px
    background: $white-color
    border-radius: 8px
    font-weight: 500
    @include font-size-lh(16, 22)
    color: $pen-color
    z-index: 2
    @include bp($tabletS)
      top: auto
      bottom: 40px
    @include bp($phone)
      display: none
    & a
      text-decoration: none
      color: $pen-color
  &__logo, &__info
    max-width: 1327px
    width: 100%
    z-index: 2
    @include bp($desktopS)
      max-width: 1180px
    @include bp($desktopXS)
      max-width: 910px
    @include bp($tablet)
      max-width: 640px
    @include bp($phone)
      max-width: 272px
  &__logo
    position: absolute
    right: 80px
    top: -74px
    @include bp($desktopS)
      top: -66px
    @include bp($desktopXS)
      right: 40px
      top: -50px
    @include bp($tablet)
      //right: 40px
      top: -36px
    @include bp($tabletS)
      right: 50%
      transform: translateX(50%)
    @include bp($phone)
      top: -16px
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    border-radius: 40px
    z-index: 1
    @include bp($phone)
      border-radius: 24px
  &__info
    display: flex
    align-items: flex-end
    justify-content: space-between
    position: relative
    @include bp($tablet)
      flex-direction: column
      height: 100%
    @include bp($tabletS)
      max-width: 100%
    @include bp($phone)
      max-width: 425px
      justify-content: flex-end
  &__text
    max-width: 720px
    font-weight: 600
    @include font-size-lh(40, 44)
    color: $white-color
    position: relative
    @include bp($desktopXS)
      @include font-size-lh(32, 36)
    @include bp($tablet)
      max-width: 570px
      margin-right: auto
    @include bp($tabletS)
      max-width: 640px
      margin: 0 auto
    @include bp($phone)
      @include font-size-lh(20, 24)
    & p
      margin: 0
    & small
      position: absolute
      left: 0
      top: calc(100% + 24px)
      font-weight: 400
      @include font-size-lh(20, 26)
      @include bp($desktopXS)
        display: block
        position: relative
        top: 0
        margin-top: 24px
      @include bp($phone)
        @include font-size-lh(14, 18)
        margin-top: 16px
  &__buttons
    display: flex
    margin-left: 48px
    @include bp($desktopXS)
      margin-left: 32px
    @include bp($tablet)
      margin-left: 0
    @include bp($phone)
      flex-direction: column
      width: 100%
      margin-top: 24px
  &__button
    margin-left: 18px
    @include bp($phone)
      width: 100%
      margin: 16px 0 0
    &:first-child
      margin: 0
  &._company
    height: 480px
    padding: 70px 312px
    justify-content: flex-start
    @include bp($desktopS)
      padding: 70px 259px
    @include bp($desktopXS)
      height: 356px
      padding: 56px 208px
    @include bp($tablet)
      align-items: flex-end
      padding: 64px
    @include bp($phone)
      align-items: center
      padding: 16px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: rgba($black, .3)
      border-radius: 40px
      z-index: 2
      @include bp($phone)
        border-radius: 24px
    & .d-index-about-top
      &__logo
        right: 50%
        top: -72px
        transform: translateX(50%)
        width: 1280px
        @include bp($desktopS)
          top: -60px
          width: 1064px
        @include bp($desktopXS)
          top: -48px
          width: 842px
        @include bp($tablet)
          top: -36px
          width: 624px
        @include bp($phone)
          top: -16px
          width: calc(100% - 24px)
      &__info
        max-width: 712px
        font-weight: 600
        @include font-size-lh(40,44)
        color: $white-color
        @include bp($desktopXS)
          max-width: 576px
          @include font-size-lh(32,36)
        @include bp($tablet)
          position: relative
          left: 50%
          transform: translateX(-296px)
          max-width: 428px
          height: auto
          @include font-size-lh(24,28)
        @include bp($phone)
          transform: translateX(-50%)
          max-width: 358px
          @include font-size-lh(20,24)

.d-index-about-fact
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 336px
  background: $white-color
  border-radius: 32px
  font-weight: 600
  color: $pen-color
  @include bp($desktopXS)
    height: 282px
  @include bp($tablet)
    height: 232px
  @include bp($tabletS)
    height: 254px
  @include bp($phone)
    width: 284px
    height: 214px
    flex-shrink: 0
    border-radius: 24px
  &__top
    display: flex
    align-items: flex-end
    padding: 40px
    border-bottom: 1px solid $grey20
    @include bp($desktopXS)
      padding: 32px
    @include bp($phone)
      padding: 24px 16px
  &__bottom
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 40px 40px 32px
    @include bp($desktopXS)
      padding: 32px 30px
    @include bp($phone)
      padding: 24px 16px
  &__label
    @include font-size-lh(24,28)
    margin-left: 8px
  &__value
    @include font-size-lh(40,44)
    @include bp($desktopXS)
      @include font-size-lh(32,36)
  &__title
    @include font-size-lh(18,21)
  &__text
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-top: 8px
