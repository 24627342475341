.d-zoom-map
  position: absolute
  right: 40px
  top: 50%
  transform: translateY(-50%)
  z-index: 11
  background: $white-color
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
  border-radius: 12px
  @include bp($desktopS)
    right: 24px
  @include bp($tablet)
    right: 16px
  &:before, &:after
    content: ''
    position: absolute
    top: 50%
    transform: translateY(-50%)
    width: 16px
    height: 1px
    background: $grey20
  &:before
    left: 0
  &:after
    right: 0
  &__button
    display: flex
    align-items: center
    justify-content: center
    width: 64px
    height: 64px
    background: none
    border: none
    color: $pen-color
    @include transition(color)
    cursor: pointer
    @include hover
      color: $blue-color
    &[disabled]
      opacity: 0.1
      pointer-events: none
    & svg
      width: 24px
      height: 24px
      fill: currentColor
      flex-shrink: 0
