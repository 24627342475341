.d-sorter
  display: block
  position: relative
  &._open
    & .d-sorter
      &__label
        & svg
          transform: rotate(-90deg)
      &__drop
        opacity: 1
        visibility: visible
  &__head
    height: 24px
    display: flex
    align-items: center
  & .d-sorter-direction
    margin: 0 12px 0 4px
  &__label
    display: flex
    align-items: center
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    @include transition(color)
    cursor: pointer
    @include hover
      color: $blue-color
    & svg
      flex-shrink: 0
      width: 16px
      height: 16px
      margin-left: 10px
      transform: rotate(90deg)
      fill: currentColor
  &__drop
    position: absolute
    left: 0
    top: calc(100% + 4px)
    width: 100%
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 12px
    padding: 15px 6px 15px 16px
    z-index: 11
    opacity: 0
    visibility: hidden
    min-width: 241px
    @include transition(all)
  &__list
    max-height: 280px
    padding-right: 10px
  &__item
    padding: 9px 8px
    margin-bottom: 2px
    font-weight: 400 //500
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    border-radius: 8px
    @include transition(all)
    @include hover
      background: $grey5
      color: $blue-color
    &:last-child
      margin: 0
    &._active
      background: $grey5
      border-radius: 8px
