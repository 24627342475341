.d-aux-rooms-popup-info
  flex-grow: 1
  display: flex
  flex-direction: column
  @include bp($tablet)
    flex-direction: row
  @include bp($phone)
    flex-direction: column-reverse
  &__params
    margin-bottom: 32px
    @include bp($desktopXS)
      margin-bottom: 24px
    @include bp($tablet)
      flex-grow: 1
      padding-right: 24px
      margin-bottom: 0
    @include bp($phone)
      padding: 0
      margin-top: 24px
    & .d-flat-info
      &__row:first-child
        @include bp($phone)
          display: flex
      &__label
        font-weight: 400
      &__value
        @include bp($tablet)
          font-size: 20px
      &__info
        &:hover
          & .d-flat-info__tooltip
            @include bp($isTouchDevice)
              opacity: 0
              visibility: hidden
        & button
          width: 100%
          height: 100%
          background: none
          border: none
          cursor: pointer
      &__tooltip
        @include bp($isTouchDevice)
          width: 300px
          padding-right: 44px
          text-align: left
          white-space: normal
        @include bp($phone)
          width: 250px
        & img
          display: none
          @include bp($isTouchDevice)
            display: block
            position: absolute
            right: 12px
            top: 12px
            width: 24px
        &._show
          @include bp($isTouchDevice)
            opacity: 1 !important
            visibility: visible !important
  &__bottom
    margin-top: auto
    position: relative
    @include bp($tablet)
      flex-shrink: 0
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 296px
      padding-left: 24px
      margin-top: 0
    @include bp($phone)
      width: 100%
      padding: 0
    &:before
      content: ''
      position: absolute
      left: 0
      top: -24px
      width: 1px
      height: calc(100% + 56px)
      background: $grey20
      display: none
      @include bp($tablet)
        display: block
      @include bp($phone)
        display: none
  &__buttons
    display: flex
    justify-content: flex-start
    column-gap: 8px
    margin-top: 32px
    @include bp($desktopXS)
      margin-top: 24px
    @include bp($tablet)
      flex-direction: column
      gap: 8px 0
  &__order
    @include bp($desktopXS)
      width: 235px
    @include bp($tablet)
      width: 100%
  &__callMe
    @include bp($desktopXS)
      width: 181px
    @include bp($tablet)
      width: 100%