.d-object-single-advanatages
  position: relative
  & .d-tabs
    @include bp($desktopS)
      display: flex
      flex-direction: column
      align-items: flex-start
    &__content
      width: 100%
      @include bp($phone)
        padding-bottom: 72px
  &__title
    //

  &__tabs
    margin-bottom: 24px
    @include bp($phone)
      width: auto
      padding: 0 12px
      margin-bottom: 16px
    & .d-tabs__buttonsInner
      display: inline-flex
      width: auto
    & .swiper-slide
      width: auto
  &__content
    display: flex
    width: 100%
    height: 654px
    background: $white-color
    border-radius: 40px
    overflow: hidden
    will-change: transform
    @include bp($desktopS)
      height: 560px
    @include bp($tablet)
      flex-direction: column-reverse
      height: auto
      border-radius: 32px
    @include bp($phone)
      flex-direction: column
      background: none
      border-radius: 0
      overflow: unset
  &__info
    display: flex
    flex-shrink: 0
    flex-direction: column
    width: 624px
    padding-bottom: 40px
    @include bp($desktopS)
      width: 488px
    @include bp($tablet)
      width: 100%
      padding-bottom: 32px
    @include bp($phone)
      background: $white-color
      border-radius: 24px
      padding-bottom: 24px
  &__subtitle
    flex-shrink: 0
    padding: 40px
    margin-bottom: 40px
    border-bottom: 1px solid $grey20
    font-weight: 600
    @include font-size-lh(36,40)
    color: $pen-color
    @include bp($desktopS)
      padding-bottom: 24px
      margin-bottom: 24px
      @include font-size-lh(32,36)
    @include bp($tablet)
      padding: 32px
    @include bp($phone)
      @include font-size-lh(20,24)
      padding: 24px 16px
      margin-bottom: 16px
  &__text
    //flex-grow: 1
    max-height: 315px
    padding: 0 24px 0 32px
    margin: 0 8px auto 0
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    @include bp($desktopS)
      max-height: 280px
    @include bp($tablet)
      max-height: 100%
      margin: 0
    @include bp($phone)
      padding: 0 16px
      @include font-size-lh(14,18)
    & h1, & h2, & h3, & h4, & h5, & h6
      font-weight: 600
      color: $pen-color
      margin: 0 0 24px
      @include bp($desktopS)
        margin: 0 0 16px
      @include bp($phone)
        margin: 0 0 12px
    & h4
      @include font-size-lh(18,22)
      @include bp($phone)
        @include font-size-lh(16,22)
    & h5
      @include font-size-lh(16,22)
    & p, & ul, & ol
      padding: 0
      margin: 0
      & + p, & + ul, & + ol, & + h1, & + h2, & + h3, & + h4, & + h5, & + h6
        margin: 24px 0 0
        @include bp($desktopS)
          margin: 16px 0 0
    & ul li
      list-style: none
      padding-left: 24px
      position: relative
      &:before
        content: ''
        position: absolute
        left: 8px
        top: 8px
        width: 4px
        height: 4px
        background: currentColor
        border-radius: 50%
  &__button
    flex-shrink: 0
    margin: 40px 72px 0 auto
    @include bp($desktopS)
      margin: 16px 40px 0 auto
    @include bp($tablet)
      margin: 24px 32px 0 auto
    @include bp($phone)
      position: absolute
      left: 0
      top: calc(100% + 24px)
      width: 100%
      margin: 0
  &__gallery
    flex-grow: 1
    @include bp($tablet)
      height: 440px
    @include bp($phone)
      height: 368px
      margin-top: 16px