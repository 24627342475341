.d-purchase
  padding-top: 60px
  color: $pen-color
  @include bp($tablet)
    padding-top: 42px
  @include bp($tabletS)
    padding-top: 34px
  @include bp($phone)
    padding-top: 40px

  &__title
    font-weight: 600
    @include font-size-lh(52, 56)
    color: $blue-color
    margin-bottom: 24px
    @include bp($desktopXS)
      @include font-size-lh(40, 44)
    @include bp($tablet)
      margin-bottom: 24px
    @include bp($phone)
      @include font-size-lh(36, 40)

  &__inner
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      //display: block
      margin-bottom: 64px

  & .d-tabs
    &__buttons
      margin-bottom: 40px
      @include bp($tablet)
        width: calc(100% + 48px)
        padding: 0 24px
        margin-left: -24px
        margin-right: -24px
        margin-bottom: 32px
      @include bp($phone)
        margin-bottom: 24px
        margin-left: calc((var(--padding) / 2) * -1)
        margin-right: calc((var(--padding) / 2) * -1)
        padding: 0 calc(var(--padding) / 2)
        width: calc(100% + 24px)  //100vw
        overflow-x: auto
        scroll-behavior: smooth

    &__buttonsInner
      @include bp($tabletS)
        display: inline-flex
        width: auto
      @include bp($phone)
        //width: fit-content
      @include bp($phoneS)
        //margin-right: 12px

    &__content
      width: 100%
      &._active
        animation: fadeIn 0.5s normal forwards ease
        animation-iteration-count: 1
  &__disclaimer
    font-weight: 400
    @include font-size-lh(16, 22)
    margin: 40px 0 0 40px
    @include bp($desktopXS)
      margin-top: 24px
    @include bp($tablet)
      margin-left: 0
    @include bp($phone)
      @include font-size-lh(14, 18)

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

