.utp-month
  background-color: #edeff3
  box-shadow: inset 0.2em 0.2em 0 0 rgb(210 220 233 / 90%), inset -0.2em -0.2em 0 0 rgb(255 255 255 / 70%)
  padding-top: 48px
  border-radius: 40px
  @include bp($tablet)
    background: transparent
    box-shadow: none
  &__title
    color: #D73E93
    font-size: 32px
    line-height: 38px
    font-weight: 600
    text-align: center
    @include bp($tablet)
      font-size: 25px
      line-height: 30px
      text-align: left
      padding: 0 2.5rem
    @include bp($phone)
      font-size: 18px
      line-height: 22px
  &__list
    display: flex
    margin-top: 24px
    padding: 0px 94px 48px !important
    @include bp($tablet)
      padding: 0 2.5rem !important
      margin-top: 28px
    @include bp($phone)
      padding: 0 2.5rem !important
      margin-top: 16px
    & .swiper-wrapper
      display: flex
      justify-content: center
      @include bp($tablet)
        justify-content: flex-start


.utp-item
  &:hover
    & .utp-item__icon svg
      fill: #36187D
    & .utp-item__name
      color: #36187D
  &.swiper-slide
    background: #F9F9F9
    box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
    border-radius: 24px
    padding: 24px 16px
    width: 234px
    min-height: 153px
    position: relative
    @include bp($desktopS)
      width: 200px
    @include bp($tablet)
      width: 246px

  & + .utp-item
    margin-left: 13px
    @include bp($tablet)
      margin-left: 24px
    @include bp($phone)
      margin-left: 16px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
  &__name
    font-weight: 600
    font-size: 16px
    line-height: 22px
    color: $brand-color
    transition: color $transition
  &__icon
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    bottom: 21px
    right: 20px
    width: 43px
    height: 43px
    border-radius: 50%
    background: #F2F3F7
    box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
    &  svg
      fill: $brand-color
      transition: all $transition

