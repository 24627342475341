.d-info-sections-list
  display: none
  @include bp($tablet)
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
  &._active
    display: flex
    @include bp($tablet)
      opacity: 0
      visibility: hidden
      z-index: -1
      @include transition(all)
  &._show
    background: rgba(0, 0, 0, 0.6)
    backdrop-filter: blur(20px)
    @include bp($tablet)
      opacity: 1
      visibility: visible
      z-index: 111
  &__inner
    display: flex
    flex-direction: column
    align-items: flex-start
    row-gap: 12px
    @include bp($desktopXS)
      row-gap: 8px
    @include bp($tablet)
      max-width: 402px
      width: calc(100% - 24px)
      padding: 8px
      background: $white-color
      border-radius: 12px
      align-items: center
      row-gap: 0
    @include bp($phone)
      padding: 4px
  &__item
    font-weight: 600
    @include font-size-lh(18,24)
    color: $grey50
    @include transition(color)
    text-decoration: none
    @include bp($desktopXS)
      @include font-size-lh(14,18)
    @include bp($tablet)
      width: 100%
      padding: 13px 16px
      @include font-size-lh(16,22)
      color: $pen-color
      text-align: center
      border-radius: 8px
    @include bp($phone)
      padding: 11px 16px
      @include font-size-lh(14,18)
    &._active
      color: $pen-color
      @include bp($tablet)
        background: $pen-color
        color: $white-color
    @include hover
      color: $blue-color
  &__close
    display: none
    position: fixed
    right: 24px
    top: 24px
    @include bp($tablet)
      display: flex
    @include bp($phone)
      right: 12px
      top: 12px