.d-purchase-fact
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 272px
  height: 100%
  background: $white-color
  border-radius: 32px
  @include bp($desktopXS)
    min-height: 224px
  @include bp($phone)
    min-height: 155px
    border-radius: 24px
  &__title
    display: flex
    align-items: center
    min-height: 124px
    padding: 32px 40px
    border-bottom: 1px solid $grey20
    font-weight: 600
    @include font-size-lh(24,28)
    @include bp($desktopXS)
      min-height: 100px
      padding: 20px 32px 8px
      @include font-size-lh(20,24)
    @include bp($tablet)
      padding: 26px 64px 26px 32px
    @include bp($tabletS)
      padding-right: 32px
    @include bp($phone)
      min-height: 84px
      padding: 24px 16px
      @include font-size-lh(24,28)
    & .big
      @include font-size-lh(40,44)
      margin-bottom: -3px
      @include bp($desktopXS)
        @include font-size-lh(32,36)
        margin-top: -12px
      @include bp($tablet)
        margin-top: 0
      &:first-child
        margin-right: 8px
      &:last-child
        margin-left: 8px
    & .small
      @include font-size-lh(20,24)
  &__titleInner
    display: flex
    align-items: flex-end
  &__text
    padding: 40px
    font-weight: 400
    @include font-size-lh(18,24)
    @include bp($desktopXS)
      padding: 32px 80px 32px 32px
      @include font-size-lh(16,22)
    @include bp($phone)
      //max-width: 222px
      padding: 24px 16px
  &._capital
    min-height: 124px
    @include bp($phone)
      min-height: 84px
    & .d-purchase-fact
      &__title
        border-bottom: none
        @include bp($desktopXS)
          padding: 4px 32px 0
        @include bp($phone)
          padding: 24px 16px
          @include font-size-lh(20,24)
  &._trade-in
    min-height: 170px
    @include bp($desktopXS)
      min-height: 138px
      justify-content: center
    @include bp($tablet)
      min-height: 146px
      justify-content: flex-start
    @include bp($phone)
      min-height: unset
    & .d-purchase-fact
      &__title
        min-height: unset
        padding-top: 40px
        padding-bottom: 0
        margin-bottom: 16px
        border: none
        @include bp($desktopXS)
          @include font-size-lh(20,24)
          padding-top: 20px
          margin-bottom: 10px
        @include bp($tablet)
          padding: 24px 24px 0
          margin-bottom: 8px
        @include bp($phone)
          padding: 24px 16px
          margin-bottom: 0
          border-bottom: 1px solid $grey20
      &__text
        padding-top: 0
        @include bp($desktopXS)
          padding-right: 32px
          padding-bottom: 20px
        @include bp($tablet)
          padding: 0 24px 24px
        @include bp($phone)
          padding: 24px 16px
