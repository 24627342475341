.d-tabs
  &__buttons
    //width: 100%
    margin-bottom: 16px
    overflow: auto
    &::-webkit-scrollbar
      display: none
  &__buttonsInner
    position: relative
    display: inline-flex
    align-items: center
    background: $grey5
    border-radius: 12px
    padding: 8px
    @include bp($tabletS)
      display: flex
      width: 100%
    @include bp($phone)
      padding: 4px
  &__button
    height: 48px
    padding: 0 24px
    display: flex
    align-items: center
    justify-content: center
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    white-space: nowrap
    background: none
    border: 0
    cursor: pointer
    user-select: none
    @include transition(color)
    position: relative
    z-index: 2
    text-decoration: none!Important
    @include hover
      color: $blue-color
    @include bp($phone)
      height: 40px
      padding: 0 16px
      @include font-size-lh(14,18)
    &._active
      pointer-events: none
      color: $white-color
    &._single
      pointer-events: none
      color: $pen-color
      background: none
    &._disabled
      opacity: 0.5
      pointer-events: none
  &__buttonBg
    position: absolute
    top: 8px
    height: 48px
    background: $pen-color
    border-radius: 8px
    transition: left $transition, width $transition
    @include bp($phone)
      top: 4px
      height: 40px
  &__content
    display: none
    &._active
      display: block
  &._filter
    & .d-tabs
      &__buttonsInner
        display: flex
      &__button
        flex-grow: 1
  &._whiteBg
    & .d-tabs
      &__buttonsInner
        background: $white-color
  &._static
    & .d-tabs
      &__button._active
        background: $pen-color
        border-radius: 8px
      &__buttonBg
        display: none
  &._availFilter
    @include bp($phone)
      & .d-tabs__buttons
        margin-bottom: 0
        width: 100%
      & .d-tabs__button
        width: 50%
.d-flat-top .d-tabs__buttonsInner
  @include bp($phone)
    width: fit-content
