.d-index-filter
  &__top
    display: flex
    align-items: center
    position: relative
  &__col
    &._left
      flex-grow: 1
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 24px
      &._objects
        grid-template-columns: 222px 1fr 1fr 1fr
      @include bp($tablet)
        width: 100%
        height: 100%
        padding: 72px 24px 111px
        background: $white-color
        display: block
        z-index: -1
        opacity: 0
        visibility: hidden
        overflow: auto
        pointer-events: none
        @include transition(all)
      @include bp($phone)
        padding: 64px 12px 95px
      &._show
        z-index: 111
        opacity: 1
        visibility: visible
        pointer-events: all
      &._show .d-index-filter-item__drop
        pointer-events: all
    &._clear
      flex-shrink: 0
      padding-left: 64px
      @include bp($desktopS)
        padding-left: 56px
      @include bp($desktopXS)
        padding-left: 72px
      @include bp($tablet)
        display: none
  &__clear
    display: flex
    align-items: center
    flex-shrink: 0
    background: none
    border: none
    padding: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    cursor: pointer
    @include transition(color)
    @include hover
      color: $blue-color
    &._popup
      font-weight: 400
      @include font-size-lh(14,18)
      color: $grey50
      margin-top: 4px
    & svg
      width: 24px
      height: 24px
      margin-right: 8px
      fill: currentColor
    &[disabled]
      opacity: 0.5
      pointer-events: none
    &._objects
      display: none
      margin-top: 36px
      margin-bottom: 32px
      @include bp($tablet)
        display: flex
        position: absolute
        right: 0
        top: 0
        margin: 0
      @include bp($phone)
        position: static
        margin-top: 26px

  &__buttons
    & .d-button
      padding: 0 40px !important
      @include bp($phone)
        height: 48px
        padding: 0 28px 0 24px !important

  &__show
    &.d-button
      height: 48px
      border-radius: 8px
      @include bp($tablet)
        display: flex
        width: 142px
        padding: 0
  &__map
    &.d-button
      display: none
      @include bp($phone)
        display: flex

  &__head
    display: none
    top: 0
    padding: 18px 24px
    align-items: center
    justify-content: flex-start
    column-gap: 12px
    border-bottom: 1px solid $grey20
    @include bp($tablet)
      position: fixed
      left: 0
      width: 100%
      background: $white-color
      display: flex
      z-index: 2
    @include bp($phone)
      column-gap: 16px
      padding: 16px 12px

  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $blue-color
    position: relative
    @include bp($phone)
      @include font-size-lh(24,28)
  &__buttons
    display: none
    top: 8px
    right: 0
    justify-content: flex-end
    position: absolute
    @include bp($tablet)
      display: flex
    @include bp($phone)
      width: 100%
      justify-content: space-between
      top: 64px
      padding: 0
    & .d-button
      @include bp($phone)
        width: calc(50% - 6px)
        margin-left: 0

  &__popup-buttons
    display: none
    justify-content: flex-end
    position: absolute
    bottom: 24px
    right: 0
    padding: 0 24px
    @include bp($tablet)
      display: flex
    & .d-button
      padding: 0 40px !important
      @include bp($phone)
        height: 48px
    & .skeleton-button
      min-width: 206.83px
    @include bp($phone)
      bottom: 29px
      padding: 0 12px
      width: 100%
      & .d-button
        width: 100%

  @include bp($tablet)
    height: calc(var(--vh, 1vh) * 100 - 96px)
    overflow-y: auto
