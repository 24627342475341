.d-visual-floor-info
  text-decoration: none!important
  display: grid
  grid-template-areas: "title title" "object object" "location location"  "plan plan"  "labels labels"  "area price"
  background: $white-color
  border-radius: 24px
  min-width: 380px
  width: fit-content
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
  position: relative
  @include bp($tablet)
    grid-template-columns: 272px auto
    grid-template-areas: "plan title title" "plan labels labels" "plan location location"  "plan area price"  "plan link link"
    width: 720px
    border-radius: 24px 24px 0 0
    height: fit-content
  @include bp($phone)
    box-shadow: unset
    grid-template-columns: unset
    grid-template-areas: "title title" "labels labels" "plan plan"  "location location"  "area price"  "link link"
    width: 100vw
    max-width: 100vw
    min-width: unset
    border-radius: 0
  &__sticker
    font-style: normal
    &._new
      display: inline-flex !important
      align-items: center
      height: 30px
      padding: 0 8px
      margin-left: 6px
      border: 1px solid $blue-color
      border-radius: 15px
      font-weight: 700
      @include font-size-lh(16,22)
      background: linear-gradient(180deg, #FE5285 0%, #FE404F 100%)
      background-clip: text
      -webkit-text-fill-color: transparent
      text-fill-color: transparent
      color: #FE404F
      text-transform: uppercase
      position: relative
      top: -2px
      @include bp($desktopXS)
        height: 20px
        padding: 0 4px
        border-radius: 10px
        @include font-size-lh(12,16)
      @include bp($phone)
        height: 16px
        border-radius: 8px
        @include font-size-lh(10,12)
        margin-left: 4px
        top: -3px
  &__title
    grid-area: title
    font-weight: 600
    color: $pen-color
    white-space: nowrap
    & span.mobile, & span.mobil
      display: none
    @include font-size-lh(20,24)
    padding: 24px 24px 16px 24px
    @include bp($tablet)
      padding: 24px calc(32px + 12px + 36px) 12px 24px
      & span
        display: none
      & span.mobile, & span.mobil
        display: inline
    @include bp($phone)
      padding: 24px calc(48px + 12px + 36px) 0 12px

  &__object
    grid-area: object
    font-weight: 600
    color: $blue-color
    @include font-size-lh(16,22)
    padding: 0 24px 2px 24px
    @include bp($tablet)
      display: none

  &__location
    grid-area: location
    font-weight: 400
    color: $grey50
    @include font-size-lh(16,22)
    padding: 0 24px 16px 24px
    border-bottom: 1px solid $grey20
    @include bp($tablet)
      border-bottom: unset
      border-top: 1px solid $grey20
      padding: 16px 24px 12px 24px
    @include bp($phone)
      border-top: unset
      padding: 0 12px 12px

  &__plan
    grid-area: plan
    padding: 16px 24px
    display: flex
    align-items: center
    justify-content: center
    height: 274px
    justify-self: center
    @include bp($tablet)
      display: flex
      align-items: center
      border-right: 1px solid $grey20
      width: 272px
      padding: 24px
      height: 100%
    @include bp($phone)
      height: 192px + 48px
      width: 224px //calc(100vw - 24px)
      border-right: unset
      padding: 16px 0 32px
      margin: 0 auto
    img
      width: 100%
      max-height: 274px
      height: 100%
      object-fit: contain

  &__labels
    grid-area: labels
    padding: 0 24px 12px 24px
    display: flex
    align-items: center
    justify-content: flex-start
    column-gap: 8px
    @include bp($tablet)
      padding: 0 16px 24px 24px
    @include bp($phone)
      padding: 12px 12px 16px 12px
      column-gap: 4px
      border-bottom: 1px solid $grey20

    &-label
      border-radius: 8px
      padding: 5px 16px

  &__area
    grid-area: area
    font-weight: 600
    color: $pen-color
    @include font-size-lh(24,28)
    padding: 0 24px 24px 24px
    align-self: end
    @include bp($tablet)
      padding: 0 24px 27px 24px
    @include bp($phone)
      @include font-size-lh(20,24)
      border-right: unset
      padding: 0 12px 24px 12px

  &__price
    grid-area: price
    font-weight: 600
    color: $blue-color
    display: flex
    flex-direction: column
    row-gap: 2px
    align-items: flex-end
    align-self: end
    @include font-size-lh(24,28)
    padding: 0 24px 24px 24px
    @include bp($tablet)
      padding: 0 16px 27px 16px
    @include bp($phone)
      row-gap: 4px
      border-right: unset
      padding: 0 12px 24px 12px
    &._price-request
      font-size: 20px
    &-old
      color: $grey50
      font-weight: 400
      text-decoration-line: line-through
      @include font-size-lh(20,24)
      @include bp($tablet)
        @include font-size-lh(16,22)

  &__link
    grid-area: link
    text-align: right
    display: none
    @include bp($tablet)
      display: block
      padding: 0 16px 24px 24px
    @include bp($phone)
      padding: 0 12px 24px 12px
    .d-button
      width: 221px
      height: 48px
      padding: 0 36px 0 32px
      border-radius: 8px
      @include bp($phone)
        width: 100%




.tippy-box[data-theme="visual-floor"]
  max-width: 380px !important
  background: transparent
  border: none
  & .tippy-content
    padding: 0
