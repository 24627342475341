.d-projects-map
  height: 748px
  position: relative
  @include bp($desktopS)
    height: 633px
  @include bp($tablet)
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 40
  &__content
    position: relative
    @include bp($tablet)
      z-index: 99999!important
    @include bp($phone)
      position: static
  &__available, &__choosePlace, &__buttonList
    position: absolute
    bottom: calc(100% + 36px)
    @include bp($tablet)
      bottom: auto
      top: 24px
      box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    @include bp($phone)
      top: 12px
  &__available
    right: 260px + 24px + 220px + 32px + 8px + 268px + 40px
    z-index: 3
    @include bp($desktopXS)
      right: auto
      left: 32px
      bottom: auto
      top: 24px
    @include bp($tablet)
      left: 24px
    @include bp($phone)
      left: 12px
      top: 68px
    & .d-tabs__buttons
      margin: 0
  &__choosePlace
    position: absolute!important
    right: 32px + 220px + 8px + 268px + 40px
    width: 260px
    @include bp($desktopXS)
      right: 24px + 220px + 8px + 268px + 32px
    @include bp($tablet)
      right: auto
      left: 24px + 222px + 16px
      width: 216px
    @include bp($phone)
      left: 12px
      width: calc(100% - 84px)
  &__buttonList
    width: 220px
    right: 8px + 268px + 40px
    @include bp($desktopXS)
      right: 8px + 268px + 32px
    @include bp($tablet)
      right: auto
      left: 24px + 222px + 16px + 216px + 18px
      width: 138px
      padding: 0
      z-index: 2
    @include bp($phone)
      left: 50%
      top: auto
      bottom: 16px
      transform: translateX(-50%)
      width: 130px
    &:before
      @include bp($tablet)
        display: none
    & span
      @include bp($tablet)
        display: none
      &.tablet
        display: none
        @include bp($tablet)
          display: flex
          align-items: center
          font-weight: 400
        & svg
          flex-shrink: 0
          width: 16px
          height: 16px
          margin-right: 8px
          fill:  $pen-color
  &__map
    height: 100%
    position: relative
    & .d-map-container
      border-radius: 0
  &__zoom
    position: absolute
    right: 40px
    z-index: 11
    @include bp($desktop)
      right: 50%
      transform: translate(920px, -50%)
    @include bp($desktopXS)
      right: 32px
    @include bp($tablet)
      display: none
  &__close
    display: none
    @include bp($tablet)
      display: flex
      position: absolute
      right: 24px
      top: 24px
      z-index: 3
    @include bp($phone)
      right: 12px
      top: 12px
  &__about
    position: absolute
    left: 40px
    top: 32px
    width: 400px
    @include bp($desktop)
      left: 50%
      transform: translate(-920px)
    @include bp($desktopS)
      top: 16px
    @include bp($desktopXS)
      left: 32px
    @include bp($tablet)
      left: 50%
      top: auto
      bottom: 0
      transform: translateX(-50%)
      max-width: 768px
      width: 100%
      height: auto
    @include bp($phone)
      left: 0
      transform: none
      max-width: 100%
      height: 100%
