.d-object-single-location
  &__top
    display: flex
    justify-content: space-between
    margin-bottom: 56px
    @include bp($desktopS)
      display: block
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 24px
  &__title
    width: 550px
    margin-bottom: 0
    @include bp($desktopS)
      width: 100%
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 24px
  &__info
    max-width: 904px
    width: 100%
    padding-top: 22px
    @include bp($desktopS)
      max-width: 898px
      padding-top: 0
    @include bp($tablet)
      max-width: 720px
    @include bp($phone)
      max-width: 100%
  &__text
    flex-grow: 1
    & p
      margin: 0
      & + p
        margin-top: 24px
        @include bp($tabletS)
          margin-top: 32px
        @include bp($phone)
          margin-top: 24px
  &__more
    display: none
    background: none
    border: none
    padding: 0
    @include bp($phone)
      //display: block
      margin-top: 12px
      font-weight: 500
      @include font-size-lh(16,22)
      color: $blue-color
      text-align: left
  &__times
    //
      display: grid
      grid-template-columns: repeat(3, 280px)
      grid-column-gap: 8px
    display: flex
    flex-wrap: wrap
    gap: 8px
    margin: -16px 0 56px
    @include bp($desktopS)
      margin: 0 0 40px
    @include bp($tablet)
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 8px
    @include bp($tabletS)
      grid-template-columns: repeat(2, 1fr)
    @include bp($phone)
      grid-template-columns: 1fr
      margin-bottom: 24px
    & .d-object-single-location-time
      padding-right: 16px
  &__map
    height: 740px
    position: relative
    @include bp($desktopS)
      display: flex
      height: 492px
      //height: auto
    @include bp($tabletS)
      height: 700px
      padding-bottom: 64px
    @include bp($phone)
      min-height: 0
      height: 0
      padding: 0
  &__mobileButton
    display: none
    @include bp($phone)
      display: flex
      align-items: center
      justify-content: center
      height: 160px
      background: url('../assets/img/d/map_320.jpg') no-repeat center / cover
      border-radius: 24px
    & .d-button
      @include bp($phone)
        padding: 0 20px 0 12px