.d-flat-action
  padding: 5px 16px
  background: $pen-color
  border-radius: 8px
  font-weight: 500
  //cursor: pointer
  @include font-size-lh(16, 22)
  color: $white-color
  position: relative
  border: none
  outline: none
  @include hover
    & .d-flat-action__toolp
      opacity: 1
      visibility: visible

  & img, & svg
    display: block
  &__toolp
    position: absolute
    left: 0
    bottom: calc(100% + 8px)
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    width: 185px
    padding: 12px 16px
    font-weight: 400
    color: $blue-bright
    white-space: normal
    opacity: 0
    visibility: hidden
    @include transition(all)
    &._center
      left: 50%
      transform: translateX(-50%)
      text-align: center
      @include bp($phone)
        left: auto
        right: 0
        transform: none

  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 2


  //&._black-friday
  //  display: none
  //  background: none
  //  @include bp($tablet)
  //    display: block

  &._black-friday-bage
    background: none
    padding: 0
    @include bp($tablet)
      display: none

  &._white
    background: $white-color
    color: $pen-color

  &._orange
    background: #F87700

  &._green
    background: #11BC7E

  &._red
    background: $red

  &._blue1
    background: #5131F5

  &._blue2
    background: $blue-color

  &._blue3
    background: #007AFF


.tippy-box[data-theme="index-filter"], .tippy-box[data-theme="index-filter-touch"]
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  width: fit-content
  max-width: 288px !important
  min-height: 44px
  padding: 12px 16px
  color: $blue-bright
  @include font-size-lh(16, 22)
  font-weight: 400
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 4px

.tippy-box[data-theme="index-filter-touch"]
  @include bp($phone)
    max-width: 240px !important
    padding: 12px
    @include font-size-lh(12, 14)
  &:after
    align-self: flex-start
    content: url('../assets/img/d/icon_close_pen.svg')
    width: 20px
    height: 20px

.tippy-box[data-theme="index-filter"] .tippy-content, .tippy-box[data-theme="index-filter-touch"] .tippy-content
  padding: 0 !important

.tippy-box[data-theme="flat-button"]
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  padding: 12px 16px
  font-weight: 500
  @include font-size-lh(16, 22)
  color: $blue-bright

.tippy-box[data-theme="flat-button"] .tippy-content
  padding: 0 !important

.tippy-box[data-theme="flat-panoram-button"]
  background: $white-color
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08)
  border-radius: 12px
  padding: 24px
  font-weight: 400
  @include font-size-lh(16, 22)
  @include bp($phone)
    width: calc(100vw - 24px)
    max-width: 100vw!important

.tippy-box[data-theme="flat-panoram-button"] .tippy-content
  padding: 0 !important
  display: flex
  flex-direction: column
  row-gap: 12px

.tippy-box[data-theme="flat-panoram-button"] .tippy-content span
  color: $pen-color
  cursor: pointer
  &:hover
    color: $blue-color
