.d-favorites-section
  position: relative
  & .d-button._favorite
    & .d-button__tooltip
      display: none
  &__title
    font-weight: 600
    @include font-size-lh(36,40)
    color: $pen-color
    margin-bottom: 44px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 24px
  &__list
    position: relative
    & .swiper
      @include bp($desktopXS)
        padding: 0 32px
        margin: 0 -32px
      @include bp($tablet)
        padding: 0 24px
        margin: 0 -24px
      @include bp($phone)
        padding: 0 12px
        margin: 0 -12px
    & .swiper-slide
      display: flex
      flex-direction: column
      height: auto
      @include bp($desktopXS)
        width: 485px
      @include bp($phone)
        width: 284px
    &._line
      display: flex
      flex-direction: column
      row-gap: 8px
    &._aux
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 32px
      @include bp($tablet)
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 24px
      @include bp($phone)
        grid-template-columns: 1fr
        grid-gap: 12px
  &__sorter
    margin-bottom: 8px
    @include bp($tablet)
      display: none
  &__arrows
    position: absolute
    right: 0
    top: -32px
    transform: translateY(-100%)
    @include bp($phone)
      display: none
