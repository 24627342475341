.chess
  border-bottom: 1px solid $grey20
  border-top: 1px solid $grey20
  padding-top: 32px
  margin-top: 32px
  overflow: hidden
  position: relative
  @include bp($phone)
    margin-top: 16px
    padding-top: 24px
    width: calc(100% + 24px)
    margin-left: -12px
  &__slider
    & .swiper-wrapper
      align-items: flex-end
  &__slide
    display: flex
    overflow: hidden
    justify-content: center
    @include bp($phone)
      justify-content: flex-start
      position: relative
  &__flat
    max-width: 380px
    @include bp($tablet)
      max-width: 720px
  &__popup-tablet
    display: none
    position: fixed
    bottom: 0
    max-width: 720px
    width: 100%
    z-index: 10
    left: 50%
    transform: translateX(-50%)
    &._show
      @include bp($tablet)
        display: block
      @include bp($phone)
        display: none
  &__section-btn
    position: absolute
    top: 24px
    z-index: 20
    padding: 13px 20px
    background: $white-color
    border-radius: 8px
    @include font-size-lh(16,22)
    outline: none
    border: none
    display: flex
    align-items: center
    color: $pen-color
    transition: all $transition
    @include bp($tabletS)
      top: 20px
    @include bp($phone)
      top: 24px
      padding: 0
      background: transparent
      color: $grey50
      font-weight: 600
    @include hover
      &:hover
        cursor: pointer
        background: $pen-color
        color: $white-color
        & svg
          fill: $white-color
          @include bp($phone)
            fill: $grey50
    & svg
      width: 12px
      height: 12px
      fill: $pen-color
      transition: all $transition
      @include bp($phone)
        fill: $grey50
    &._prev
      left: 0
      @include bp($phone)
        left: 12px
      & svg
        margin-right: 8px
        transform: rotate(180deg)
    &._next
      right: 0
      @include bp($phone)
        right: 12px
      & svg
        margin-left: 8px
.tippy-box[data-theme="chess-flat"]
  max-width: 380px !important
  background: transparent
  padding: 0
