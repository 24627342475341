.d-purchase-options
  &__content
    display: flex
    @include bp($desktopXS)
      display: block

.d-purchase-mortgage
  display: flex
  flex-grow: 1
  flex-direction: column
  background: $white-color
  border-radius: 32px
  @include bp($phone)
    border-radius: 24px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    padding: 40px
    border-bottom: 1px solid $grey20
    @include bp($phone)
      flex-direction: column
      padding: 24px 16px 20px
      align-items: flex-start
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(32,36)
      margin-bottom: 20px
    & span
      color: $blue-color
  &__content
    display: flex
    flex-grow: 1
    @include bp($tabletS)
      flex-direction: column
    &._info
      align-items: center
      justify-content: center
      min-height: 422px
      @include bp($tablet)
        min-height: 377px
      @include bp($tabletS)
        padding: 64px 65px
      @include bp($phone)
        min-height: 314px
        padding: 20px 16px 32px 16px
        border-radius: 24px

  &__item
    &._left
      flex-shrink: 0
      width: 435px
      padding: 40px 20px 40px 40px
      border-right: 1px solid $grey20
      @include bp($desktopS)
        width: 405px
      @include bp($desktopXS)
        width: 435px
      @include bp($tablet)
        width: 394px
      @include bp($tabletS)
        width: 100%
        padding: 40px
        border-right: none
        border-bottom: 1px solid $grey20
        display: flex
        align-items: flex-end
        justify-content: space-between
      @include bp($phone)
        padding: 20px 16px
        display: block
    &._right
      flex-grow: 1
      padding: 40px
      @include bp($desktopS)
        padding-left: 32px
      @include bp($desktopXS)
        padding: 40px
      @include bp($tablet)
        padding-left: 24px
      @include bp($tabletS)
        padding-left: 40px
      @include bp($phone)
        padding: 20px 16px


  &__programmsTitle
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 32px
    @include bp($tabletS)
      margin-bottom: 18px
    @include bp($phone)
      margin-bottom: 16px


  &__programmsList
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    & button, & a, & div
      background: none
      border: none
      font-weight: 600
      @include font-size-lh(18,21)
      color: $pen-color
      text-align: left
      padding: 0
      margin-top: 20px
      text-decoration: none
      //display: flex
      //align-items: center
      //justify-content: flex-start
      //column-gap: 4px
      @include transition(color)
      @include bp($phone)
        @include font-size-lh(16,22)
        margin-top: 8px
      &:first-child
        margin-top: 0

  &__programm-info
    display: inline-block
    width: 16px
    height: 16px
    content: url('../assets/img/d/icon_info_new.svg')
    cursor: pointer
    color: #35B8FC
    margin-left: 4px
    &:hover, &._active
      content: url('../assets/img/d/icon_info_new2.svg')

  &__more
      margin-top: 40px
      @include bp($tabletS)
        flex-shrink: 0
        width: 293px
      @include bp($phone)
        width: 100%
        margin-top: 20px

.d-purchase-mortgage-calc
  display: flex
  @include bp($phone)
    display: block
  &__col
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 24px
    @include bp($phone)
      grid-row-gap: 20px
    &._left
      flex-shrink: 0
      width: 304px
      @include bp($desktopS)
        width: 272px
      @include bp($desktopXS)
        width: 304px
      @include bp($tablet)
        width: 278px
      @include bp($tabletS)
        width: 307px
      @include bp($phone)
        width: 100%
    &._right
      flex-grow: 1
      margin-left: 50px
      @include bp($desktopS)
        margin-left: 40px
      @include bp($desktopXS)
        margin-left: 50px
      @include bp($tablet)
        margin-left: 32px
      @include bp($tabletS)
        margin-left: 40px
      @include bp($phone)
        grid-row-gap: 16px
        margin: 28px 0 0

  &__item
    line-height: 0

  &__label
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 12px
    @include bp($phone)
      margin-bottom: 4px

  &__value
    font-weight: 600
    @include font-size-lh(40,64)
    color: $blue-color
    margin: auto 0
    @include bp($phone)
      @include font-size-lh(32,36)

  &__button
    width: 100%
    @include bp($phone)
      width: 100%
      margin-top: -18px
    &.d-button
      padding: 0
      & span
        @include bp($desktopS)
          display: none
        @include bp($desktopXS)
          display: block
        @include bp($tablet)
          display: none
        @include bp($tabletS)
          display: none
        &.middle
          display: none
          @include bp($tabletS)
            display: block
        &.short
          display: none
          @include bp($desktopS)
            display: block
          @include bp($desktopXS)
            display: none
          @include bp($tablet)
            display: block
          @include bp($tabletS)
            display: none

.d-purchase-installment
  width: 592px
  margin-left: 32px
  display: flex
  flex-shrink: 0
  flex-direction: column
  background: $blue-color url('../assets/img/d/index_installment_bg.png') no-repeat -250px -250px
  border-radius: 32px
  color: $white-color
  position: relative
  overflow: hidden
  @include bp($desktopS)
    width: 485px
  @include bp($desktopXS)
    width: 100%
    margin: 32px 0 0
    background-position: -220px -310px
  @include bp($phone)
    margin-top: 12px
    border-radius: 24px
    background-position: -470px -380px
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    padding: 40px
    border-bottom: 1px solid $white-color
    @include bp($phone)
      @include font-size-lh(32,36)
      padding: 24px 16px
  &__content
    display: flex
    flex-grow: 1
    flex-direction: column
    padding: 40px
    font-weight: 500
    @include font-size-lh(16,22)
    @include bp($desktopXS)
      flex-direction: row
    @include bp($tabletS)
      flex-direction: column
    @include bp($phone)
      padding: 24px 16px 16px
  &__text
    max-width: 655px
  &__more
    margin: auto 0 0 auto
    @include bp($tabletS)
      width: 292px
      margin-top: 40px
    @include bp($phone)
      width: 100%
      margin-top: 24px

.d-purchase-mortgage-calc-empty
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  &__text
    padding-top: 88px
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    position: relative
    @include bp($desktopS)
      margin-left: -12px
      margin-right: -12px
    @include bp($desktopXS)
      margin-left: 0
      margin-right: 0
    @include bp($tablet)
      margin-left: -6px
      margin-right: -6px
    @include bp($tabletS)
      margin-left: 0
      margin-right: 0
    @include bp($phone)
      max-width: 320px
      padding: 84px 0 0
      margin: 0 auto 20px
      @include font-size-lh(16,22)
    &:before
      content: ''
      position: absolute
      left: 50%
      top: 0
      transform: translateX(-50%)
      width: 64px
      height: 64px
      background: $grey5 url('../assets/img/d/icon_refresh.svg') no-repeat center / 32px
      border-radius: 50%
  &__button
    width: 100%
    margin-top: auto
    & span
      @include bp($desktopS)
        width: 74px
        white-space: nowrap
        overflow: hidden
      @include bp($desktopXS)
        width: auto
      @include bp($tablet)
        width: 74px
      @include bp($tabletS)
        width: auto
  &._error
    & .d-purchase-mortgage-calc-empty
      &__text
        @include bp($tablet)
          margin-left: -12px
          margin-right: -12px
        @include bp($tabletS)
          padding-left: 12px
          padding-right: 12px
          margin-left: 0
          margin-right: 0
        @include bp($phone)
          padding: 84px 0 0
          margin: 0 auto 20px
        &:before
          background-image: url('../assets/img/d/icon_close_red.svg')


.tippy-box[data-theme="purchase-mortgage__programm-info"], .tippy-box[data-theme="purchase-mortgage__programm-info-touch"]
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  width: fit-content
  max-width: 215px
  min-height: 44px
  padding: 12px 16px
  color: $blue-bright
  @include font-size-lh(16, 22)
  font-weight: 400
  display: flex
  align-items: center
  justify-content: space-between
  column-gap: 4px

.tippy-box[data-theme="purchase-mortgage__programm-info-touch"]
  @include bp($phone)
    padding: 12px
    @include font-size-lh(12, 14)
  &:after
    align-self: flex-start
    content: url('../assets/img/d/icon_close_pen.svg')
    width: 20px
    height: 20px

.tippy-box[data-theme="purchase-mortgage__programm-info"] .tippy-content, .tippy-box[data-theme="purchase-mortgage__programm-info-touch"] .tippy-content
  padding: 0 !important
