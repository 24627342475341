.d-text-page
  padding-bottom: 80px
  @include bp($desktopS)
    padding-bottom: 88px
  @include bp($tablet)
    padding-bottom: 72px
  @include bp($phone)
    padding-bottom: 52px
  &__top
    display: flex
    align-items: center
    justify-content: center
    position: relative
    height: 376px
    padding: 104px 24px 0
    margin: -128px 0 40px
    @include bp($desktopXS)
      height: 360px
    @include bp($phone)
      height: 304px
      padding: 64px 12px 0
      margin: -72px 0 32px
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    z-index: 1
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))
      z-index: 2
  &__title
    max-width: 904px
    padding: 0
    margin: 0
    font-weight: 600
    @include font-size-lh(40,44)
    color: $white-color
    text-align: center
    position: relative
    z-index: 3
    @include bp($desktopXS)
      @include font-size-lh(36,40)
    @include bp($phone)
      @include font-size-lh(20,24)
  &__inner
    max-width: 952px
    width: 100%
    padding: 0 24px
    margin: 0 auto
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    @include bp($phone)
      padding: 0 12px
      @include font-size-lh(14,18)
    & *
      margin: 0
      & + *:not(br)
        margin-top: 24px
        @include bp($phone)
          margin-top: 16px
    & h2, h3, h4, h5, h6
      font-weight: 600
    & h2
      @include font-size-lh(20,24)
      @include bp($phone)
        font-size: 18px
    & h3
      @include font-size-lh(18,24)
      @include bp($phone)
        @include font-size-lh(16,22)
    & h4, h5, h6
      @include font-size-lh(16,22)
      @include bp($phone)
        @include font-size-lh(14,18)
    & ol, & ul
      padding: 0
      & li
        list-style: none
        margin: 0
        position: relative
    & ol
      counter-reset: point
      & li
        &:before
          content: counters(point, '.') ' '
          counter-increment: point
          display: inline-block
          margin-right: 4px
        & ol
          margin-left: 12px
    & ul
      & li
        padding-left: 24px
        @include bp($phone)
          padding-left: 20px
        &:before
          content: ''
          counter-increment: unset
          position: absolute
          left: 10px
          top: 10px
          width: 4px
          height: 4px
          background: $pen-color
          border-radius: 50%
          @include bp($phone)
            left: 8px
            top: 8px
    & b, & strong
      font-weight: 600
  & a
    color: $blue-color
    text-decoration-color: transparent
    text-underline-offset: 3px
    @include transition(text-decoration-color)
    @include hover
      text-decoration-color: $blue-color