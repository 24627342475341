.d-commercial-empty
  max-width: 591px
  width: 100%
  margin: 64px auto 0
  display: flex
  flex-direction: column
  align-items: center
  @include bp($phone)
    margin-top: 32px
    max-width: 100%
    padding: 0 16px
  &__icon
    width: 64px
    height: 64px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background: $white-color
    margin-bottom: 24px
    & svg
      width: 32px
      height: 32px
      fill: $blue-color
  &__text
    font-weight: 600
    color: $pen-color
    font-size: 20px
    line-height: 24px
    margin-bottom: 24px
    text-align: center
    @include bp($phone)
      font-size: 16px
      line-height: 22px
      margin-bottom: 20px