@mixin font-size-lh($size, $lh)
  font-size: $size + px
  line-height: $lh + px

@mixin objectFit($size, $fit)
  display: block
  width: $size
  height: $size
  object-fit: $fit

@mixin transition($param)
  transition: $param $transition

@mixin transitionSlow($param)
  transition: $param $transitionSlow

@mixin hover()
  @include bp($hover)
    &:hover
      @content

@mixin mfp-close()
  background-color: #f2f3f7
  box-shadow: -1.01266px -1.01266px 16.2025px #FFFFFF, 10.1266px 10.1266px 20.2532px rgba(210, 220, 233, 0.8), inset 1.01266px 1.01266px 0px rgba(255, 255, 255, 0.9)
  opacity: 1
  border-radius: 50%
  color: $white-color !important
  transform: translate(-24px, 24px)
  outline: none
  display: flex
  justify-content: center
  align-items: center
  font-size: 0
  width: 48px
  height: 48px
  transition: background-color $transition
  &:hover
    background-color: $white-color
  &:before
    content: ''
    width: 16px
    height: 16px
    display: block
    background: url('../assets/img/icons/close.svg') no-repeat
    background-size: contain
  @include bp($tablet)
    transform: translate(-16px, 16px)

@mixin tooltip()
  position: absolute
  left: 50%
  transform: translateX(-50%)
  padding: 8px 12px
  background: $purple
  border-radius: 16px
  font-weight: 500
  font-size: 14px
  line-height: 18px
  color: $white-color
  white-space: normal
  box-sizing: border-box
  opacity: 0
  visibility: hidden
  transition: all $transition
