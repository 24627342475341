.d-commercial-object-top
  display: grid
  grid-template-columns: 1fr 584px
  grid-template-rows: auto 1fr auto
  padding: 40px
  background: $white-color
  border-radius: 40px
  @include bp($desktopS)
    grid-template-columns: 1fr 477px
  @include bp($desktopS)
    grid-template-columns: 1fr 484px
  @include bp($tablet)
    grid-template-columns: 1fr
    padding: 32px
  @include bp($phone)
    padding: 24px 12px 12px
    border-radius: 24px
  &__item
    position: relative
    &._top
      padding-left: 40px
      @include bp($desktopXS)
        padding-left: 24px
      @include bp($tablet)
        padding: 0
      @include bp($tabletS)
        //padding: 0 144px 0 0
      @include bp($phone)
        //padding: 0
    &._plans, &._info
      &:before, &:after
        content: ''
        position: absolute
        background: $grey20
      &:after
        display: none
    &._plans
      grid-row: 1 / 3
      display: flex
      flex-grow: 1
      padding-right: 40px
      @include bp($tablet)
        grid-row: unset
        padding: 22px 0 0
        margin-top: 24px
      @include bp($tabletS)
        padding: 24px 0 0
        margin-top: 32px
      @include bp($phone)
        padding-top: 16px
        margin-top: 24px
      &:before
        right: 0
        top: -40px
        width: 1px
        height: calc(100% + 80px)
        @include bp($desktopXS)
          top: -32px
          height: calc(100% + 64px)
        @include bp($tablet)
          display: none
      &:after
        @include bp($tablet)
          display: block
          left: -32px
          top: 0
          width: calc(100% + 64px)
          height: 1px
        @include bp($phone)
          left: -12px
          width: calc(100% + 24px)
    &._info
      display: flex
      flex-direction: column
      padding: 33px 0 0 40px
      margin-top: 32px
      @include bp($desktopXS)
        padding: 25px 0 0 24px
        margin-top: 24px
      @include bp($tablet)
        flex-direction: row
        align-items: stretch
        padding-left: 0
        margin-top: 32px
      @include bp($phone)
        flex-direction: column-reverse
        padding-top: 0
        margin-top: 24px
      &:before
        right: -40px
        top: 0
        width: calc(100% + 40px)
        height: 1px
        @include bp($desktopXS)
          right: -32px
          width: calc(100% + 32px)
        @include bp($tablet)
          width: calc(100% + 64px)
        @include bp($phone)
          display: none
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    color: $pen-color
    margin: 0 128px 12px 0
    @include bp($desktopS)
      margin: 0 112px 12px 0
    @include bp($desktopXS)
      @include font-size-lh(32,36)
    @include bp($tablet)
      margin-right: 230px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-right: 32px
  &__icons
    position: absolute
    right: 0
    top: 0
    display: flex
    column-gap: 8px
    & .d-button
      &__tooltip
        position: absolute
    & .d-share
      @include bp($phone)
        left: auto
        transform: none
        right: 0
      &__tooltip
        @include bp($phone)
          display: none
  &__pdf
    @include bp($phone)
      display: none
    //& span
    //  &.d-button__tooltip
    //    display: block !important
    &._mobile
      display: none
      @include bp($phone)
        display: flex
        margin-top: 4px
  &__metro
    display: flex
    flex-wrap: wrap
    margin-left: -16px
    & .d-metro
      font-weight: 600
      @include font-size-lh(16,22)
      color: $pen-color
      margin-left: 16px
      @include bp($phone)
        @include font-size-lh(14,18)
  &__address
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-top: 8px
    @include bp($phone)
      @include font-size-lh(14,18)
  &__infoBottom
    display: flex
    align-items: flex-end
    justify-content: space-between
    margin-top: auto
    @include bp($tablet)
      flex-shrink: 0
      flex-direction: column
      align-items: flex-start
      width: 361px
      padding-left: 33px
      margin: 0 0 0 32px
      position: relative
    @include bp($tabletS)
      width: 247px
      padding-left: 25px
      margin: 0 0 0 24px
    @include bp($phone)
      width: 100%
      padding: 0 0 25px
      margin: 0 0 16px
    &:before
      content: ''
      position: absolute
      left: 0
      top: -24px
      width: 1px
      height: calc(100% + 56px)
      background: $grey20
      display: none
      @include bp($tablet)
        display: block
      @include bp($phone)
        left: -12px
        top: 100%
        width: calc(100% + 24px)
        height: 1px
  &__order
    margin-left: 16px
    @include bp($tablet)
      width: 222px
      margin: 16px 0 0
    @include bp($phone)
      width: 100%
  &__chooseFloor
    position: absolute
    left: 244px + 24px
    bottom: calc(100% + 24px)
    width: 184px
    height: 64px
    // start можно удалить на фронте
    display: flex
    align-items: center
    justify-content: center
    background: $grey5
    border-radius: 12px
    // end можно удалить на фронте
    @include bp($desktopXS)
      bottom: calc(100% + 16px)
    @include bp($phone)
      left: 0
      bottom: auto
      top: 0
      width: 100%
      height: 48px
  &__zoom
    position: absolute
    right: 0
    bottom: calc(100% + 24px)
    width: 64px
    height: 64px
    display: flex
    align-items: center
    justify-content: center
    background: $grey5
    border: 0
    border-radius: 50%
    color: $blue-color
    cursor: pointer
    @include transition(color)
    @include bp($desktopXS)
      bottom: calc(100% + 16px)
    @include bp($phone)
      width: 48px
      height: 48px
      bottom: calc(100% + 24px)
      //background-size: 16px
    @include hover
      color: $pen-color
    & svg
      width: 24px
      height: 24px
      fill: currentColor
      @include bp($phone)
        width: 16px
        height: 16px
  & .d-flat-info
    margin-bottom: 19px
    @include bp($desktopXS)
      grid-row-gap: 4px
    @include bp($tablet)
      flex-grow: 1
      margin-bottom: 0
    &__row
      &:first-child
        @include bp($phone)
          display: flex
    &__label
      font-weight: 400
      @include bp($desktopXS)
        @include font-size-lh(14,18)
    &__value
      @include bp($desktopXS)
        @include font-size-lh(16,22)
  & .d-flat-price
    &__old
      font-weight: 600
      @include font-size-lh(20,24)
      margin-bottom: 2px
      @include bp($phone)
        @include font-size-lh(16,22)
        margin-bottom: 4px
    &__now
      @include bp($phone)
        @include font-size-lh(24,28)
  & .d-tabs
    width: 100%
    display: flex
    flex-direction: column
    &__buttons
      margin-bottom: 24px
      @include bp($desktopXS)
        margin-bottom: 16px
      @include bp($phone)
        margin-bottom: 24px
    &__buttonsInner
      @include bp($tabletS)
        width: fit-content
    &__content
      width: 100%
      min-height: 592px
      flex-grow: 1
      position: relative
      @include bp($desktopS)
        min-height: 540px
      @include bp($desktopXS)
        min-height: 378px
      @include bp($tablet)
        min-height: 0
        height: auto