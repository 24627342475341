.d-national-project
  &__top
    color: $white-color
    position: relative
    & .swiper-slide
      height: 732px
      @include bp($desktopXS)
        height: 560px
      @include bp($tablet)
        height: 520px
      @include bp($phone)
        height: 440px
      & img
        @include objectFit(100%, cover)
        border-radius: 40px
        @include bp($phone)
          border-radius: 24px
  &__facts
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 4
    @include bp($phone)
      top: 16px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      max-width: 1205px
      width: 100%
      height: 100%
      background: linear-gradient(90deg, rgba($black, .6) 0%, rgba($black, 0) 100%)
      border-radius: 40px 0 0 40px
      z-index: 2
      @include bp($desktopXS)
        max-width: 1000px
      @include bp($tablet)
        max-width: 900px
      @include bp($tabletS)
        max-width: 100%
        border-radius: 40px
      @include bp($phone)
        border-radius: 24px
        top: -16px
        height: calc(100% + 16px)
  &__name, &__price, &__arrows
    position: absolute
    z-index: 5
    @include bp($phone)
      position: relative
  &__name, &__price
    left: 0
    padding: 0 40px
    @include bp($tablet)
      padding: 0 32px
    @include bp($phone)
      padding: 0 16px
  &__name
    top: 56px
    max-width: 1120px
    width: 100%
    font-weight: 600
    @include font-size-lh(88,96)
    @include bp($desktopXS)
      top: 40px
      max-width: 920px
      @include font-size-lh(64,68)
    @include bp($tablet)
      top: 32px
    @include bp($phone)
      top: 0
      @include font-size-lh(52,56)
    & small
      display: block
      @include font-size-lh(24,28)
      margin-top: 24px
      @include bp($phone)
        @include font-size-lh(20,24)
  &__price
    bottom: 40px
    font-weight: 600
    @include font-size-lh(36,40)
    @include bp($tablet)
      bottom: 32px
    @include bp($phone)
      bottom: 0
      @include font-size-lh(24,28)
      margin-top: 24px
  &__arrows
    right: 40px
    bottom: 40px
    @include bp($tablet)
      right: 32px
      bottom: 32px
    @include bp($phone)
      position: absolute
      right: 16px
      bottom: 16px
  &__bottom
    display: flex
    column-gap: 188px
    color: $pen-color
    margin-top: 96px
    @include bp($desktopS)
      column-gap: 160px
    @include bp($desktopXS)
      column-gap: 136px
      margin-top: 64px
    @include bp($tablet)
      column-gap: 190px
      margin-top: 48px
    @include bp($tabletS)
      flex-direction: column
      gap: 40px 0
    @include bp($phone)
      margin-top: 24px
  &__about
    width: 748px
    font-weight: 600
    @include font-size-lh(24,28)
    @include bp($desktopS)
      width: 40.5% //615px
    @include bp($desktopXS)
      width: 40.15% //488px
    @include bp($tablet)
      flex-grow: 1
      width: auto
    @include bp($tabletS)
      @include font-size-lh(20,24)
    @include bp($phone)
      font-size: 18px
    & p
      margin: 0
      & + p
        margin-top: 1em
  &__info
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    row-gap: 40px
    @include bp($tablet)
      flex-shrink: 0
      width: 310px
    @include bp($tabletS)
      flex-shrink: unset
      width: 100%
  &__params
    display: flex
    column-gap: 40px
    @include bp($tablet)
      flex-direction: column
      align-items: flex-start
      gap: 40px 0
    @include bp($tabletS)
      flex-direction: row
      gap: 0 40px
    @include bp($phone)
      flex-direction: column
      gap: 40px 0
  &__param
    font-weight: 600
    @include font-size-lh(40,28)
    @include bp($desktopXS)
      @include font-size-lh(32,36)
    @include bp($phone)
      @include font-size-lh(24,28)
    & small
      display: block
      font-weight: 400
      @include font-size-lh(16,22)
      color: $grey50
      margin-top: 16px
      @include bp($desktopXS)
        margin-top: 8px
      @include bp($phone)
        margin-top: 16px
  &__button
    @include bp($tablet)
      width: 100%
