.d-news-preview
  position: relative
  &__slider
    &.swiper
      @include bp($desktopS)
        margin: 0 -40px
        padding: 0 40px
      @include bp($desktopXS)
        margin: 0 -32px
        padding: 0 32px
      @include bp($tablet)
        margin: 0 -24px
        padding: 0 24px
      @include bp($phone)
        margin: 0 -12px
        padding: 0 12px
  &__arrows
    position: absolute
    right: 0
    top: 12px
    @include bp($desktopS)
      top: 0
    @include bp($phone)
      display: none
  &__viewAll
    display: flex
    justify-content: center
    margin-top: 40px
    @include bp($phone)
      margin-top: 24px
  &._single
    & .d-news-preview
      &__slider
        &.swiper
          @include bp($desktopS)
            margin: 0
            padding: 0
          @include bp($tablet)
            margin: 0 -24px
            padding: 0 24px
          @include bp($phone)
            margin: 0 -12px
            padding: 0 12px
      &__arrows
        @include bp($tablet)
          display: none

.d-news-preview-item
  display: flex
  flex-direction: column
  background: $white-color
  border-radius: 32px
  position: relative
  @include bp($phone)
    border-radius: 24px
  @include hover
    & .d-news-preview-item
      &__image
        & img
          transform: scale(1.07)
      &__more
        color: $white-color
        &:before
          border-top-color: $white-color
          border-right-color: $white-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
  &.swiper-slide
    max-width: 592px
    height: 442px
    @include bp($phone)
      max-width: 284px
      height: 340px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    padding: 32px 40px
    border-bottom: 1px solid $grey20
    @include bp($phone)
      padding: 16px
  &__content
    display: flex
    flex-grow: 1
    flex-direction: column
    justify-content: space-between
    padding: 40px
    @include bp($phone)
      padding: 16px
  &__bottom
    display: flex
    align-items: flex-end
    justify-content: space-between
    @include bp($phone)
      display: block
  &__date
    background: $blue-color
    border-radius: 8px
    padding: 5px 16px
    font-weight: 500
    @include font-size-lh(16,22)
    color: $white-color
  &__image
    will-change: transform
  &__likes
    display: flex
    align-items: center
    height: 24px
    padding-right: 31px
    font-weight: 500
    @include font-size-lh(16,24)
    color: $pen-color
    position: relative
    z-index: 5
    & button, & .button
      flex-shrink: 0
      background: none
      border: none
      padding: 0
      margin-left: 8px
      cursor: pointer
      @include hover
        & svg
          fill: $red
    &.active button
      & svg
        fill: $red
    & svg
      display: block
      width: 24px
      height: 24px
      fill: $pen-color
      @include transition(fill)
  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $pen-color
    max-height: 144px
    overflow: hidden
    @include bp($phone)
      @include font-size-lh(20,24)
      max-height: 72px
  &__text
    font-weight: 500
    @include font-size-lh(16,19)
    color: $grey50
    max-height: 95px
    overflow: hidden
    @include bp($phone)
      @include font-size-lh(14,18)
      max-height: 90px
  &__more
    flex-shrink: 0
    margin-left: 56px
    @include bp($phone)
      margin: 16px 0 0
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
  &._single
    height: auto
    @include bp($tablet)
      width: 384px
    @include bp($phone)
      width: 284px
      border-radius: 16px
    & .d-news-preview-item
      &__date
        position: absolute
        left: 32px
        top: 32px
        padding: 0
        z-index: 2
        @include bp($desktopXS)
          left: 24px
          top: 24px
        @include bp($phone)
          left: 16px
          top: 16px
      &__image
        height: 362px
        border-radius: 32px
        overflow: hidden
        @include bp($desktopXS)
          height: 254px
        @include bp($phone)
          height: 224px
          border-radius: 16px
        & img
          @include objectFit(100%, cover)
          @include transition(transform)
        & .skeleton-field
          height: 100%
          margin: 0
      &__content
        justify-content: flex-start
        padding: 24px 32px 32px 32px
        @include bp($desktopXS)
          padding: 24px
        @include bp($phone)
          padding: 16px
      &__source
        font-weight: 600
        @include font-size-lh(16,22)
        color: $blue-color
        margin-bottom: 8px
        &.skeleton-field
          width: 73%
          height: 22px
          margin: 0 0 8px
      &__title
        @include font-size-lh(24,28)
        max-height: 84px
        margin-bottom: 16px
        -webkit-line-clamp: 3
        display: -webkit-box
        -webkit-box-orient: vertical
        overflow: hidden
        @include bp($desktopXS)
          @include font-size-lh(20,24)
          max-height: 72px
        @include bp($phone)
          @include font-size-lh(16,22)
          max-height: 66px
        & .skeleton-field
          width: 100%
          height: 22px
          margin: 0
          & + .skeleton-field
            width: 63%
            margin-top: 4px
      &__bottom
        align-items: center

        margin-top: auto
        @include bp($phone)
          display: flex
      &__likes
        padding: 0
        background: none
        position: relative
        z-index: 5
        & .d-news-likes
          flex-direction: row-reverse
          & button, & .button
            margin: 0 8px 0 0
        &.skeleton-field
          width: 38px
          height: 38px
          margin: 0
          background: #F2F3F7
      &__more
        height: 48px
        @include bp($phone)
          margin: 0
        &.skeleton-field
          width: 154px
          margin: 0
  &._skeleton
    & .d-news-preview-item
      &__image
        padding: 32px 32px 0
        @include bp($desktopXS)
          padding: 24px 24px 0
        @include bp($phone)
          padding: 16px 16px 0
