.d-choose-params-empty
  display: flex
  flex-direction: column
  align-items: center
  padding: 88px 0 64px
  margin-top: 64px
  position: relative
  @include bp($phone)
    padding: 84px 0 32px
    margin-top: 32px
  &:before
    content: ''
    position: absolute
    left: 50%
    top: 0
    transform: translateX(-50%)
    width: 64px
    height: 64px
    background: $white-color url('../assets/img/d/icon_refresh.svg') no-repeat center / 32px
    border-radius: 50%
  &__text
    max-width: 590px
    width: 100%
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    margin-bottom: 24px
    @include bp($phone)
      max-width: 470px
      @include font-size-lh(16,22)
      margin-bottom: 20px
  &._error
    &:before
      background-image: url('../assets/img/d/icon_close_red.svg')
    & .d-choose-params-empty
      &__text
        max-width: 410px
        @include bp($phone)
          max-width: 330px