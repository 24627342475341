.d-switch-view
  display: inline-flex
  align-items: center
  background: $white-color
  border-radius: 12px
  padding: 8px
  position: relative
  @include bp($phone)
    border-radius: 8px
    padding: 4px
  &._list
    & .d-switch-view__buttonBg
      left: 8px
  &__button
    display: flex
    align-items: center
    justify-content: center
    width: 48px
    height: 48px
    padding: 0
    background: none
    border: none
    border-radius: 8px
    color: $pen-color
    @include transition(color)
    position: relative
    z-index: 2
    cursor: pointer
    @include bp($phone)
      width: 40px
      height: 40px
    @include hover
      color: $blue-color
    &._active
      color: $white-color
      @include bp($phone)
        display: none
    & svg
      width: 24px
      height: 24px
      fill: currentColor
  &__buttonBg
    position: absolute
    left: 56px
    top: 8px
    width: 48px
    height: 48px
    background: $pen-color
    border-radius: 8px
    z-index: 1
    @include transition(all)
    @include bp($phone)
      display: none