.d-aux-rooms-line
  display: grid
  grid-template-columns: 1fr 4% 5% 12% 7% 9% 10% 242px 104px
  grid-column-gap: 32px
  align-items: center
  text-align: center
  height: 128px
  padding: 0 32px 0 40px
  background: $white-color
  border-radius: 24px
  position: relative
  @include transition(box-shadow)
  @include bp($desktopXS)
    grid-template-columns: 1fr 4% 5% 12% 7% 9% 10% 242px 60px
    grid-column-gap: 20px
    height: 104px
    padding-left: 32px
  @include bp($tablet)
    grid-template-columns: repeat(2, 8%) 17% repeat(2, 9%) 1fr
    grid-column-gap: 32px
    align-items: end
    text-align: left
    height: 126px
    padding: 0 24px 16px
  @include bp($tabletS)
    grid-template-columns: repeat(2, 7%) 20% repeat(2, 9%) 1fr
  @include bp($phone)
    display: flex
    flex-wrap: wrap
    align-items: center
    grid-column-gap: 0
    height: auto
    padding: 42px 16px 12px
    border-radius: 16px
  @include hover
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
  &__item
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    white-space: nowrap
    @include bp($desktopXS)
      @include font-size-lh(16,22)
    @include bp($phone)
      font-weight: 600
      @include font-size-lh(14,18)
      position: relative
    &._name, &._advantages, &._buttons
      @include bp($tablet)
        position: absolute
    &._name
      font-weight: 600
      line-height: 24px
      white-space: normal
      text-align: left
      @include bp($desktopXS)
        line-height: 22px
      @include bp($tablet)
        left: 24px
        top: 16px
        max-width: 330px
      @include bp($phone)
        font-weight: 400
        position: relative
        left: 0
        top: 0
        max-width: 100%
        width: 100%
        margin-bottom: 24px
        order: 1
      &:after
        content: ''
        position: absolute
        left: -16px
        bottom: -12px
        width: calc(100% + 32px)
        height: 1px
        background: $grey20
        display: none
        @include bp($phone)
          display: block
      & small
        display: block
        font-weight: 400
        @include font-size-lh(16,22)
        margin-top: 8px
        @include bp($desktopXS)
          @include font-size-lh(14,18)
          margin-top: 8px
        @include bp($tablet)
          margin-top: 2px
        @include bp($phone)
          @include font-size-lh(12,14)
          margin-top: 0
    &._build, &._number
      @include bp($phone)
        display: none
    &._type
      @include bp($phone)
        order: 2
    &._square, &._level
      @include bp($phone)
        padding-left: 12px
      &:before
        content: ''
        position: absolute
        left: 4px
        top: 7px
        width: 4px
        height: 4px
        background: $grey30
        border-radius: 50%
        display: none
        @include bp($phone)
          display: block
    &._square
      @include bp($phone)
        order: 3
    &._level
      @include bp($phone)
        order: 4
    &._advantages
      display: flex
      justify-content: center
      column-gap: 4px
      & img
        pointer-events: none!important
      @include bp($tablet)
        right: 116px
        top: 20px
      @include bp($tabletS)
        right: 112px
      @include bp($phone)
        right: 16px
        top: 16px
        column-gap: 8px
    &._price
      @include bp($phone)
        position: absolute
        left: 16px
        top: 16px
    &._buttons
      display: flex
      justify-content: center
      column-gap: 8px
      @include bp($desktopXS)
        column-gap: 12px
      @include bp($tablet)
        right: 24px
        top: 24px
      @include bp($phone)
        position: relative
        right: 0
        top: 0
        margin-left: auto
        order: 5
      &._left
        justify-content: flex-end
  &__label
    display: none
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
    @include bp($tablet)
      display: block
    @include bp($phone)
      display: none
    &._mobile
      display: none
      @include bp($phone)
        display: inline-block
        margin-left: 4px
        font-weight: 600
        color: $pen-color
  &__size
    display: inline-block
    padding-right: 28px
    position: relative
    z-index: 2
    @include bp($phone)
      padding-right: 20px
    &._notip
      padding-right: 0
  &__sizeInfo
    position: absolute
    right: 0
    top: 50%
    transform: translateY(-50%)
    width: 24px
    height: 24px
    background: url('../assets/img/d/icon_info_new.svg') no-repeat center / contain
    cursor: pointer
    @include bp($phone)
      width: 16px
      height: 16px
    @include hover
      &~.d-aux-rooms-line__sizeTooltip
        opacity: 1
        visibility: visible
    & button
      width: 100%
      height: 100%
      background: none
      border: none
      cursor: pointer
  &__sizeTooltip
    position: absolute
    left: 50%
    bottom: calc(100% + 6px)
    transform: translateX(-50%)
    width: 250px
    padding: 12px 16px
    background: $white-color
    border-radius: 12px
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    font-weight: 400
    @include font-size-lh(16,22)
    color: $blue-bright
    white-space: normal //nowrap
    opacity: 0
    visibility: hidden
    @include transition(all)
    @include bp($phone)
      left: -2px
      transform: none
    @include bp($isTouchDevice)
      padding-right: 44px
    & img
      display: none
      @include bp($isTouchDevice)
        display: block
        position: absolute
        right: 12px
        top: 12px
        width: 24px
    &._show
      @include bp($isTouchDevice)
        opacity: 1
        visibility: visible
  &__button
    &.d-button._flatIcon
      z-index: 2
      @include bp($desktopXS)
        width: 24px
        height: 24px
        border: none
        @include hover
          background: none
          color: $blue-color
    & .d-button__tooltip
      left: auto
      right: 0
      transform: none
    &._scheme
      @include bp($phone)
        display: none
  &__order
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 1
    border: none
    background: none
    cursor: pointer
  & .d-flat-price
    justify-content: center
    @include bp($tablet)
      align-items: flex-end
    &__digits
      position: relative
      @include bp($phone)
        display: flex
        flex-direction: row-reverse
        align-items: center
        justify-content: flex-start
    &__old
      position: absolute
      right: 0
      bottom: calc(100% + 4px)
      margin-bottom: 0
      font-weight: 600
      @include font-size-lh(18,24)
      @include bp($desktopXS)
        bottom: 100%
        @include font-size-lh(14,22)
      @include bp($phone)
        position: relative
        bottom: 0
        margin-left: 8px
        @include font-size-lh(12,20)
    &__now
      @include font-size-lh(24,28)
      @include bp($desktopXS)
        @include font-size-lh(16,22)
      @include bp($phone)
        @include font-size-lh(18,24)
  & .d-flat-action
    z-index: 2
    @include bp($phone)
      padding: 0
      background: none!important
    &__name
      @include bp($phone)
        display: none
    & img
      display: none
      @include bp($phone)
        display: block
        width: 16px
  &._carPlaceSkeleton, &._storeroomSkeleton
    & .skeleton-field
      width: 96%
      height: 42px
      margin: 0 auto
      @include bp($tablet)
        height: 32px
        margin: 0
      @include bp($phone)
        height: 16px
        border-radius: 8px
    & .d-aux-rooms-line
      &__item
        &._name
          @include bp($phone)
            margin-bottom: 20px
          &:after
            display: none
          & .skeleton-field
            margin: 0
            @include bp($tablet)
              width: 194px
              height: 42px
            @include bp($phone)
              height: 16px
              margin-top: 10px
        &._all
          @include bp($phone)
            width: calc(100% - 24px)
            order: 2
        &._price
          & .skeleton-field
            @include bp($tablet)
              width: 161px
              height: 42px
            @include bp($phone)
              width: 121px
              height: 28px
        &._buttons
          justify-content: end
          @include bp($tablet)
            right: 16px
            top: 16px
          @include bp($phone)
            right: 0
            top: 0
          & .skeleton-field
            width: 48px
            height: 48px
            margin: 0
            @include bp($desktopXS)
              margin-left: -4px
            @include bp($tablet)
              width: 38px
              height: 38px
            @include bp($phone)
              width: 24px
              height: 24px
            &:last-child
              @include bp($phone)
                display: none
  &._carPlaceSkeleton
    grid-template-columns: 1fr 45% 10% 378px
    @include bp($desktopXS)
      grid-template-columns: 1fr 45% 10% 326px
    @include bp($tablet)
      grid-template-columns: 1fr 161px
    @include bp($phone)
      align-items: flex-end
  &._viewed
    display: flex
    flex-wrap: wrap
    align-items: center
    grid-column-gap: 0
    height: auto
    padding: 42px 16px 12px
    border-radius: 16px
    cursor: pointer
    & .d-aux-rooms-line
      &__item
        font-weight: 600
        @include font-size-lh(14,18)
        position: relative
        &._name
          font-weight: 400
          position: relative
          left: 0
          top: 0
          max-width: 100%
          width: 100%
          margin-bottom: 24px
          order: 1
          &:after
            display: block
          & small
            //@include font-size-lh(12,14)
            //margin-top: 0
            display: none
        &._type
          order: 2
        &._square, &._level
          padding-left: 12px
          &:before
            display: block
        &._square
          order: 3
        &._level
          order: 4
        &._advantages
          position: absolute
          right: 16px
          top: 16px
          column-gap: 8px
          & .d-flat-action__toolp
            display: none
        &._price
          position: absolute
          left: 16px
          top: 16px
        &._buttons
          position: relative
          right: 0
          top: 0
          margin-left: auto
          order: 5
      &__label
        display: none
        &._mobile
          display: inline-block
          margin-left: 4px
          font-weight: 600
          color: $pen-color
      &__size
        display: block !important
        padding-right: 20px
        &._notip
          padding-right: 0
      &__sizeInfo
        width: 16px
        height: 16px

      &__button
        &.d-button._flatIcon
          width: 24px
          height: 24px
          border: none
          @include hover
            background: none
            color: $blue-color
    & .d-flat-price
      align-items: flex-end
      &__digits
        display: flex
        flex-direction: row-reverse
        align-items: center
        justify-content: flex-start
      &__old
        position: relative
        bottom: 0
        margin-left: 8px
        @include font-size-lh(12,20)
      &__now
        @include font-size-lh(18,24)
    & .d-flat-action
      padding: 0
      background: none
      &__name
        display: none
      & img
        display: block
        width: 16px
  &._storeroom
    grid-template-columns: 1fr 4% 5% 15% 7% 9% 10% 186px 104px
    @include bp($desktopXS)
      grid-template-columns: 1fr 4% 5% 15% 7% 9% 10% 186px 60px
    @include bp($tablet)
      grid-template-columns: repeat(2, 10%) 24% repeat(2, 11%) 1fr
    @include bp($tabletS)
      grid-template-columns: repeat(2, 8%) 26% repeat(2, 8%) 1fr

  &._storeroomSkeleton
    grid-template-columns: 1fr 48% 10% 322px
    @include bp($desktopXS)
      grid-template-columns: 1fr 48% 10% 266px
    @include bp($tablet)
      grid-template-columns: 1fr 161px

  &._carPlace._tooltip
    display: none


.tippy-box[data-theme="d-aux-rooms-size-tip"]
  max-width: 296px !important
  width: fit-content
  background: transparent
  padding: 0
.tippy-box[data-theme="d-aux-rooms-size-tip"] .tippy-content
  padding: 12px 16px
  background: $white-color
  border-radius: 12px
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  font-weight: 400
  @include font-size-lh(16,22)
  color: $blue-bright
  @include bp($phone)
    @include font-size-lh(14,18)
  @include bp($isTouchDevice)
    display: flex
    align-items: center
    justify-content: space-between
    gap: 8px
    pointer-events: all
  & img
    display: none
    @include bp($isTouchDevice)
      display: block
      width: 24px
