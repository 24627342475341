.d-company-history
  &__title
    margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
  &__list
    display: flex
    flex-direction: column
    row-gap: 32px
    @include bp($tablet)
      row-gap: 24px
    @include bp($phone)
      row-gap: 12px