.d-choose-utp
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 40px
  background: $blue-color url('../assets/img/d/index_installment_bg.png') no-repeat -280px -280px
  border-radius: 32px
  @include bp($desktopXS)
    padding: 24px
    border-radius: 24px
  @include bp($phone)
    padding: 24px 16px 16px 16px
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    color: $white-color
    margin-bottom: 24px
    @include bp($desktopXS)
      @include font-size-lh(32,36)
      margin-bottom: 20px
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 16px
  &__list
    display: flex
    flex-direction: column
    gap: 8px
  &__item
    display: flex
    align-items: center
    justify-content: space-between
    column-gap: 8px
    border: 1px solid $white-color
    border-radius: 16px
    padding: 20px 16px 20px 20px
    font-weight: 600
    @include font-size-lh(20,24)
    color: $white-color
    text-decoration: none
    @include transition(all)
    @include bp($desktopXS)
      padding: 16px 16px 16px 20px
      @include font-size-lh(16,22)
    @include bp($phone)
      padding: 12px
    @include hover
      background: $white-color
      color: $pen-color
    & svg
      flex-shrink: 0
      width: 20px
      height: 20px
      fill: currentColor
  &._list
    background-position: -280px -280px
    & .d-choose-utp
      &__title
        text-align: center
        @include bp($phone)
          text-align: left
      &__list
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(264px, 1fr))
        @include bp($desktopXS)
          grid-template-columns: repeat(3, 1fr)
        @include bp($tablet)
          grid-template-columns: repeat(2, 1fr)
        @include bp($phone)
          grid-template-columns: 1fr
      &__item
        padding: 24px 16px 24px 24px
        @include bp($desktopS)
          padding: 20px 16px 20px 20px
        @include bp($desktopXS)
          padding: 16px 16px 16px 20px
        @include bp($phone)
          padding: 12px