.d-object-single-easy-to-buy-address
  position: absolute
  left: 40px
  top: 40px
  max-width: 380px
  width: 100%
  padding: 32px
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 24px
  z-index: 11
  transition: all $transition
  @include bp($desktopS)
    left: 24px
    top: 24px
  &._hide
    @include bp($phone)
      transform: translateY(calc(100% - 40px))
  //@include bp($tablet)
  //  top: auto
  //  bottom: 24px
  //@include bp($tabletS)
  //  max-width: calc(100% - 48px)
  //  display: flex
  @include bp($phone)
    left: 0
    top: unset
    bottom: 0
    max-width: 100%
    padding: 42px 12px 12px
    display: block
    border-radius: 24px 24px 0 0
    @include transition(all)
  &._close
    @include bp($tablet)
      bottom: auto
      top: calc(100% - 40px)
  &__back
    display: flex
    align-items: center
    margin-bottom: 16px
    font-weight: 500
    @include font-size-lh(14,16)
    color: $pen-color
    @include transition(color)
    background: none
    border: none
    padding: 0
    cursor: pointer
    @include bp($tabletS)
      position: absolute
      left: 32px
      top: 32px
    @include bp($isTouchDevice)
      display: none
    @include hover
      color: $blue-color
    & svg
      transform: rotate(180deg)
      fill: currentColor
      width: 12px
      height: 12px
      margin-right: 4px
  &__title
    position: relative
    padding-left: 40px
    margin-bottom: 16px
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    @include bp($tabletS)
      //flex-grow: 1
      //margin-top: 32px
    @include bp($phone)
      padding-left: 44px
      margin: 0 0 26px
      @include font-size-lh(20,24)
  &__num
    position: absolute
    left: 0
    top: 0
    width: 28px
    height: 28px
    padding-top: 1px
    display: flex
    align-items: center
    justify-content: center
    background: $blue-color
    border-radius: 50%
    font-weight: 600
    @include font-size-lh(16,22)
    color: $white-color
    @include bp($phone)
      left: 4px
      top: -2px
  & .d-tabs__buttons
    //@include bp($tabletS)
    //  position: absolute
    //  left: 32px
    //  bottom: 16px
    @include bp($phone)
      position: relative
      left: 0
      bottom: 0
      margin-bottom: 12px
  & .d-tabs__button
      width: 50%
  & .d-tabs__content
    padding-top: 20px
    position: relative
    //@include bp($tabletS)
    //  flex-shrink: 0
    //  width: 340px
    //  padding: 0 0 0 24px
    //@include bp($phone)
    //  width: 100%
    //  padding: 12px 0 0
    &:before
      content: ''
      position: absolute
      left: -32px
      top: 0
      width: calc(100% + 64px)
      height: 1px
      background: $grey20
      //@include bp($tabletS)
      //  left: 0
      //  top: -32px
      //  width: 1px
      //  height: calc(100% + 64px)
      //@include bp($phone)
      //  left: -12px
      //  top: 0
      //  width: calc(100% + 24px)
      //  height: 1px
  &__swipe
    display: none
    @include bp($phone)
      width: 100%
      height: 32px
      display: block
      position: absolute
      top: 0
      left: 0
      &:before
        content: ''
        display: block
        position: absolute
        left: 50%
        top: 12px
        transform: translateX(-50%)
        width: 48px
        height: 4px
        background: $grey30
        border-radius: 2px
        z-index: 2

.d-object-single-easy-to-buy-contacts
  font-weight: 500
  @include font-size-lh(14,18)
  color: $grey50
  margin-top: 16px
  position: relative
  padding-top: 20px
  &:before
    content: ''
    width: calc(100% + 64px)
    height: 1px
    position: absolute
    top: 0
    left: -32px
    background: $grey20
    display: block
  @include bp($phone)
    padding-top: 16px
  &__phone
    display: inline-block
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    text-decoration: none
    margin-bottom: 8px
    transition: color $transition
    @include bp($phone)
      @include font-size-lh(20,24)
      margin-bottom: 12px
    @include hover
      color: $pen-color
  &__address
    @include font-size-lh(14,18)
  &__buttons
    display: flex
    margin-top: 24px
    @include bp($phone)
      margin-top: 16px
  &__button
    width: 64px
    height: 64px
    padding: 0
    background: none
    border: 1px solid $pen-color
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    margin-left: 16px
    cursor: pointer
    @include transition(all)
    text-decoration: none
    position: relative
    @include bp($phone)
      width: 48px
      height: 48px
      margin-left: 8px
    @include hover
      background: $blue-color
      border-color: $blue-color
      color: $white-color
      & .d-object-single-easy-to-buy-contacts__buttonTooltip
        opacity: 1
        visibility: visible
      & svg
        fill: $white-color
    & svg
      fill: $pen-color
      width: 24px
      height: 24px
      display: block
      transition: all $transition
    &._show-room
      width: auto
      margin-left: 0
      margin-top: 24px
      @include hover
        border-color: $pen-color
    &:first-child
      margin-left: 0
    & svg, & img
      width: 24px
    & svg
      height: 24px
      fill: $pen-color
  &__buttonTooltip
    position: absolute
    left: 50%
    bottom: calc(100% + 8px)
    transform: translateX(-50%)
    background: $white-color
    box-shadow: 0 4px 20px rgba($black, 0.08)
    border-radius: 12px
    padding: 12px 16px
    font-weight: 400
    @include font-size-lh(16,22)
    color: $blue-color
    white-space: nowrap
    opacity: 0
    visibility: hidden
    @include transition(all)


.d-object-single-easy-to-buy-map-info-route
  &__note
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
    margin-bottom: 16px
    @include bp($phone)
      margin-bottom: 12px
    &._contacts
      font-weight: 400
      color: $pen-color
  &__search
    margin-top: 16px
    position: relative
    @include bp($phone)
      margin-top: 12px
  &__searchInput
    width: 100%
    height: 48px
    padding: 0 16px
    background: $grey5
    border: none
    outline: none
    border-radius: 12px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    &::placeholder
      color: $grey50
  &__searchDrop
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    padding: 16px
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    display: flex
    flex-direction: column
    align-items: flex-start
    z-index: 7
  &__searchItem
    background: none
    border: none
    padding: 0
    margin-top: 8px
    font-weight: 500
    @include font-size-lh(14,18)
    color: $pen-color
    text-decoration: none
    @include transition(color)
    cursor: pointer
    @include hover
      color: $blue-color
    &:first-child
      margin-top: 0
  &__buttons
    display: flex
    justify-content: space-between
    margin-top: 24px
    @include bp($phone)
      margin-top: 16px
  &__button
    width: calc(50% - 4px)
    height: 48px
    padding: 0
