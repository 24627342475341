.d-object-single-location-map
  height: 100%
  width: 100%
  position: relative
  overflow: hidden
  @include bp($desktopS)
    //height: auto
  @include bp($phone)
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    opacity: 0
    visibility: hidden
    z-index: -1
    @include transition(all)
  &._inside
    //margin-left: 380px
    //width: 1166px
    background: $white-color
    border-radius: 40px
    padding-left: 605px
    @include bp($desktopS)
      padding-left: 0
      display: flex
  &._show
    @include bp($phone)
      opacity: 1
      visibility: visible
      z-index: 24
  &__map
    width: 100%
    height: 100%
    border-radius: 40px
    overflow: hidden
    will-change: transform
    @include bp($desktopS)
      border-radius: 24px
    @include bp($phone)
      border-radius: 0
  &__zoom
    @include bp($phone)
      display: none
  &__tabs
    display: none
    @include bp($tablet)
      display: flex
      position: absolute
      left: 16px
      top: 16px
      width: 316px
      z-index: 7
    @include bp($phone)
      left: 12px
      top: 12px
    & .d-tabs__button
      width: 50%
  &__buttons
    position: absolute
    right: 16px
    bottom: 16px
    z-index: 7
    @include bp($tablet)
      display: flex
    @include bp($phone)
      right: 50%
      bottom: 12px
      transform: translateX(50%)
  &__button
    margin-left: 8px
    &.d-button._withImg._left
      height: 48px
      padding: 0 28px 0 24px
      @include bp($phone)
        padding: 0 20px 0 16px
    &:first-child
      @include bp($phone)
        display: none
    & span
      @include bp($phone)
        display: none
      &.mobile
        display: none
        @include bp($phone)
          display: block
    & svg
      fill: currentColor
      width: 16px
      height: 16px
      margin-right: 8px
    &._lock
      //
    &._filter
      display: none
      @include bp($tablet)
        display: flex
  &__count
    display: none
    font-family: 'Gilroy', Helvetica, Arial, sans-serif
    @include bp($phone)
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 24px
      margin-left: 8px
      background: $red
      border-radius: 50%
      font-weight: 500
      @include font-size-lh(12,14)
      color: $white-color
  &__note
    display: none
    @include bp($tabletS)
      display: block
      position: absolute
      left: 0
      top: calc(100% + 16px)
      width: 100%
    @include bp($phone)
      left: 12px
      top: auto
      bottom: 72px
      width: calc(100% - 24px)
  &__close
    display: none
    @include bp($phone)
      display: flex
      position: absolute
      right: 12px
      top: 12px
      z-index: 11
  &__lock
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 2
    display: none
    &._show
      display: block
