.d-regional-easy-to-buy
  &__title
    margin-bottom: 32px
  &__subtitle
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    margin-bottom: 56px
    @include bp($desktopS)
      margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 24px
  &__steps
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-column-gap: 32px
    margin-bottom: 32px
    @include bp($tablet)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 8px
      margin-bottom: 40px
  &__infoTablet
    display: none
    @include bp($tablet)
      display: block
  &__addresses
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 8px
    grid-row-gap: 8px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-column-gap: 0
  &__address
    background: $white-color
    border-radius: 24px
    min-height: 112px
    padding: 24px 40px 24px 104px
    display: flex
    flex-direction: column
    justify-content: center
    position: relative
    @include bp($phone)
      min-height: 80px
      padding: 12px 32px 16px 76px
    &:after
      content: ''
      position: absolute
      right: 20px
      top: 20px
      width: 6px
      height: 6px
      border-top: 2px solid $pen-color
      border-right: 2px solid $pen-color
      z-index: 2
  &__addressImg
    position: absolute
    left: 24px
    top: 50%
    transform: translateY(-50%)
    width: 64px
    height: 64px
    object-fit: cover
    border-radius: 50%
    @include bp($phone)
      left: 12px
      top: 12px
      transform: none
      width: 48px
      height: 48px
  &__addressTitle
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    margin-bottom: 8px
    @include bp($phone)
      @include font-size-lh(20,24)
  &__addressText
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
  &__map
    height: 740px
    @include bp($desktopS)
      height: 492px
    @include bp($tablet)
      height: 0
