.d-video-page
  padding-top: 16px
  &__title
    padding: 0
    margin: 0 0 40px
    font-weight: 600
    @include font-size-lh(40,44)
    color: $blue-color
    text-align: center
    position: relative
    z-index: 3
    @include bp($desktopXS)
      @include font-size-lh(36,40)
    @include bp($phone)
      margin-bottom: 24px
      @include font-size-lh(20,24)
  &__content
    & video
      display: block
      width: 100%
      //max-height: 80vh
      border-radius: 40px
      will-change: transform
      @include bp($phone)
        border-radius: 26px