.d-select-phone
  //position: relative
  user-select: none
  &._qr
    .d-select-phone__head
      background: $grey5
  &._open
    & .d-select-phone
      &__head:after
        transform: rotate(-90deg)

      &__drop
        opacity: 1
        visibility: visible
  &._mortgage
    .d-select-phone__head
      background: $grey5
      border-radius: 16px 0 0 16px
  &__head
    display: flex
    align-items: center
    height: 62px
    padding: 0 24px 0 24px
    background: $white-color
    border-radius: 12px
    font-weight: 600


    @include font-size-lh(16, 22)
    color: $pen-color
    cursor: pointer
    position: relative
    @include transition(color)
    @include bp($phone)
      height: 48px
    @include hover
      color: $blue-color
    & span
      width: 100%
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      & br
        display: none
    &:after
      content: ''
      position: absolute
      right: 12px
      top: 50%
      margin-top: -10px
      width: 20px
      height: 20px
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
      transform: rotate(90deg)

  &__drop
    position: absolute
    left: 0
    top: calc(100% + 8px)
    width: 100%
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 12px
    padding: 15px 12px 15px 10px
    z-index: 22
    opacity: 0
    visibility: hidden
    will-change: opacity

  &__list
    max-height: 200px
    padding-right: 10px

  &__item
    padding: 9px 8px
    margin-bottom: 2px
    font-weight: 400
    display: flex
    //align-items: center
    gap: 8px
    @include font-size-lh(16, 22)
    color: $pen-color
    cursor: pointer
    border-radius: 8px
    @include transition(all)
    @include hover
      background: $grey5
      color: $blue-color
    & span
      @include font-size-lh(16,22)
      font-weight: 600
      color: $grey50
    &:last-child
      margin: 0

    &._active
      background: $grey5
      border-radius: 8px

    &._disable
      background: $grey5
      color: $grey_shadow
      pointer-events: none

  &__flag
    width: 20px
    position: relative
    top: 4px

// Dropdown Menu Animation
.dropdown-enter-active,
.dropdown-leave-active
  transition: all $transitionSlow


.dropdown-enter,
.dropdown-leave-to
  opacity: 0
