.d-object-single-about
  display: flex
  background: $white-color
  border-radius: 32px
  overflow: hidden
  @include bp($tabletS)
    display: block
  &__item
    &._left
      display: flex
      flex-grow: 1
      justify-content: space-between
      padding: 56px
      @include bp($desktopS)
        flex-direction: column
        justify-content: flex-start
        padding: 40px
      @include bp($tablet)
        padding: 32px 24px 40px 32px
      @include bp($tabletS)
        padding: 32px 32px 24px
      @include bp($phone)
        padding: 24px 16px
    &._right
      display: flex
      flex-shrink: 0
      flex-direction: column
      align-items: flex-start
      width: 625px
      padding: 56px 48px
      border-left: 1px solid $grey20
      @include bp($desktopS)
        width: 592px
        padding: 40px
      @include bp($tablet)
        width: 475px
        padding: 32px
      @include bp($tabletS)
        width: 100%
        padding: 24px 32px 32px
        border-left: none
        border-top: 1px solid $grey20
      @include bp($phone)
        padding: 24px 16px 16px
  &__title
    @include bp($desktopS)
      margin-bottom: 24px
    @include bp($phone)
      margin-bottom: 16px
  &__text
    max-width: 534px
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
  &__more
    display: none
    @include bp($phone)
      //display: block
      background: none
      border: none
      padding: 0
      margin-top: 12px
      font-weight: 500
      @include font-size-lh(16,22)
      color: $blue-color
      text-align: left
  &__address
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    margin-bottom: 4px
    @include bp($phone)
      @include font-size-lh(18,22)
    &:hover
      cursor: pointer
  &__metro
    display: flex
    flex-wrap: wrap
    margin-left: -12px
    & .d-metro, & .d-metro-way
      @include font-size-lh(14,18)
      color: $grey50
      margin: 4px 0 0 12px
  &__facts
    margin: 40px 0
    @include bp($tablet)
      margin: 24px 0 32px
    @include bp($tabletS)
      margin: 24px 0 0
  &__button
    margin-top: auto
    @include bp($desktopS)
      margin-left: auto
    @include bp($tabletS)
      margin-top: -64px
    @include bp($phone)
      width: 100%
      margin: 24px 0 0
  &__navs-mobile
    display: none
    @include bp($phone)
      position: relative
      display: flex
      flex-wrap: wrap
    & .d-object-navs__item._more
      @include bp($phone)
        & svg
          display: none
      & .d-object-navs__sub
        @include bps($phone)
          display: none