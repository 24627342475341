.d-aux-rooms-viewed
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
  &__list
    &.swiper
      overflow: unset
      overflow-x: hidden
      overflow-y: unset
      padding: 16px
      margin: 6px -16px -16px
      @include bp($tablet)
        padding-left: 24px
        padding-right: 24px
        margin-left: -24px
        margin-right: -24px
      @include bp($phone)
        padding-left: 12px
        padding-right: 12px
        margin: 0 -12px -16px
    & .swiper-slide
      width: 296px