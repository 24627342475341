.d-flat-mortgage-calc
  display: grid
  grid-template-columns: 1fr
  grid-row-gap: 32px
  background: $white-color
  border-radius: 40px
  padding: 40px
  font-weight: 500
  color: $grey50
  @include bp($desktopS)
    grid-row-gap: 28px
  @include bp($tablet)
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 24px
    grid-row-gap: 24px
    padding: 32px
  @include bp($phone)
    grid-template-columns: 1fr
    grid-column-gap: 0
    grid-row-gap: 20px
    border-radius: 24px
    padding: 24px 16px
  &__result
    display: flex
    align-items: flex-end
    justify-content: space-between
    padding: 20px 0 14px
    @include bp($desktopS)
      padding: 4px 0 0
      margin-bottom: -8px
    @include bp($tablet)
      display: block
      padding: 0
      margin: 0
  &__resultLabel
    @include font-size-lh(16,22)
    @include bp($tablet)
      margin-bottom: 12px
  &__resultValue
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($tablet)
      line-height: 64px
    @include bp($phone)
      line-height: 28px
  &__bottom
    display: flex
    align-items: center
    @include bp($desktopS)
      flex-direction: column-reverse
    @include bp($tablet)
      flex-direction: row
      justify-content: space-between
      grid-column: 1/-1
    @include bp($phone)
      flex-direction: column-reverse
      grid-column: unset
  &__note
    flex-grow: 1
    @include font-size-lh(14,18)
    @include bp($desktopS)
      @include font-size-lh(12,14)
    @include bp($tablet)
      max-width: 300px
    @include bp($phone)
      max-width: 100%
      text-align: center
  &__button
    flex-shrink: 0
    margin-left: 16px
    @include bp($desktopS)
      width: 100%
      margin: 0 0 22px
    @include bp($tablet)
      width: calc(50% - 12px)
      margin: 0
    @include bp($phone)
      width: 100%
      margin-bottom: 20px
  &._purchase
    grid-row-gap: 32px
    border-radius: 32px
    padding: 32px
    @include bp($tablet)
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 24px
    @include bp($tabletS)
      grid-template-columns: repeat(2, 1fr)
      grid-row-gap: 16px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-gap: 24px 0
      border-radius: 24px
      padding: 20px 16px 28px
    & .d-flat-mortgage-calc
      &__result
        padding: 0
        margin-bottom: 0
        font-weight: 600
        @include font-size-lh(16,22)
        color: $pen-color
        @include bp($desktopXS)
          @include font-size-lh(14,18)
        @include bp($tablet)
          grid-column: 1/-1
        @include bp($tabletS)
          grid-column: unset
          margin-top: 49px
        @include bp($phone)
          margin-top: 0

        .skeleton-field
          margin: 0
          width: 269px
          height: 22px
          @include bp($desktopXS)
            height: 18px
