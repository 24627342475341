.d-object-single
  &__section
    margin-bottom: 160px
    @include bp($desktopS)
      margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 72px
    &._header
      margin-bottom: 80px
      @include bp($tablet)
        margin-bottom: 110px
      @include bp($phone)
        margin-bottom: 118px
      &._mos
        @include bp($tablet)
          margin-bottom: 150px
        @include bp($phone)
          margin-bottom: 146px
        & .d-object-header-mobile__name
          @include bps($tablet)
            @include font-size-lh(28,32)
          @include bps($phone)
            @include font-size-lh(16,22)
    &._top
      margin-bottom: 24px
      position: relative
    &._genplan
      @include bp($tablet)
        margin-bottom: 160px
      @include bp($phone)
        margin-bottom: 72px
    &._docs
      @include bp($tablet)
        margin-bottom: 86px
      @include bp($phone)
        margin-bottom: 80px
    &._advanatages
      @include bps($phone)
        margin-bottom: 144px
