.d-zoom-popup
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background: $background
  overflow: hidden
  z-index: 111
  @include transition(all)

  &__image
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 70%
    height: 70%

    & img
      @include objectFit(100%, contain)

    & .pinch-scroll-zoom__content
      display: flex
      align-items: center

  &__note
    display: none
    @include bp($tablet)
      display: block
      position: absolute
      left: 32px
      bottom: 32px
      max-width: 395px
      width: calc(100% - 24px)
      z-index: 11
    @include bp($phone)
      left: 12px
      bottom: 12px
      max-width: 320px

  &__zoom, &__close
    position: absolute
    right: 40px
    z-index: 11
    @include bp($tablet)
      right: 32px
    @include bp($phone)
      right: 12px

  &__zoom
    top: auto
    bottom: 40px
    transform: none
    @include bp($tablet)
      bottom: 32px
    @include bp($phone)
      display: none

  &__close
    top: 40px
    @include bp($tablet)
      top: 32px
    @include bp($phone)
      top: 12px

  &__tip
    position: absolute
    top: 40px
    left: 40px
    background: #FFFFFF
    box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    padding: 16px 20px
    color: #36187D
    font-weight: 400
    @include font-size-lh(16,22)
    @include bp($tablet)
      top: 32px
      left: 32px
    @include bp($phone)
      top: 12px
      left: 12px
      @include font-size-lh(12,14)

  &__tip:before
    display: inline-block
    content: ''
    width: 12px
    height: 12px
    background: #35B8FC
    border-radius: 50%
    margin-right: 6px
    @include bp($phone)
      width: 8px
      height: 8px

  &__disclaimer
    position: absolute
    left: 40px
    bottom: 40px
    z-index: 7
    @include bp($tablet)
      left: 32px
      bottom: 32px
    @include bp($phone)
      left: 12px
      bottom: 12px
      max-width: calc(100% - 24px)
