.d-favorites
  display: flex
  flex-grow: 1
  flex-direction: column
  padding-top: 56px
  @include bp($phone)
    padding-top: 40px
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    margin-bottom: 40px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
      margin-bottom: 32px
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    @include bp($tabletS)
      flex-direction: column
      align-items: flex-start
      gap: 32px 0
    @include bp($phone)
      row-gap: 24px
  &__controls
    display: flex
    align-items: center
    column-gap: 24px
    @include bp($tablet)
      column-gap: 16px
    @include bp($tabletS)
      width: 100%
      justify-content: space-between
    @include bp($phone)
      column-gap: 8px
  &__sorter
    width: 312px
    @include bp($phone)
      flex-grow: 1
      width: auto
    @include bp($phoneXS)
      max-width: 240px
  &__tabs
    @include bp($phone)
      width: 100%
    & .d-tabs
      &__buttons
        margin: 0
        @include bp($phone)
          width: calc(100% + 24px)
          padding: 0 12px
          margin: 0 -12px
      &__buttonsInner
        @include bp($phone)
          display: inline-flex
          width: auto
      &__button[data-count]:after
        content: attr(data-count)
        display: flex
        align-items: center
        justify-content: center
        width: 24px
        height: 24px
        margin-left: 8px
        background: $blue-color
        border-radius: 50%
        font-weight: 400
        @include font-size-lh(12,14)
        color: $white-color
      &__button[data-count]._active:after
        background: $white-color
        color: $pen-color
  &__content
    display: flex
    flex-grow: 1
    flex-direction: column
    border-top: 1px solid $grey20
    margin-top: 40px
    @include bp($desktopXS)
      margin-top: 32px
    @include bp($phone)
      margin-top: 24px
    &._hide
      display: none
  &__section
    border-top: 1px solid $grey20
    padding-top: 64px
    margin-top: 48px
    @include bp($desktopXS)
      padding-top: 48px
    @include bp($phone)
      padding-top: 24px
      margin-top: 24px
    &:first-child
      border: none
      margin-top: 0
  &__bottom
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 32px
    margin-top: 160px
    @include bp($desktopS)
      margin-top: 120px
    @include bp($desktopXS)
      margin-top: 104px
    @include bp($tablet)
      grid-column-gap: 24px
      margin-top: 96px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-gap: 24px 0
    @include bp($phone)
      grid-row-gap: 12px
      margin-top: 64px
