.d-purchase-popup
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  max-width: 1216px
  width: 100%
  background: $white-color
  border-radius: 32px
  color: $pen-color
  z-index: 100
  opacity: 0
  visibility: hidden
  @include transition(all)
  @include bp($tablet)
    max-width: 100%
    height: 100%
    border-radius: 0
    overflow: auto
  &._show, &._show + .d-purchase-popup-close
    opacity: 1
    visibility: visible
  &__top
    display: flex
    padding: 34px 40px
    border-bottom: 1px solid $grey20
    @include bp($tablet)
      flex-direction: column-reverse
      padding: 24px
    @include bp($phone)
      padding: 12px 12px 24px
  &__title
    flex-grow: 1
    font-weight: 600
    @include font-size-lh(40,44)
    margin-right: 24px
    @include bp($tablet)
      @include font-size-lh(32,36)
      margin-right: 0
    @include bp($phone)
      @include font-size-lh(20,24)
  &__logo
    flex-shrink: 0
    width: 112px
    height: 56px
    object-fit: contain
    @include bp($tablet)
      margin-bottom: 16px
    @include bp($phone)
      width: 96px
      height: 48px
      margin-bottom: 12px
  &__content
    display: flex
    @include bp($tablet)
      display: block
  &__info, &__form
    padding: 40px
    @include bp($tablet)
      padding: 32px 24px
    @include bp($phone)
      padding: 24px 12px
  &__info
    flex-grow: 1
  &__form
    flex-shrink: 0
    width: 601px
    border-left: 1px solid $grey20
    @include bp($tablet)
      width: 100%
      border-left: none
      border-top: 1px solid $grey20
  &__facts
    display: grid
    grid-template-columns: repeat(3, auto)
    grid-column-gap: 56px
    justify-content: flex-start
    @include bp($phone)
      grid-column-gap: 16px
  &__factLabel
    font-weight: 400
    @include font-size-lh(18,24)
    @include bp($phone)
      @include font-size-lh(14,18)
      margin-top: 4px
    color: $grey50
  &__factValue
    font-weight: 600
    @include font-size-lh(40,44)
    color: $blue-color
    @include bp($phone)
      @include font-size-lh(24,28)
  &__about
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 16px
  &__aboutRow
    display: grid
    grid-template-columns: 122px 1fr
    grid-column-gap: 16px
    font-size: 14px
    @include bp($tablet)
      grid-template-columns: 136px 1fr
    @include bp($phone)
      display: block
    &:first-child
      margin-top: 40px
      @include bp($tablet)
        margin-top: 32px
      @include bp($phone)
        margin-top: 16px
  &__aboutLabel
    font-weight: 600
    line-height: 18px
  &__aboutValue
    font-weight: 500
    line-height: 22px
    white-space: pre-wrap
    @include bp($phone)
      margin-top: 8px

.d-purchase-popup-close
  right: 32px
  top: 32px
  z-index: 101
  opacity: 0
  visibility: hidden
  @include bp($tablet)
    right: 24px
    top: 12px
    width: 48px !important
    height: 48px
  @include bp($phone)
    right: 12px
  &._desktop
    position: fixed
    @include bp($tablet)
      display: none
  &._mobile
    position: absolute
    opacity: 1
    visibility: visible
    @include bp('(min-width: 1280px)')
      display: none

.d-purchase-form
  &__title
    font-weight: 600
    @include font-size-lh(16,22)
    margin-bottom: 27px
    @include bp($tablet)
      margin-bottom: 24px
    @include bp($phone)
      margin-bottom: 16px
  &__fields
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px
    margin-bottom: 17px
    @include bp($phone)
      margin-bottom: 16px
  &__items
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-gap: 20px 0
  &__item
    &._button
      @include bp($phone)
        padding-top: 4px
    &._agree
      grid-column: 1/-1
      font-weight: 400
      @include font-size-lh(14,18)
      color: $grey50
      @include bp($phone)
        grid-column: unset
      & a
        color: $grey50
        text-underline-offset: 3px
        @include transition(color)
        @include hover
          color: $blue-color
    & .captcha-info
      color: $pen-color
      margin-top: 12px
      @include bp($tablet)
        margin-top: 16px
      & a
        color: $pen-color
        @include hover
          color: $blue-color
    & .d-select__head
      padding-right: 50px
