.d-company
  padding-top: 120px
  //
    padding-top: 137px
    @include bp($desktopS)
      padding-top: 121px
    @include bp($desktopXS)
      padding-top: 128px
  @include bp($tablet)
    padding-top: 87px
  @include bp($phone)
    padding-top: 32px
  &__top
    margin-bottom: 120px
    @include bp($desktopXS)
      margin-bottom: 104px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
  &__inner
    display: flex
    align-items: flex-start
    margin-bottom: 160px
    @include bp($desktopXS)
      margin-bottom: 120px
    @include bp($tablet)
      display: block
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 64px
  &__tabs
    flex-shrink: 0
    width: 280px
    margin: 0 32px 0 0
    position: sticky
    top: 184px
    @include transition(all)
    @include bp($desktopS)
      width: 227px
    @include bp($desktopXS)
      width: 176px
    @include bp($tablet)
      width: auto
      margin: 0 0 48px
      top: auto
    @include bp($tabletS)
      width: calc(100% + 48px)
      padding: 0 24px
      margin: 0 -24px 48px
    @include bp($phone)
      width: calc(100% + 24px)
      padding: 0 12px
      margin: 0 -12px 40px
    &._noHeader
      top: 76px
      @include bp($desktopXS)
        top: 32px
      @include bp($tablet)
        top: 0
    &.d-tabs__buttons
      &._show
        @include bp($tablet)
          position: fixed
          left: 0
          top: 0
          width: 100%
          height: 100%
          padding: 0
          margin: 0
          display: flex
          align-items: center
          justify-content: center
          background: rgba(0, 0, 0, 0.6)
          backdrop-filter: blur(20px)
          opacity: 1 !important
          z-index: 111
        & .d-tabs
          @include bp($tablet)
            &__buttonsInner
              max-width: 402px
              width: calc(100% - 24px)
              flex-direction: column
            &__button
              width: 100%
              &._active
                background: $pen-color
                color: $white-color
        & .d-company__close
          @include bp($tablet)
            display: flex
    & .d-tabs
      &__buttonsInner
        flex-direction: column
        align-items: flex-start
        row-gap: 12px
        padding: 0
        background: none
        @include bp($tablet)
          display: inline-flex
          flex-direction: row
          row-gap: 0
          width: auto
          padding: 8px
          background: $white-color
        @include bp($phone)
          padding: 4px
      &__button
        height: auto
        padding: 0

        @include font-size-lh(18,24)
        color: $grey50
        @include bp($desktopXS)
          @include font-size-lh(16,22)
        @include bp($tablet)
          height: 48px
          padding: 0 16px
          color: $pen-color
        @include bp($phone)
          height: 40px
          @include font-size-lh(14,18)
        &._active
          background: none !important
          color: $pen-color
          @include bp($tablet)
            background: $pen-color !important
            color: $white-color !important
        @include hover
          color: $blue-color
  &__content
    flex-grow: 1
    & .d-tabs__content
      @include transition(margin)
    &._showISButton
      padding-bottom: 1px
      & .d-company__contentInner
        @include bp($tablet)
          margin-top: -64px
          margin-bottom: 96px
        @include bp($phone)
          margin-top: -48px
          margin-bottom: 76px
  &__contentInner
    margin: 0
    @include transition(all)
  &__buttons
    display: flex
    justify-content: center
    position: sticky
    left: 12px
    top: 100%
    width: 100%
    height: 0
    overflow: hidden
    z-index: 11
    opacity: 0
    visibility: hidden
    @include transition(all)
    &._show
      @include bp($tablet)
        top: calc(100% - 96px)
        height: 64px
        opacity: 1
        visibility: visible
      @include bp($phone)
        top: calc(100% - 64px)
        height: 48px
  &__choose
  //
  &__up
    position: absolute
    right: 0
    bottom: 0
    width: 64px
    padding: 0
    @include bp($phone)
      width: 48px
    &:before, &:after
      display: none
    & img
      display: block
      transform: rotate(-90deg)
  &__close
    display: none
    position: fixed
    right: 24px
    top: 24px
    @include bp($phone)
      right: 12px
      top: 12px
.d-company-title
  font-weight: 600
  @include font-size-lh(64, 68)
  color: $blue-color
  @include bp($desktopXS)
    @include font-size-lh(52, 56)
  @include bp($phone)
    @include font-size-lh(36, 40)