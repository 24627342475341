.d-company-affiliate-item
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 0 32px
  padding: 40px
  background: $white-color
  border-radius: 32px
  color: $pen-color
  @include bp($desktopS)
    grid-template-columns: 445px 1fr
  @include bp($desktopXS)
    grid-template-columns: 344px 1fr
  @include bp($tablet)
    display: block
    padding: 32px
  @include bp($phone)
    padding: 24px 16px 16px
    border-radius: 24px
  &__link
    margin-top: 16px
    & a
      display: inline-block
      font-weight: 600
      @include font-size-lh(20,24)
      color: $blue-color
      text-underline-offset: 4px
      text-decoration-thickness: 1px
      text-decoration-color: transparent
      @include transition(all)
      position: relative
      @include bp($phone)
        @include font-size-lh(16,22)
        text-underline-offset: 3px
      @include hover
        text-decoration-color: $blue-color
      &:after
        content: ''
        position: absolute
        left: calc(100% + 4px)
        top: 4px
        width: 16px
        height: 16px
        background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
  &__text
    font-weight: 400
    @include font-size-lh(16,22)
    @include bp($tablet)
      margin-top: 24px
    @include bp($phone)
      margin-top: 16px
    & p
      margin: 0
      & + p
        margin-top: 1.5em
  &__logo
    & img
      display: block
      max-width: 264px
      @include bp($phone)
        max-width: 166px