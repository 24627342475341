.object-navs
  display: flex
  margin-left: 54px
  position: relative
  z-index: 5
  align-items: center
  flex-shrink: 0
  @include bp($desktopS)
    margin-left: 32px
  @include bp($phone)
    margin-top: 24px
  &._main
    margin-right: 32px
    @include bp($tablet)
      display: none
    & .object-navs__item._more
      &:last-child
        & .object-navs__sub
          left: 0
          transform: none
          @include bp($phone)
            left: 0
            right: unset
  &._right
    margin-left: auto
    @include bp($phone)
      margin-left: 0
    & .object-navs__item._more
      & .object-navs__sub
        @include bp($phone)
          left: 0
          right: unset
          transform: none
      &:last-child
        & .object-navs__sub
          left: unset
          right: 0
          transform: none
          @include bp($phone)
            left: 0
            right: unset
  &._tablet
    margin-left: auto
    @include bp($phone)
      display: none
    & .object-navs__sub
      right: 0
      top: calc(100% + 16px)
      left: unset
      transform: none
  &._mobile
    margin-left: 0
    position: absolute
    flex-direction: column
    align-items: flex-start
    display: none
    @include bp($phone)
      display: flex
      margin-top: 0
    & .object-navs__item
      margin-left: 0
      @include font-size-lh(16,18)
      margin-bottom: 16px
      &:last-child
        margin-bottom: 0
  &__item
    text-decoration: none
    flex-shrink: 0
    @include font-size-lh(16,19)
    color: $pen-color
    transition: opacity $transition
    position: relative
    @include bp($phone)
      @include font-size-lh(14,17)
    &:hover:not(._more)
      opacity: 0.6
      cursor: pointer
    & + .object-navs__item
      margin-left: 16px
      @include bp($desktopS)
        margin-left: 12px
    &._more
      display: flex
      align-items: center
      & + .object-navs__item._more
        margin-left: 32px
        @include bp($desktopS)
          margin-left: 28px
        @include bp($phone)
          margin-left: 12px
      &:after
        content: ''
        width: 12px
        height: 12px
        //position: absolute
        //left: calc(100% + 4px)
        //top: 50%
        transform: rotate(90deg)
        display: block
        background: url('../assets/img/icons/arrow.svg') no-repeat
        background-size: contain
        margin-left: 4px
      &:hover
        cursor: pointer
        &:after
          transform: rotate(270deg)
        & .object-navs__sub
          opacity: 1
          visibility: visible
    & span
      @include bp($phone)
        display: none
  &__sub
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
    background: $grey-light
    box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
    border-radius: 16px
    opacity: 0
    visibility: hidden
    transition: opacity $transition
    padding: 16px
    display: flex
    flex-direction: column
    & .object-navs__item
      white-space: nowrap
      margin-left: 0
      margin-bottom: 12px
      &:last-child
        margin-bottom: 0
      &:hover
        opacity: 0.6