.d-range-slider-single
  font-weight: 500
  @include font-size-lh(16,22)
  color: $grey50
  &__title
    font-weight: 400
    margin-bottom: 12px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    @include bp($phone)
      margin-bottom: 8px
    &-tip
      @include font-size-lh(16,22)
      margin-right: 16px
      @include bp($tabletS)
        margin-right: 0

  &__content
    display: flex
    align-items: center
    justify-content: space-between
    height: 64px
    padding: 0 24px
    background: $grey5
    border-radius: 12px
    position: relative
    @include bp($phone)
      height: 48px
      padding: 0 16px
    @include hover
      & .d-range-slider-single
        &__value
          color: $blue-color
  &__value
    flex-grow: 1
    background: none
    border: none
    outline: none
    width: 70%
    padding: 0
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include transition(color)
    @include bp($phone)
      @include font-size-lh(16,22)
  &__label
    flex-shrink: 0
    margin-left: 8px

  &__line
    position: absolute
    left: 26px
    bottom: 0
    max-width: calc(100% - 68px)
    height: 1px
    background: $blue-color
    @include bp($phone)
      left: 22px
      max-width: calc(100% - 60px)

  &__bullet
    position: absolute
    left: 100%
    top: 50%
    transform: translateY(-50%)
    width: 16px
    height: 16px
    background: $white-color
    border: 6px solid $blue-color
    border-radius: 50%
    @include transition(all)
    cursor: pointer
    @include hover
      background: $blue-color

  &__slider
    position: absolute!important
    left: 26px
    bottom: 0
    max-width: calc(100% - 56px)
    width: 100%!important
    height: 1px!important
    padding: 0!important
    @include bp($phone)
      left: 22px
      max-width: calc(100% - 44px)

    & .vue-slider-rail
      background: none

    & .vue-slider-process
      background: $blue-color
      transform: translateY(1px)

    & .vue-slider-dot-handle
      width: 16px
      height: 16px
      background: $white-color
      border: 6px solid $blue-color
      border-radius: 50%
      box-shadow: none
      @include transition(all)
      cursor: pointer
      @include hover
        background: $blue-color
