.ba-form
  width: 100%
  height: 100%
  padding: 80px 64px
  display: flex
  justify-content: center
  align-items: center
  display: none
  @include font-size-lh(16,18)
  @include bp($tablet)
    align-items: flex-start
  @include bp($phone)
    padding: 40px 16px
  &__inner
    display: none
    @include bp($phone)
      width: 100%
    &._success
      //
    &._error
      //
    &._active
      display: block
  &__title
    color: $pen-color
    @include font-size-lh(48,57)
    font-weight: 500
    margin: 0
    text-align: center
    @include bp($phone)
      @include font-size-lh(20,24)
  &__fields
    margin-top: 40px
  &__step
    margin-bottom: 32px
    position: relative
    &:last-child
      margin-bottom: 0
  &__inp
    display: block
    position: relative
    width: 100%
    margin: 0
    box-sizing: border-box
    border: none
    border-radius: 2.2em
    box-shadow: inset 1px 1px 2px 0 #d2dce9
    font-size: 16px
    text-align: left
    color: $pen-color
    background: $form-color
    padding: 24px 48px
    outline: none
    font-weight: 500
    &::placeholder
      color: rgba($pen-color, 0.5)

    & ~ .error
      position: absolute
      top: calc(100% + 4px)
      left: 48px
      color: $error
      z-index: 2
      font-style: normal
      background: $form-color
    &._textarea
      height: 100px
      width: 100%
      resize: none
  &__submit
    background-color: $submit-color
    //opacity: 0.5
    //pointer-events: none
    flex-shrink: 0
    transition: all $transition
    color: $white-color
    border-radius: 48px
    height: 54px
    width: 200px
    outline: none
    font-weight: 500
    border: none
    margin-right: 48px
    box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(200 235 172 / 90%), 2.5em 2.5em 5em rgb(140 214 81 / 40%), -3.45em -3.45em 6.9em #fff
    &._active
      opacity: 1
      pointer-events: all
    &:hover
      cursor: pointer
      box-shadow: inset 0.2em 0.2em 0.2em 0 rgb(200 235 172 / 90%), 1.5em 1.5em 3em rgb(140 214 81 / 30%), -2.4em -2.4em 4.8em rgba(#fff, 0.1)
    @include bp($phone)
      margin-right: 0
      width: 100%
      max-width: 240px
  &__bottom
    display: flex
    align-items: center
    margin-top: 48px
    @include bp($phone)
      flex-direction: column
  &__agree-icon

    width: 12px
    height: 12px
    border-radius: 50%
    border: 1px solid $brand-color
    display: flex
    align-items: center
    justify-content: center
    margin-right: 8px
    display: none
    @include bp($tablet)
      width: 20px
      height: 20px
    &:before
      content: ''
      width: 6px
      height: 6px
      display: block
      background: $brand-color
      border-radius: 50%
      opacity: 0
  &__agree
    color: $brand-color
    @include font-size-lh(12,14)
    font-weight: 500
    display: flex
    align-items: center
    @include bp($phone)
    margin-top: 16px
    &._active
      & .ba-form__agree-icon
        &:before
          opacity: 1
    &:hover
      cursor: pointer
    & a
      color: $brand-color
      font-weight: 500
      @include bp($tablet)
        text-decoration: underline
      &:hover
        text-decoration: none
        @include bp($tablet)
          text-decoration: underline