.d-visual-quarters
  &__slider
    margin-top: 32px
    position: relative
  &__section-btn
    position: absolute
    top: 40px
    z-index: 20
    padding: 13px 20px
    background: $grey5
    border-radius: 8px
    @include font-size-lh(16,22)
    outline: none
    border: none
    display: flex
    align-items: center
    color: $pen-color
    transition: all $transition
    &:hover
      cursor: pointer
      background: $pen-color
      color: $white-color
      & svg
        fill: $white-color
    & svg
      width: 12px
      height: 12px
      fill: $pen-color
      transition: all $transition
    &._prev
      left: 40px
      & svg
        margin-right: 8px
        transform: rotate(180deg)
    &._next
      right: 40px
      & svg
        margin-left: 8px