.d-phone-input
  &._error
    & .d-phone-input__inner
      border-color: $red !important
  &._qr
    & .d-phone-input__inner
      background-color: $grey5 !important
      border: 1px solid $white-color
  &__inner
    position: relative
    display: flex
    background-color: $white-color !important
    border: 1px solid $white-color
    border-radius: 12px
  &__code
    width: 100px
    flex-shrink: 0
    border-right: 1px solid $grey20