.d-input
  position: relative
  &._cashback
    & .d-input__error
      opacity: 0
      visibility: hidden
      transition: all $transition
      @include bp($tablet)
        opacity: 1
        visibility: visible
    &._error
      & .d-input__field:focus
        .d-input__error
          opacity: 1
          visibility: visible
      &:hover,
      &._focus
        .d-input__error
          opacity: 1
          visibility: visible
  &__label
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 4px
  &__field
    width: 100%
    height: 64px
    padding: 0 24px
    background-color: $white-color !important
    border: 1px solid $white-color
    border-radius: 12px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color!important
    outline: none
    @include transition(all)
    @include bp($phone)
      height: 48px
    @include hover
      border-color: $blue-color
      color: $blue-color!important
      &::placeholder
        color: $blue-color
    &::placeholder
      color: $grey50
      opacity: 1
    &._tel._filled, &._tel:focus,&._tel:hover, &._tel
      border: none !important
      outline: none
      //padding-left: 42px
    &._tel
      color: $pen-color
      height: 62px
      border-radius: 0 12px 12px 0
      @include bps($phone)
        height: 48px
      &::placeholder
        color: $pen-color
    &._tel:focus + .d-input__tel-prefix, &._tel._filled + .d-input__tel-prefix
      opacity: 1
  &._textarea
    & .d-input__field
      resize: none
      padding: 21px 24px
      height: 120px
      @include bp($phone)
        height: 144px
  &__error
    position: absolute
    right: 14px
    bottom: 100%
    background: $red
    border-radius: 8px 8px 0 0
    padding: 2px 4px
    font-weight: 500
    @include font-size-lh(12,14)
    color: $white-color
  &._error
    & .d-input__field
      color: $red !important
      border-color: $red
      &::placeholder
        color: $red
    & .d-input__tel-prefix
      color: $red
    & .d-phone-input__inner
      border-color: $red
  &__tel-prefix
    display: flex
    align-items: center
    width: 42px
    height: 64px
    padding: 0 24px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    position: absolute
    left: 0
    top: 0
    opacity: 0
    @include transition(all)
    @include bp($phone)
      height: 48px

  &:hover:not(._error) &__tel-prefix
      color: $blue-color

  &._grey
    .d-input
      &__field
        background-color: $grey5 !important

  &._done
    & .d-input
      &__field
        padding-right: 56px
        background-image: url('../assets/img/d/icon_done.svg')
        background-repeat: no-repeat
        background-position: calc(100% - 24px) center
        background-size: 24px
        @include bp($phone)
          padding-right: 48px
          background-position: calc(100% - 16px) center