.page-not-found
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  padding: 32px 12px 24px
  @include bp($phone)
    //padding: 144px 0 80px
  &__wrap
    display: flex
    flex-direction: column
    align-items: center
    @include bp($phone)
      max-width: 425px
      width: 100%
  &__media
    width: 592px
    height: 360px
    border-radius: 40px
    overflow: hidden
    position: relative
    will-change: transform
    @include bp($phone)
      width: 100% //296px
      height: auto //180px
      aspect-ratio: 1/0.61
      border-radius: 26px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: url('../assets/img/d/404_overlay.svg') no-repeat center / cover
      z-index: 2
      background-blend-mode: multiply
    & video
      @include objectFit(100%, cover)
  &__text
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    text-align: center
    margin: 32px 0 40px
    @include bp($phone)
      margin-bottom: 32px
    & a
      color: $pen-color
      text-decoration-thickness: 1px
      text-underline-offset: 3px
      @include transition(color)
      @include hover
        color: $blue-color
    & br
      @include bp($phone)
        display: none
  &__button
    width: 220px
    @include bp($phone)
      width: 208px
