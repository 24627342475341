.d-header
  //background: $background
  z-index: 35
  transition: transform $transition
  position: fixed
  top: 0
  left: 50%
  transform: translateX(-50%)
  max-width: 1920px
  width: 100%
  padding: 24px 40px
  @include bp($desktopXS)
    padding: 24px 32px
  @include bp($tablet)
    padding: 24px
  @include bp($phone)
    padding: 8px 12px
  &._menuOpen
    transform: translateX(-50%) !important
    @include bp($tablet)
      background: transparent
  &._hide
    transform: translateX(-50%) translateY(-100%)
  &._fixed
    //@include bp($tablet)
      position: fixed
      top: 0
      transform: translateY(0)
  &__inner
    width: 100%
    height: 80px
    padding: 0 32px 0 34px
    background: $white-color
    box-shadow: 0 0 20px rgba(49, 31, 120, 0.06)
    border-radius: 16px
    display: flex
    align-items: center
    justify-content: space-between
    @include bp($phone)
      height: 56px
      padding: 0 18px 0 16px
      border-radius: 12px
  &__left, &__right
    width: auto
    display: flex
    align-items: center
    @include bp($desktop)
      width: 25%
  &__left
    justify-content: flex-start
  &__right
    justify-content: flex-end
  &__burger
    margin-right: 32px
    @include bp($phone)
      display: none
    &._mobile
      display: none
      margin: 0 0 0 18px
      @include bp($phone)
        display: block
  &__logo
    position: relative
    & img
      display: block
      width: 140px
      height: 36px
      position: relative
      z-index: 2
      @include bp($desktopXS)
        width: 124px
        height: 32px
      @include bp($phone)
        width: 104px
        height: 27px
    &._ny
      &:before, &:after
        content: ''
        position: absolute
      &:before
        left: -10px
        top: -2px
        width: 28px
        height: 28px
        background: url('../assets/img/d/NY2024/snowhat.png') no-repeat center / contain
        z-index: 3
        @include bp($desktopXS)
          left: -10px
        @include bp($phone)
          left: -10px
          top: -4px
          width: 24px
          height: 24px
      &:after
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        width: 180px
        height: 80px
        background: url('../assets/img/d/NY2024/snowflakes.svg') no-repeat center / 180px 141px
        z-index: 1
        overflow: hidden
        pointer-events: none
        @include bp($phone)
          transform: translate(-49%,-50%)
          width: 123px
          height: 56px
          background-size: 123px 96px
  &__menu
    display: flex
    align-items: center
    margin: 0 auto
    position: relative
    z-index: 2
    @include bp($desktop)
      margin: 0
    @include bp($tablet)
      margin-left: 6.2%
    @include bp($tablet)
      display: none
    & a
      color: $pen-color
      text-decoration: none
      margin: 0 28px
      @include font-size-lh(16,19)
      @include transition(color)
      @include hover()
        color: $blue-color
      @include bp($desktopS)
        margin: 0 12px
  &__info
    display: flex
    flex-direction: column
    align-items: flex-end
    transition: all $transition
    @include bp($phone)
      display: none
  &__animateWrap
    height: 22px
    overflow: hidden
  &__animateInner
    display: flex
    flex-direction: column
    align-items: flex-end
    position: relative
    animation: 8s ease-in-out 0s infinite timeAnimate
  &__phone
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    text-decoration: none
    @include transition(color)
    @include hover()
      color: $blue-color
  &__time
    font-weight: 500
    @include font-size-lh(12,14)
    margin-bottom: 8px
    color: $grey50
    white-space: nowrap
  &__callMe
    font-weight: 600
    @include font-size-lh(16,22)
    color: $blue-color
    @include transition(color)
    @include hover()
      color: $pen-color
  &__icons
    display: flex
    align-items: stretch
    margin-left: 48px
    column-gap: 24px
    @include bp($desktopS)
      margin-left: 32px
      column-gap: 20px
    @include bp($phone)
      margin: 0
      column-gap: 16px
  &__icon
    width: 24px
    //margin-left: 24px
    @include bp($desktopS)
      //margin-left: 20px
    @include bp($phone)
      //margin-left: 16px
    &._phone
      display: none
      @include bp($phone)
        display: block
    &._search
      @include bp($phone)
        display: none
    &:after
      display: block
      background-color: currentColor
      content: ''
      mask-size: cover
      width: 24px
      height: 24px
    &._icon-search:after
      mask: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C12.9036 19 14.652 18.3351 16.0255 17.2249C16.0661 17.4016 16.1552 17.5694 16.2929 17.7071L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L17.7071 16.2929C17.5694 16.1552 17.4016 16.0661 17.2249 16.0255C18.3351 14.652 19 12.9036 19 11C19 6.58172 15.4183 3 11 3ZM5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11Z' /%3E%3C/svg%3E%0A")
    &._icon-favorite
      position: relative
    &._icon-favorite[data-count]:before
      position: absolute
      content: attr(data-count)
      display: flex
      background-color: $red
      color: $white-color
      border: 2px solid $white-color
      border-radius: 50%
      width: 18px
      height: 18px
      align-items: center
      justify-content: center
      @include font-size-lh(12,14)
      right: -14px
      top: -9px
      text-decoration: none
      z-index: 2
    &._icon-favorite:after
      mask: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.9984 7.14039C17.4864 5.19166 14.4515 5.51847 13.3891 7.74443C12.8315 8.91277 11.1685 8.91277 10.6108 7.74443C9.54845 5.51848 6.51357 5.19166 5.00161 7.1404L4.69226 7.53912C3.3632 9.25212 3.5551 11.695 5.13534 13.1794L12 19.628L18.8646 13.1794C20.4449 11.695 20.6368 9.25212 19.3077 7.53912L18.9984 7.14039ZM12 6.15848C13.9769 3.25705 18.329 3.01496 20.5785 5.9144L20.8879 6.31312C22.8493 8.84118 22.5661 12.4463 20.234 14.6371L13.2814 21.1684C13.2748 21.1745 13.2681 21.1808 13.2613 21.1872C13.1598 21.2827 13.0374 21.3978 12.9198 21.4883C12.781 21.5952 12.5726 21.7303 12.2888 21.786C12.0981 21.8234 11.9019 21.8234 11.7111 21.786C11.4274 21.7303 11.219 21.5952 11.0802 21.4883C10.9626 21.3978 10.8402 21.2827 10.7386 21.1872C10.7319 21.1808 10.7252 21.1745 10.7186 21.1684L3.76599 14.6371C1.43387 12.4463 1.15066 8.84119 3.11209 6.31313L3.42144 5.9144C5.671 3.01497 10.0231 3.25705 12 6.15848Z' /%3E%3C/svg%3E%0A")
    &._icon-lk:after
      mask: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z' /%3E%3Cpath d='M12 15C10.0805 15 8.19383 15.4738 6.63113 16.3732C5.06902 17.2722 3.88124 18.5702 3.33091 20.1106C3.1451 20.6307 3.41608 21.203 3.93617 21.3888C4.45626 21.5746 5.02851 21.3036 5.21432 20.7835C5.57558 19.7723 6.39653 18.8157 7.62872 18.1066C8.86033 17.3978 10.3974 17 12 17C13.6027 17 15.1397 17.3978 16.3713 18.1066C17.6035 18.8157 18.4245 19.7723 18.7857 20.7835C18.9715 21.3036 19.5438 21.5746 20.0639 21.3888C20.584 21.203 20.8549 20.6307 20.6691 20.1106C20.1188 18.5702 18.931 17.2722 17.3689 16.3732C15.8062 15.4738 13.9195 15 12 15Z' /%3E%3C/svg%3E%0A")
    &._icon-phone:after
      mask: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.9994 4.91481C18.5154 6.49099 18.6703 8.93954 17.353 10.696L16.1714 12.2714C14.9206 13.9391 13.4391 15.4206 11.7714 16.6714L10.196 17.853C8.43954 19.1703 5.99099 19.0154 4.41481 17.4994L6.99999 14.9142L8.02114 15.9354C8.48778 16.402 9.20067 16.5177 9.79093 16.2225C12.3578 14.9391 14.4391 12.8578 15.7225 10.2909C16.0177 9.70067 15.902 8.98778 15.4354 8.52114L14.4142 7.49999L16.9994 4.91481ZM18.3519 3.43765C17.6052 2.69103 16.3947 2.69103 15.6481 3.43765L13 6.08578C12.2189 6.86682 12.2189 8.13315 13 8.9142L13.7795 9.69373C12.7336 11.6388 11.1388 13.2336 9.19373 14.2795L8.4142 13.5C7.63315 12.7189 6.36682 12.7189 5.58577 13.5L2.93765 16.1481C2.19103 16.8947 2.19103 18.1052 2.93765 18.8519C5.21476 21.129 8.81974 21.3852 11.396 19.453L12.9714 18.2714C14.7908 16.9069 16.4069 15.2908 17.7714 13.4714L18.953 11.896C20.8852 9.31974 20.629 5.71476 18.3519 3.43765Z' fill='%2336187D'/%3E%3C/svg%3E%0A")

  & button
    padding: 0
    background: none
    border: none
    cursor: pointer

  &._national
    & .d-header
      &__title
        font-weight: 600
        @include font-size-lh(24,28)
        color: $blue-color
        text-align: center
        @include bp($tablet)
          width: 355px
          @include font-size-lh(20,24)
        @include bp($tabletS)
          width: 280px
          @include font-size-lh(16,22)
        @include bp($phone)
          display: none

.d-header-icon
  color: $pen-color
  outline: none
  @include transition(color)
  @include hover()
    color: $blue-color
  & svg
    width: 24px
    height: 24px
    fill: currentColor
  & img
    display: block
    width: 24px

@keyframes timeAnimate
  0%
    top: 0
  40%
    top: 0
  50%
    top: -100%
  90%
    top: -100%
  100%
    top: 0
