.d-press-pager, .d-search-pager
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  row-gap: 4px
  column-gap: 4px
  user-select: none
  width: fit-content

  &__inner
    display: flex
    flex-direction: row

  &__control
    height: 64px
    width: 64px
    flex-grow: 0
    flex-shrink: 0
    background: none
    border-radius: 12px
    border: 1px solid $pen-color
    color: $pen-color
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    @include transition(all)
    @include bp($phone)
      height: 40px
      width: 40px
      border-radius: 8px
    @include hover
      background-color: $pen-color
      color: $white-color
    & svg
      fill: currentColor

    &._next
      margin-left: 16px

    &._prev
      margin-right: 16px
      & svg
        transform: rotate(-180deg)

    & svg
      width: 24px
      height: 24px

    &._disabled
      background: $grey5!important
      border-color: $grey30
      color: $white-color!important
      pointer-events: none !important
      & svg
        fill: $grey30

  &__pagination
    display: flex
    flex-direction: row
    column-gap: 4px
    width: fit-content!important
    justify-content: flex-start
    margin: 0 auto


  &__pagination-bullet
    flex-shrink: 0
    height: 64px
    width: 64px
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    border-radius: 12px
    background-color: $white-color
    color: $pen-color
    cursor: pointer
    transition: background-color $transition, color $transition
    opacity: 1
    user-select: none
    @include hover
      background-color: $blue-color
      color: $white-color


    @include bp($phone)
      height: 40px
      width: 40px
      border-radius: 8px

    &._active, &._prev
      animation-timing-function: linear
      animation-iteration-count: 1
      animation-fill-mode: forwards
      animation-duration: 0.5s
      transition: none

    &._active
      animation-name: colorIn!important
      animation-delay: 0.25s
      pointer-events: none!important

    &._prev
      background-color: $blue-color
      color: $white-color
      animation-name: colorOut

  &__pagination-bullet:only-child
    display: flex!important

@keyframes colorIn
  0%
    color: $pen-color
    background: $white-color
  100%
    color: $white-color
    background: $blue-color

@keyframes colorOut
  0%
    color: $white-color
    background: $blue-color
  100%
    color: $pen-color
    background: $white-color
