.d-object-single-easy-to-buy-map
  height: 100%
  border-radius: 40px
  overflow: hidden
  will-change: transform
  @include bp($tablet)
    position: fixed
    left: 0
    top: 0
    width: 100%
    border-radius: 0
    z-index: 11
  &._hide
    @include bp($tablet)
      opacity: 0
      visibility: hidden
      @include transition(all)
  &__zoom
    @include bp($tablet)
      display: none
  &__tabs, &__close
    display: none
    @include bp($tablet)
      display: block
      position: absolute
      top: 24px
      z-index: 11
    @include bp($phone)
      top: 12px
  &__tabs
    left: 24px
    @include bp($phone)
      display: none
  &__close
    right: 24px
    @include bp($phone)
      right: 12px

.d-object-single-easy-to-buy-map-point
  position: absolute
  width: 48px
  height: 48px
  display: flex
  align-items: center
  justify-content: center
  background: $blue-color
  border-radius: 50%
  font-weight: 600
  @include font-size-lh(20,24)
  color: $white-color
  @include transition(all)
  cursor: pointer
  z-index: 11
  @include hover
    background: $pen-color
    &:after
      background: $pen-color
    & .d-object-single-easy-to-buy-map-point__toolpit
      opacity: 1
      visibility: visible
  &:after
    content: ''
    position: absolute
    left: 50%
    top: calc(100% + 4px)
    transform: translateX(-50%)
    width: 8px
    height: 8px
    background: $blue-color
    border: 2px solid $white-color
    border-radius: 50%
    @include transition(all)
  &__toolpit
    position: absolute
    left: 50%
    bottom: calc(100% + 8px)
    transform: translateX(-50%)
    width: 185px
    padding: 12px 16px
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    font-weight: 500
    @include font-size-lh(14,18)
    color: $blue-color
    text-align: center
    opacity: 0
    visibility: hidden
    @include transition(all)

.d-object-single-easy-to-buy-map-info
  position: absolute
  left: 40px
  top: 40px
  max-width: 380px
  width: 100%
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 24px
  z-index: 11
  @include bp($desktopS)
    left: 24px
    top: 24px
  @include bp($tablet)
    //display: none
  &._hide
    display: none
  &._contacts
    & .d-object-single-easy-to-buy-map-info
      &__title
        display: none
      &__content
        padding: 0 24px

  &__title
    padding: 32px
    margin-bottom: 4px
    border-bottom: 1px solid $grey20
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
  &__content
    padding: 0 32px 8px
    counter-reset: point
    max-height: 517px
    @include bps($desktopS)
      max-height: 416px
    & .simplebar-track.simplebar-vertical
      right: 4px !important
      & .simplebar-scrollbar:before
        top: 16px
        bottom: 16px
  &__address
    padding: 24px 24px 24px 40px
    background: url('../assets/img/d/arrow_pen.svg') no-repeat right 24px
    border-top: 1px solid $grey20
    position: relative
    cursor: pointer
    @include hover
      & .d-object-single-easy-to-buy-map-info__addressNum
        background: $pen-color
    &._active
      & .d-object-single-easy-to-buy-map-info__addressNum
        background: $pen-color
    &:first-child
      border: none
  &__addressNum
    position: absolute
    left: 0
    top: 22px
    width: 28px
    height: 28px
    padding-top: 1px
    display: flex
    align-items: center
    justify-content: center
    background: $blue-color
    border-radius: 50%
    font-weight: 600
    @include font-size-lh(16,22)
    color: $white-color
    @include transition(all)
    cursor: pointer
  &__addressTitle
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
  &__addressText
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
    margin-top: 4px