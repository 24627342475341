.d-commercial-map
  height: 100%
  background: $grey20
  position: relative
  @include bp($tablet)
    position: fixed
    left: 0
    top: 0
    width: 100%
    z-index: 99
  &__close
    position: absolute
    right: 24px
    top: 24px
    z-index: 11
    display: none
    @include bp($tablet)
      display: flex
    @include bp($phone)
      right: 12px
      top: 12px
      width: 48px
      height: 48px