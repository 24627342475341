.skeleton-field
  height: 44px
  margin: 20px 0 12px
  background: #F2F3F7
  border-radius: 12px
  position: relative
  @include bp($phone)
    height: 36px
    margin: 0
  &:before
    display: inline-block
    content: " "
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    background-image: linear-gradient(269.98deg, #F2F3F7 0.01%, #FBFBFB 48.25%, #F2F3F7 99.98%)
    background-repeat: no-repeat
    background-size: 250px 100%
    border-radius: 12px
    animation: shimmer 2s linear infinite
    animation-delay: 0.4s
  &._field1
    width: 168px
  &._field2
    width: 144px
  &._field3
    width: 320px
    margin: 20px 0 42px
    @include bp($tabletS)
      margin: 20px 0 0
    @include bp($phone)
      width: 100%
      margin: 4px 0 24px
@keyframes shimmer
  0%
    background-position: -500px 0
  100%
    background-position: 500px 0

.skeleton-button
  pointer-events: none
  &__loader
    position: absolute !important
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 8px
    height: 8px
    border-radius: 50%
    display: block !important
    color: #fff
    animation: dots 1.8s linear infinite

.skeleton-text
  height: 22px
  width: 165px
  margin: 20px 0 12px
  pointer-events: none
  position: relative
  border-radius: 12px
  &:before
    position: absolute
    left: 0
    top: 0
    content: " "
    height: 100%
    width: 100%
    background-image: linear-gradient(269.98deg, #F2F3F7 0.01%, #FBFBFB 48.25%, #F2F3F7 99.98%)
    background-repeat: no-repeat
    background-size: 250px 100%
    border-radius: 12px
    animation: shimmer 2s linear infinite
    animation-delay: 0.4s

.d-tabs__button.skeleton-field
  pointer-events: none
  margin: unset
  min-width: 116px
  &:before
    left: 12px
    width: calc(100% - 24px)

.skeleton-slide
  background: #eee
  background: linear-gradient(90deg, #FBFBFB 8%, #F2F3F7 18%, #FBFBFB 33%)
  border-radius: 5px
  background-size: 200% 100%
  animation: 1.5s shine linear infinite

@keyframes shine
  to
    background-position-x: -200%

@keyframes dots
  0%
    box-shadow: 10px 0 0 -1px,  30px 0 0 -1px,  -10px 0 0 -1px,  -30px 0 0 -1px
  15%
    box-shadow: 10px 0 0 -1px,  30px 0 0 -1px,  -10px 0 0 -1px,  -30px 0 0 1px
  50%
    box-shadow: 10px 0 0 -1px,  30px 0 0 -1px,  -10px 0 0 1px,  -30px 0 0 -1px
  75%
    box-shadow: 10px 0 0 1px,  30px 0 0 -1px,  -10px 0 0 -1px,  -30px 0 0 -1px
  100%
    box-shadow: 10px 0 0 -1px,  30px 0 0 1px,  -10px 0 0 -1px,  -30px 0 0 -1px
