.offices-contacts-card-active
  display: none
  &._show
    display: block
  &__back
    background: transparent
    border: none
    color: $pen-color
    padding: 0
    @include font-size-lh(14,16)
    font-weight: 500
    display: flex
    align-items: center
    &:hover
      cursor: pointer
    & svg
      width: 12px
      height: 12px
      transform: rotate(180deg)
      margin-right: 4px
  &__tabs
    margin-top: 16px
    display: flex
    flex-wrap: wrap
    @include bp($phone)
      margin-top: 0
  &__tab
    background: #F3F4F8
    box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.7), inset 2px 1px 0px #FEFEFE
    border-radius: 46px
    padding: 12px 16px
    border: none
    color: $pen-color
    @include font-size-lh(14,17)
    font-weight: 600
    margin-right: 16px
    margin-bottom: 16px
    transition: color $transition, background-color $transition
    &:last-child
      margin-right: 0
    &:hover,
    &._active
      cursor: pointer
      background: #35B8FC
      color: $white-color
  &__title
    margin-top: 8px
    @include font-size-lh(24,28)
    font-weight: 600
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(18,21)
  &__phone
    @include font-size-lh(24,28)
    font-weight: 600
    color: $brand-color
    margin-bottom: 8px
    margin-top: 88px
    @include bp($tablet)
      margin-top: 24px
    @include bp($phone)
      margin-top: 16px
      @include font-size-lh(14,28)
  &__address,
  &__time,
  &__text
    @include font-size-lh(14,22)
    font-weight: 600
    color: $dark-grey
    opacity: 0.7
  &__text
    margin-top: 24px
  &__content
    display: none
    &._active
      display: block
  &__input
    //display: none

    width: 100%
    display: block
    background: #EDEFF3
    box-shadow: inset -2px -2px 3px #EBEDF2, inset 2px 1px 3px #D9E0EA
    border-radius: 46px
    padding: 12px 16px
    border: none
    @include font-size-lh(14,16)
    color: #8F87B5
    font-weight: 500
    margin-top: 8px
    outline: none
    width: 100%
    &::placeholder
      color: #8F87B5
  &__search
    position: relative
    max-width: 440px
  &__location
    width: 24px
    height: 24px
    background: transparent
    border: none
    outline: none
    position: absolute
    top: 50%
    right: 16px
    transform: translateY(-50%)
    & svg
      fill: $brand-color
      width: 24px
      height: 24px
  &__buttons
    display: flex
    margin-top: 16px
    & a
      display: block
      margin-right: 16px
      &:last-child
        margin-right: 0