.buy-easy
  font-family: 'Gilroy', Helvetica, Arial, sans-serif
  margin-top: 101px
  @include bp($tablet)
    margin-top: 80px
  @include bp($phone)
    margin-top: 60px
  &__title
    color: #311F78
    @include font-size-lh(38,45)
    font-weight: 500
    margin-left: 167px
    @include bp($tablet)
      margin-left: 0
      @include font-size-lh(25,30)
    @include bp($phone)
      @include font-size-lh(18,22)
  &__subtitle
    margin-top: 20px
    font-weight: 700
    color: #767E94
    margin-left: 167px
    @include font-size-lh(14,16)
    @include bp($tablet)
      margin-left: 0
      margin-top: 16px
      @include font-size-lh(19,22)
      @include bp($phone)
        font-weight: 500
        @include font-size-lh(14,16)
  &__list
    margin-top: 40px
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: 36px
    @include bp($tablet)
      gap: 24px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-row-gap: 24px

.buy-easy-item
  background: #F2F3F7
  box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  border-radius: 20px
  padding: 24px 24px 24px 128px
  position: relative
  transition: box-shadow $transition
  @include bp($tablet)
    display: grid
    grid-template: repeat(2, 1fr) / 80px auto
    padding: 24px
  @include bp($tabletS)
    padding-left: 128px
    display: block
    min-height: 128px
  @include bp($phone)
    padding: 16px 16px 16px 72px
  &__title
    font-weight: 600
    display: flex
    align-items: center
    @include font-size-lh(24,28)
    color: $brand-color
    @include bp($tablet)
      margin-left: 16px

    @include bp($tabletS)
      margin-left: 0
      display: block
    @include bp($phone)
      @include font-size-lh(14,18)
  &__text
    font-weight: 600
    @include font-size-lh(14,20)
    color: #6D758D
    margin-top: 8px
    @include bp($tablet)
      width: 100%
      margin-top: 16px
      grid-area: 2 / 1 / auto / span 2
    @include bp($phone)
      @include font-size-lh(14,17)
      font-weight: 500
      margin-top: 4px
  &__icon
    position: absolute
    left: 24px
    top: 24px
    @include bp($tablet)
      position: static
    @include bp($tabletS)
      position: absolute
    @include bp($phone)
      left: 16px
      top: 16px

  &__link
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1

