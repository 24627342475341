.d-object-single-gallery
  position: relative
  &__title
    @include bps($tablet)
      max-width: calc(100% - 48px)
    @include bps($phone)
      max-width: calc(100% - 24px)
  &__tabs
    position: absolute !important
    right: 0
    top: 4px
    @include bp($tablet)
      width: calc(100% + 48px)
      padding: 0 24px
      margin: 0 -24px 24px
      position: relative !important
      top: 0
    @include bp($phone)
      width: auto
      padding: 0 12px
      margin: 0 -12px 16px
    & .d-tabs__buttonsInner
      display: inline-flex
      width: auto
  &__slider
    height: 760px
    border-radius: 40px
    overflow: hidden
    will-change: transform
    position: relative
    @include bp($desktopS2)
      height: 560px
    @include bp($tablet)
      height: 480px + 56px
      margin: 0 -24px
      border-radius: 0
    @include bp($phone)
      height: 312px + 56px
      margin: 0 -12px
