.chess-section
  position: relative
  flex-shrink: 0
  user-select: none
  @include bp($phone)
    padding-left: 45px
    overflow-x: auto
    -webkit-overflow-scrolling: touch
    position: static
    width: 100%
    display: flex
    flex-wrap: wrap
    padding-right: 17px
    padding-top: 40px
  &__border
    border-radius: 28px 28px 0 0
    border: 1px solid black
    border-bottom: none
    padding: 4px 4px 0 4px
  &__inner
    padding: 24px
    background: $white-color
    position: relative
    display: flex
    border-radius: 24px 24px 0 0
    border-bottom: none
    @include bp($phone)
      padding: 24px
      position: static
  &__num
    @include font-size-lh(20,24)
    color: $pen-color
    margin-bottom: 24px
    font-weight: 600
    text-align: center
    //opacity: 0
    //visibility: hidden
    transition: all $transition
    @include bp($tablet)
      margin-bottom: 22px
      //display: none
    @include bp($phone)
      @include font-size-lh(16,22)
      margin-bottom: 18px
      position: absolute
      left: 50%
      top: 0
      transform: translateX(-50%)
      //display: none
    &._active
      opacity: 1
      visibility: visible
  &__floors
    position: absolute
    left: 0
    top: 0
    transform: translateX( calc(-100% - 10px - 16px) )
    height: 100%
    transition: all .45s cubic-bezier(.4, 0, 0, 1)
    padding-top: 24px
    &._desktop
      @include bp($phone)
        display: none
    &._mobile
      display: none
      @include bp($phone)
        // transform translateX( calc(-100%) )
        left: 0px
        background: $background
        position: absolute
        top: 40px
        z-index: 2
        width: 45px
        transform: none
        display: flex
        flex-direction: column
        align-items: center

  &__floor
    margin-bottom: 4px
    display: flex
    align-items: center
    font-size: 14px
    line-height: 18px
    height: 32px
    justify-content: flex-end
    transition: all $transition
    position: relative
    color: $grey50
    //@include hover_none
    //  opacity: 1
    &:last-child
      margin-bottom: 0
    &._hovered
      color: $blue-color
    &._title
      margin-bottom: 17px
      font-size: 10px
      font-weight: 600
      text-transform: uppercase
      opacity: 1
    &._active
      opacity: 1
  &__flats
    position: relative
    box-sizing: border-box

  &__row
    display: flex
    margin-bottom: 4px
    &:last-child
      margin-bottom: 0
  &__cell
    width: 32px
    height: 32px
    box-sizing: border-box
    background: transparent
    font-size: 16px
    line-height: 22px
    font-weight: 600
    color: $grey30
    display: flex
    justify-content: center
    align-items: center
    margin-right: 8px
    transition: all .45s cubic-bezier(.4, 0, 0, 1)
    position: relative
    overflow: hidden
    border-radius: 6px
    &:last-child
      margin-right: 0
    &._commercial
      width: 100%
      background: $grey5
      color: $grey30
      border-radius: 6px
      height: 32px
      margin: 4px 0
      pointer-events: none
    &._active
      border: 1px solid $blue-color
      background: $white-color
      & .chess-section__room
        background: $pen-color
        color: $white-color
  &__room
    box-sizing: border-box
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 32px
    height: 32px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 6px
    transition: all $transition
    background: $white-color
    border: 1px solid $grey30
    color: $pen-color
    overflow: hidden
    outline: none
    &._special
      border-color: $blue-color
      background: $blue-color
      color: $grey5
    &._disabled
      background: $grey5
      border-color: $grey5
      color: $grey30
      pointer-events: none
      cursor: default

    &:hover
      @include hover
        background: $pen-color
        color: $white-color
        cursor: pointer
    &._commercial
      background: $grey5
      border-color: $grey5
      color: $grey30
      pointer-events: none
      cursor: default
      &:hover
        background: $grey5
        color: $grey5
        cursor: default
  &__link
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 100
  &__info
    width: 312px
  &__popup
    position: absolute
    bottom: 0
    left: 50%
    transform: translate(-50%, 100%)
    z-index: 20
    width: 638px
    opacity: 0
    visibility: hidden
    transition: all $transition
    &._floor
      width: 466px
      @include bp($phone)
        width: 100%
    &._show
      opacity: 1
      visibility: visible
      transform: translate(-50%, 0)
      @include bp($phone)
        transform: translate(0, 0)
    @include bp($phone)
      width: 100%
      left: 0
      transform: translate(0, 100%)
      position: fixed
