.d-index-filter-choose
  display: flex
  justify-content: space-between
  border-top: 1px solid $grey20
  padding-top: 29px
  margin-top: 32px
  min-height: 81px
  column-gap: 41px
  &._objects
    align-items: flex-start
    column-gap: 41px
    & .d-index-filter__clear
      margin-top: 12px
      @include bp($tablet)
        margin-top: 0
  &__list
    display: flex
    flex-grow: 1
    flex-wrap: wrap
    gap: 3px
  &__count
    flex-shrink: 0
    padding-top: 13px
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    &:last-child
      align-self: flex-start
    &._objects
      font-weight: 400
      @include bp($tablet)
        padding-top: 0
        margin-top: 24px
        margin-bottom: 24px
      @include bp($phone)
        margin-bottom: 16px
  &._popup
    display: none
    @include bp($tablet)
      flex-direction: row
      border-top: none
      padding-top: 0
      margin-top: 13px
    &._active
      @include bp($tablet)
        display: flex

  &._tablet
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    padding-top: 32px
    margin-bottom: 32px
    gap: 23px
    @include bp($phone)
      margin-top: 96px
      margin-bottom: 0
    .d-index-filter-choose__count
      flex-grow: 1
      padding-top: 0
    .d-index-filter__clear
      flex-grow: 0
    .d-index-filter-choose__list
      flex-grow: 1
      flex-basis: 100%

.d-index-filter-choose:not(._tablet)
  @include bp($tablet)
    display: none
