.d-object-header
  padding: 8px 40px 0
  transition: all $transition
  position: fixed
  top: 0
  width: 100%
  left: 0
  transform: translateY(128px)
  z-index: 23
  opacity: 0
  &._loaded
    opacity: 1
  @include bp($desktopS)
    padding: 8px 32px 0
  @include bp($tablet)
    display: none
    padding: 0 24px
  @include bp($phone)
    padding: 0 12px
    transform: translateY(80px)
  &._fixed
    transform: translateY(24px)
    z-index: 25
    padding-top: 0
    @include bp($tablet)
      margin: 0
    @include bp($phone)
      display: none
    &._offset
      transform: translateY(112px)
    & .d-object-header
      &__inner
        background: $white-color
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
        border-radius: 16px
        padding: 16px 16px 16px 32px
        @include bp($tabletS)
          justify-content: space-between
          padding: 12px 12px 12px 24px
      &__back
        display: none
      &__button
        display: flex
    & .d-object-navs
      &._main
        @include bp($tablet)
          display: none
      &._right
        @include bp($tabletS)
          display: none
      &__item
        &._hide
          @media (max-width: 1610px)
            display: none
        &._show
          display: none
          @media (max-width: 1610px)
            display: block
      &__sub
        padding-top: 32px
  &._offset
    transform: translateY(128px)
    @include bp($tablet)
      //transform: none
    @include bp($phone)
      transform: translateY(80px)
    &:not(._stay)
      padding-bottom: 24px
      @include bp($tablet)
        padding-bottom: 32px
      & .d-object-header__back
        display: none
        @include bp($tablet)
          display: flex
  &._stay
    @include bp($tablet)
      display: block
    & .d-object-navs
      &__item
        &._hide
          @media (max-width: 1360px)
            display: none
          @include bp($tablet)
            display: block
        &._show
          display: none
          @media (max-width: 1360px)
            display: block
          @include bp($tablet)
            display: none
  &__inner
    display: flex
    align-items: center
    max-width: 1840px
    width: 100%
    margin: 0 auto
    position: relative
    @include bp($phone)
      flex-direction: column
      align-items: flex-start
  &__back
    display: flex
    align-items: center
    margin-right: 28px
    @include font-size-lh(14,16)
    color: $pen-color
    @include transition(color)
    text-decoration: none
    @include bp($phone)
      margin: 0 0 16px
    @include hover
      cursor: pointer
      color: $blue-color
    & svg
      transform: rotate(180deg)
      fill: currentColor
      width: 12px
      height: 12px
      margin-right: 4px
  &__name
    font-weight: 600
    @include font-size-lh(36,40)
    color: $pen-color
    margin: 0
    text-transform: uppercase
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 16px
  &__button
    display: none
    height: 48px
    margin-left: 40px
    flex-shrink: 0
