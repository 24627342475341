.d-object-single-gallery-slider
  & .swiper-lazy-preloader
    border-color: $blue-color
    border-top-color: transparent
  &.swiper
    height: 100%
  &._empty
    &._osAdvantages
      & .d-object-single-gallery-slider
        &__disclaimer
          @include bps($phone)
            left: 16px
    &._osGallery
      & .d-object-single-gallery-slider
        &__disclaimer
          @include bps($tablet)
            left: 16px
    & .d-object-single-gallery-slider
      &__pagination
        display: block
      &__slide.swiper-slide
        @include bp($phone)
          width: 100% !important
  &__slide
    &.swiper-slide
      will-change: transform
      position: relative
      &:before, &:after
        content: ''
        position: absolute
        left: 0
        width: 100%
        height: 200px
        background: linear-gradient(0deg, rgba($black, .6) 0%, rgba($black, 0) 100%)
        z-index: 1
        @include bp($tablet)
          display: none
      &:before
        display: none
        top: 0
        transform: rotate(180deg)
      &:after
        bottom: 0
    & img
      transition: all $transition
      @include objectFit(100%, cover)
      &.swiper-lazy
        opacity: 0
        visibility: hidden
      &.swiper-lazy-loaded
        opacity: 1
        visibility: visible
  &__paginationWrap, &__arrows
    position: absolute
    bottom: 40px !important
    z-index: 2
  &__paginationWrap
    left: 40px !important
    display: flex
    align-items: center
  &__pagination
    margin-right: 24px
    @include bp($tablet)
      margin-right: 32px
  &__disclaimer
    position: absolute
    left: 140px
    bottom: 40px
    z-index: 2
    @include bp($tablet)
      left: 132px
      bottom: 32px
    @include bp($phone)
      left: 16px
      bottom: 16px
      max-width: 160px
  &__arrows
    right: 40px
  &__show-gallery-button
    position: absolute
    top: 40px
    right: 40px
    width: 64px
    height: 64px
    border-radius: 50%
    overflow: hidden
    background-color: $white-color
    z-index: 2
    cursor: pointer
    @include transition(background-color)
    @include bp($tablet)
      top: 24px
      right: 24px
    @include bp($phone)
      top: 16px
      right: 16px
      width: 40px
      height: 40px
    & svg
      object-fit: contain
      *
        fill: #35B8FC
        @include transition(fill)
    @include hover
      background-color: $pen-color
      & svg
        *
          fill: #ffffff




  &._osAdvantages
    @include bp($phone)
      margin: 0 -12px
    @include bp($phone)
      padding: 0 12px 56px
    & .d-object-single-gallery-slider
      &__slide
        @include bp($phone)
          border-radius: 24px
          overflow: hidden
          will-change: transform
        &.swiper-slide
          @include bp($phone)
            width: 284px
          &:after
            @include bp($tablet)
              display: block
              height: 148px
            @include bp($phone)
              display: none
      &__paginationWrap, &__arrows
        @include bp($tablet)
          bottom: 32px !important
        @include bp($phone)
          bottom: 16px !important
      &__paginationWrap
        @include bp($tablet)
          left: 32px !important
        @include bp($phone)
          left: 28px !important
      &__paginationWrap
        @include bp($phone)
          left: 50% !important
          bottom: 0 !important
          transform: translateX(-50%)
          margin: 0
      &__pagination
        @include bps($phone)
          margin: 0
      &__arrows
        @include bp($phone)
          display: none
      &__arrows
        @include bp($tablet)
          right: 32px
  &._osGallery
    @include bp($tablet)
      padding: 0 24px 56px
    @include bp($phone)
      padding: 0 12px 56px
    & .d-object-single-gallery-slider
      &__slide
        border-radius: 40px
        overflow: hidden
        @include bp($phone)
          border-radius: 24px
        &.swiper-slide
          @include bp($tablet)
            width: 698px
          @include bp($phone)
            width: 284px
      &__paginationWrap
        @include bp($tablet)
          left: 50% !important
          bottom: 0 !important
          transform: translateX(-50%)
      &__pagination
        @include bp($tablet)
          margin: 0
      &__disclaimer
        @include bp($tablet)
          left: 32px
        @include bp($phone)
          left: 16px
      &__arrows
        @include bp($tablet)
          display: none

