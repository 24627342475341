.d-visual-header
  margin-top: 34px
  position: relative
  @include bp($phone)
    margin-top: 14px
  &__title
    margin-bottom: 16px
    @include font-size-lh(52,56)
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($tabletS)
      order: 1
      margin: auto 0
    @include bp($phone)
      @include font-size-lh(24,28)
  &__row
    display: flex
    align-items: center
    @include bp($tabletS)
      flex-wrap: wrap
    &._chess
      & .d-visual-header__choose
        @include bp($tablet)
          margin-left: auto
  &__legend
    @include bp($desktopS)
      order: 5
  &__choose
    @include bp($tabletS)
      order: 2
      margin-left: auto
    @include bp($phone)
      margin-left: 0
      margin-top: 24px
      order: 4
      width: 100%
    &._single
      margin-left: auto
  &__types,
  &__controls
    margin-left: auto
    margin-right: 40px
    @include bp($tabletS)
      order: 4
      margin-left: 0
      margin-right: 0
      width: 100%
      margin-top: 24px
    @include bp($phone)
      order: 3
    &._chess
      @include bp($tablet)
        display: none
  &__breadcrumbs
    &._desktop
      @include bp($tabletS)
        display: none
    &._tablet
      display: none
      @include bp($tabletS)
        display: flex
        flex-grow: 1
        width: 100%
        order: 3
        margin-top: 6px
        flex-wrap: wrap
      @include bp($phone)
        order: 2
        margin-top: 8px
