.d-faq
  padding-top: 56px
  position: relative
  @include bp($tablet)
    padding-top: 24px
  @include bp($phone)
    padding-top: 40px
  &__inner
    display: flex
    align-items: flex-start
    margin-bottom: 120px
    @include bp($tablet)
      display: block
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 64px
  &__item
    &._item1
      position: sticky
      top: 184px
      flex-shrink: 0
      width: 592px
      @include transition(top)
      @include bp($desktopS)
        width: 486px
      @include bp($desktopXS)
        width: 384px
      @include bp($tablet)
        position: relative
        top: 0
        width: 100%
        margin-bottom: 30px
        display: flex
        align-items: center
        justify-content: space-between
      @include bp($phone)
        flex-direction: column
        align-items: flex-start
        margin-bottom: 32px
      &._noHeader
        top: 32px
        @include bp($tablet)
          top: 0
    &._item2
      flex-grow: 1
      width: 100%
      padding-top: 10px
      margin-left: 32px
      @include bp($desktopXS)
        padding-top: 0
      @include bp($tablet)
        margin-left: 0
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    margin-bottom: 40px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
      margin-bottom: 32px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__sections
    //
  &__buttons
    display: flex
    justify-content: center
    position: sticky
    left: 12px
    top: 100%
    width: 100%
    height: 0
    overflow: hidden
    z-index: 11
    opacity: 0
    visibility: hidden
    @include transition(all)
    &._show
      @include bp($tablet)
        top: calc(100% - 96px)
        height: 64px
        opacity: 1
        visibility: visible
      @include bp($phone)
        top: calc(100% - 64px)
        height: 48px
  &__choose
    //
  &__up
    position: absolute
    right: 0
    bottom: 0
    width: 64px
    padding: 0
    @include bp($phone)
      width: 48px
    &:before, &:after
      display: none
    & img
      display: block
      transform: rotate(-90deg)
  &__note
    margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 32px
    @include bp($phone)
      @include font-size-lh(14,18)
      margin-bottom: 24px
  & .d-tabs
    &__buttons
      margin-bottom: 40px
      @include bp($desktopXS)
        margin-bottom: 32px
      @include bp($tablet)
        margin-bottom: 0
    &__content
      @include transition(margin)
      @include bp($tablet)
        margin-top: 0
  &._showISButton
    & .d-tabs
      &__content
        @include bp($tablet)
          padding-bottom: 1px
          margin-top: -64px
        @include bp($phone)
          margin-top: -48px
    & .d-faq-section
      &:last-child
        @include bp($tablet)
          margin-bottom: 96px
        @include bp($phone)
          margin-bottom: 76px
