.d-contacts-simple-office
  background: $white-color
  border-radius: 32px
  padding: 32px
  min-height: 280px
  display: flex
  flex-direction: column
  @include bp($tabletS)
    min-height: 273px
  @include bp($tabletS)
    min-height: unset
  @include bp($phone)
    border-radius: 24px
    padding: 20px
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(20,24)
  &__text
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-top: 8px
    @include bp($phone)
      @include font-size-lh(14,18)
    & p
      margin: 0
      & + p
        margin-top: 8px
  &__bottom
    position: relative
    margin-top: auto
    min-height: 89px
    display: flex
    flex-direction: column
    justify-content: flex-end
    @include bp($tabletS)
      margin-top: 24px
      padding-top: 24px
      min-height: unset
      flex-direction: row
      justify-content: space-between
      align-items: flex-end
    @include bp($phone)
      padding-top: 20px
      margin-top: 20px
      flex-direction: column
      align-items: flex-start
    &:before
      content: ''
      display: block
      width: calc(100% + 64px)
      height: 1px
      background: $grey20
      position: absolute
      left: -32px
      top: 0
      @include bp($phone)
        width: calc(100% + 40px)
        left: -20px
  &__phone
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    text-decoration: none
    transition: color $transition
    @include bp($tabletS)
      order: 2
    @include bp($phone)
      order: 1
    @include hover
      color: $pen-color
    & + .d-contacts-simple-office__time
      margin-top: 8px
      @include bp($tabletS)
        margin-top: 0
      @include bp($phone)
        margin-top: 8px
  &__time
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    @include bp($tabletS)
      order: 1
    @include bp($phone)
      order: 2