.d-callMe
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  max-width: 720px
  width: 100%
  border-radius: 40px
  @include transition(all)
  @include bp($phone)
    left: 0
    top: 0
    transform: none
    max-width: 100%
    height: 100%
    background: $background
    border-radius: 0
  &._show
    opacity: 1
    visibility: visible
    z-index: 99
  &__form, &__success
    &._hide
      display: none
  &__form, &__success
    @include bp($phone)
      height: 100%
      overflow: auto
    & .d-select__list
      @include bp($tablet)
        max-height: 124px
  &__form._regional &__top
    @include bp($phone)
      height: 256px

  &__top, &__success
    position: relative
    overflow: hidden
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 55%
      height: 100%
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
      z-index: 2
      @include bp($phone)
        width: 100%
  &__top
    height: 262px
    padding: 48px 48px 32px
    border-radius: 40px 40px 0 0
    @include bp($phone)
      height: 238px
      padding: 68px 12px 40px
      border-radius: 0
    &:before
      border-radius: 40px 0 0
      @include bp($phone)
        border-radius: 0
  &__bg
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    z-index: 1
  &__title, &__subtitle
    max-width: 370px
    font-weight: 600
    color: $white-color
    position: relative
    z-index: 3
    @include bp($phone)
      max-width: 290px
  &__title
    @include font-size-lh(52,56)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__subtitle
    font-weight: 400
    @include font-size-lh(18,21)
    margin-top: 16px
    margin-bottom: 61px
  &__content
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 24px
    grid-row-gap: 24px
    padding: 48px 48px 40px
    background: $background
    border-radius: 0 0 40px 40px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      max-width: 425px
      width: 100%
      margin: 0 auto
      padding: 32px 12px
      border-radius: 0
  &__item
    &._full
      grid-column-start: 1
      grid-column-end: 3
      @include bp($phone)
        grid-column-start: unset
        grid-column-end: unset
    &._item1
      @include bp($phone)
        order: 1
    &._item2
      @include bp($phone)
        order: 2
    &._item3
      @include bp($phone)
        order: 3
    &._item4
      @include bp($phone)
        order: 4
    &._item5
      grid-column: 1 / -1
      order: 6
    &._item6
      grid-column: 1 / -1
      order: 5
    & .captcha-info
      color: $pen-color
      margin: 16px 0 -8px
      & a
        @include hover
          color: $blue-color
  &__button
    max-width: 100%
    width: 100%
    position: relative
    z-index: 3
    @include bp($phone)
      max-width: 100%
    &._success
      margin-top: auto
      width: 300px
      @include bp($phone)
        width: 100%
  &__success
    min-height: 373px
    padding: 48px 48px
    border-radius: 40px
    display: flex
    flex-direction: column
    @include bp($phone)
      height: 100%
      padding: 68px 12px 48px
      border-radius: 0
    &:before
      border-radius: 40px 0 40px
      @include bp($phone)
        border-radius: 0
  &__close
    position: absolute
    right: 32px
    top: 32px
    z-index: 5
    @include bp($phone)
      right: 12px
      top: 12px

  &__agree
    label
      user-select: none
      font-weight: 400
    a, a:visited, span
      cursor: pointer
      color: $pen-color
      text-underline-offset: 3px
      text-decoration-thickness: 1px
      text-decoration: underline
      font-weight: 400
    &._national
      color: $pen-color
      font-weight: 400
      @include font-size-lh(16,22)
      span
        @include transition(color)
        @include hover
          color: $blue-color


  &._flatOrder
    & .d-callMe
      &__item
        &._item5
          grid-column: auto
          align-self: center
          @include bp($phone)
            order: 5
        &._item6
          @include bp($phone)
            order: 6

  &._contacts
    & .d-callMe
      &__title
        max-width: 100%
        @include bp($phone)
          max-width: 270px
      &__subtitle
        font-weight: 600
        margin: 72px 0 0
        @include bp($phone)
          max-width: 300px
          margin-top: 16px
      &__item
        &._item1, &._item4, &._item5, &._item6
          grid-column: -1/1
          @include bp($phone)
            grid-column: auto
        &._item5
          order: 5
        &._item6
          margin-top: -6px
          order: 6
          @include bp($phone)
            margin-top: 0
      &__success
        & .d-callMe__subtitle
          margin-top: 16px
