.d-object-single-utp-text
  display: flex
  padding: 40px
  background: $white-color
  border-radius: 32px
  position: relative
  @include bp($phone)
    flex-direction: column
    padding: 24px 16px 16px
    border-radius: 24px
  @include hover
    & .d-object-single-utp-text
      &__title
        color: $blue-color
      &__button
        color: $white-color
        &:before
          border-top-color: $white-color
          border-right-color: $white-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
  &.swiper-slide
    width: 592px
    min-height: 200px
    @include bp($desktopS)
      width: 488px
    @include bp($phone)
      width: 284px
  &._glow
    background: $blue-color url('../assets/img/d/index_installment_bg.png') no-repeat -380px -480px
    @include hover
      & .d-object-single-utp-text__button
        border-color: $pen-color
    & .d-object-single-utp-text__title
      color: $white-color
  &__title
    flex-grow: 1
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    margin-right: 16px
    @include transition(all)
    @include bp($desktopS)
      @include font-size-lh(24,28)
    @include bp($phone)
      max-height: 72px
      margin: 0
      overflow: hidden
  &__button
    flex-shrink: 0
    margin-top: auto
    @include bp($phone)
      margin: auto 0 0 auto
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
