.d-favorites-registration
  display: flex
  flex-direction: column
  row-gap: 32px
  height: 480px
  padding: 40px
  background: $blue-color url('../assets/img/d/purchase_about_bg.png') no-repeat -256px -256px
  border-radius: 32px
  color: $white-color
  position: relative
  @include bp($desktopXS)
    row-gap: 24px
    height: 418px
  @include bp($tablet)
    row-gap: 16px
    padding: 32px 32px 40px
  @include bp($tabletS)
    height: 290px
    padding-right: 40px
  @include bp($phone)
    row-gap: 12px
    height: auto
    padding: 20px
    border-radius: 24px
  @include hover
    & .d-favorites-registration__button
      color: $white-color
      &:before
        border-top-color: $white-color
        border-right-color: $white-color
      &:after
        transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
        opacity: 1
  &:after
    content: ''
    position: absolute
    left: 0
    bottom: 144px
    height: 1px
    width: 100%
    background: rgba($white-color, .5)
    @include bp($desktopXS)
      bottom: 128px
    @include bp($phone)
      bottom: 88px
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    @include bp($desktopXS)
      @include font-size-lh(32,36)
    @include bp($tablet)
      @include font-size-lh(24,28)
  &__text
    font-weight: 400
    @include font-size-lh(20,26)
    @include bp($tablet)
      @include font-size-lh(16,22)
    @include bp($phone)
      @include font-size-lh(14,18)
  &__button
    margin: auto 0 0 auto
    @include bp($phone)
      width: 100%
      margin-top: 30px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3