.d-share
  display: flex
  column-gap: 8px
  flex-shrink: 0
  position: absolute
  left: 50%
  bottom: calc(100% + 8px)
  transform: translateX(-50%)
  background: $white-color
  box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
  border-radius: 12px
  padding: 8px
  color: $blue-bright
  opacity: 0
  visibility: hidden
  margin: 0
  @include transition(all)
  z-index: 3
  &__item
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 32px
    height: 32px
    padding: 0
    background: $grey5
    border: none
    border-radius: 8px
    color: $blue-color
    cursor: pointer
    @include transition(color)
    outline: none
    @include hover()
      color: $pen-color
    & svg
      display: block
      width: 20px
      height: 20px
      fill: currentColor
    &._link
      background: $grey5 url('../assets/img/d/icon_done.svg') no-repeat center / 0
      &._active
        background-size: 20px
        & svg
          opacity: 0
        & .d-share__tooltip
          opacity: 1
          visibility: visible
  &__tooltip
    position: absolute
    right: 0
    bottom: calc(100% + 2px)
    padding: 5px 8px
    background: $white-color
    color: $green
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 8px
    font-weight: 500
    @include font-size-lh(12,14)
    opacity: 0
    visibility: hidden
    @include bp($phone)
      top: calc(100% + 2px)
      bottom: unset
    &._camera
      top: calc(100% + 2px)
      bottom: unset
