.d-cashback
  padding-top: 40px
  padding-bottom: 120px
  display: flex
  justify-content: center
  @include bp($desktopXS)
    padding-top: 24px
    padding-bottom: 72px
  @include bp($tablet)
    padding-top: 56px
    padding-bottom: 100px
  @include bp($phone)
    padding-top: 22px
    padding-bottom: 54px
  &__inner
    max-width: 700px
    width: 100%
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    text-align: center
    margin-bottom: 24px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
      margin-bottom: 16px
    @include bp($phone)
      @include font-size-lh(32,36)
      margin-bottom: 12px
  &__content
    position: relative
