.d-flat-plan
  width: 100%
  height: 100%
  margin: 0 auto
  //background: $red
  border-radius: 32px
  overflow: hidden
  position: relative
  @include bp($phone)
    border-radius: 16px
  @include hover
    cursor: pointer
    //& .d-flat-plan__zoom
      opacity: 1
      visibility: visible
  &__img
    height: 100%
  & img
    @include objectFit(100%, cover)
  &__zoom
    position: absolute
    border: 0
    outline: none
    right: 40px
    top: 0
    //
      left: 50%
      top: 50%
      transform: translate(-50%,-50%)
    width: 64px
    height: 64px
    background: $background url('../assets/img/d/icon_zoom.svg') no-repeat center / 20px
    //box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 50%
    //
      opacity: 0
      visibility: hidden
    z-index: 12
    cursor: pointer
    @include transition(all)
    @include bp($tablet)
      right: 32px
      top: 32px
    @include bp($tabletS)
      right: 0
      top: 24px
    @include bp($phone)
      right: 0
      top: auto
      bottom: 72px + 28px
      width: 48px
      height: 48px
      background-size: 18px
  &__tooltip
    position: absolute
    width: 164px
    padding: 8px 12px
    font-weight: 500
    @include font-size-lh(12,14)
    color: $white-color
    text-align: center
    background: $pen-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 8px
    z-index: 11
    display: none
    &:after
      content: ''
      position: absolute
      left: 100%
      top: 50%
      width: 25px
      height: 70px
      background: url('../assets/img/d/flat_image_tooltip_line.svg') no-repeat right center
    &._top
      &:after
        top: auto
        bottom: 50%
        transform: scale(1, -1)

  &__street
    position: absolute
    font-weight: 500
    @include font-size-lh(14,18)
    color: $grey50
    text-align: center
    @include bp($phone)
      @include font-size-lh(12,14)
    @include bp($phone360-)
      display: none
    &._top, &._bottom
      left: 0
      width: 100%
      //padding: 12px 0
      @include bp($phone)
        //padding: 10px 0
    &._top
      top: 0
      padding-bottom: 12px
    &._bottom
      bottom: 0
      padding-top: 12px
    &._left, &._right
      top: 0
      height: 100%
      writing-mode: tb-rl
      //padding: 0 12px
      padding-left: 12px
      @include bp($phone)
        //padding: 0 10px
    &._left
      left: 0
      transform: rotate(180deg)
    &._right
      right: 0
    &._eye
      font-size: 0
      line-height: 0

    &_eye
      cursor: pointer
      width: 32px
      height: 32px
      border-radius: 50%
      display: inline-flex
      transition: background-color $transition
      background-color: $white-color
      @include bp($phone)
        width: 24px
        height: 24px
      & svg
        width: 32px
        height: 32px
        object-fit: contain
        @include bp($phone)
          width: 24px
          height: 24px
        *
          transition: fill $transition
          fill: $pen-color
      @include hover
        background-color: #36187D
        & svg
          *
            fill: $background

  &._street
    max-width: 696px+(32px+12px)*2
    max-height: 456px+(32px+12px)*2
    padding: 32px+12px
    border-radius: 0
    overflow: unset
    @include bp($desktopS)
      max-width: 551px+(32px+12px)*2
      max-height: 363px+(32px+12px)*2
    @include bp($tablet)
      max-width: 824px+(32px+12px)*2
      max-height: 478px+(32px+12px)*2
    @include bp($tabletS)
      max-width: 568px+(32px+12px)*2
      max-height: 470px+(32px+12px)*2
    @include bp($phone)
      max-width: 425px+(24px+12px)*2
      max-height: 350px+(24px+12px)*2
      padding: 24px+12px
    @include bp($phone360-)
      max-height: 372px
      padding: 0
    & img
      object-fit: contain

  &._house
    & img
      @include bp($tabletS)
        width: auto
        object-fit: contain
    & .d-flat-plan
      &__img
        @include bp($tabletS)
          position: absolute
          right: 0
          top: 0
          //transform: translateX(14%)
          width: 100%
          overflow: auto
      &__tooltip
        display: block
      &__swipeInfo
        @include bp($tabletS)
          display: block
      &__disclaimer
        display: block

  &__disclaimer
    position: absolute
    left: 24px
    bottom: 24px
    z-index: 11
    display: none
    @include bp($tabletS)
      left: 20px
      bottom: 20px
    @include bp($phone)
      left: 12px
      bottom: 12px
      max-width: calc(100% - 24px)
  &__swipeInfo
    position: absolute
    left: 20px
    bottom: 20px
    max-width: calc(100% - 24px)
    z-index: 13
    display: none
    @include bp($phone)
      left: 12px
      bottom: 12px
  &__tip
    position: absolute
    top: 14px
    left: 14px
    background: #FFFFFF
    box-shadow: 0px 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    padding: 16px 20px
    color: #36187D
    font-weight: 400
    @include font-size-lh(16,22)
    @include bp($phone)
      @include font-size-lh(12,14)

  &__tip:before
    display: inline-block
    content: ''
    width: 12px
    height: 12px
    background: #35B8FC
    border-radius: 50%
    margin-right: 6px
    @include bp($phone)
      width: 8px
      height: 8px

.d-flat-plan-floor
  position: absolute
  left: 24px
  top: 24px
  width: 200px
  aspect-ratio: 1/1
  display: none
  @include bp($desktopS)
    left: auto //calc(100% - 129px)
    right: 0
    top: auto //411px
    bottom: 0
    width: 129px
  @include bp($tablet)
    left: auto
    right: 0
    top: auto
    bottom: 0
  @include bp($phone)
    //
      left: calc(100% - 104px)
      top: calc(100% - 104px)
    right: 50%
    transform: translateX(50%)
    width: 104px
  & img
    @include objectFit(100%, contain)
    max-width: 100% !important
    max-height: 100% !important
  &._zoom
    width: 200px
    left: auto
    right: 40px
    top: 50%
    transform: translateY(-50%)
    @include bp($tablet)
      width: 129px
    @include bp($tabletS)
      right: 50%
      top: auto
      bottom: 32px
      transform: translateX(50%)
    @include bp($phone)
      bottom: 12px
.modal-container._flatPlan
  & .pinch-scroll-zoom__content
    @include bp($tabletS)
      padding-bottom: 180px
    @include bp($phone)
      padding-bottom: 160px
  & .d-zoom-popup__image img
    max-width: 848px
    max-height: 848px
    margin: auto
    @include bp($desktopXS)
      max-width: 748px
      max-height: 748px
    @include bp($tablet)
      max-width: 554px
      max-height: 554px
    @include bp($tabletS)
      max-width: 586px
      max-height: 70% //57.6% //586px //
    @include bp($phone)
      max-width: 95%
      max-height: 70% //363px
  & .d-flat-plan-floor
    display: block
