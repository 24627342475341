.d-regional-utp
  @include bp($phone)
    padding: 0 12px!important
  &__cards
    display: grid!important
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 24px
    @include bp($desktopXS)
      grid-template-columns: repeat(2, 1fr)
    @include bp($tablet)
      grid-column-gap: 16px
    @include bp($tabletS)
      grid-template-columns: 1fr
      grid-row-gap: 16px
    @include bp($phone)
      display: flex!important
      column-gap: unset
      grid-gap: unset
      align-items: stretch

  &__card
    display: flex
    flex-direction: row
    align-items: center
    padding: 24px 32px
    column-gap: 24px
    min-height: 200px
    background: $white-color
    border-radius: 32px
    position: relative
    @include bp($desktopS)
      min-height: 232px
    @include bp($desktopXS)
      column-gap: 8px
      min-height: 160px
      padding: 20px 16px
      border-radius: 24px
    @include bp($tablet)
      min-height: 0
      padding: 24px 16px
    @include bp($phone)
      flex-direction: column
      row-gap: 12px
      align-items: flex-start
      padding: 16px 16px 32px
      height: 100%
      max-width: calc(100vw - 36px)
      flex-grow: 1
      align-self: stretch
    & a
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    &-img
      img
        display: block
        width: 88px
        height: 88px
        @include bp($desktopXS)
          width: 80px
          height: 80px
        @include bp($phone)
          width: 64px
          height: 64px
    &-content
      display: flex
      flex-direction: column
      row-gap: 12px
      @include bp($desktopXS)
        row-gap: 8px
    &-title
      color: $pen-color
      font-weight: 600
      @include font-size-lh(24,28)
      @include bp($desktopXS)
        @include font-size-lh(20,24)
    &-text
      font-weight: 400
      @include font-size-lh(16,22)
