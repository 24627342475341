.d-investment
  padding-top: 56px
  @include bp($tablet)
    padding-top: 40px
  &__top
    display: flex
    justify-content: space-between
    margin-bottom: 40px
    @include bp($tablet)
      margin-bottom: 56px
    @include bp($tabletS)
      margin-bottom: 34px
    @include bp($phone)
      display: block
      margin-bottom: 24px
  &__title
    flex-shrink: 0
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    margin-right: 190px
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($tablet)
      max-width: 310px
      margin-right: 32px
    @include bp($phone)
      @include font-size-lh(36,40)
      margin: 0 0 24px
  &__info
    max-width: 592px
    width: 100%
    font-weight: 400
    @include font-size-lh(20,26)
    color: $grey50
    @include bp($desktopS)
      max-width: 616px
    @include bp($desktopXS)
      width: calc(50% - 12px)
      @include font-size-lh(16,22)
    @include bp($phone)
      width: 100%
  &__advantages
    margin-bottom: 120px
    @include bp($tablet)
      margin-bottom: 90px
    @include bp($tabletS)
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 56px
  &__subtitle
    font-weight: 600
    @include font-size-lh(40,44)
    color: $pen-color
    margin-bottom: 40px
    @include bp($tabletS)
      margin-bottom: 24px
    @include bp($phone)
      @include font-size-lh(32,36)
      margin-bottom: 24px
  &__content
    display: flex
    align-items: flex-start
    position: relative
    margin-bottom: 160px
    @include bp($desktopXS)
      margin-bottom: 68px
    @include bp($tablet)
      flex-direction: column
      margin-bottom: 96px
    @include bp($phone)
      margin-bottom: 64px
    & .d-tabs
      &__buttons
        margin: 0 0 -8px
        @include bp($tablet)
          position: absolute
          right: 0
          bottom: calc(100% + 30px)
          margin: 0
        @include bp($tabletS)
          position: relative
          bottom: 0
          margin-bottom: 24px
      &__buttonsInner
        width: 100%
        @include bp($tablet)
          background: $white-color
      &__button
        width: calc(100% / 3)
        @include bp($desktopXS)
          width: auto
          padding: 0 21.5px
        @include bp($phone)
          padding: 0 16px
  &__item
    @include bp($tablet)
      width: 100%
    &._item1
      position: sticky
      top: 184px
      flex-shrink: 0
      width: 592px
      padding: 32px
      margin-right: 32px
      background: $white-color
      border-radius: 32px
      @include transition(top)
      @include bp($desktopS)
        width: 484px
      @include bp($desktopXS)
        width: 384px
        padding: 24px
      @include bp($tablet)
        position: relative
        top: auto
        width: 100%
        padding: 24px 24px 32px
        margin: 0 0 48px
      @include bp($tabletS)
        margin-bottom: 40px
      @include bp($phone)
        padding: 24px 16px
        margin-bottom: 32px
        border-radius: 24px
      &._noHeader
        top: 32px
    &._item2
      flex-grow: 1
  &__list
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 8px
    @include bp($phone)
      grid-row-gap: 12px
  &__more
    display: flex
    justify-content: center
    margin-top: 40px
    @include bp($phone)
      margin-top: 32px
  &__buttonFilterWrap
    position: sticky
    bottom: 24px
    left: 0
    z-index: 11
    width: 100%
    margin-top: 24px
    display: flex
    justify-content: center
    @include bp($phone)
      bottom: 16px
      margin-top: 16px
    &:empty
      display: none
  &__buttonFilter
    align-items: center
    justify-content: center
    padding: 0 24px
    background: $blue-color
    border: none
    border-radius: 12px
    font-weight: 400
    cursor: pointer
    width: 127px
    height: 64px
    z-index: 98
    animation: slideUp 0.5s normal forwards ease-in-out
    @include font-size-lh(16,22)
    color: $white-color
    text-decoration: none
    order: 1
    @include bp($tablet)
      display: flex
    @include bp($phone)
      width: 119px
      height: 48px
    & img
      width: 16px
      margin-right: 8px

@keyframes slideUp
    from
      opacity: 0
      transform: translateY(100%)
    to
      opacity: 1
      transform: translateY(0)
