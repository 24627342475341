.d-techno-works
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  padding-top: 40px
  @include bp($desktopXS)
    padding-top: 32px
  @include bp($tablet)
    padding-top: 24px
  @include bp($phone)
    padding-top: 12px
  &__top
    flex-shrink: 0
    width: 100%
    height: 480px
    background: url('../assets/img/d/techno_works.jpg') no-repeat center / cover
    border-radius: 40px
    will-change: transform
    position: relative
    overflow: hidden
    @include bp($desktopXS)
      height: 320px
    @include bp($tablet)
      height: 204px
      border-radius: 32px
    @include bp($tabletS)
      height: 420px
    @media (min-width: 768px) and (max-height: 901px)
      height: 204px
      border-radius: 32px
    @include bp($phone)
      height: 228px
      border-radius: 24px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: rgba($black, 0.3)
      z-index: 2
  &__video
    @include objectFit(100%, cover)
    position: relative
    z-index: 1
  &__logo
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    width: 514px
    //margin-bottom: 16px
    z-index: 3
    @include bp($desktopXS)
      width: 352px
      margin: 0
    @include bp($phone)
      width: 198px
    @media (min-width: 768px) and (max-height: 901px)
      width: 352px
      margin: 0
  &__content
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 90px 0
    text-align: center
    @include bp($desktopXS)
      padding: 48px 0
    @include bp($phone)
      padding: 10px 16px
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(24,28)
  &__info
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    margin-top: 24px
    @include bp($desktopXS)
      margin-top: 32px
    @include bp($phone)
      @include font-size-lh(14,18)
      margin-top: 8px
  &__phone
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-top: 56px
    @include bp($phone)
      @include font-size-lh(14,18)
      margin-top: 22px
    & span, & a
      display: block
      font-weight: 600
      @include font-size-lh(36,40)
      color: $pen-color
      text-decoration: none
      margin-top: 24px
      @include bp($phone)
        @include font-size-lh(24,28)
        margin-top: 8px
  &__social
    display: flex
    justify-content: center
    margin-top: 16px
    & [data-social-share-list]
      display: none