.d-object-single-location-types
  display: inline-flex
  background: $grey5
  border-radius: 12px
  padding: 13px 20px
  margin-bottom: 25px
  &._desktop
    @include bp($tablet)
      display: inline-flex
  &._tablet
    display: none
    @include bp($tablet)
      display: inline-flex
      position: absolute
      left: 16px
      top: 16px
      z-index: 3
    @include bp($phone)
      display: none
  &._mobile
    left: 12px
    top: 12px
    position: absolute
    z-index: 20
  &__item
    font-weight: 400
    font-size: 16px
    line-height: 22px
    display: flex
    align-items: center
    margin-right: 20px
    color: $pen-color
    @include bp($phone)
      @include font-size-lh(14,18)
    &:last-child
      margin-right: 0
    &:before
      content: ''
      width: 12px
      height: 12px
      border-radius: 50%
      display: block
      margin-right: 6px
    &._outside
      &:before
        background: $blue-color
    &._inside
      &:before
        background: #5131F5
