.ba-contacts-header
  display: flex
  flex-wrap: wrap
  position: relative
  @include bp($phone)
    width: calc(100% + 32px)
    margin-left: -16px
    padding-top: 8px
  &__title
    width: 100%
    margin: 0 0 32px 0
    @include bp($tablet)
      order: 1
      width: auto
      margin-bottom: 0
    @include bp($phone)
      width: 100%
      padding: 0 16px
  &__tabs
    display: flex
    @include bp($tablet)
      order: 3
      margin-top: 32px
    @include bp($phone)
      overflow-x: auto
      padding-bottom: 32px
      padding: 0 16px 32px 16px
  &__buttons
    display: flex
    margin-left: auto
    @include bp($tablet)
      order: 2
    @include bp($phone)
      flex-direction: column
      width: 100%
      margin-top: 16px
  &__button
    margin-right: 16px
    &:last-child
      margin-right: 0
    @include bp($phone)
      height: 41px
    &._forms
      width: 256px
      @include bp($tablet)
        width: 100%
        height: 48px
        max-width: 296px
      @include bp($phone)
        margin: 16px auto 0
        &:first-child
          margin-top: 0