.d-index-flat
  width: 100%
  height: 400px
  padding: 40px
  display: flex
  flex-direction: column
  justify-content: flex-end
  position: relative
  border-radius: 32px
  overflow: hidden
  will-change: transform
  @include bp($tablet)
    height: 364px
    padding: 128px 24px 24px
    border-radius: 24px
    justify-content: flex-start
  @include bp($phone)
    padding: 16px
    justify-content: flex-end
  @include hover
    & .d-index-flat
      &__img:not(.swiper)
        transform: scale(1.05)
      &__more
        width: 166px
        padding: 0 40px
        @include font-size-lh(16,22)
  &:not(._slider):before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.15) 100%)
    z-index: 2
  &__img
    position: absolute
    left: 0
    top: 0
    transition: transform $transitionSlow
    @include objectFit(100%, cover)
    z-index: 1
  &__inner
    font-weight: 600
    @include font-size-lh(16,22)
    color: $white-color
    position: relative
    z-index: 3
    @include bp($tablet)
      display: flex
      flex-grow: 1
      flex-direction: column
  &__name, &__title
    @include font-size-lh(40,44)
  &__name
    @include bp($tablet)
      @include font-size-lh(32,36)
    @include bp($phone)
      margin-top: auto
  &__title
    @include bp($tablet)
      @include font-size-lh(36,40)
    @include bp($phone)
      @include font-size-lh(24,28)
  &__subtitle
    font-weight: 500
    @include font-size-lh(20,24)
    margin-top: 24px
    @include bp($phone)
      @include font-size-lh(16,22)
      margin-top: 16px
  &__address
    margin-top: 20px
  &__metro
    margin-top: 8px
    @include bp($tablet)
      display: flex
      flex-direction: column
    & .d-metro
      @include bp($tablet)
        margin: 0 0 4px
  &__price
    @include font-size-lh(24,28)
    margin-top: 24px
    @include bp($tablet)
      margin-top: auto
    @include bp($phone)
      margin-top: 24px
  &__actions
    position: absolute
    left: 40px
    top: 40px
    display: flex
    z-index: 6
    @include bp($tablet)
      left: 24px
      top: 24px
    @include bp($phone)
      left: 16px
      top: 16px
    & .d-flat-action
      margin-right: 8px
    &._objects
      flex-wrap: wrap
      & .d-flat-action
        margin-bottom: 8px
  &__more
    position: absolute
    right: 40px
    top: 40px
    width: 64px
    height: 64px
    border: 1px solid $white-color
    border-radius: 12px
    display: flex
    align-items: center
    font-weight: 600
    @include font-size-lh(0,0)
    color: $white-color
    padding: 0 32px
    transition: all $transitionSlow
    z-index: 3
    @include bp($tablet)
      display: none
    @include bp($phone)
      right: 16px
      height: 48px
      padding: 0 16px
    &:before
      content: ''
      position: absolute
      right: 12px
      top: 12px
      width: 6px
      height: 6px
      border-top: 2px solid $white-color
      border-right: 2px solid $white-color
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    padding: 0
    background: none
    border: none
    cursor: pointer
    z-index: 4
  &._slider
    height: 656px
    padding: 0
    border-radius: 0
    @include bp($desktopXS)
      height: 480px
    @include bp($phone)
      height: 396px
    &._objects
      @include bp($phone)
        height: 419px
  &._exclusive
    height: 334px
    padding-right: 256px
    padding-bottom: 67px
    @include bp($desktopXS)
      padding-bottom: 44px
    @include bp($tablet)
      height: 296px
      padding: 24px 256px 24px 24px
    @include bp($phone)
      height: 364px
      padding: 16px
    @include hover
      & .d-index-flat__more
        border-color: $pen-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
    & .d-index-flat
      &__inner
        @include bp($tablet)
          justify-content: flex-end
      &__name
        @include font-size-lh(64,68)
        @include bp($desktopXS)
          @include font-size-lh(52,56)
        @include bp($tablet)
          @include font-size-lh(40,44)
          max-width: 456px
        @include bp($phone)
          @include font-size-lh(32,36)
      &__more
        top: auto
        bottom: 40px
        width: 166px
        @include font-size-lh(16,22)
        padding: 0 40px
        @include bp($tablet)
          display: flex
          right: 24px
          bottom: 24px
        @include bp($phone)
          position: relative
          right: 0
          bottom: 0
          margin-top: 32px
  &._exclusive-small
    @include bp($phone)
      padding-top: 136px
    @include hover
      & .d-index-flat__more
        border-color: $pen-color
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
    & .d-index-flat
      &__name
        width: 320px
        @include bp($phone)
          width: 260px
          margin-top: 0
      &__more
        top: auto
        bottom: 40px
        width: 166px
        @include font-size-lh(16,22)
        @include bp($tablet)
          right: auto
          left: 24px
          bottom: 24px
          display: flex
          width: 154px
          height: 48px
        @include bp($phone)
          left: 16px
          bottom: 16px
  &._callMe, &._choose
    height: 388px
    justify-content: flex-start
    @include bp($tablet)
      padding: 40px
      border-radius: 32px
    @include bp($tabletS)
      height: 312px
    @include bp($phone)
      height: 272px
      padding: 24px 16px
      border-radius: 24px
    & .d-index-flat
      &__more
        top: auto
        bottom: 40px
        width: auto !important
        padding: 0 40px
        @include font-size-lh(16,22)
        @include bp($tablet)
          display: flex
        @include bp($phone)
          bottom: 24px
          width: calc(100% - 32px) !important
          padding: 0 16px
    @include hover
      & .d-index-flat__more
        &:after
          transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
          opacity: 1
  &._callMe
    & .d-index-flat
      &__subtitle
        max-width: 332px
        @include bp($tabletS)
          max-width: 100%
  &._choose
    background: $blue-color url('../assets/img/d/index_installment_bg.png') no-repeat -250px -250px
    &:before
      display: none
    @include hover
      & .d-index-flat__more
        color: $white-color
        &:before
          border-color: $white-color
    & .d-index-flat
      &__inner
        @include bp($tablet)
          flex-grow: 0
        &:after
          content: ''
          position: absolute
          left: -40px
          top: calc(100% + 40px)
          width: calc(100% + 80px)
          height: 1px
          background: rgba($white-color, .5)
          @include bp($phone)
            left: -16px
            top: calc(100% + 24px)
            width: calc(100% + 32px)
  &._objects
    & .d-result-slide
      &__title
        font-weight: 600
        @include bp($phone)
          @include font-size-lh(32,36)
      &__address
        @include font-size-lh(20,24)
        @include bp($phone)
          @include font-size-lh(14,18)

