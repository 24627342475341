.d-popup-video-content-wrap
  &.mfp-bg
    background: rgba(0, 0, 0, 0.6)
    backdrop-filter: blur(10px)
    opacity: 1
  & .mfp-container
    padding: 0
  & .mfp-content
    @include bp($tablet)
      height: 100%
  & .mfp-close
    display: none
.d-popup-video-content
  background: $white-color
  //max-width: 720px
  margin: 32px auto
  padding: 32px

  position: relative
  display: flex
  flex-direction: column

  @include bp($tablet)
    margin: 0
    //min-height: 100%
    //max-width: 100%
    //width: 100%
    border-radius: 12px
    max-width: 422px
    width: 100%
    //height: 632px
    //height: 200px
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
  @include bp($phone)
    padding: 16px 12px
    height: 100%
    width: 100%
    border-radius: 0
  &__inner
    width: 100%
    overflow: hidden
    margin: auto
    @include bp($phone)
      height: 100%
      width: 100%
      margin: 0
  & .utp-title
    @include font-size-lh(20,24)
    margin: 0 0 32px 0
    font-weight: 600
    color: $blue-color
    @include bp($tablet)
      max-width: calc(100% - 80px)
    @include bp($phone)
      max-width: calc(100% - 60px)
      margin: 0 0 14px 0
      @include font-size-lh(18,24)
  &__item
    position: relative
    padding-left: 28px
    & img
      position: absolute
      left: 0
      top: 0
      width: 20px
      height: 20px
      object-fit: contain
    & .d-object-navs__item
      margin-bottom: 0
    @include bp($tablet)
      margin-top: 12px

      &:first-child
        margin-top: 0
  &__list
    @include bp($tablet)
      max-height: 532px
    @include bp($phone)
      //max-height: calc(100vh - 128px)
      max-height: calc(100% - 64px)
  &__title
    color: $blue-color
    @include font-size-lh(24,28)
    font-weight: 600
    margin-bottom: 24px
    @include bp($phone)
      margin-bottom: 26px
  &__close
    position: absolute
    right: 32px
    top: 32px
    width: 64px !important
    height: 64px !important
    @include bp($phone)
      top: 12px
      right: 12px
      width: 32px !important
      height: 32px !important
  & .mfp-close
    display: none