.d-favorites-return
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  width: 248px
  padding: 16px 20px
  border-radius: 16px
  background: rgba($black, .5)
  backdrop-filter: blur(18px)
  border: none
  font-weight: 400
  @include font-size-lh(16,22)
  color: $white-color
  text-align: center
  display: flex
  flex-direction: column
  row-gap: 8px
  align-items: center
  z-index: 11
  cursor: pointer
  @include transition(background)
  @include hover
    background: rgba($black, .6)
  &__button
    display: block
    font-weight: 600
    color: $blue-color
    text-align: center
  &._carPlace
    width: 264px
    &._line
      @include bp($phone)
        max-width: 380px
        width: calc(100% - 34px)
  &._line
    width: auto
    flex-direction: row
    gap: 0 16px
    white-space: nowrap
    @include bp($phone)
      max-width: 350px
      width: calc(100% - 48px)
      flex-direction: column
      gap: 16px 0
      white-space: normal