.circle-icon
  width: 80px
  height: 80px
  border-radius: 50%
  background: #F2F3F7
  box-shadow: -1.01266px -1.01266px 16.2025px #FFFFFF, 10.1266px 10.1266px 20.2532px rgba(210, 220, 233, 0.8), inset 1.01266px 1.01266px 0px rgba(255, 255, 255, 0.9)
  display: flex
  align-items: center
  justify-content: center
  position: relative
  @include bp($phone)
    width: 48px
    height: 48px
  & img
    width: 40%
    object-fit: contain
  &__help
    position: absolute
  &._object-card-map
    width: 48px
    height: 48px
    transition: box-shadow $transition
    &:hover
      box-shadow: none
