.d-index-filter-item
  position: relative
  &._availFilter
    background: $white-color
    border-radius: 12px
    max-width: 222px
    & .d-tabs__buttons
      margin-bottom: 0!important
    & .d-tabs__buttonsInner
      background: $white-color

  &._open
    & .d-index-filter-item
      &__head
        width: 100%
        &:after
          transform: rotate(-90deg)
      &__drop
        opacity: 1
        visibility: visible
        pointer-events: all
  &._active
    & .d-index-filter-item__head:before
      opacity: 1
      @include bp($tablet)
        opacity: 0
  &._price
    &._objects
      & .d-index-filter-item__drop
        @include bp($desktopXS)
          left: unset
          right: 0
  & .d-index-filter-item
    &__head
      @include bp($tablet)
        padding: 24px 0 0 0
    &__drop
      @include bp($tablet)
        padding: 12px 0 24px 0
  &._commercial
    & .d-index-filter-item
      &__drop
        min-width: 100%
        width: auto
        @include bp($tablet)
          pointer-events: all

  &__head
    display: flex
    align-items: center
    height: 64px
    padding: 0 104px 0 24px
    background: $white-color
    border-radius: 12px
    font-weight: 600
    user-select: none
    @include font-size-lh(16,22)
    color: $pen-color
    white-space: nowrap
    cursor: pointer
    @include transition(color)
    position: relative
    white-space: nowrap
    @include bp($tablet)
      height: auto
      padding: 24px 0 0
      border-radius: 0
    @include bp($phone)
      padding-top: 20px
    @include hover
      color: $blue-color
    &:before, &:after
      content: ''
      position: absolute
      top: 50%
    &:before
      position: absolute
      right: 64px
      margin-top: -6px
      width: 12px
      height: 12px
      background: $red
      border-radius: 50%
      opacity: 0
      @include transition(opacity)
    &:after
      right: 22px
      margin-top: -10px
      width: 20px
      height: 20px
      background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
      transform: rotate(90deg)
      @include bp($tablet)
        display: none
  &__drop
    position: absolute
    left: 0
    top: calc(100% + 8px)
    min-width: 416px
    width: 100%
    padding: 24px
    background: $white-color
    box-shadow: 0 4px 20px rgba($black, 0.08)
    border-radius: 12px
    opacity: 0
    visibility: hidden
    pointer-events: none
    @include transition(all)
    z-index: 7
    @include bp($tablet)
      position: relative
      top: 0
      min-width: unset
      padding: 24px 0
      box-shadow: none
      border-radius: 0
      border-bottom: 1px solid $grey20
      opacity: 1
      visibility: visible
      z-index: 1
    @include bp($phone)
      padding-top: 16px
  &._location
    @include bp($tablet)
      padding-bottom: 24px
      border-bottom: 1px solid $grey20
    & .d-index-filter-item
      &__head
        &:after
          @include bp($tablet)
            display: block
            right: 5px
            margin-top: 0
            width: 24px
            height: 24px
            background-image: url('../assets/img/d/icon_plus.svg')
            transform: none
      &__drop
        @include bp($tablet)
          position: absolute
          opacity: 0
          visibility: hidden
          pointer-events: none
    &._open:not(.d-projects-map__choosePlace)
      @include bp($tablet)
        display: block !important
        padding-bottom: 0
        border: none
      & .d-index-filter-item
        &__head
          @include bp($tablet)
            position: fixed
            left: 24px
            top: 0
            padding: 18px 40px
            background: $white-color
            z-index: 112
            font-weight: 600
            @include font-size-lh(32,36)
            color: $blue-color
            border-bottom: 1px solid $grey20
            @include bp($phone)
              left: 12px
              padding: 16px 40px
              @include font-size-lh(24,28)
            &:after
              right: auto
              left: 0
              top: 50%
              transform: translateY(-50%) rotate(180deg)
              width: 24px
              height: 24px
              margin: 0
              background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
        &__drop
          @include bp($tablet)
            padding-bottom: 0
            border: none
            position: relative
            opacity: 1
            visibility: visible
            pointer-events: all
    &._projects-map
      @include bp($tablet)
        padding-bottom: 0
        z-index: 3
      & .d-index-filter-item
        &__head
          border-radius: 12px
          height: 64px
          padding: 0 104px 0 24px
          @include bp($phone)
            height: 48px
            padding: 0 48px 0 16px
          &:after
            right: 22px
            margin-top: -10px
            width: 20px
            height: 20px
            background: url('../assets/img/d/arrow_pen.svg') no-repeat center / contain
            transform: rotate(90deg)
            @include bp($tablet)
              display: block
        &__drop
          position: absolute
          left: 0
          top: calc(100% + 8px)
          min-width: 416px
          width: 100%
          padding: 24px
          background: $white-color
          box-shadow: 0 4px 20px rgba($black, 0.08)
          border-radius: 12px
          opacity: 0
          visibility: hidden
          pointer-events: none
          @include bp($tablet)
            padding: 16px
          @include bp($phone)
            min-width: 308px
      &._open
        & .d-index-filter-item
          &__drop
            opacity: 1
            visibility: visible
            pointer-events: all
      & .d-search__stations
        @include bp($tablet)
          max-height: calc(100vh - 320px)
        @include bp($phone)
          max-height: 280px
  &:last-child &__drop
      @include bp($tablet)
        border-bottom: none
  &._choose
    & .d-index-filter-item
      &__head
        @include bps($phone)
          border: none !important

  &._tags
    @include bp($tablet)
      border-bottom: none
.d-projects-map
  .d-index-filter-item__choose
    display: none!important
