.d-visual-building-info
  background: #FFFFFF
  border-radius: 24px
  max-width: 356px
  width: 100%
  overflow: hidden
  @include bp($phone)
    width: 100%
    height: 100%
    max-width: 100%
    border-radius: 0
    display: flex
    flex-direction: column
    overflow-y: auto
  & .d-choose-builds-info__slider
    padding: 0 20px
    margin: 0 -20px !important
    @include bp($phone)
      padding: 0 12px
      margin: 0 -12px !important
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    padding: 16px 20px 16px
    @include bp($phone)
      @include font-size-lh(20,24)
      padding: 24px 12px
  &__content
    border-top: 1px solid $grey20
    padding: 15px 20px 20px
    display: flex
    flex-direction: column
    @include bp($tablet)
      padding: 24px
    @include bp($phone)
      padding: 24px 12px 16px
      flex-grow: 1
  &__row
    display: flex
    margin-bottom: 16px
  &__sections
    display: none
    @include bp($tablet)
      display: block
      margin-bottom: 24px
    &.d-choose-builds-info__floor
      border-top: 1px solid $grey20
      padding-top: 24px
  &__param
    display: flex
    flex-direction: column
    margin-right: 16px
    &:last-child
      margin-right: 0
    &._flat
      flex-direction: row
      align-items: center
      width: 100%
  &__value
    font-weight: 600
    @include font-size-lh(18,24)
    color: $pen-color
  &__name
    color: $grey50
    @include font-size-lh(14,18)
  & a.d-visual-building-info__name
    &:hover
      text-decoration: none
  &__flats
    @include bp($tablet)
      margin-bottom: 24px
    & .d-visual-building-info
      &__param
        margin-top: 8px
        &:first-child
          margin-top: 0
      &__name
        font-weight: 400
      &__value
        display: flex
        align-items: flex-end
        flex-shrink: 0
        flex-grow: 1
        white-space: nowrap
        @include font-size-lh(14,18)
        &:before
          content: ''
          border-bottom: 1px dashed $grey50
          display: block
          width: 100%
          position: relative
          bottom: 4px
          margin: 0 4px
  &__btn
    margin: 16px auto 0
    width: 100%
    @include bps($tablet)
      height: 48px
  & p._empty
    @include font-size-lh(18,24)
    color: $blue-color

.tippy-box[data-theme="visual-building"]
  max-width: 356px !important
  background: transparent
  border: none
  & .tippy-content
    padding: 0
.tippy-box[data-theme="visual-quarter"]
  max-width: 536px !important
  background: transparent
  border: none
  & .tippy-content
    padding: 0
