.d-investment-card
  display: grid
  grid-template-columns: 369px 1fr
  background: $white-color
  border-radius: 32px
  position: relative
  overflow: hidden
  @include bp($desktopS)
    grid-template-columns: 257px 1fr
  @include bp($desktopXS)
    grid-template-columns: 223px 1fr
  @include bp($tablet)
    grid-template-columns: 230px 1fr
  @include bp($tabletS)
    grid-template-columns: 255px 1fr
  @include bp($phone)
    display: flex
    flex-direction: column
    border-radius: 24px
  &._skeleton
    & *
      @include font-size-lh(0,0)
    & .d-investment-card__info
      width: 100%
      height: 54px
      @include bp($desktopXS)
        height: 68px
      @include bp($tabletS)
        height: 50px
      @include bp($phone)
        height: 154px
  & .skeleton-field:before
    border-radius: 8px
  &__img
    display: flex
    align-items: center
    grid-row: 1/3
    padding: 40px
    border-right: 1px solid $grey20
    @include bp($desktopXS)
      padding: 24px
    @include bp($tabletS)
      grid-row: unset
      padding-right: 19px
    @include bp($phone)
      order: 2
      width: 211px
      height: 132px
      padding: 0 0 24px
      margin: 0 auto
      border: none
    & img, & .skeleton-field
      max-height: 268px
      @include objectFit(100%, contain)
      margin: 0
      @include bp($desktopXS)
        max-height: 224px
      @include bp($tabletS)
        max-height: 132px
      @include bp($phone)
        max-height: 100%
    & .skeleton-field:before
      border-radius: 12px
  &__top
    min-height: 122px
    padding: 40px 234px 24px 40px
    position: relative
    @include bp($desktopS)
      padding-left: 24px
    @include bp($desktopXS)
      min-height: 98px
      padding: 24px 204px 24px 24px
    @include bp($tabletS)
      padding: 24px 24px 106px
    @include bp($phone)
      order: 1
      min-height: 86px
      padding: 24px 16px 20px
    &:before
      content: ''
      position: absolute
      right: 16px
      top: 16px
      width: 6px
      height: 6px
      border-top: 2px solid $pen-color
      border-right: 2px solid $pen-color
      display: none
      @include bp($phone)
        display: block

  &__title, &__project
    color: $pen-color
  &__title
    font-weight: 600
    @include font-size-lh(24,28)
    @include transition(color)
    @include bp($desktopXS)
      @include font-size-lh(20,24)
    @include bp($phone)
      font-size: 18px
    &.skeleton-field
      width: 80%
      height: 28px
      margin: 0
      @include bp($desktopXS)
        height: 24px
  &__project
    display: inline-block
    font-weight: 600
    @include font-size-lh(16,22)
    padding-right: 20px
    margin-top: 8px
    @include bp($desktopXS)
      @include font-size-lh(14,18)
    @include bp($phone)
      margin-top: 4px
    &.skeleton-field
      width: 180px
      height: 22px
      margin-bottom: 0
      @include bp($desktopXS)
        height: 18px
  &__more
    position: absolute
    right: 40px
    top: 40px
    @include bp($desktopXS)
      right: 24px
      top: 24px
    @include bp($tabletS)
      right: auto
      left: 24px
      top: auto
      bottom: 24px
    @include bp($phone)
      display: none
    &.skeleton-field
      width: 154px
      height: 48px
      margin: 0
  &__bottom
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 24px 40px 40px
    border-top: 1px solid $grey20
    position: relative
    z-index: 5
    @include bp($desktopS)
      padding-left: 24px
    @include bp($desktopXS)
      padding: 24px 24px 50px
    @include bp($tablet)
      padding-bottom: 24px
    @include bp($tabletS)
      grid-column: -1/1
      padding-top: 20px
    @include bp($phone)
      grid-column: uset
      order: 3
      padding: 16px
  &__info
    display: flex
    column-gap: 32px
    margin-bottom: 43px
    @include bp($desktopS)
      column-gap: 16px
    @include bp($desktopXS)
      //column-gap: 16px
      margin-bottom: 48px
    @include bp($tablet)
      column-gap: 24px
    @include bp($tabletS)
      column-gap: 16px
    @include bp($phone)
      flex-direction: column
      gap: 12px 0
      width: 100%
      margin-bottom: 24px
  &__infoItem
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    &:first-child
      flex-shrink: 0
      width: 160px
      @include bp($desktopXS)
        width: 124px
      @include bp($phone)
        width: auto
    &.skeleton-field
      max-width: 200px
      width: 100%
      height: 46px
      margin: auto 0
      @include bp($phone)
        height: 38px
        margin: 0
  &__infoLabel
    font-weight: 400
    @include font-size-lh(14,18)
    color: $grey50
    white-space: nowrap
    margin-bottom: 8px
    @include bp($desktopXS)
      max-width: 154px
      white-space: normal
    @include bp($tabletS)
      max-width: 100%
      white-space: nowrap
    @include bp($phone)
      @include font-size-lh(12,14)
      margin-bottom: 0
  &__infoValue
    display: flex
    align-items: center
    font-weight: 600
    @include font-size-lh(24,28)
    color: $blue-color
    white-space: nowrap
    margin-top: auto
    position: relative
    @include bp($desktopXS)
      @include font-size-lh(20,24)
    @include bp($phone)
      width: 100%
    &:hover
      cursor: default
      & svg
        fill: $pen-color
      & .d-investment-card__infoToolp
        opacity: 1
        visibility: visible
    & svg
      width: 24px
      height: 24px
      margin-left: 8px
      fill: $blue-color
      @include transition(fill)
      @include bp($desktopXS)
        margin-left: 4px
  &__infoToolp
    position: absolute
    left: 50%
    bottom: calc(100% + 4px)
    transform: translateX(-50%)
    background: $white-color
    box-shadow: 0 4px 20px rgba(54, 24, 125, 0.08)
    border-radius: 12px
    width: 454px
    padding: 12px 16px
    font-weight: 400
    @include font-size-lh(12,14)
    color: $blue-color
    white-space: normal
    z-index: 7
    opacity: 0
    visibility: hidden
    @include transition(all)
    @include bp($phone)
      left: 0
      transform: none
      max-width: 454px
      width: 100%
  &__mortgage
    font-weight: 400
    @include font-size-lh(14,18)
    color: $pen-color
    text-underline-offset: 3px
    position: absolute
    left: 0
    top: calc(100% + 8px)
    @include transition(color)
    @include bp($desktopXS)
      @include font-size-lh(12,16)
    @include bp($phone)
      position: relative
      top: 0
    @include hover
      color: $blue-color
  &__buttons
    display: flex
    justify-content: flex-end
    column-gap: 8px
    width: 100%
    margin-top: auto
    @include bp($phone)
      flex-direction: column
      gap: 8px 0
  &__button
    max-width: 260px
    width: 100%
    padding: 0
    @include bp($phone)
      max-width: 100%
    &.skeleton-field
      height: 64px
      margin: 0
      @include bp($phone)
        height: 48px
  &__link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3
    @include hover
      & + div + div
        & .d-investment-card
          &__title
            color: $blue-color
          &__more
            color: $white-color
            &:before
              border-top-color: $white-color
              border-right-color: $white-color
            &:after
              transform: translate3d(-50%,-50%,0) scale3d(1,1,1)
              opacity: 1
  &._commercial
    & .d-investment-card
      &__img
        padding: 0
        @include bp($phone)
          width: 100%
        & img
          max-height: 100%
          object-fit: cover