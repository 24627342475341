.form-dropdown.ss-main
  & .ss-single-selected
    height: auto
    display: block
    position: relative
    width: 100%
    margin: 0
    box-sizing: border-box
    border: none
    border-radius: 2.2em
    box-shadow: inset 1px 1px 2px 0 #d2dce9
    font-size: 16px
    text-align: left
    color: $pen-color
    background: $form-color
    padding: 24px 48px
    outline: none
    font-weight: 500
    &.ss-open-below
      border-radius: 2.2em
    & .ss-arrow
      position: absolute
      right: 48px
      top: 50%
      transform: translateY(-50%)
    & .placeholder
      color: rgba($pen-color, 0.5)
      font-size: 16px
      min-height: unset
      & .ss-disabled
        color: rgba($pen-color, 0.5)
  & .ss-content
    top: calc(100% + 8px)
    border-radius: 2.2em
    padding: 24px 16px 24px 48px
    background-color: #f2f3f7
    border-top: 1px solid #f2f3f7
    box-shadow: 0 0 2em rgb(139 144 166 / 20%)
    & .ss-list
      & .ss-option
        padding: 0
        margin-bottom: 12px
        color: $pen-color
        @include font-size-lh(16,18)
        &:hover
          background: transparent
          color: $blue-color
        &.ss-disabled
          background: transparent
          opacity: 0.5
  & .simplebar-track.simplebar-vertical
    width: 8px
  & .simplebar-scrollbar:before
    background: $blue-color
    opacity: 1
    border-radius: 4px