.d-company-awards
  &__app
    position: relative
  &__top
    display: flex
    align-items: flex-end
    justify-content: space-between
    max-width: calc(100% - 288px + 32px)
    @include bp($tabletS)
      max-width: calc(100% - 232px + 32px)
    @include bp($phone)
      display: block
      margin-bottom: 84px
      max-width: 100%
  &__title
    & small
      display: block
      font-weight: 400
      @include font-size-lh(20,26)
      color: $pen-color
      margin-top: 16px
      @include bp($tabletS)
        @include font-size-lh(16,22)
      @include bp($phone)
        margin-top: 12px
  &__year
    flex-shrink: 0
    width: 280px
    margin-left: 40px
    position: absolute
    right: 0
    bottom: calc(100% + 40px)
    @include bp($tabletS)
      width: 232px
      bottom: calc(100% + 32px)
    @include bp($phone)
      width: 100%
      margin: 12px 0 0
      bottom: calc(100% + 24px)
  &__list
    display: flex
    flex-direction: column
    row-gap: 32px
    margin-top: 40px
    @include bp($tablet)
      row-gap: 24px
      margin-top: 32px
    @include bp($phone)
      row-gap: 12px
      margin-top: 24px
  &__more
    display: flex
    justify-content: center
    margin-top: 40px
    @include bp($phone)
      margin-top: 32px