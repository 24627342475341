.d-company-affiliate
  &__top
    display: flex
    justify-content: space-between
    margin-bottom: 56px
    @include bp($desktopXS)
      margin-bottom: 40px
    @include bp($tablet)
      display: block
      margin-bottom: 32px
    @include bp($phone)
      margin-bottom: 24px
  &__title
    @include bp($desktopXS)
      flex-shrink: 0
      width: 384px
    @include bp($tablet)
      width: 100%
  &__info
    width: calc(50% - 16px)
    padding-top: 16px
    font-weight: 600
    @include font-size-lh(24,28)
    color: $pen-color
    @include bp($desktopS)
      max-width: 744px
      width: 100%
      margin-left: 32px
    @include bp($desktopXS)
      max-width: 100%
      margin-left: 32px
      @include font-size-lh(20,24)
    @include bp($tablet)
      max-width: 720px
      padding: 0
      margin: 32px 0 0
    @include bp($phone)
      @include font-size-lh(18,24)
      margin-top: 24px
  &__list
    display: flex
    flex-direction: column
    row-gap: 32px
    @include bp($tablet)
      row-gap: 24px
    @include bp($phone)
      row-gap: 18px