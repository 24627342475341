.chess-legend
  display: flex
  align-items: center

  &._desktop
    margin-left: 216px
    @include bp($desktopS)
      display: none
  &._desktop-small
    display: none
    @include bp($desktopS)
      display: flex
      margin-top: 35px
    @include bp($phone)
      margin-top: 24px
      width: calc(100% + 12px)
  &__item
    margin-right: 25px
    color: $pen-color
    @include font-size-lh(16,22)
    display: flex
    align-items: center
    @include bp($phone)
      @include font-size-lh(12,14)
      margin-right: 4px
    &:before
      content: ''
      width: 14px
      height: 14px
      border: 1px solid transparent
      display: block
      margin-right: 6px
      border-radius: 4px
      @include bp($phone)
        width: 10px
        height: 10px
        margin-right: 4px
    &._special
      &:before
        background: $blue-color
        border-color: $blue-color
    &._free
      &:before
        background: $white-color
        border-color: $white-color
    &._sales
      &:before
        background: $background
        border-color: $grey30
    &:last-child
      margin-right: 0