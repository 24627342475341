.ba-contacts
  padding-top: 114px
  padding-bottom: 32px
  @include bp($phone)
    padding-top: 64px
    padding-bottom: 16px
  &__body
    display: none
    &._active
      display: block
  &__officess
    border-radius: 24px
    overflow: hidden
    @include bp($tablet)
      padding-bottom: 32px
    @include bp($phone)
      width: calc(100% + 32px)
      margin-left: -16px

  &__services
    margin-top: 50px
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    gap: 24px
    @include bp($desktopS)
      grid-template-columns: 1fr 1fr 1fr
    @include bp($tablet)
      margin-top: 32px

    @include bp($tabletS)
      grid-template-columns: 1fr 1fr
    @include bp($phone)
      gap: 16px
    @include bp($phoneS)
      grid-template-columns: 1fr