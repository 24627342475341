.div_100
  position: absolute
  height: 100%
  width: 100%
  top: 0
  left: 0

.search_results_content .column.t, .search__flat-details .item._t .value
  white-space: nowrap


.object__parking-plans-frame
  background-color: rgba(22,28,36,0.48)
  transition: visibility .5s ease,opacity .5s ease
  &.active
    z-index: 35
    visibility: visible
    opacity: 1

  .object__parking-plans-inner
    position: absolute
    top: 5em
    right: 11.97917%
    bottom: 5em
    left: 11.97917%
    background-color: #f2f3f7
    border-radius: 5em
    transform: translateY(30%)
    transition: transform .5s ease
    bottom: unset !important
    border-radius: 44px

.ba-cp-popup
  position: relative
  width: calc(100% - 11.97917% * 2)
  max-height: calc(100% - 64px)
  height: auto
  padding: 48px
  background: $background
  border-radius: 44px

.ba-cp-popup__controls
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  margin-bottom: 24px
  column-gap: 24px

  .object__plans-dropdown
    position: relative !important
    right: unset !important
    height: 50px
    margin-top: 0 !important
    min-width: 260px !important

  .object__plans-dropdown-list-frame
    margin-top: 4.5rem !important

  .object__plans-dropdown.section .object__plans-dropdown-list-frame
    top: 50% !important
    margin-top: 5.5em !important
    bottom: auto !important
    margin-bottom: 0 !important

  .object__plans-dropdown-active
    border-radius: 50px

ba-cp-popup__controls .object__plans-dropdown-active
  border-radius: 50px

.ba-cp-popup__controls
  .object__plans-dropdown-active > span, .object__plans-dropdown-item > span
    font-size: 16px
    font-weight: 600

  .object__plans-dropdown-list-scroll
    margin: 10px 0

  .object__plans-dropdown-list
    position: relative
    margin: 0 20px

.ba-cp-popup__content, .ba-cp-popup__planWrap
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

.ba-cp-popup__content
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  justify-content: space-between
  padding-bottom: 32px

.ba-cp-popup__planWrap
  height: 500px
  position: relative

.ba-cp-popup__plan
  width: 863px
  height: 100%
  font-size: 0
  line-height: 0
  overflow: visible
  position: relative

.ba-cp-popup__planInfo
  padding: 32px 24px
  background: $white-color !important
  border-radius: 32px

.ba-cp-planInfo__title
  font-weight: 600
  font-size: 32px
  line-height: 38px
  color: $brand-color
  margin-bottom: 24px

.ba-cp-planInfo__params
  display: grid
  grid-template-columns: repeat(2, 140px)
  grid-column-gap: 8px
  grid-row-gap: 16px

.ba-cp-planInfo__label
  font-weight: 500
  font-size: 14px
  line-height: 17px
  color: $dark-grey
  margin-top: 4px

.ba-cp-planInfo__value
  font-weight: 600
  font-size: 20px
  line-height: 24px
  color: $pen-color

.ba-cp-popup__legend
  position: absolute
  left: 0
  top: calc(100% + 16px)
  width: 624px
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center

.ba-cp-popup__legendItem
  font-weight: 600
  font-size: 14px
  line-height: 17px
  color: $pen-color
  padding-left: 12px
  position: relative

  &:before
    content: ""
    position: absolute
    left: 0
    top: 50%
    -webkit-transform: translateY(-50%)
    transform: translateY(-50%)
    width: 8px
    height: 8px
    background: #89C9F7
    border: 1px solid $brand-color
    border-radius: 50%

  &._busy
    margin-left: 17px

    &:before
      background: none
      border-color: $pen-color

.ba-cp-popup__zoomControls, .ba-cp-popup .pinchZoom__buttons
  width: 56px !important
  margin-left: 58px !important
  height: auto !important
  transform: translateY(-50%)

.ba-cp-popup__zoom
  width: 56px !important
  height: 56px !important
  background: $background
  -webkit-box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
  box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
  border: none
  border-radius: 50%
  cursor: pointer
  position: relative
  padding: 1px 6px
  background-image: unset !important
  -webkit-transition: opacity .3s ease
  transition: opacity .3s ease

.ba-cp-popup .pinchZoom__button
  width: 56px !important
  height: 56px !important
  background: $background
  -webkit-box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
  box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
  border: none
  border-radius: 50%
  cursor: pointer
  position: relative
  padding: 1px 6px
  background-image: unset !important
  -webkit-transition: opacity .3s ease
  transition: opacity .3s ease

  &.inactive
    opacity: 0.5 !important
    cursor: default !important

.ba-cp-popup__zoom
  &:before, &:after
    content: ""
    position: absolute !important
    left: 50% !important
    top: 50% !important
    -webkit-transform: translate(-50%, -50%) !important
    transform: translate(-50%, -50%) !important
    background: $brand-color !important
    background-image: unset !important

.ba-cp-popup .pinchZoom__button
  &:before, &:after
    content: ""
    position: absolute !important
    left: 50% !important
    top: 50% !important
    -webkit-transform: translate(-50%, -50%) !important
    transform: translate(-50%, -50%) !important
    background: $brand-color !important
    background-image: unset !important

.ba-cp-popup__zoom:before, .ba-cp-popup .pinchZoom__button:before
  width: 14px !important
  height: 2px !important

.ba-cp-popup__zoom:after, .ba-cp-popup .pinchZoom__button:after
  width: 2px !important
  height: 14px !important

.ba-cp-popup__zoom._minus, .ba-cp-popup .pinchZoom__button.out
  margin-top: 8px

.ba-cp-popup__zoom._minus:after, .ba-cp-popup .pinchZoom__button.out:after
  display: none

.ba-cp-popup__about
  width: 298px

.ba-cp-popup__title
  font-weight: 600
  font-size: 32px
  line-height: 38px
  color: $brand-color
  margin-bottom: 24px

.ba-cp-popup__params
  display: grid
  grid-template-columns: repeat(2, 140px)
  grid-column-gap: 18px
  grid-row-gap: 16px
  margin-bottom: 24px

.ba-cp-popup__param._pw
  display: none
  grid-column: 1/-1
  font-weight: normal
  color: $brand-color

  .ba-cp-popup__paramValue
    font-weight: 500
    font-size: 14px
    line-height: 18px
    color: $brand-color

.ba-cp-popup__paramLabel
  font-weight: 500
  font-size: 14px
  line-height: 17px
  color: $dark-grey
  margin-bottom: 2px

.ba-cp-popup__paramValue
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  font-weight: 600
  font-size: 20px
  line-height: 24px
  color: $pen-color

.ba-cp-popup__more
  margin-left: 6px
  cursor: pointer
  position: relative

  svg
    fill: rgba(1, 150, 227, 0.4)
    -webkit-transition: fill .3s ease
    transition: fill 0.3s ease

.search_results_content .column.t svg
  fill: rgba(1, 150, 227, 0.4)
  -webkit-transition: fill .3s ease
  transition: fill 0.3s ease

.search__flat-details .item._t .value
  svg
    fill: rgba(1, 150, 227, 0.4)
    -webkit-transition: fill .3s ease
    transition: fill 0.3s ease

    &:focus
      fill: $brand-color

  &._hover svg:focus + .__tooltip
    opacity: 1
    visibility: visible

@media (hover: hover)
  .ba-cp-popup__more:hover svg, .search_results_content .column.t svg, .search__flat-details .item._t .value:hover svg
    fill: $brand-color

  .ba-cp-popup__more:hover .ba-cp-popup__tooltip, .search_results_content .column.t:hover .__tooltip, .search__flat-details .item._t .value:hover .__tooltip
    opacity: 1
    visibility: visible

.ba-cp-popup__tooltip, .search_results_content .column.t .__tooltip, .search__flat-details .item._t .value .__tooltip
  bottom: calc(100% + 8px)
  position: absolute
  left: 50%
  -webkit-transform: translateX(-50%)
  transform: translateX(-50%)
  padding: 8px 12px
  background: #aba3be
  border-radius: 16px
  font-weight: 500
  font-size: 14px
  line-height: 18px
  color: #fff
  -webkit-box-sizing: border-box
  box-sizing: border-box
  opacity: 0
  visibility: hidden
  -webkit-transition: all .3s ease
  transition: all .3s ease
  white-space: nowrap

.search_results_content .column.t .__tooltip
  bottom: calc(100% + -32px)

.search__flat-details .item._t .value .__tooltip span
  font-size: 14px
  line-height: 18px

.ba-cp-popup__button
  width: 100%
  height: 48px
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  font-weight: 600
  font-size: 14px
  line-height: 17px
  border: none
  border-radius: 24px
  cursor: pointer

  &._request
    background: $blue-color
    -webkit-box-shadow: -11.491px -9.642px 10.392px rgba(255, 255, 255, 0.89), 14px 14px 34px rgba(53, 184, 252, 0.3), inset 2px 2px 0px rgba(255, 255, 255, 0.3)
    box-shadow: -11.491px -9.642px 10.392px rgba(255, 255, 255, 0.89), 14px 14px 34px rgba(53, 184, 252, 0.3), inset 2px 2px 0px rgba(255, 255, 255, 0.3)
    color: #fff

  &._order
    background: $background
    -webkit-box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
    box-shadow: 14px 14px 28px rgba(210, 220, 233, 0.8), -8.90148px -7.46916px 8.05014px rgba(255, 255, 255, 0.89), inset 2px 2px 1px rgba(255, 255, 255, 0.72)
    color: $pen-color
    margin-top: 16px

.ba-cp-popup__watched
  margin-top: 24px

.ba-cp-popup__watchedTitle
  font-weight: 600
  font-size: 20px
  line-height: 24px
  color: $pen-color
  margin-bottom: 16px

.ba-cp-popup__watchedList
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  column-gap: 24px

.ba-cp-popup__watchedItem
  -ms-flex-negative: 0
  flex-shrink: 0
  width: 362px
  cursor: pointer
  display: flex
  flex-direction: row
  column-gap: 31px

.ba-cp-popup__close
  position: absolute
  right: 32px
  top: 32px
  width: 57px
  height: 57px
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  background: $blue-color
  -webkit-box-shadow: -11.491px -9.642px 10.392px rgba(255, 255, 255, 0.89), 14px 14px 34px rgba(53, 184, 252, 0.3), inset 2px 2px 0px rgba(255, 255, 255, 0.3)
  box-shadow: -11.491px -9.642px 10.392px rgba(255, 255, 255, 0.89), 14px 14px 34px rgba(53, 184, 252, 0.3), inset 2px 2px 0px rgba(255, 255, 255, 0.3)
  border: none
  border-radius: 50%
  cursor: pointer
  padding: 0

  img
    width: 22px

.ba-cp-watched
  background: $background
  -webkit-box-shadow: -1px -1px 16px #fff, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  box-shadow: -1px -1px 16px #fff, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  border-radius: 16px
  padding: 16px

.ba-cp-watched__title
  font-weight: 600
  font-size: 24px
  line-height: 29px
  color: $brand-color
  margin-bottom: 4px

.ba-cp-watched__params
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  flex-direction: column
  row-gap: 6px

  &:first-child
    flex-grow: 1

.ba-cp-watched__label
  font-weight: 500
  font-size: 14px
  line-height: 17px

.ba-cp-watched__value
  font-weight: 600
  font-size: 14px
  line-height: 17px
  color: $pen-color
  white-space: nowrap

@media screen and (max-width: 1919px)
  .object__parking-plans-frame .object__parking-plans-inner
    right: 32px !important
    left: 32px !important

  .ba-cp-popup
    max-width: 1216px
    width: calc(100% - 64px)
    margin: 0 auto

  .ba-cp-popup__planWrap
    height: 448px

  .ba-cp-popup__plan
    width: 624px

@media screen and (max-width: 1599px)
  .ba-cp-popup__planWrap
    height: 350px

@media screen and (max-width: 1279px)
  .object__parking-plans-frame .object__parking-plans-inner
    right: 16px !important
    left: 16px !important

  .ba-cp-popup
    max-height: calc(100% - 32px)
    width: calc(100% - 32px)
    margin: 0 auto

  .ba-cp-popup__planWrap
    height: 350px

  .ba-cp-popup__plan
    width: 480px

  .ba-cp-popup__zoomControls, .ba-cp-popup .pinchZoom__buttons
    margin-left: 0 !important

  .ba-cp-popup__watched
    overflow-x: auto
    padding-bottom: 20px

@media screen and (max-width: 1023px)
  .object__parking-plans-frame .object__parking-plans-inner
    right: 0 !important
    left: 0 !important
    top: 0 !important
    bottom: 0 !important
    height: 100%

  .ba-cp-popup
    max-height: 100%
    max-width: 100%
    width: 100%
    margin: 0
    padding: 0

  .ba-cp-popup__controls
    padding: 40px 32px 0 32px

  .ba-cp-popup__zoomControls, .ba-cp-popup .pinchZoom__buttons
    margin-left: -90px !important

  .ba-cp-popup__planWrap
    flex-direction: column-reverse
    row-gap: 13px
    width: 100%
    height: 400px

  .ba-cp-popup__plan
    width: 100%
    box-shadow: inset 0 0 20px 4px rgb(0 0 0 / 5%)

  .ba-cp-popup__content
    flex-direction: column
    row-gap: 32px

  .ba-cp-popup__legend
    position: static
    width: 100%
    justify-content: flex-start
    padding-left: 52px

  .ba-cp-popup__about
    width: 100% !important
    padding: 0 32px

  .ba-cp-popup__buttons
    display: flex
    column-gap: 32px

  .ba-cp-popup__more
    display: none

  .ba-cp-popup__params
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-column-gap: 32px
    grid-row-gap: 16px
    margin-bottom: 24px

  .ba-cp-popup__button._order
    margin-top: 0

  .ba-cp-popup__param._pw
    display: block

  .ba-cp-popup__watched
    margin: 24px 32px 32px 32px

@media screen and (max-height: 720px)
  .ba-cp-popup__planWrap
    height: 250px

@media screen and (max-width: 719px)
  .page__content.wide
    padding: 0 16px !important

  .parking-search_frame .search__flat
    padding: 24px 16px !important

.tippy-box[data-theme='parking-search']
  background-color: transparent
  color: inherit
  border-radius: 0

.parking-search_frame .search__flat-delimiter
  margin: 0 !important
