.d-object-single-progress
  @include bp($phone)
    padding-bottom: 80px
    position: relative
  &__title
    @include bp($tabletS)
      max-width: 400px
  &__controls
    display: flex
    padding-bottom: 32px
    margin-bottom: 32px
    border-bottom: 1px solid $grey20
    position: relative
    @include bp($tablet)
      justify-content: space-between
      padding-bottom: 0
      margin-bottom: 40px
      border: none
    @include bp($phone)
      margin-bottom: 20px
      position: static
  &__selects
    display: grid
    grid-template-columns: repeat(3, 286px)
    grid-column-gap: 24px
    margin-right: 32px
    @include bp($desktopXS)
      grid-template-columns: repeat(3, 216px)
    @include bp($tabletS)
      width: 100%
      grid-template-columns: repeat(3, 1fr)
      margin-right: 0
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 8px
  &__button
    @include bp($tabletS)
      position: absolute
      right: 0
      bottom: calc(100% + 40px)
    @include bp($phone)
      bottom: 0
      width: 100%