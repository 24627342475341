.d-disclaimer-popup
  max-width: 720px
  width: 100%
  height: 592px
  display: flex
  flex-direction: column
  background: $background
  padding: 32px 32px 40px 32px
  border-radius: 40px
  @include bp($phone)
    max-width: 100%
    height: 100%
    border-radius: 0
    overflow: auto
    padding: 12px 12px 40px 12px
  &__title
    font-weight: 600
    @include font-size-lh(20,24)
    color: $blue-color
    margin-bottom: 20px
    margin-right: calc(96px - 32px)
    @include bp($phone)
      @include font-size-lh(18,24)
      margin-bottom: 14px
      margin-right: calc(72px - 12px)
  &__text
    flex-grow: 1
    font-weight: 400
    @include font-size-lh(16,22)
    color: $pen-color
    margin-right: -10px
    padding-right: 10px
    overflow: auto
  &__textInner, & .simplebar-wrapper
    height: 100%
  &__textInner
    & p
      margin: 0
      & + p
        margin-top: 16px

