.full-popup
  &.mfp-bg
    background-color: rgba(242,243,247,1)
    opacity: 0.9
  & .mfp-content
    height: 100%
  &__inner
    &._scheme
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
  & .mfp-close
    background-color: #f2f3f7
    box-shadow: -1.01266px -1.01266px 16.2025px #FFFFFF, 10.1266px 10.1266px 20.2532px rgba(210, 220, 233, 0.8), inset 1.01266px 1.01266px 0px rgba(255, 255, 255, 0.9)
    opacity: 1
    border-radius: 50%
    color: $white-color !important
    transform: translate(-24px, 24px)
    outline: none
    display: flex
    justify-content: center
    align-items: center
    font-size: 0
    width: 48px
    height: 48px
    transition: background-color $transition
    &:hover
      background-color: $white-color
    &:before
      content: ''
      width: 16px
      height: 16px
      display: block
      background: url('../assets/img/icons/close.svg') no-repeat
      background-size: contain
    @include bp($tablet)
      transform: translate(-16px, 16px)
  &__img
    margin: 0 auto
    display: block
    border-radius: 30px
    max-width: 100%
    max-height: calc(100% - 150px)
  &__buttons
    display: flex
    justify-content: center
    margin-top: 32px
  &__btn
    display: inline-block
    position: relative
    padding: 12px 24px
    vertical-align: middle
    cursor: pointer
    background-color: #f2f3f7
    border-radius: 20px
    text-align: center
    text-decoration: none
    transition: background-color $transition
    white-space: nowrap
    border: none
    color: $pen-color
    font-weight: 500
    margin-right: 24px
    @include font-size-lh(16,18)
    &:last-child
      margin-right: 0
    &:hover
      background-color: $white-color
