.month-offers
  @include bp($tablet)
    margin-top: 102px
  @include bp($phone)
    margin-top: 68px
  &__title
    display: none
    color: #311F78
    margin-bottom: 80px
    margin-left: 75px
    font-weight: 500
    @include bp($tablet)
      display: inline
      margin-left: 65px
      @include font-size-lh(25, 27)
    @include bp($phone)
      margin-left: 42px
      @include font-size-lh(16, 17)
  &__list
    display: flex
    position: relative
    align-items: stretch
    margin: 0 120px
    @include bp($desktopS)
      margin: 0 80px
    @include bp($tablet)
      margin: 0
      margin-top: 32px
    @include bp($phone)
      margin-top: 14px
  &__sliderWrapper
    flex: 2
    padding: 20px 0
    overflow: hidden
    padding-right: 26px
    padding-left: 10px
    @include bp($desktopS)
      padding-right: 18px
    @include bp($tablet)
      padding-right: 0
      padding-left : 0
      margin-left: 0
    &--vip
      padding-right: 48px
      padding-left: 10px
      margin-left: -24px
      @include bp($desktopS)
        padding-right: 32px
        margin-left: -16px
      @include bp($tablet)
        padding-right: 0
        padding-left : 0
        margin-left: 0
    & .swiper
      overflow: unset
      @include bp($tablet)
        padding: 0 36px
      @include bp($phone)
        padding: 0 28px
      &-slide
        &--vip
          display: none
          @include bp($tablet)
            display: flex
          & .offer__tag
            display: flex
    &--luxury
      & .swiper
        &-slide
          &--vip
            display: flex
          & .offer__tag
            display: flex
  &__vip
    flex: 1
    padding: 20px 0
    @include bp($desktopS)
      padding-right: 18px
    @include bp($tablet)
      padding-right: 0
      display: none
    & .offer__tag
      display: flex
  &__pagination
    display: none
    font-weight: 500
    text-align: center
    color: $blue-color
    @include bp($tablet)
      display: inline-block
      padding-top: 30px
      @include font-size-lh(20, 23)
    @include bp($phone)
      @include font-size-lh(13, 16)
  &__navigation
    display: flex
    position: absolute
    width: 100%
    justify-content: space-between
    top: 50%
    transform: translateY(-50%)
    z-index: 10
    pointer-events: none
    @include bp($tablet)
      display: none
  &__button
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: 40px
    height: 60px
    pointer-events: all
    cursor: pointer
    & img
      width: 30px
      height: 60px
      transition: transform $transition
      @include bp($desktopS)
        width: 20px
        height: 40px
    &--next
      right: -80px
      @include bp($desktopS)
        right: -55px
      &:hover
        & img
          transform: translateX(10px)
          transition: transform $transition
    &--prev
      left: -85px
      @include bp($desktopS)
        left: -60px
      & img
        transform: rotate(180deg)
      &:hover
        & img
          transform: translateX(-10px) rotate(180deg)
          transition: transform $transition
    &.swiper-button-disabled
      visibility: hidden
.offer
  display: flex
  flex-direction: column
  justify-content: flex-end
  width: 100%
  // height: 100%
  // margin: 24px
  padding: 24px 20px 20px
  background: $background
  box-shadow: -1px -1px 16px #FFFFFF, 10px 10px 20px rgba(210, 220, 233, 0.8), inset 1px 1px 0px rgba(255, 255, 255, 0.9)
  border-radius: 32px
  position: relative
  overflow: hidden
  transition: box-shadow $transition
  text-decoration: none
  color: $pen-color
  @include bp($desktopS)
    // margin: 16px
    padding: 16px 13px 13px
    border-radius: 20px
  @include bp($tablet)
    // margin: 8px
    padding: 30px 20px 20px
    border-radius: 40px
  @include bp($phone)
    // margin: 7px
    padding: 20px 14px 14px
    border-radius: 28px
  &:hover
    & .offer__button
      background-color: $brand-color
  &__image
    height: 286px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 34px
    text-align: center
    @include bp($desktopS)
      height: 190px
      margin-bottom: 16px
    @include bp($tablet)
      height: 260px
      margin-bottom: 30px
    @include bp($phone)
      height: 170px
      margin-bottom: 20px
    & img
      height: 90%
      width: 70%
      object-fit: contain
  &__title
    margin-bottom: 16px
    font-weight: 500
    font-size: 24px
    line-height: 1.9
    text-align: center
    color: #36187D
    @include bp($desktopS)
      font-size: 16px
    @include bp($tablet)
      margin-bottom: 20px
      font-weight: 600
      font-size: 20px
    @include bp($phone)
      margin-bottom: 14px
      font-size: 14px
  &__placeTag
    width: fit-content
    margin: 0 auto
    margin-bottom: 8px
    padding: 5px 10px
    color: white
    font-size: 14px
    line-height: 1
    background-color: $tag-color
    border-radius: 40px
    white-space: nowrap
    @include bp($phone)
      font-size: 10px
      margin-bottom: 6px
      padding: 4px 8px
  &__info
    display: flex
    margin-bottom: 32px
    justify-content: space-around
    @include bp($desktopS)
      margin-bottom: 20px
    @include bp($tablet)
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 28px
  &__button
    display: flex
    width: 100%
    height: 52px
    align-items: center
    justify-content: center
    background-color: $blue-color
    border-radius: 38px
    @include font-size-lh(20, 17)
    color: $white-color
    @include bp($desktopS)
      height: 35px
      font-size: 14px
    @include bp($tablet)
      height: 56px
      font-size: 18px
    @include bp($phone)
      height: 37px
      font-size: 12px
  &__tag
    display: none
    width: 100px
    position: absolute
    top: 0
    right: 0
    @include bp($desktopS)
      width: 70px
    @include bp($tablet)
      width: 100px
    @include bp($phone)
      width: 66px
    & img
      object-fit: contain
      width: 100%
      height: 100%

.info
  text-align: center
  &__title
    margin: 0
    @include font-size-lh(16, 16)
    color: $dark-grey
    font-weight: 500
    text-align: center
    @include bp($desktopS)
      @include font-size-lh(11, 11)
    @include bp($tablet)
      @include font-size-lh(18, 18)
    @include bp($phone)
      @include font-size-lh(12, 12)
  &__text
    margin: 0
    font-weight: 600
    font-size: 24px
    line-height: 1.9
    text-align: center
    color: $pen-color
    @include bp($desktopS)
      font-size: 16px
    @include bp($tablet)
      font-size: 20px
    @include bp($tablet)
      font-size: 14px
