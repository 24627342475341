.d-map-contacts
  height: 740px
  @include bp($desktopS)
    height: 640px
  @include bp($tablet)
    height: 100%
    width: 100%
  &._contacts
    @include bp($desktopS)
      height: 640px
  &__map
    height: 100%
    border-radius: 40px
    overflow: hidden
    @include bp($tablet)
      border-radius: 0
    & .ymaps-2-1-79-copyright
      margin-right: 2% !important
    & .ymaps-2-1-79-map-copyrights-promo
      left: 3% !important

