.d-infra-map-mobile
  width: 100%
  height: 100%
  position: relative
  &__btn-filter
    position: absolute
    left: 50%
    bottom: 12px
    transform: translateX(-50%)
    z-index: 20
  &__point
    position: absolute
    bottom: 72px
    left: 50%
    transform: translateX(-50%)
    &._offset
      bottom: 150px
  &__swipe
    position: absolute
    bottom: 72px
    left: 50%
    transform: translateX(-50%)
    max-width: 296px
    width: 100%