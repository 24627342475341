.d-commercial-object-advantages
  display: grid
  grid-template-columns: repeat(2, 1fr)
  @include bp($desktopS)
    grid-template-columns: 1fr 874px
  @include bp($desktopXS)
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 24px
  @include bp($tablet)
    //grid-template-columns: 1fr 252px
    display: flex
    flex-direction: column
    align-items: center
  @include bp($phone)
    grid-template-columns: 1fr
    grid-gap: 24px 0
    max-width: 720px
    width: 100%
    margin-left: auto
    margin-right: auto
  &__item
    &._title
      grid-row: 1 / 3
      @include bp($desktopS)
        padding-right: 32px
      @include bp($desktopXS)
        grid-row: unset
        padding: 0
      @include bp($tablet)
        margin-bottom: 32px
      @include bp($tabletS)
        order: 1
    &._text
      @include bp($tabletS)
        order: 3
      @include bp($tablet)
        order: 2
      @include bp($phone)
        order: 2
    &._times
      max-width: 100%
      @include bp($desktopXS)
        grid-column: 1 / -1
      @include bp($tabletS)
        grid-row: 3/-1
        grid-column: 2
        order: 2
      @include bp($tablet)
        order: 3
        grid-column: unset
      @include bp($phone)
        grid-row: unset
        grid-column: unset
        order: 3
        width: 100%
  &__title
    max-width: 748px
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopXS)
      @include font-size-lh(40,44)
    @include bp($phone)
      @include font-size-lh(36,40)
  &__text, &__times
    max-width: 904px
    width: 100%
    margin-left: auto
    @include bp($desktopXS)
      margin-left: 0
  &__text
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    @include bp($tabletS)
      margin-top: 32px
    @include bp($phone)
      @include font-size-lh(16,22)
      margin-top: 0
    & p, & ul, & ol
      padding: 0
      margin: 0
    & ul li
      list-style: none
      position: relative
      padding-left: 24px
      &:before
        content: ''
        position: absolute
        left: 10px
        top: 10px
        width: 4px
        height: 4px
        background: $pen-color
        border-radius: 50%
        @include bp($phone)
          top: 8px
  &__times
    display: grid
    grid-template-columns: repeat(3, 252px)
    grid-column-gap: 8px
    margin-top: 40px
    @include bp($desktopXS)
      max-width: 100%
    @include bp($tablet)
      display: flex
      gap: 8px
      flex-wrap: wrap
    @include bp($phone)
      display: grid
      grid-template-columns: 1fr
      margin-top: 0