.fit-block
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden
  user-select: none
  transform: translateZ(0)
  &__inner
    position: absolute
    top: 50%
    left: 50%
    visibility: hidden
    overflow: hidden
  &__pusher
    display: block
    pointer-events: none
    max-width: unset !important
    max-height: unset !important
  &._scrollable
    @include bp($tablet)
      margin-left: 0 !important
      left: 0
      overflow-x: scroll !important
      -webkit-overflow-scrolling: touch
  &._scrollable > .fit-block__inner
    @include bp($tablet)
      margin-left: 0 !important
      left: 0
.genplan-labels
  width: 100%
  height: 100%
  left: 0
  top: 0
  position: absolute
  z-index: 15
