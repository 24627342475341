.d-commercial-object
  padding-top: 8px
  &__section
    margin-bottom: 120px
    @include bp($desktopXS)
      margin-bottom: 96px
    @include bp($tablet)
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 64px
    &._top
      margin-top: 32px
      @include bp($phone)
        margin: 16px -12px 68px