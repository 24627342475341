.d-commercial
  padding-top: 56px
  @include bp($phone)
    padding-top: 40px
  &__top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 36px
    @include bp($desktopS)
      margin-bottom: 30px
    @include bp($tablet)
      margin-bottom: 0
    @include bp($tabletS)
      align-items: flex-end
    @include bp($phone)
      display: block
  &__title
    font-weight: 600
    @include font-size-lh(52,56)
    color: $blue-color
    @include bp($desktopS)
      @include font-size-lh(40,44)
    @include bp($tabletS)
      max-width: 364px
    @include bp($phone)
      @include font-size-lh(36,40)
      margin-bottom: 24px
  &__buttons
    @include bp($tablet)
      display: none
    &._mobile
      display: none
      @include bp($tablet)
        display: flex
        column-gap: 12px
  &__buttonFilter, &__buttonOnMap
    display: flex
    align-items: center
    justify-content: center
    height: 64px
    padding: 0 24px
    border: none
    border-radius: 12px
    font-weight: 400
    @include font-size-lh(16,22)
    text-decoration: none
    @include bp($phone)
      flex-grow: 1
      height: 48px
    & img
      width: 16px
      margin-right: 8px
  &__buttonFilter
    background: $blue-color
    color: $white-color
    &._fixed
      position: fixed
      bottom: 24px
      left: 50%
      transform: translateX(-50%)
      z-index: 5
      transition: all $transition
      opacity: 0
      &._show
        @include bp($tablet)
          opacity: 1
          transform: translateX(-50%)

  &__buttonOnMap
    background: $white-color
    color: $pen-color
  &__filter
    @include bp($tablet)
      display: none
  &__content
    border-top: 1px solid $grey20
    padding-top: 24px
    margin: 40px 0 120px
    @include bp($tablet)
      margin: 32px 0 80px
    @include bp($phone)
      padding-top: 16px
      margin: 24px 0 64px
    &._params
      @include bp($tablet)
        display: block !important
    &._map
      height: 700px
      padding-top: 0
      margin: 40px 0
      border: none
      border-radius: 48px
      overflow: hidden
      @include bp($desktopXS)
        height: 560px
      @include bp($tablet)
        display: none
  &__controls
    display: flex
    align-items: center
    @include bp($tabletS)
      flex-wrap: wrap
    @include bp($phone)
      order: 2
      margin-top: 8px
  &__count
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    margin-right: auto
    @include bp($tablet)
      width: auto
      margin-top: 0
      order: unset
    &._desktop
      @include bp($phone)
        display: none
    &._mobile
      display: none
      @include bp($phone)
        display: block
        order: 1
  &__sorter
    @include bp($tabletS)
      margin-left: auto
      order: 2
    @include bp($phone)
      width: 100%
      margin: 16px 0 0
      order: 4
    &._hide
      display: none
      @include bp($phone)
        display: block
    &._line
      margin: 0 0 8px
      @include bp($tabletS)
        margin-left: 0
        order: unset
      @include bp($phone)
        display: none
  &__switchView
    margin-left: 16px
    @include bp($tablet)
      order: 3
    @include bp($phone)
      display: none
  &__results
    margin-top: 32px
    @include bp($tabletS)
      margin-top: 24px
    &._list
      & .d-commercial
        &__list
          display: none
          @include bp($phone)
            display: grid
          &._list
            display: flex
            flex-direction: column
            row-gap: 8px
            @include bp($phone)
              display: none
  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 32px
    @include bp($desktopXS)
      grid-gap: 24px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
    @include bp($phone)
      grid-template-columns: 1fr
      grid-gap: 12px 0
    &._list
      display: none
  &__bottom
    grid-column: -1/1
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 40px
    @include bp($tabletS)
      flex-direction: column-reverse
    @include bp($phone)
      margin-top: 32px
  &__pager
    @include bp($tabletS)
      margin-top: 40px
    @include bp($phone)
      margin-top: 32px
  & .d-tabs__buttons
    margin: 0 30px 0 0
    @include bp($tabletS)
      margin-right: auto
      order: 1
    & + .d-commercial__count
      @include bp($tabletS)
        width: 100%
        margin: 24px 0 0
        order: 4
      @include bp($phone)
        order: 3
  &__tags
    display: flex
    flex-wrap: wrap
    gap: 5px
    margin-top: 24px
    @include bp($phone)
      order: 1
      margin-top: 16px
  &__sorter
    width: 348px
    @include bp($phone)
      max-width: 100%
    &._line
      width: 100%
  &__inner
    & .d-tabs__content
      @include bp($phone)
      order: 3
    @include bp($phone)
      display: flex
      flex-direction: column