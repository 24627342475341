.d-metro-way
  display: flex
  align-items: center
  font-weight: 500
  @include font-size-lh(12,14)
  color: $grey40
  & img
    width: 16px
    margin-left: 4px
  &._left
    & img
      margin: 0 4px 0 0