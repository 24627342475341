.d-company-about
  &__title
    margin-bottom: 56px
    @include bp($desktopXS)
      margin-bottom: 40px
    @include bp($phone)
      margin-bottom: 24px
  &__section
    margin-bottom: 120px
    @include bp($desktopXS)
      margin-bottom: 104px
    @include bp($tablet)
      margin-bottom: 80px
    @include bp($phone)
      margin-bottom: 56px
    &:last-child
      margin-bottom: 0