.object-header
  padding: 24px 0 99px
  transition: all $transition
  position: fixed
  top: 0
  width: 100%
  left: 0
  transform: translateY(114px)
  z-index: 23
  @include bps($tablet, $isTouchDevice)
    //display: none
    position: static
    transform: none
    padding-bottom: 32px
    background: $white-color
    box-shadow: 0px 4px 16px rgba(201, 212, 225, 0.4)
  @include bp($phone)
    display: flex
    position: static
    transform: none
    background: transparent
    box-shadow: none
  &._fixed
    transform: translateY(0)
    z-index: 25
    background: #FDFDFD
    box-shadow: 0px 4px 16px rgba(201, 212, 225, 0.4)
    padding-bottom: 24px
    & .object-header__back
      display: none
    & .object-header__buttons
      transform: translateY(52px)
  &._offset
    transform: translateY(114px)
    //@include bps($tablet, $isTouchDevice)
    //  transform: none
    &:not(._stay)
      padding-bottom: 24px
      @include bps($tablet, $isTouchDevice)
        padding-bottom: 32px
      & .object-header__back
        display: none
        @include bps($tablet, $isTouchDevice)
          display: flex
  &__inner
    display: flex
    align-items: center
    width: 100%
    padding: 0 32px
    margin: 0 auto
    position: relative
    @include bp($desktop)
      padding: 0 54px
    @include bp($phone)
      padding: 0 16px
      flex-direction: column
      align-items: flex-start
  &__back
    @include font-size-lh(14,16)
    position: absolute
    left: 32px
    top: 94px
    transition: opacity $transition
    display: flex
    align-items: center
    color: $pen-color
    @include bp($desktop)
      left: 54px
    @include bp($tablet)
      position: static
    @include bp($phone)
      left: 16px
    &:hover
      cursor: pointer
      opacity: 0.6
    & svg
      transform: rotate(180deg)
      fill: $pen-color
      width: 12px
      height: 12px
      margin-right: 4px
  &__name
    color: $pen-color
    margin: 0 !important
    text-transform: uppercase
    font-weight: 400
    @include font-size-lh(32,38)
    @include bp($tablet)
      display: none
  &__name-link
    cursor: pointer
  &__buttons
    position: absolute
    top: 80px
    right: 32px
    display: flex
    z-index: 2
    transition: transform $transition
    @include bp($tablet)
      display: none
      top: unset
      bottom: 32px
      & .object-header-button-group,
      & .object-header__button._choose
        @include bp($phone)
          display: none
    @include bp($phone)
      //
    &._mobile
      display: none
      @include bps($tablet, $isTouchDevice)
        display: flex
        position: fixed
        bottom: 76px
        right: 32px
  &__button
    text-decoration: none
    background: $blue-color
    @include font-size-lh(16,19)
    color: $white-color
    padding: 16px 32px
    border-radius: 60px
    display: flex
    align-items: center
    justify-content: center
    height: 52px
    border: none
    outline: none
    margin-right: 16px
    font-weight: 600
    position: relative
    transition: background-color $transition
    @include bp($phone)
      background: $brand-color
    &:hover:not(._circle)
      background: $pen-color
    &:last-child
      margin-right: 0
    &:hover
      cursor: pointer
    &._circle
      border-radius: 50%
      width: 52px
      padding: 0
    &._chat
      & button
        position: absolute
        top: 0
        left: 0
        transition: transform $transition
        opacity: 0
        & span
          position: absolute
          right: calc(100% + 4px)
          top: 50%
          transform: translateY(-50%)
          opacity: 0
          visibility: hidden
          transition: opacity $transition
          color: $blue-color
          white-space: nowrap
          @include font-size-lh(12,12)
      &:hover
        & button._chat
          transform: translateY(calc(100% + 4px))
          &:hover
            & span
              opacity: 1
              visibility: visible
              z-index: 2
        & button._wa
          transform: translateY(calc(100% + 56px))
          opacity: 1
          &:hover
            & span
              opacity: 1
              visibility: visible
              z-index: 2
  &__button-label
    background: $blue-color
    color: $white-color
    padding: 8px 16px
    border-radius: 32px
    position: absolute
    top: 0
    right: 100%
    @include font-size-lh(12,18)
    white-space: nowrap

    //opacity: 0
    //visibility: hidden
.object-header-button-group
  position: relative
  display: inline-flex
  & .object-header__button
    position: relative
    //opacity: 0
    z-index: 3
    &._chat
      transform: translateY(4px)
      @include bps($tablet)
        transform: translateY(-16px)
    &._wa
      transform: translateY(8px)
      @include bps($tablet)
        transform: translateY(-8px)
    &:hover
      & span
        opacity: 1
        visibility: visible
        z-index: 2
        right: calc(100% + 4px)
        transition: all $transition

    & span
      position: absolute
      right: calc(100% - 32px)
      top: 50%
      transform: translateY(-50%)
      opacity: 0
      visibility: hidden
      transition: opacity $transition
      color: $blue-color
      white-space: nowrap
      @include font-size-lh(12,12)
    &._chat
      top: calc(100% + 4px)
      @include bps($tablet)
        top: unset
        bottom: calc(100% + 8px)
    &._wa
      top: calc(200% + 8px)
      @include bps($tablet)
        top: unset
        bottom: calc(100% + 4px)
  &:hover
    cursor: pointer
    & .object-header-button-group__sub
      opacity: 1
      visibility: visible
    & .object-header-button-group__preview
      & img
        &:first-child
          display: none
        &:last-child
          display: block
    & .object-header__button
      opacity: 1
      visibility: visible
      transition: all $transition

  &__preview
    width: 52px
    height: 52px
    background: $blue-color
    @include font-size-lh(16,19)
    color: $white-color
    padding: 16px 32px
    border-radius: 60px
    display: flex
    align-items: center
    justify-content: center
    height: 52px
    width: 52px
    padding: 0
    border: none
    outline: none
    margin-right: 16px
    font-weight: 600
    position: relative
    transition: background-color $transition
    & img
      &:first-child
        //
      &:last-child
        display: none
        transform: rotate(90deg)
        @include bps($tablet)
          transform: rotate(270deg)
  &__sub
    position: absolute
    top: 52px
    left: 0
    opacity: 0
    visibility: hidden
    transition: all $transition
    @include bps($tablet)
      top: unset
      bottom: 52px
