.d-index-filter-params
  display: grid
  grid-template-columns: 1fr
  grid-row-gap: 24px
  &._params
    padding-top: 14px
    @include bp($tablet)
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 24px
      padding-top: 8px
    @include bp($phone)
      grid-template-columns: 1fr
      grid-column-gap: 0
      grid-row-gap: 16px
      padding-top: 0
  &__row
    //
  &__label
    font-weight: 500
    @include font-size-lh(16,22)
    color: $grey50
    margin-bottom: 12px
  &__checkboxes
    display: flex
    overflow-x: auto
    flex-wrap: wrap
    gap: 4px
    @include bp($phone)
      max-width: calc(100vw - 12px)
    &._rooms, &._level
      & .d-checkbox
        &__label
          @include bp($tablet)
            height: 64px
          @include bp($phone)
            height: 48px
    &._rooms
      & .d-checkbox
        flex-shrink: 0
        min-width: 74.83px
        width: max-content
        //flex-grow: 1
        flex-basis: calc(25% - 4px)
        @include bp($tablet)
          min-width: 59px
          flex-grow: 0
          flex-basis: calc(20% - 4px)
        @include bp($phone)
          min-width: 46px
    &._level
      margin-bottom: 4px
      @include bp($tablet)
        width: auto
      &:last-child
        margin-bottom: 0
      & .d-checkbox
        @include bp($tablet)
          flex-grow: 0
        &__label
          width: 48px
          padding: 0
          text-align: center
          @include bp($tablet)
            width: 64px
          @include bp($phone)
            width: 48px