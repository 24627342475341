.d-owners-cards
  background: $white-color
  border-radius: 40px
  width: 100%
  margin: 54px 0 24px
  @include bp($desktopXS)
    border-radius: 32px
    margin-top: 40px
  @include bp($phone)
    display: none
  &__row
    display: grid
    grid-template-columns: 1fr repeat(3, 19.1%) 20% //1fr repeat(4, 19.1%)
    border-top: 1px solid $grey20
    @include bp($desktopS)
      grid-template-columns: 1fr repeat(3, 17.1%) 24%
    @include bp($desktopXS)
      grid-template-columns: repeat(5, 20%)
    @include bp($tablet)
      grid-template-columns: 1fr repeat(4, 19.44%)
    &:first-child
      border: none
      & .d-owners-cards__col:first-child
        @include bp($desktopXS)
          padding-top: 24px
  &__col
    padding: 24px
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    border-left: 1px solid $grey20
    @include bp($desktopXS)
      padding: 16px
      @include font-size-lh(14,18)
    &:first-child
      padding: 24px 32px
      border: none
      @include bp($desktopXS)
        padding: 16px
  & .big
    @include font-size-lh(32,36)
    @include bp($desktopXS)
      @include font-size-lh(24,28)
  & small
    display: block
    font-weight: 400
    @include font-size-lh(12,14)
    margin-top: 8px
    @include bp($desktopXS)
      margin-top: 2px
  & img
    display: block
    width: 40px
  &._mobile
    display: none
    @include bp($phone)
      display: flex
      flex-direction: column
      row-gap: 12px
      margin: 16px 0
      background: none
      border-radius: 0
    & .d-owners-cards
      &__item
        background: $white-color
        border-radius: 24px
      &__top
        padding: 16px
        border-bottom: 1px solid $grey20
      &__bottom
        padding: 16px 16px 24px
      &__advantages
        padding: 0
        font-weight: 600
        @include font-size-lh(14,18)
        & li
          list-style: none
          padding-left: 20px
          margin-top: 8px
          background: url('../assets/img/d/icon_done.svg') no-repeat left 2px / 16px
          &:before
            display: none
          &:first-child
            margin-top: 0