.d-flat-top
  display: grid
  grid-template-columns: 1fr 584px
  padding: 40px
  background: $white-color
  border-radius: 40px
  font-weight: 500
  @include font-size-lh(16,22)
  color: $grey50
  @include bp($desktopS)
    grid-template-columns: 1fr 465px
  @include bp($tablet)
    grid-template-columns: 1fr 268px //1fr 260px
    padding: 32px 24px 32px 32px
  @include bp($tabletS)
    display: block
  @include bp($phone)
    padding: 24px 12px
    border-radius: 24px
  &__item
    position: relative
    &._top
      padding: 0 0 0 40px
      @include bp($tablet)
        grid-column: 1/-1
        padding: 0 382px 0 0
      @include bp($tabletS)
        padding: 0 144px 0 0
      @include bp($phone)
        padding: 0
    &._plans, &._info
      &:before, &:after
        content: ''
        position: absolute
        background: $grey20
      &:after
        display: none
    &._plans
      grid-row: 1 / 3
      display: flex
      flex-grow: 1
      padding-right: 40px
      @include bp($tablet)
        grid-row: unset
        padding: 32px 32px 0 0
        margin-top: 24px
      @include bp($tabletS)
        padding: 24px 0 0
        margin-top: 32px
      @include bp($phone)
        padding-top: 16px
        margin-top: 24px
      &:before
        right: 0
        top: -40px
        width: 1px
        height: calc(100% + 80px)
        @include bp($tablet)
          top: 0
          height: calc(100% + 32px)
        @include bp($tabletS)
          display: none
      &:after
        @include bp($tablet)
          display: block
          left: -32px
          top: 0
          width: calc(100% + 32px)
          height: 1px
        @include bp($tabletS)
          width: calc(100% + 56px)
        @include bp($phone)
          left: -12px
          width: calc(100% + 24px)
      & .d-tabs
        display: flex
        flex-direction: column
        width: 100%
        &__buttons
          flex-shrink: 0
          margin-bottom: 24px
          @include bp($phone)
            padding: 0 12px
            margin: 24px -12px 0
            order: 2
        &__buttonsInner
          @include bp($tabletS)
            display: inline-flex
            width: auto
          @include bp($phone)
            display: flex
            width: fit-content
        &__button
          @include bp($desktopS)
            padding: 0 20px
          @include bp($tabletS)
            padding: 0 24px
        &__content
          flex-grow: 1
          min-height: 592px
          @include bp($desktopS)
            min-height: 540px
          @include bp($tablet)
            min-height: 462px
          @include bp($phone)
            min-height: 428px
            order: 1
          @include bp($phone360-)
            min-height: 372px
          &._active
            display: flex
            align-items: center
            justify-content: center
          &._flatPlan
            position: relative
            padding: 0 40px 0 224px
            @include bp($desktopS)
              padding: 0 0 123px
            @include bp($tablet)
              //padding: 0 0 0 137px
              padding-bottom: 100px
            @include bp($phone)
              padding: 0 0 120px
            & .d-flat-plan-floor
              display: block
    &._info
      padding: 33px 0 0 40px
      margin-top: 32px
      @include bp($desktopS)
        padding: 33px 0 0 32px
        margin-top: 24px
      @include bp($tablet)
        padding-left: 24px
      @include bp($tabletS)
        display: flex
        padding: 33px 0 0
        margin-top: 32px
      @include bp($phone)
        flex-direction: column-reverse
        padding-top: 24px
        margin-top: 16px
      &:before
        right: -40px
        top: 0
        width: calc(100% + 40px)
        height: 1px
        @include bp($tablet)
          right: -24px
          width: calc(100% + 24px)
        @include bp($tabletS)
          width: calc(100% + 64px)
        @include bp($phone)
          right: -12px
          width: calc(100% + 24px)
  &__name
    display: inline-block
    font-weight: 600
    @include font-size-lh(14,18)
    color: $blue-color
    text-decoration: none
    margin-bottom: 14px
    @include bp('(min-width: 768px)')
      display: none
  &__title
    font-weight: 600
    @include font-size-lh(40,44)
    color: $pen-color
    @include bp($desktopS)
      @include font-size-lh(32,36)
  &__sticker
    display: flex
    align-items: center
    font-style: normal
    &._new
      height: 32px
      padding: 0 8px
      margin-right: 12px
      background: $white-color
      border: 1px solid $blue-color
      border-radius: 15px
      font-weight: 700
      @include font-size-lh(16,22)
      text-transform: uppercase
      cursor: default
      & span
        background: linear-gradient(180deg, #FE5285 0%, #FE404F 100%)
        background-clip: text
        -webkit-text-fill-color: transparent
        text-fill-color: transparent
        color: #FE404F
  &__actions
    display: flex
    margin-top: 12px
    @include bp($phone)
      margin-top: 16px
    & .d-flat-action + .d-flat-action
      margin-left: 4px
  &__icons
    display: flex
    margin-bottom: 16px
    column-gap: 8px
    @include bp($phone)
      column-gap: 16px
    @include bp($tablet)
      position: absolute
      right: 0
      top: 0
      margin-bottom: 0
    @include bp($phone)
      right: 16px
      top: -4px
    & .d-button
      order: 5
    & ._icon1
      @include bp($tablet)
        order: 2
      @include bp($phone)
        display: none
    & ._icon2, & ._icon3
      @include bp($tablet)
        order: 1
      @include bp($tabletS)
        display: none
  &__infoTop
    @include bp($tabletS)
      flex-grow: 1
      padding-right: 32px
      position: relative
    @include bp($phone)
      padding: 24px 0 0
      margin-top: 24px
    &:before
      display: none
      content: ''
      position: absolute
      right: 0
      top: -32px
      width: 1px
      height: calc(100% + 64px)
      background: $grey20
      @include bp($tabletS)
        display: block
      @include bp($phone)
        right: -12px
        top: 0
        width: calc(100% + 24px)
        height: 1px
  &__infoBottom
    @include bp($tabletS)
      flex-shrink: 0
      width: 272px
      padding-left: 32px
    @include bp($phone)
      width: 100%
      padding: 0
    &._sold
      position: absolute
      bottom: 0
      color: $blue-color
      font-weight: 600
      @include font-size-lh(32,36)
      @include bp($tabletS)
        position: unset
        flex-shrink: 0
        width: 272px
        padding-left: 32px
      @include bp($phone)
        width: 100%
        padding: 0

  &__buttonsPrint
    display: none
    @include bp($tabletS)
      display: inline-grid
      grid-template-columns: repeat(2, auto)
      grid-column-gap: 8px
      grid-row-gap: 8px
      margin-top: 20px
    @include bp($phone)
      display: inline-flex
      column-gap: 8px
      row-gap: 8px
      justify-content: space-between
      flex-wrap: wrap
      margin-top: 24px
    & .d-button
      @include bp($phone)
        width: calc(50% - 4px)
        flex-grow: 1
    & .d-button._phone
      display: none
      @include bp($phone)
        display: inline-flex
  &__price
    margin-top: 53px
    @include bp($desktopS)
      margin-top: 33px
    @include bp($tabletS)
      margin-top: 0
  &__buttons
    display: flex
    margin-top: 48px
    @include bp($desktopS)
      margin-top: 32px
    @include bp($tablet)
      flex-direction: column
      margin-top: 24px
    @include bp($tabletS)
      margin-top: 20px
  &__button
    width: 206px
    padding: 0 32px 0 24px
    margin-left: 8px
    @include bp($desktopS)
      width: auto
      flex-grow: 1
    @include bp($tablet)
      width: 100%
      margin: 8px 0 0
    &:first-child
      width: 260px
      margin: 0
      @include bp($desktopS)
        width: auto
        flex-grow: 0
  &__furnish
    @include font-size-lh(16,22)
    color: $grey50
    font-weight: 500
