.d-progress-popup-slider
  position: relative
  width: 100%
  height: 100%
  &__arrow
    position: absolute
    bottom: 32px
    right: 32px
    z-index: 4
    @include bp($phone)
      bottom: 12px
      right: 12px
  &__swiper
    width: 100%
    height: 100%
  &__slide
    width: 100%
    height: 100%
    position: relative
    display: flex
    align-items: center
    @include bp($tablet)
      overflow-x: auto
    &:after
      content: ''
      width: 100%
      height: 200px
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
      display: block
      position: absolute
      bottom: 0
      left: 0
      pointer-events: none
      z-index: 2
    & .pinch-scroll-zoom
      border: none !important
      position: relative
      z-index: 3
    & .pinch-scroll-zoom__content
      display: flex
      justify-content: center
      align-items: center
  &__pagination
    position: absolute
    bottom: 32px !important
    left: 32px !important
    z-index: 3
    //display: none
    @include bp($tablet)
      display: block
      top: 34px
      bottom: unset !important
    @include bp($phone)
      bottom: unset !important
      top: 12px
      left: 12px !important
  &__info
    display: block
    position: absolute
    bottom: 32px
    left: 32px
    z-index: 10
    @include bp($desktopS)
      max-width: 356px
    @include bp($tablet)
      max-width: 288px
    @include bp($phone)
      bottom: 76px
      left: 12px
      max-width: calc(100% - 24px)
  &__img
    position: absolute
    left: 0
    top: 0
    @include objectFit(100%, cover)
    transition: all $transitionSlow
    z-index: 1
    @include bp($isTouchDevice)
      width: auto
      height: auto
      max-width: 100%
      max-height: 100%
      object-fit: contain
      position: relative
      z-index: 2
  &__slide-bg
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-size: cover
    pointer-events: none
    z-index: 1
    &:after
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 0
      backdrop-filter: blur(10px)
      background: rgba(0, 0, 0, 0.6)
      display: block

.d-progress-popup-slider__slide .zoomist-container
  position: absolute
  z-index: 2

.d-progress-popup-slider__slide .zoomist-container,
.d-progress-popup-slider__slide .zoomist-wrapper,
.d-progress-popup-slider__slide .zoomist-image
  width: 100%
  height: 100%

.d-progress-popup-slider__slide .zoomist-wrapper
  background: none

.d-progress-popup-slider__slide .zoomist-image img
  width: 100%
  height: 100%
  object-fit: contain
  object-position: center
