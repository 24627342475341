.d-company-about-projects-new
  &__top
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-column-gap: 32px
    margin-bottom: 40px
    @include bp($tablet)
      grid-column-gap: 24px
    @include bp($phone)
      display: block
      margin-bottom: 24px
  &__title
    font-weight: 600
    @include font-size-lh(32,36)
    color: $blue-color
    @include bp($phone)
      @include font-size-lh(24,28)
      margin-bottom: 16px
  &__text
    font-weight: 400
    @include font-size-lh(20,26)
    color: $pen-color
    @include bp($desktopXS)
      @include font-size-lh(16,22)
  &__arrows
    position: absolute
    right: 56px
    bottom: 56px
    z-index: 4
    @include bp($desktopXS)
      right: 40px
      bottom: 40px
    @include bp($tablet)
      right: 32px
      bottom: 32px
    @include bp($phone)
      right: 16px
      bottom: 16px
  &__slider
    position: relative
    & .d-tabs__buttons
      position: absolute
      left: 56px
      top: 56px
      opacity: 1 !important
      z-index: 3
      @include bp($desktopXS)
        left: 40px
        top: 40px
      @include bp($tablet)
        left: 32px
        top: 32px
      @include bp($phone)
        left: 16px
        top: 16px
    & .swiper
      display: none
      &._active
        display: block
  &__slide
    &.swiper-slide
      height: 656px
      border-radius: 32px
      overflow: hidden
      will-change: transform
      @include bp($desktopXS)
        height: 536px
      @include bp($phone)
        height: 440px
        border-radius: 24px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.15) 100%)
      z-index: 2
    & img
      @include objectFit(100%, cover)
  &__info
    position: absolute
    left: 0
    top: 0
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 100%
    height: 100%
    padding: 276px 56px 56px
    color: $white-color
    z-index: 3
    @include bp($desktopXS)
      padding: 208px 40px 40px
    @include bp($tablet)
      padding: 208px 32px 32px
    @include bp($phone)
      justify-content: center
      padding: 100px 16px
  &__name
    font-weight: 600
    @include font-size-lh(88,96)
    @include bp($desktopXS)
      @include font-size-lh(64,68)
    @include bp($phone)
      @include font-size-lh(32,36)
  &__slogan
    font-weight: 600
    @include font-size-lh(32,36)
    margin: 24px 0
    @include bp($desktopXS)
      @include font-size-lh(24,28)
    @include bp($phone)
      @include font-size-lh(20,24)
      margin: 16px 0
  &__link
    margin-top: auto
    @include bp($phone)
      position: absolute
      left: 16px
      bottom: 16px
      margin-top: 0
      z-index: 2
  &__more
    display: flex
    justify-content: center
    margin-top: 40px
    @include bp($phone)
      margin-top: 32px