.d-news-single-text
  font-weight: 400
  @include font-size-lh(20,26)
  color: $pen-color
  @include bp($desktopXS)
    @include font-size-lh(16,22)
  & h2
    font-weight: 600
    @include font-size-lh(40,44)
    margin: 0
    @include bp($desktopXS)
      @include font-size-lh(24,28)
    @include bp($phone)
      @include font-size-lh(18,24)
    & + *
      margin-top: 24px
      @include bp($phone)
        margin-top: 16px
  & img
    display: block
    object-fit: cover
    width: 100%
    height: 560px
    border-radius: 24px
    @include bp($desktopXS)
      height: 420px
    @include bp($phone)
      height: 240px
    & + p, & + ul, & + ol
      margin-top: 24px
  & p.photo-owner
    @include font-size-lh(16,22)
    color: $grey50
    margin-top: 16px
    @include bp($desktopXS)
      @include font-size-lh(12,14)
  & p, & ul, & ol
    margin: 0
    & + p, & + ul, & + ol, & + video
      margin-top: 24px
    & + h2
      margin-top: 32px
  & ul
    padding: 0
    & li
      list-style: none
      padding-left: 24px
      position: relative
      &:before
        content: ''
        position: absolute
        left: 10px
        top: 10px
        width: 4px
        height: 4px
        background: currentColor
        border-radius: 50%
        @include bp($desktopXS)
          top: 9px
  & b, & strong
    font-weight: 600
  & a
    color: $blue-color
    text-decoration: none
    text-underline-offset: 4px
    @include transition(all)
    @include hover
      text-decoration: underline
    &[target=_blank]
      &:after
        content: ''
        display: inline-block
        width: 16px
        height: 16px
        margin-left: 4px
        background: url('../assets/img/d/icon_link.svg') no-repeat center / contain
        position: relative
        top: 2px
  & video
    width: 100%
    & + *
      margin-top: 24px
  & .swiper
    @include bp($tablet)
      overflow: unset
    &-slide
      &:after
        content: ''
        position: absolute
        left: 0
        top: 360px
        width: 100%
        height: 200px
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)
        border-radius: 0 0 24px 24px
        z-index: 2
        @include bp($desktopXS)
          top: 220px
        @include bp($tablet)
          display: none
    &__pagination, &__arrows
      position: absolute
      z-index: 3
      @include bp($tablet)
        display: none
    &__pagination
      left: 32px
      bottom: auto
      top: 496px
      @include bp($desktopXS)
        left: 24px
        top: 364px
    &__arrows
      right: 32px
      top: 464px
      @include bp($desktopXS)
        right: 24px
        top: 332px
    & + p, & + ul, & + ol
      margin-top: 24px