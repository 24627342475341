.offices-contacts
  position: relative
  margin-top: 56px
  @include bp($tablet)
    margin-top: 80px
  //@include bp($phone)
  //  display: none
  &._desktop
    @include bp($tablet)
      display: none
  &._mobile
    display: none
    @include bp($tablet)
      display: block
    &._contacts
      margin-top: 0
      @include bp($phone)
        margin-left: 16px
  &__map
    background: #c1c1c1
    width: 100%
    height: 596px
    border-radius: 20px
    &._contacts
      height: 701px
      overflow: hidden
  &__inner
    position: relative
  &__list
    &._mobile
      margin-top: 16px
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: 24px
      @include bp($tabletS)
        display: block
      &._contacts
        margin-top: 32px
        grid-template-columns: 1fr 1fr 1fr
        display: grid
        gap: 24px
        @include bp($tabletS)
          grid-template-columns: 1fr 1fr
        @include bp($phone)
          margin-top: 16px
          gap: 0
          grid-template-columns: 1fr
  &__title
    &._mobile
      @include font-size-lh(16,21)
      color: $pen-color
      font-weight: 600

