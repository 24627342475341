.d-tabs-contacts
  &__buttons
    //width: 100%
    @include bp($phone)
    width: calc(100% + 24px)
    padding: 0 12px
    margin-left: -12px
    margin-bottom: 48px
    overflow: auto
    display: flex
    align-items: center
    scroll-behavior: smooth
    &::-webkit-scrollbar
      display: none
    @include bp($desktopS)
      margin-bottom: 40px
    @include bp($tabletS)
      margin-bottom: 48px
    @include bp($phone)
      display: flex
      margin-bottom: 16px
  &__buttonsInner
    position: relative
    display: inline-flex
    align-items: center
    background: $white-color
    border-radius: 12px
    padding: 8px
    @include bp($tablet)
      width: 100%
    @include bp($tabletS)
      display: flex
      width: 100%
    @include bp($phone)
      padding: 4px
      width: auto
  &__button
    height: 48px
    padding: 0 24px
    display: flex
    align-items: center
    justify-content: center
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    white-space: nowrap
    background: none
    border: 0
    cursor: pointer
    user-select: none
    @include transition(color)
    position: relative
    z-index: 2
    @include hover
      color: $blue-color
    @include bp($tablet)
      width: calc(100% / 3)
    @include bp($phone)
      width: auto
      height: 40px
      padding: 0 16px
      @include font-size-lh(14,18)
    &._active
      color: $white-color
  &__buttonBg
    position: absolute
    top: 8px
    height: 48px
    background: $pen-color
    border-radius: 8px
    transition: all $transition
    @include bp($phone)
      top: 4px
      height: 40px
  &__content
    display: none
    &._active
      display: block
  &._filter
    & .d-tabs-contacts
      &__buttonsInner
        display: flex
      &__button
        flex-grow: 1
  &._whiteBg
    & .d-tabs
      &__buttonsInner
        background: $white-color
