.d-object-single-location-time
  display: flex
  align-items: center
  background: $white-color
  border-radius: 24px
  padding: 8px
  font-weight: 600
  @include font-size-lh(16,22)
  & small
    display: block
    font-weight: 500
    @include font-size-lh(12,14)
    color: $grey50
  &__item
    &._left
      display: flex
      flex-shrink: 0
      flex-direction: column
      align-items: center
      justify-content: center
      width: 64px
      height: 64px
      margin-right: 12px
      background: $grey5
      border-radius: 50%
      color: #5CB7F6
      & small
        margin-top: -5px
    &._right
      color: $pen-color