.d-choose-floor
  width: 100%
  height: 784px
  position: relative
  margin-top: 32px
  border-top: 1px solid $grey20
  padding-top: 32px
  @include bp($desktopS)
    height: 628px
    border-bottom: 1px solid $grey20
    margin-bottom: 32px
  @include bp($tablet)
    height: 874px
    border-bottom: 1px solid $grey20
  @include bp($tabletS)
    height: 1021px
    margin-bottom: 24px
  @include bp($phone)
    height: 849px
    margin-top: 16px
    padding-top: 12px


  &__container
    display: grid
    grid-column-gap: 16px
    grid-template-columns: calc(300px - 16px) auto 41px 64px
    grid-template-areas: "quarter floor floor2 pager" "quarter plan floor2 pager" "compass plan floor2 pager"

    @include bp($desktopS)
      grid-template-columns: calc(160px + 32px - 16px) auto 41px 64px

    @include bp($tablet)
      grid-column-gap: 0
      grid-template-columns: 200px calc(100vw - var(--padding) - 200px)
      grid-template-areas: "floor controls" "quarter plan" "compass plan" "floor2 floor2" "pager pager"

    @include bp($tabletS)
      grid-template-columns: 24px calc(100vw - var(--padding) - 24px)
      grid-template-areas: "floor controls" "plan plan" "compass quarter" "floor2 floor2" "pager pager"

    @include bp($phone)
      grid-template-columns: 24px calc(100vw - var(--padding) - 24px)
      grid-template-areas: "controls controls" "floor floor" "plan plan" "compass quarter" "floor2 floor2" "pager pager"

  &__quarter-wrapper
    grid-area: quarter
    @include bp($tabletS)
      text-align: right
    & img
      max-width: 160px
      max-height: 111px
      margin-right: 140px
      @include bp($desktopS)
        margin-right: 32px
      @include bp($tablet)
        margin-right: 40px
      @include bp($tabletS)
        margin-right: 0
      @include bp($phone)
        max-width: 103px
        max-height: 71px

  &__floor
    font-weight: 600
    @include font-size-lh(20,24)
    color: $pen-color
    text-align: center
    transition: transform $transitionSlow 0.1s
    @include bp($tablet)
      text-align: left
    @include bp($phone)
      text-align: center

  &__floor-wrapper
    grid-area: floor
    padding-bottom: 40px
    @include bp($desktopS)
      padding-bottom: 32px
    @include bp($tablet)
      padding-bottom: 40px
      display: flex
      align-items: center
    @include bp($phone)
      justify-content: center
      padding-bottom: 32px

  &__floor-wrapper2
    grid-area: floor2
    width: 41px
    @include bp($tablet)
      padding: 40px 0 24px 0
      width: 100%
    @include bp($phone)
      padding: 32px 0 16px 0

  &__floor-wrapper2 &__floor
    @include font-size-lh(16,22)
    span
      display: none
    @include bp($tablet)
      @include font-size-lh(20,24)
      text-align: center
      position: static
      span
        display: inline

  &__pager-wrapper
    grid-area: pager
    position: relative
    width: 64px
    @include bp($tablet)
      text-align: center
      margin: 0 -24px
      width: calc(100% + 48px)
      //width: calc(100vw - var(--padding))
    @include bp($phone)
      margin: 0 -12px
      width: calc(100% + 24px)

  &__plan-wrapper
    grid-area: plan
    display: flex
    align-items: center
    justify-content: center
    @include bp($tabletS)
      margin-bottom: 20px
    @include bp($phone)
      margin-bottom: 40px
      position: relative
      z-index: 1

  &__plan
    display: flex
    align-items: center
    justify-content: center
    width: 1240px
    height: 568px
    @include bp($desktopS)
      width: 832px
      height: 508px
    @include bp($tablet)
      width: 776px
      height: 546px
    @include bp($tabletS)
      width: 720px
      height: 546px
    @include bp($phone)
      width: calc(100% + 24px)
      height: 378px
      margin: 0 -12px

  &__compass-wrapper
    grid-area: compass
    @include bp($tabletS)
      align-self: end

  &__controls
    grid-area: controls
    display: flex
    column-gap: 8px
    row-gap: 12px
    @include bp($tablet)
      padding-bottom: 40px
      display: flex
      align-items: center
      justify-content: flex-end
    @include bp($phone)
      flex-direction: column
      padding-bottom: 32px
      position: relative
      z-index: 5
    &-select
      min-width: 184px
      @include bp($phone)
        width: 100%

  &__wrapper
    .d-visual-header
      margin-top: 14px
      .d-choose-floor__controls
        @include bp($tablet)
          display: none
    .d-visual-header__choose
      margin-top: 0
    .d-visual-header__row
      justify-content: space-between
      row-gap: 46px
    .d-visual-breadcrumbs
      @include bp($tabletS)
        display: flex!important
      @include bp($phone)
        position: absolute
        top: 36px


  &__slider
    width: 100%
    height: 100%

  &__slide-labels
    position: absolute
    display: block
    pointer-events: none
    margin: auto

  &__slide
    display: flex
    align-items: center
    justify-content: center
    @include bp($phone)
      padding: 0 12px
      overflow-x: auto
      justify-content: flex-start
      &._vertical
        padding: 0
        justify-content: space-around
        & .d-choose-floor__floor-plan
          @include bp($phone)
            max-width: calc(100% - 24px)
        & .d-choose-floor__slide-labels
          @include bp($phone)
            max-width: 100%

  &__floor-plan
    position: relative
    width: 100%
    height: 100%
    //border: 1px dashed $grey40
    display: flex
    align-content: center
    justify-content: center
    @include bp($phone)
      width: 720px
      flex-basis: 720px
      flex-shrink: 0
      //padding-bottom: 10px
    img, svg
      width: 100%
      height: 100%
      object-fit: contain
      object-position: center
      max-width: 100%
      max-height: 100%
    img
      pointer-events: none
      z-index: 1
    svg
      z-index: 0
    ._label
      position: absolute
      border-radius: 12px
      transform: translateX(-50%) translateY(-50%)
      z-index: 2
      min-width: 53px
      @include font-size-lh(12,14)
      padding: 4px 10px
      transition: border-radius $transition, padding $transition, width $transition, height $transition
      @include bp($desktopS)
        @include font-size-lh(10,12)
        padding: 2px 6px
        min-width: 40px
      &._avail
        background-color: $blue-color
        fill: $blue-color
        color: $white-color
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        row-gap: 3.65968px
        i
          height: 1px
          background-color: rgba($white-color,0.5)
          display: block
          width: 50%
        &._hover
          color: transparent
          overflow: hidden
          min-width: unset
          width: 16px
          height: 16px
          padding: 0
          border-radius: 50%
          i
            opacity: 0
      &._unavail
        @include font-size-lh(10,12)
        padding: 2px 6px
        background-color: $grey20
        fill: $grey20
        color: $brand-color
        @include bp($tablet)
          display: none


    path._empty
      pointer-events: none
      fill-opacity: 0.9
      fill: $white-color
