.d-sorter-line
  display: grid
  @include bp($phone)
    display: none
  &__item
    display: flex
    align-items: center
    font-weight: 400
    @include font-size-lh(16,22)
    color: $grey50
    white-space: nowrap
    &._active
      color: $pen-color
    &._desc
      //
    &._asc
      //
    &._disabled
      pointer-events: none
      & .d-sorter-direction._line
        display: none !important
  & button
    background: none
    border: none
    padding: 0
    cursor: pointer
    color: $grey50
    @include transition(color)
    @include hover
      color: $blue-color
  & span
    &.short
      display: none
  & .d-sorter-direction
    margin-right: 7px
  &._flatMortgage
    grid-template-columns: 164px 1fr 10% 18% 18%
    grid-column-gap: 64px
    margin-bottom: 6px
    @include bp($desktopS)
      grid-template-columns: 146px 1fr 9% 15% 18%
      grid-column-gap: 16px
    @include bp($desktopXS)
      grid-template-columns: 146px 1fr 10% 19% 20%
    @include bp($tablet)
      grid-template-columns: 146px 1fr 13% 19% 19%
      grid-column-gap: 24px
      margin-bottom: 8px
    @include bp($tabletS)
      grid-template-columns: 146px 1fr 11% 21% 20%
      grid-column-gap: 8px
    & .d-sorter-line__item
      &:first-child
        padding-left: 40px
        @include bp($tablet)
          padding-left: 32px
      & span
        @include bp($desktopS)
          display: none
        &.short
          @include bp($desktopS)
            display: block
  &._purchase
    grid-template-columns: 168px 1fr 7% 16% 14% 11% 88px
    grid-column-gap: 24px
    @include bp($desktopS)
      grid-template-columns: 168px 1fr 6% 12% 13% 11% 88px
    @include bp($desktopXS)
      grid-template-columns: 120px 1fr 8% 10% 13% 11% 64px
      align-items: flex-start
    @include bp($tablet)
      grid-template-columns: 96px 1fr 10% 11.5% 13% 11% 72px
      grid-column-gap: 20px
    @include bp($tabletS)
      grid-template-columns: 100px 1fr 8% 10.5% 13.5% 13% 64px
      grid-column-gap: 16px
    & .d-sorter-line
      &__item
        @include bp($desktopS)
          @include font-size-lh(14,18)
        @include bp($desktopXS)
          align-items: flex-start
          text-align: left
          white-space: normal
        @include bp($tablet)
          @include font-size-lh(12,14)
          white-space: nowrap
        @include bp($tabletS)
          white-space: normal
        &:first-child
          padding-left: 40px
          @include bp($desktopXS)
            padding-left: 34px
          @include bp($tablet)
            padding-left: 16px
        & span
          @include bp($desktopS)
            display: none
          &.short
            @include bp($desktopS)
              display: block
    & .d-sorter-direction
      @include bp($desktopXS)
        margin-top: 4px
      @include bp($tablet)
        margin-top: 2px
  &._commercial
    grid-template-columns: 316px 1fr repeat(4, 13%)
    grid-column-gap: 24px
    padding-right: 40px
    @include bp($desktopXS)
      grid-template-columns: 199px repeat(5, 1fr)
    @include bp($tablet)
      grid-template-columns: 183px repeat(5, 1fr)
      grid-column-gap: 16px
      padding-right: 24px
    @include bp($tabletS)
      grid-template-columns: 160px repeat(4, 1fr)
      padding: 0 18px 0 24px
    & .d-sorter-line
      &__item
        &:first-child
          @include bp($tabletS)
            display: none
        & span
          pointer-events: none
          @include bp($tabletS)
            display: none
          &.short
            @include bp($tabletS)
              display: block

  &._carPlace, &._storeroom
    grid-column-gap: 32px
    padding: 0 32px 0 40px
    @include bp($desktopXS)
      grid-template-columns: 1fr 4% 5% 12% 7% 9% 10% 242px 60px
      grid-column-gap: 20px
      padding-left: 32px
    & .d-sorter-line
      &__item
        justify-content: center
        @include bp($desktopXS)
          @include font-size-lh(14,18)
        &:first-child
          justify-content: start
  &._carPlace
    grid-template-columns: 1fr 4% 5% 12% 7% 9% 10% 242px 104px
    @include bp($desktopXS)
      grid-template-columns: 1fr 4% 5% 12% 7% 9% 10% 242px 60px
  &._storeroom
    grid-template-columns: 1fr 4% 5% 15% 7% 9% 10% 186px 104px
    @include bp($desktopXS)
      grid-template-columns: 1fr 4% 5% 15% 7% 9% 10% 186px 60px

  &._choose
    grid-template-columns: 160px 8% 10% 7% 7% 6% 7% 1fr 1fr 88px
    grid-column-gap: 40px
    @include bp($desktopS)
      grid-column-gap: 24px
    @include bp($desktopXS)
      grid-template-columns: 144px 9% 11% 6% 6% 5% 9% 10% 1fr 64px
      grid-column-gap: 16px
    & .d-sorter-line
      &__item
        justify-content: center
        @include bp($desktopXS)
          @include font-size-lh(14,18)
        &._item1
          padding-left: 40px
        &._item1, &._item2, &._item3, &._item9, &._item9
          justify-content: start
    &._type
      grid-template-columns: 208px 11% 12% 11% 11% 1fr 250px
      @include bp($desktopXS)
        grid-template-columns: 184px 11% 13% 9% 12% 1fr 226px
      @include bps($tablet)
        display: none
      & .d-sorter-line
        &__item
          justify-content: flex-start
        &__link
          z-index: 3
    &._favorites
      grid-template-columns: 160px 6% 9% 6% 5% 4% 5% 1fr 1fr 354px
      @include bp($desktopS)
        grid-template-columns: 160px 6% 9% 6% 5% 3% 5% 1fr 1fr 48px -4px + 246px + 40px
      @include bp($desktopXS)
        grid-template-columns: 144px 7% 11% 6% 5% 4% 6% 9% 1fr 224px
  &._type-single
    grid-template-columns: 144px 9% 11% 6% 6% 5% 9% 10% 1fr 64px
    grid-column-gap: 16px
    @include bp($desktopS)
      display: none
    & .d-sorter-line
      &__item
        justify-content: center
        &._item1
          padding-left: 0
        &._item2, &._item3, &._item9
          justify-content: start