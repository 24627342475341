.d-object-navs
  display: flex
  position: relative
  z-index: 5
  align-items: center
  flex-shrink: 0
  &._main
    margin: 0 32px
    @include bp($tablet)
      position: absolute
      left: 0
      top: calc(100% + 24px)
      margin: 0
    @include bp($phone)
      display: none
    & .d-object-navs__item._more
      &:last-child
        & .d-object-navs__sub
          left: 0
          transform: none
          @include bp($tablet)
            right: 0
            left: unset
          @include bp($phone)
            left: 0
            right: unset
  &._right
    margin-left: auto
    @include bp($phone)
      margin-left: 0
      width: 100%
    & .d-object-navs__item._more
      & svg
        @include bp($tablet)
          display: none


      & .d-object-navs__sub
        min-width: 360px
        //max-height: 304px
        @include bps($tablet)
          display: none
        @include bp($phone)
          left: 0
          right: unset
          transform: none
          width: 100%
      &:nth-last-child(-n+2)
        & .d-object-navs__sub
          left: unset
          right: 0
          transform: none
          @include bp($phone)
            left: 0
            right: unset
    &._desktop
      @include bp($phone)
        display: none
    &._mobile
      display: none
      @include bp($phone)
        display: flex
        flex-direction: row
        & .d-object-navs__item + .d-object-navs__item
          margin-left: 24px
  &._tablet
    margin-left: auto
    @include bp($phone)
      display: none
    & .d-object-navs__sub
      right: 0
      top: calc(100% + 16px)
      left: unset
      transform: none
  &._mobile
    margin: 0
    display: none
    flex-direction: column
    align-items: flex-start
    @include bp($tablet)
      display: flex
    & .d-object-navs__item
      margin-left: 0
      @include font-size-lh(18,21)
      margin-bottom: 16px
      @include bp($phone)
        @include font-size-lh(16,18)
      &:last-child
        margin-bottom: 0
  &__item
    text-decoration: none
    flex-shrink: 0
    font-weight: 600
    @include font-size-lh(16,22)
    color: $pen-color
    @include transition(color)
    position: relative
    margin-right: 16px
    @include bp($tablet)
      margin-right: 24px
    @include bp($phone)
      margin-bottom: 16px
    &:last-child
      margin-right: 0
    &:hover
      color: $blue-color
      cursor: pointer
    & + .d-object-navs__item
      //margin-left: 16px
      @include bp($tablet)
        //margin-left: 24px
      @include bp($tablet)
        display: block
    &._hide
      //@include bp($desktopXS)
      //  display: none
    &._more
      display: flex
      align-items: center
      @include bp($phone)
        position: static
      & + .d-object-navs__item._more
        //margin-left: 12px
      & svg
        width: 12px
        height: 12px
        transform: rotate(90deg)
        margin-left: 4px
        fill: currentColor
      &:hover
        cursor: pointer
        & svg
          transform: rotate(270deg)
        & .d-object-navs__sub
          opacity: 1
          visibility: visible

    & span
      margin-left: 4px
      @include bp($phone)
        display: none
  &__sub
    position: absolute
    top: calc(100% + 3px)
    left: 50%
    transform: translateX(-50%)
    border-radius: 12px
    opacity: 0
    visibility: hidden
    @include transition(all)
    display: flex
    flex-direction: column
    align-items: flex-start
    background: transparent
    &._show
      cursor: pointer
      opacity: 1
      visibility: visible
      & svg
        transform: rotate(270deg)
    & .simplebar-track.simplebar-horizontal
      display: none
    & .simplebar-track.simplebar-vertical
      right: 8px !important
      top: 24px
      bottom: 24px
    & .simplebar-content
      overflow-x: hidden


    & .d-object-navs__item
      //white-space: nowrap
      margin-left: 0
      margin-bottom: 12px
      display: block
      &:last-child
        margin-bottom: 0
  &__sub-inner
    padding: 24px
    background: $white-color
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 12px
    height: 100%
    width: 100%
    max-height: 304px