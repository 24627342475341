.d-floor-slider
  position: absolute
  right: 0
  top: 0
  //top: 248px
  //height: 716px
  width: 64px
  //bottom: 80px
  z-index: 2
  display: flex
  flex-direction: column
  align-items: center
  justify-content: flex-start
  row-gap: 4px
  column-gap: 4px
  user-select: none
  @include bp($tablet)
    position: static
    //width: calc(100vw - var(--padding))
    width: 100%

  &__title
    font-size: 14px
    line-height: 20px
    color: #333
    flex-grow: 0

  &__inner
    display: flex
    flex-direction: column
    @include bp($tablet)
      display: block
      //width: calc(100vw - var(--padding))
      width: 100%
      padding: 0 24px
      overflow-y: auto
      scroll-behavior: smooth
    @include bp($phone)
      padding: 0 12px

  &__control
    height: 64px
    width: 64px
    flex-grow: 0
    flex-shrink: 0
    background: none
    border-radius: 12px
    border: 1px solid $pen-color
    color: $pen-color
    cursor: pointer
    @include transition(all)
    @include bp($tablet)
      display: none
    @include hover
      background-color: $pen-color
      color: $white-color
    & svg
      fill: currentColor

    &.swiper-button-disabled
      background: $grey5!important
      border-color: $grey30
      color: $white-color!important
      pointer-events: none !important
      & svg
        fill: $grey30

    &._next
      margin-top: 12px !important
      @include bp($phone)
        margin-top: unset !important
        margin-left: 12px !important
      & svg
        transform: rotate(90deg)

    &._prev
      margin-bottom: 12px !important
      @include bp($phone)
        margin-top: unset !important
        margin-right: 12px !important
      & svg
        transform: rotate(-90deg)

    & svg
      width: 24px
      height: 24px

  &__pagination
    display: flex
    flex-direction: column
    width: 48px
    row-gap: 4px
    @include bp($tablet)
      flex-direction: row
      column-gap: 4px
      width: fit-content!important
      justify-content: flex-start
      margin: 0 auto


  .swiper-pagination-bullet
    flex-shrink: 0
    height: 64px
    width: 64px
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    border-radius: 12px
    background-color: $white-color
    color: $pen-color
    cursor: pointer
    transition: background-color $transition, color $transition
    opacity: 1
    user-select: none
    @include hover
      background-color: $blue-color
      color: $white-color

    &._unavail
      color: $grey30
    //  pointer-events: none

    @include bp($phone)
      height: 48px
      width: 48px

  .swiper-pagination-bullet-hidden
    display: none !important

  .swiper-pagination-bullet-active, .swiper-pagination-bullet-prev
    animation-timing-function: linear
    animation-iteration-count: 1
    animation-fill-mode: forwards
    animation-duration: 0.5s
    transition: none

  .swiper-pagination-bullet-active
    animation-name: colorIn!important
    animation-delay: 0.25s

  .swiper-pagination-bullet-prev
    background-color: $blue-color
    color: $white-color
    animation-name: colorOut

  .swiper-pagination-bullet-prev._unavail
    animation-name: colorOutUnavail

  .swiper-button-lock
    display: block!important

  .swiper-pagination-lock, .swiper-pagination-bullet:only-child
    display: flex!important

@keyframes colorIn
  0%
    color: $pen-color
    background: $white-color
  100%
    color: $white-color
    background: $blue-color

@keyframes colorOut
  0%
    color: $white-color
    background: $blue-color
  100%
    color: $pen-color
    background: $white-color

@keyframes colorOutUnavail
  0%
    color: $white-color
    background: $blue-color
  100%
    color: $grey30
    background: $white-color
