.genplan-bubble
  background: $grey-light
  box-shadow: -17px -17px 45px #FFFFFF, 12px 12px 25px rgba(210, 220, 233, 0.9), inset 3px 3px 0px rgba(255, 255, 255, 0.9)
  border-radius: 30px
  padding: 32px 24px
  margin-top: 200px
  max-width: 588px
  z-index: 10
  position: relative

  &__title
    @include font-size-lh(24,29)
    color: #311F78
    font-weight: 600
  &__subtitle
    @include font-size-lh(14,16)
    color: $dark-grey
    margin-top: 4px
  &__content
    border-bottom: 1px solid #DBDFEA
    padding-bottom: 12px
    &._empty
      font-size: 20px
      line-height: 24px
      color: $brand-color
      margin-top: 24px
      border: none
      padding-bottom: 0
    &._hide
      display: none
    & p
      color: #311F78
      @include font-size-lh(14,22)
      margin-bottom: 8px
      &:last-child
        margin-bottom: 0
    & ul
      margin: 0
      padding: 0
    & li
      position: relative
      padding-left: 14px
      list-style: none
      &:before
        content: ''
        width: 6px
        height: 6px
        position: absolute
        top: 8px
        border-radius: 50%
        left: 0
        background: $blue-color
      color: #311F78
      margin-bottom: 8px
      @include font-size-lh(14,22)
      &:last-child
        margin-bottom: 0
  &__params
    margin-top: 12px
    display: flex
    &._hide
      display: none
  &__param
    display: flex
    flex-direction: column
    & + .genplan-bubble__param
      margin-left: 40px
    & samp
      color: $brand-color
      @include font-size-lh(24,28)
      font-weight: 600
      font-style: normal
      font-family: "Gilroy", Helvetica, Arial, sans-serif
    & span
      color: $dark-grey
      @include font-size-lh(14,16)
  &__flats
    margin-top: 21px
    display: flex
    flex-wrap: wrap
    flex-direction: column
    min-height: 30px
    max-height: 93px
    column-gap: 24px
    &._hide
      display: none
  &__flat
    border-bottom: 1px solid #E7E9EF
    padding-bottom: 4px
    display: flex
    justify-content: space-between
    width: calc(50% - 12px)
    & span
      color: #311F78
      @include font-size-lh(14,16)
    & samp
      color: #311F78
      @include font-size-lh(14,26)
      font-weight: 600
      font-style: normal
      font-family: "Gilroy", Helvetica, Arial, sans-serif


  &__btn
    background: #5CB7F6
    color: $white-color
    @include font-size-lh(16,19)
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding: 16px 0
    border-radius: 60px
    text-decoration: none
    transition: background-color $transition
    margin-top: 24px
    &._hide
      display: none
    &:hover
      background: $brand-color

@media all and (max-height: 919px)
  .tippy-box[data-theme="plans"]
    .genplan-bubble
      margin-top: 60px
      &__wrapper
        max-height: 500px
        overflow-y: auto
